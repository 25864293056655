import React from "react";
import { Modal, Icon, Button } from "semantic-ui-react";
import AlertShare from "../AlertShare/AlertShare";

const AlertModal = ({ alert, onUpdate }) => {
  const [open, setOpen] = React.useState(false);
  const handleCloseModal = () => {
    setOpen(false);
  };
  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      centered={false}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <div>
          <Button style={{ borderRadius: 0 }} basic color="yellow">
            <Icon color="black" name="edit" />
          </Button>
        </div>
      }
    >
      <AlertShare
        alert={alert}
        onUpdate={onUpdate}
        // alertOption={alertOption}
        // onUpdateOption={onUpdateOption}
        handleCloseModal={handleCloseModal}
      />
    </Modal>
  );
};

export default AlertModal;
