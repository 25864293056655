import React, { useContext, useState, useEffect } from "react";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import "./Alert.css";
import {
  Popup,
  Button,
  Icon,
  Image,
  Card,
  Modal,
  Input,
} from "semantic-ui-react";
import noAvatar from "../../../assets/img/noAvatar.png";
import AlertModalUpdate from "../../Modal/AlertUpdateModal";
import { SearchTickerContext } from "../../../Context/SearchTickerContext";
import moment from "moment";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import UserProfile from "../../UserProfile/UserProfile";
moment().format();

const AlertPositions = ({
  alert,
  onDelete,
  onLike,
  onDisLike,
  onUpdate,
  onPlayer,
  handleCloseModal,
}) => {
  const { user } = useAuthContext();
  const [open, setOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const { handleTickerClick } = useContext(SearchTickerContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shareableLink, setShareableLink] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const currentDomain = window.location.origin;
  const [openUserProfile, setOpenUserProfile] = useState(false);
  const onShare = () => {
    const link = `${currentDomain}/alert/${alert._id}`;
    setShareableLink(link);
    setIsCopied(false);
    setIsModalOpen(true);
  };

  const copyToClipboard = () => {
    const input = document.getElementById("shareableLink");
    input.select();
    document.execCommand("copy");
    setIsCopied(true);
  };
  const shareOnFacebook = () => {
    window.FB.ui({
      method: "share",
      href: shareableLink,
    });
  };
  const shareOnTwitter = () => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      `${alert.short_long === "LONG" ? "BUY LONG" : "SELL SHORT"} #${
        alert.ticker
      } ${alert.entry} 
💰Entry: $${alert.entry} 
🛑StopLoss: $${alert.stoploss} 
🎯Target: $${alert.target}
👉Reason: @SMARTTRADINGHUB
${shareableLink}
$${alert.ticker} #sharealert
`
    )}`;
    window.open(twitterShareUrl, "_blank");
  };

  const fetchUser = async (userId) => {
    try {
      const response = await fetch(`api/user/${userId}`);
      const postUser = await response.json();
      return postUser;
    } catch (error) {
      console.error(error);
    }
  };

  // Hook to fetch the user object associated with the post userId
  const [postUser, setPostUser] = useState(null);
  useEffect(() => {
    const getUser = async () => {
      const user = await fetchUser(alert.userId);
      setPostUser(user);
    };
    getUser();
  }, [alert.userId]);
  return (
    <>
      {user && (
        <Card>
          <Card.Content>
            {postUser && (
              <>
                {postUser.avatarPicture === "noAvatar.png" ? (
                  <Image
                    avatar
                    floated="right"
                    size="large"
                    src={noAvatar}
                    alt="Avatar"
                  />
                ) : (
                  <Image
                    avatar
                    floated="right"
                    size="large"
                    src={postUser.avatarPicture}
                    alt="Avatar"
                  />
                )}
              </>
            )}
            <Card.Header>
              {alert.short_long === "LONG" ? (
                <>
                  {alert.status === "OPEN" ? (
                    <>
                      <Popup
                        content="Alert Open"
                        position="top center"
                        trigger={<Icon name="lock open" color="green" />}
                      />
                      <span onClick={() => handleTickerClick(alert.ticker)}>
                        {" "}
                        {alert.ticker}{" "}
                        {alert.currentPrice && `(${alert.currentPrice})`}
                      </span>
                    </>
                  ) : (
                    <>
                      <Popup
                        content="Alert Closed"
                        position="top center"
                        trigger={<Icon name="lock" color="red" />}
                      />
                      <span onClick={() => handleTickerClick(alert.ticker)}>
                        {" "}
                        {alert.ticker}{" "}
                        {alert.currentPrice && `(${alert.currentPrice})`}
                      </span>
                      <Icon color="red" name="checkmark" />
                    </>
                  )}
                </>
              ) : (
                <>
                  {alert.status === "OPEN" ? (
                    <>
                      <Popup
                        content="Alert Open"
                        position="top center"
                        trigger={<Icon name="lock open" color="green" />}
                      />
                      <span onClick={() => handleTickerClick(alert.ticker)}>
                        {" "}
                        {alert.ticker}
                        {alert.currentPrice && `(${alert.currentPrice})`}
                      </span>
                    </>
                  ) : (
                    <>
                      <Popup
                        content="Alert Closed"
                        position="top center"
                        trigger={<Icon name="lock" color="red" />}
                      />
                      <span onClick={() => handleTickerClick(alert.ticker)}>
                        {" "}
                        {alert.ticker}
                        {alert.currentPrice && `(${alert.currentPrice})`}
                      </span>
                      <Icon color="red" name="checkmark" />
                    </>
                  )}
                </>
              )}
            </Card.Header>

            <Card.Meta>
              by{" "}
              <Modal
                closeIcon
                dimmer="blurring"
                size="large"
                onClose={() => setOpenUserProfile(false)}
                onOpen={() => setOpenUserProfile(true)}
                open={openUserProfile}
                trigger={<span>{alert.username}</span>}
              >
                <Modal.Content>
                  <UserProfile postUserId={alert.userId} />
                </Modal.Content>
              </Modal>
            </Card.Meta>
            <Card.Description>
              {alert.short_long === "LONG" && (
                <>
                  <Icon
                    color="green"
                    name="hand point right"
                    onClick={() => setIsShow((prevValue) => !prevValue)}
                  />
                  <strong onClick={() => setIsShow((prevValue) => !prevValue)}>
                    <span className="long">BUY {alert.short_long} </span>
                  </strong>
                  {alert.ticker}
                  <strong onClick={() => setIsShow((prevValue) => !prevValue)}>
                    {" "}
                    {alert.entry}
                  </strong>
                </>
              )}
              {alert.short_long === "SHORT" && (
                <>
                  <Icon
                    color="red"
                    name="hand point right"
                    onClick={() => setIsShow((prevValue) => !prevValue)}
                  />
                  <strong onClick={() => setIsShow((prevValue) => !prevValue)}>
                    <span className="short">SELL {alert.short_long} </span>
                  </strong>
                  {alert.ticker}
                  <strong onClick={() => setIsShow((prevValue) => !prevValue)}>
                    {" "}
                    {alert.entry}
                  </strong>
                </>
              )}
              {isShow && (
                <>
                  <br />
                  <Icon color="yellow" name="play" />
                  <strong>Entry: </strong>
                  <strong>${alert.entry}</strong>
                  <br />
                  <Icon color="green" name="target" />
                  <strong>Target: </strong>
                  <strong>${alert.target}</strong>
                  <br />
                  <Icon color="red" name="stop circle" />
                  <strong>Stoploss: </strong>
                  <strong>${alert.stoploss}</strong>
                  <br />
                  {(alert.analyst || alert.chart) && (
                    <>
                      <Popup
                        size="large"
                        wide="very"
                        content={
                          <>
                            {alert.analyst && (
                              <>
                                <strong>Analyst:</strong> {alert.analyst}
                                <br />
                              </>
                            )}
                          </>
                        }
                        trigger={
                          <div>
                            <Icon name="comment" />
                            <strong>Analyst</strong>
                          </div>
                        }
                      />
                      <Modal
                        closeIcon
                        dimmer="blurring"
                        size="fullscreen"
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        open={open}
                        trigger={
                          <div>
                            <Icon color="orange" name="chart bar" />
                            <strong>Chart: </strong>
                            <Image size="mini" src={alert.chart} rounded />
                          </div>
                        }
                      >
                        <TransformWrapper>
                          <TransformComponent>
                            <Modal.Description>
                              Analyst: {alert.analyst}
                            </Modal.Description>
                            <Image
                              src={alert.chart}
                              alt={`Chart update by ${alert.username} ${moment
                                .utc(alert.createdAt)
                                .format("h:mm:ss A MMM, D YYYY")}`}
                            />
                            <Modal.Header>{`Alert by ${alert.username} ${moment
                              .utc(alert.createdAt)
                              .startOf("minutes")
                              .fromNow()}`}</Modal.Header>
                          </TransformComponent>
                        </TransformWrapper>
                      </Modal>
                    </>
                  )}

                  <Icon
                    color="teal"
                    name="users"
                    // onClick={() => onPlayer(alert._id)}
                  />
                  <strong>Players: {alert.player.length} | </strong>
                  <Icon
                    color="green"
                    name="thumbs up"
                    // onClick={() => onLike(alert._id)}
                  />
                  <strong>Like: {alert.like.length} </strong>
                  {/* <Icon
                    color="red"
                    name="thumbs down"
                    onClick={() => onDisLike(alert._id)}
                  />
                  <strong>DisLike: {alert.disLike.length}</strong> */}
                  <br />
                  <Card.Meta>
                    <Icon color="purple" name="calendar alternate outline" />{" "}
                    <strong>Alert Date:</strong>{" "}
                    {moment.utc(alert.createdAt).format("MMM, DD YY | h:mm A")}
                  </Card.Meta>
                  {alert.currentPrice &&
                    !alert.closed_price &&
                    alert.short_long === "LONG" && (
                      <>
                        <strong>
                          <Icon color="red" name="money" />
                          Gain/Loss:{" "}
                          {(
                            ((alert.currentPrice - alert.entry) / alert.entry) *
                            100
                          ).toFixed(2)}
                          %
                        </strong>
                      </>
                    )}
                  {alert.currentPrice &&
                    !alert.closed_price &&
                    alert.short_long === "SHORT" && (
                      <>
                        <strong>
                          <Icon color="red" name="money" />
                          Gain/Loss:{" "}
                          {(
                            ((alert.entry - alert.currentPrice) /
                              alert.currentPrice) *
                            100
                          ).toFixed(2)}
                          %
                        </strong>
                      </>
                    )}

                  {alert.closed_price &&
                    alert.status === "CLOSED" &&
                    alert.short_long === "LONG" && (
                      <>
                        <hr />
                        <Icon color="red" name="checkmark" />
                        <strong>Closed Price: </strong>${alert.closed_price}
                        <br />
                        <strong>
                          <Icon color="red" name="money" />
                          Gain/Loss:{" "}
                          {(
                            ((alert.closed_price - alert.entry) / alert.entry) *
                            100
                          ).toFixed(2)}
                          %
                        </strong>
                        <br />
                        <Card.Meta>
                          <Icon
                            color="purple"
                            name="calendar alternate outline"
                          />{" "}
                          <strong>Closed Date:</strong>{" "}
                          {moment
                            .utc(alert.updatedAt)
                            .format("MMM/D/YY | h:mm:ss A")}
                        </Card.Meta>
                      </>
                    )}
                  {alert.closed_price &&
                    alert.status === "CLOSED" &&
                    alert.short_long === "SHORT" && (
                      <>
                        <hr />
                        <Icon color="red" name="checkmark" />
                        <strong>Closed Price: </strong>${alert.closed_price}
                        <br />
                        <strong>
                          <Icon color="red" name="money" />
                          Gain/Loss:{" "}
                          {(
                            ((alert.entry - alert.closed_price) / alert.entry) *
                            100
                          ).toFixed(2)}
                          %
                        </strong>
                        <br />
                        <Card.Meta>
                          <Icon
                            color="purple"
                            name="calendar alternate outline"
                          />{" "}
                          <strong>Closed Date:</strong>{" "}
                          {moment
                            .utc(alert.updatedAt)
                            .format("MMM, DD YY | h:mm A")}
                        </Card.Meta>
                      </>
                    )}
                </>
              )}
            </Card.Description>
          </Card.Content>
        </Card>
      )}
    </>
  );
};

export default AlertPositions;
