import React, { createContext, useContext, useState, useEffect } from "react";
import META from "../Constants/en_US.json";

const MetaDataContext = createContext();

export const MetaDataProvider = ({ children }) => {
  const [locale, setLocale] = useState(
    localStorage.getItem("locale") || "en_US"
  );
  const [metaData, setMetaData] = useState(META);

  const loadLocaleData = async (locale) => {
    try {
      const localeData = await import(`../Constants/${locale}.json`);
      return localeData;
    } catch (error) {
      console.error(`Error loading locale data for ${locale}: ${error}`);
    }
  };

  const handleLanguageChange = async (newLocale) => {
    // Save the newLocale to localStorage
    localStorage.setItem("locale", newLocale);
    const data = await loadLocaleData(newLocale);
    if (data) {
      setLocale(newLocale);
      setMetaData(data);
    }
  };

  // useEffect to load default locale data on component mount
  useEffect(() => {
    loadLocaleData(locale).then((data) => {
      if (data) {
        setMetaData(data);
      }
    });
  }, [locale]);

  return (
    <MetaDataContext.Provider value={{ metaData, handleLanguageChange }}>
      {children}
    </MetaDataContext.Provider>
  );
};

export const useMetaData = () => {
  return useContext(MetaDataContext);
};
