import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Button, Icon, Divider, Table } from "semantic-ui-react";
import { useTickerContext } from "../../Hooks/useTickerContext";
import { SearchTickerContext } from "../../Context/SearchTickerContext";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useMetaData } from "../../Context/MetaDataContext";
import { Helmet } from "react-helmet";
import moment from "moment";
import "./Insiders.css";
const Insiders = () => {
  const { user } = useAuthContext();
  const { metaData } = useMetaData();
  const { insidertickers, dispatch } = useTickerContext();
  const [error, setError] = useState(null);
  const { handleTickerClick } = useContext(SearchTickerContext);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (event, data) => {
    const { activePage } = data;
    setCurrentPage(activePage);
  };

  const handleLoadMore = () => {
    if (currentPage === totalPages) {
      setCurrentPage(1); // Reset to the first page if currently on the last page
    } else if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1); // Increment the current page by 1
    }
  };
  const handleGoBack = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1); // Decrement the current page by 1
    }
  };
  // get all the new update ticker
  // Fetch insider tickers based on the current page
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/ticker/insider?page=${currentPage}`);
        if (response.ok) {
          const json = await response.json();
          dispatch({ type: "FETCH_TICKER_INSIDER", payload: json.tickers });
          setTotalPages(json.totalPages);
        } else {
          throw new Error("Failed to fetch insider tickers");
        }
      } catch (error) {
        setError(error.message);
      }
    };
    fetchData();
  }, [dispatch, currentPage]);

  return (
    <>
      {/* <Helmet>
        <title>Insider Transaction - Smart Trading Hub</title>
        <meta
          name="description"
          content="Explore insider transactions in the stock market. Gain insights into trading activities of company insiders. Smart Trading Hub provides valuable data for analyzing insider buying and selling."
        />
        <meta
          name="keywords"
          content="insider transactions, stock market, trading activities, data analysis"
        />
        <meta name="author" content="Smart Trading Hub" />
      </Helmet> */}
      <div className="Tickers">
        <Divider horizontal>
          {user &&
            (user.user.role === 1 ||
              user.user.role === 2 ||
              user.user.role === 3 ||
              user.user.role === 4 ||
              user.user.role === 5 ||
              user.user.role === 6 ||
              user.user.role === 10) && (
              <Icon name="left arrow" onClick={handleGoBack} />
            )}
          <Link to="/insider">
            <span className="insider">
              {metaData.CATEGORY.LATEST_INSIDER_TRANSACTION}
            </span>
          </Link>{" "}
          {user &&
            (user.user.role === 1 ||
              user.user.role === 2 ||
              user.user.role === 3 ||
              user.user.role === 4 ||
              user.user.role === 5 ||
              user.user.role === 6 ||
              user.user.role === 10) && (
              <Icon name="right arrow" onClick={handleLoadMore} />
            )}
        </Divider>

        <div style={{ overflowX: "auto" }}>
          <Table color="blue" singleLine unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Ticker</Table.HeaderCell>
                <Table.HeaderCell>Insider</Table.HeaderCell>
                <Table.HeaderCell>Code</Table.HeaderCell>
                <Table.HeaderCell>#Shares</Table.HeaderCell>
                <Table.HeaderCell>Ave Cost($)</Table.HeaderCell>
                <Table.HeaderCell>Total Value($)</Table.HeaderCell>
                <Table.HeaderCell>#Share Own</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {insidertickers &&
                insidertickers
                  .flatMap((ticker) =>
                    ticker.insider.map((insider) => ({
                      ...insider,
                      ticker: ticker.ticker,
                    }))
                  )
                  .sort(
                    (a, b) =>
                      new Date(b.insider_fill_date) -
                      new Date(a.insider_fill_date)
                  )
                  .map((insider, index) => (
                    <Table.Row key={index}>
                      <Table.Cell
                        onClick={() => handleTickerClick(insider.ticker)}
                      >
                        {insider.ticker}
                      </Table.Cell>
                      <Table.Cell>
                        <a
                          href={insider.linkToFilingDetails}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {insider.relationship}
                        </a>
                      </Table.Cell>
                      <Table.Cell>
                        <span
                          style={{
                            color:
                              insider.transactionCode === "S"
                                ? "red"
                                : insider.transactionCode === "P"
                                ? "green"
                                : "black",
                          }}
                        >
                          {insider.transactionCode}
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        <span
                          style={{
                            color:
                              insider.sum_transactionShares > 1000000
                                ? "red"
                                : insider.sum_transactionShares > 500000
                                ? "blue"
                                : "inherit",
                          }}
                        >
                          {insider.sum_transactionShares.toLocaleString()}
                        </span>
                      </Table.Cell>
                      <Table.Cell>{insider.ave_pricePerShare}</Table.Cell>
                      <Table.Cell>
                        <span
                          style={{
                            color:
                              insider.total_value > 1000000
                                ? "red"
                                : insider.total_value > 500000
                                ? "blue"
                                : "inherit",
                          }}
                        >
                          {insider.total_value.toLocaleString()}
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        {insider.num_shares_own.toLocaleString()}
                      </Table.Cell>
                      <Table.Cell>
                        {moment(insider.insider_fill_date).format("MMM, D Y")}
                      </Table.Cell>
                    </Table.Row>
                  ))}
            </Table.Body>
          </Table>
        </div>
        {!user && (
          <Divider horizontal>
            <Link to="/insider">
              <span className="insider">
                {metaData.PERMIT.MEMBER_TO_VIEW_MORE}
              </span>
            </Link>
          </Divider>
        )}
      </div>
    </>
  );
};

export default Insiders;
