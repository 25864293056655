import React from "react";
import { Modal, Icon } from "semantic-ui-react";
import ProfileInfo from "../Profile/ProfileInfo/ProfileInfo";

const EditProfileModal = () => {
  const [open, setOpen] = React.useState(false);
  return (
    <Modal
      onClose={() => setOpen(false)}
      // centered={false}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Icon name="edit" />}
    >
      <ProfileInfo />
    </Modal>
  );
};

export default EditProfileModal;
