import React, { createContext, useReducer } from "react";
export const JournalContext = createContext();

export const JournalReducer = (state, action) => {
  let cloneJournals;
  switch (action.type) {
    case "FETCH_JOURNAL":
      return {
        journals: action.payload,
      };
    case "CREATE_JOURNAL":
      return {
        journals: [action.payload, ...state.journals],
      };
    case "JOURNAL_UPDATE":
      cloneJournals = JSON.parse(JSON.stringify(state.journals));
      return {
        journals: cloneJournals.map((journal) =>
          journal._id === action.payload.id ? action.payload.data : journal
        ),
      };
    case "JOURNAL_LIKE":
      cloneJournals = JSON.parse(JSON.stringify(state.journals));
      cloneJournals.forEach((journal) => {
        if (journal._id === action.payload.id) {
          if (!journal.like.includes(action.payload.user)) {
            // if not exist then add in
            journal.like.push(action.payload.user);
          } else {
            // If already exist then remove out
            journal.like.splice(journal.like.indexOf(action.payload.user));
          }
        }
      });
      return {
        journals: cloneJournals,
      };
    case "JOURNAL_DISLIKE":
      cloneJournals = JSON.parse(JSON.stringify(state.journals));
      cloneJournals.forEach((journal) => {
        if (journal._id === action.payload.id) {
          if (!journal.disLike.includes(action.payload.user)) {
            // if not exist then add in
            journal.disLike.push(action.payload.user);
          } else {
            // If already exist then remove out
            journal.disLike.splice(
              journal.disLike.indexOf(action.payload.user)
            );
          }
        }
      });
      return {
        journals: cloneJournals,
      };

    case "DELETE_JOURNAL":
      return {
        journals: state.journals.filter(
          (journal) => journal._id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export const JournalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(JournalReducer, {
    journals: null,
  });
  return (
    <JournalContext.Provider value={{ ...state, dispatch }}>
      {children}
    </JournalContext.Provider>
  );
};
