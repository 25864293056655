import React, { useState, useEffect } from "react";
import "./Alerts.css";
import Alert from "./Alert/Alert";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { Divider, Card } from "semantic-ui-react";
import { useAlertsContext } from "../../Hooks/useAlertsContext";
import moment from "moment";
import AlertClosed from "./Alert/AlertClosed";
function AlertsClosed() {
  const { user } = useAuthContext();
  const [error, setError] = useState(null);
  const { alertsclosed, dispatch } = useAlertsContext();
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const handleCloseModal = () => {
    setOpen(false);
  };
  // Get the Alert data from backend
  useEffect(() => {
    const fetchData = async (page) => {
      try {
        const response = await fetch(`/api/alert/closed?page=${page}`);
        if (response.ok) {
          const json = await response.json();
          // console.log(json);
          setTotalPages(json.totalPages);
          dispatch({ type: "FETCH_ALERT_CLOSED", payload: json.alertsclosed });
        } else {
          return;
          // throw new Error("Failed to fetch alerts");
        }
      } catch (error) {
        setError(error.message);
      }
    };
    fetchData(currentPage);
  }, [dispatch, currentPage]);

  const handleLoadMore = () => {
    if (currentPage === totalPages) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };
  // Like Handler
  const handleLike = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/alert/" + eventId + "/like", {
      method: "PUT",
      body: JSON.stringify({ userId: user.user._id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "ALERT_LIKE",
        payload: { id: eventId, user: user.user._id },
      });
    } else {
      console.error("error");
    }
  };
  // DisLike Handler
  const handleDisLike = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/alert/" + eventId + "/disLike", {
      method: "PUT",
      body: JSON.stringify({ userId: user.user._id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "ALERT_DISLIKE",
        payload: { id: eventId, user: user.user._id },
      });
    } else {
      console.error("error");
    }
  };
  // HandlePlayer
  const handlePlayer = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/alert/" + eventId + "/player", {
      method: "PUT",
      body: JSON.stringify({ userId: user.user._id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "ALERT_PLAYER",
        payload: { id: eventId, user: user.user._id },
      });
    } else {
      console.error("error");
    }
  };
  // handle Update
  const handleUpdate = async (eventId, alert) => {
    if (!user) {
      return;
    }
    dispatch({
      type: "ALERT_UPDATE",
      payload: { id: eventId, data: alert },
    });
  };
  // handle Delete
  const handleDelete = async (eventId) => {
    try {
      if (!user) {
        throw new Error("Please login");
      }
      const response = await fetch(`/api/alert/${eventId}`, {
        method: "DELETE",
        body: JSON.stringify({
          userId: user.user._id,
          userRole: user.user.role,
          isAdmin: user.user.isAdmin,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        dispatch({ type: "DELETE_ALERT", payload: json });
      } else {
        throw new Error("Failed to delete alert");
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  return (
    <>
      {error && <div>{error}</div>}
      {alertsclosed && (
        <div className="Alerts">
          <Divider horizontal onClick={handleLoadMore}>
            <span className="share_alerts">SHARE ALERTS CLOSED THIS MONTH</span>
          </Divider>
          <Card.Group centered>
            {alertsclosed &&
              alertsclosed.map((alert) => (
                <AlertClosed
                  key={alert._id}
                  alert={alert}
                  onPlayer={handlePlayer}
                  onUpdate={handleUpdate}
                  onDelete={handleDelete}
                  onLike={handleLike}
                  onDisLike={handleDisLike}
                  handleCloseModal={handleCloseModal}
                />
              ))}
          </Card.Group>
        </div>
      )}
    </>
  );
}

export default AlertsClosed;
