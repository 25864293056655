import React, { createContext, useReducer } from 'react';
// import { useAuthContext } from "../Hooks/useAuthContext";
export const CalendarContext = createContext();

export const calendarReducer = (state, action) => {
	let cloneCalendars;
	switch (action.type) {
		case 'FETCH_CALENDAR':
			return {
				calendars: action.payload,
			};
		case 'CREATE_CALENDAR':
			return {
				calendars: [action.payload, ...state.calendars],
			};
		case 'CALENDAR_UPDATE':
			cloneCalendars = JSON.parse(JSON.stringify(state.calendars));
			return {
				calendars: cloneCalendars.map((calendar) =>
					calendar._id === action.payload.id ? action.payload.data : calendar
				),
			};
		case 'DELETE_CALENDAR':
			return {
				calendars: state.calendars.filter(
					(calendar) => calendar._id !== action.payload
				),
			};
		default:
			return state;
	}
};

export const CalendarContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(calendarReducer, {
		calendars: null,
	});
	// console.log("calendarContext state:", state);
	return (
		<CalendarContext.Provider value={{ ...state, dispatch }}>
			{children}
		</CalendarContext.Provider>
	);
};
