import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Calendars.css";
import { Pagination, Divider, Label, Table, Card } from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import Calendar from "../Calendars/Calendar/Calendar";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useCalendarContext } from "../../Hooks/useCalendarContext";
import { Helmet } from "react-helmet";
import { useMetaData } from "../../Context/MetaDataContext";
import moment from "moment";
moment().format();

const Calendars = () => {
  const { calendars, dispatch } = useCalendarContext();
  const [error, setError] = useState(null);
  const { user } = useAuthContext();
  const today = new Date();
  const { metaData } = useMetaData();
  // this week
  const startOfThisWeek = new Date(today);
  startOfThisWeek.setDate(today.getDate() - today.getDay());
  const endOfThisWeek = new Date(startOfThisWeek);
  endOfThisWeek.setDate(startOfThisWeek.getDate() + 7);
  // next week
  const startOfNextWeek = new Date(endOfThisWeek);
  startOfNextWeek.setDate(endOfThisWeek.getDate());
  const endOfNextWeek = new Date(startOfNextWeek);
  endOfNextWeek.setDate(startOfNextWeek.getDate() + 7);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/calendar/byWeek`);
        if (response.ok) {
          const json = await response.json();
          dispatch({ type: "FETCH_CALENDAR", payload: json });
        } else {
          throw new Error("Failed to fetch alerts");
        }
      } catch (error) {
        setError(error.message);
      }
    };
    fetchData();
  }, [dispatch]);

  const handleUpdate = async (eventId, calendar) => {
    if (!user) {
      return;
    }
    dispatch({
      type: "CALENDAR_UPDATE",
      payload: { id: eventId, data: calendar },
    });
  };

  const handleDelete = async (eventId) => {
    try {
      if (!user) {
        throw new Error("Please login");
      }
      const response = await fetch(`/api/calendar/${eventId}`, {
        method: "DELETE",
        body: JSON.stringify({
          userId: user.user._id,
          userRole: user.user.role,
          isAdmin: user.user.isAdmin,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        dispatch({ type: "DELETE_CALENDAR", payload: json });
      } else {
        throw new Error("Failed to delete calendar");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className="Calendars">
      {/* <Helmet>
        <title>Market Calendar - Smart Trading Hub</title>
        <meta
          name="description"
          content="Stay updated with our market calendar for trading events, earnings reports, and more. Smart Trading Hub provides valuable insights for successful trading."
        />
        <meta
          name="keywords"
          content="market calendar, trading events, earnings reports, insights"
        />
        <meta name="author" content="Smart Trading Hub" />
      </Helmet> */}
      {error && <div>{error}</div>}
      {calendars && (
        <>
          <Divider horizontal>
            <Link to="/calendar">
              <span className="Calendars_Page">
                {metaData.CATEGORY.CALENDAR}
              </span>
            </Link>
          </Divider>
          <div className="">
            {[1, 2, 3, 4, 5].map((day) => (
              <React.Fragment key={day}>
                <h3>{moment().day(day).format("dddd")}</h3>
                <Card.Group
                  key={day}
                  style={{
                    marginTop: "-20px",
                    marginBottom: "-25px",
                  }}
                >
                  {calendars
                    .filter(
                      (calendar) =>
                        moment(calendar.date).day() === day &&
                        moment(calendar.date).isBetween(
                          startOfThisWeek,
                          endOfThisWeek
                        )
                    )
                    .map((calendar) => (
                      <Calendar
                        calendar={calendar}
                        key={calendar._id}
                        onUpdate={handleUpdate}
                        onDelete={handleDelete}
                      />
                    ))}
                  {!calendars.some(
                    (calendar) =>
                      moment(calendar.date).day() === day &&
                      moment(calendar.date).isBetween(
                        startOfThisWeek,
                        endOfThisWeek
                      )
                  ) && (
                    <Card
                      style={{
                        marginLeft: "20px",
                      }}
                    >
                      <Card.Content>
                        <Label color="orange" ribbon>
                          <strong>{metaData.CATEGORY.NO_EVENT}</strong>
                        </Label>
                      </Card.Content>
                    </Card>
                  )}
                </Card.Group>
              </React.Fragment>
            ))}
          </div>
        </>
      )}
      {user &&
        (user.user.role === 1 ||
          user.user.role === 2 ||
          user.user.role === 3 ||
          user.user.role === 4 ||
          user.user.role === 5 ||
          user.user.role === 6 ||
          user.user.role === 10) &&
        calendars && (
          <>
            <Divider horizontal>
              <span className="Calendars_Page">
                {metaData.CATEGORY.NEXT_WEEEK_CALENDAR}
              </span>
            </Divider>
            <div className="">
              {[1, 2, 3, 4, 5].map((day) => (
                <React.Fragment key={day}>
                  <h3>{moment().day(day).format("dddd")}</h3>
                  <Card.Group
                    key={day}
                    style={{
                      marginTop: "-20px",
                      marginBottom: "-25px",
                    }}
                  >
                    {calendars
                      .filter(
                        (calendar) =>
                          moment(calendar.date).day() === day &&
                          moment(calendar.date).isBetween(
                            startOfNextWeek,
                            endOfNextWeek
                          )
                      )
                      .map((calendar) => (
                        <Calendar
                          calendar={calendar}
                          key={calendar._id}
                          onUpdate={handleUpdate}
                          onDelete={handleDelete}
                        />
                      ))}
                    {!calendars.some(
                      (calendar) =>
                        moment(calendar.date).day() === day &&
                        moment(calendar.date).isBetween(
                          startOfNextWeek,
                          endOfNextWeek
                        )
                    ) && (
                      <Card
                        style={{
                          marginLeft: "20px",
                        }}
                      >
                        <Card.Content>
                          <Label color="orange" ribbon>
                            <strong>{metaData.CATEGORY.NO_EVENT}</strong>
                          </Label>
                        </Card.Content>
                      </Card>
                    )}
                  </Card.Group>
                </React.Fragment>
              ))}
            </div>
          </>
        )}
    </div>
  );
};

export default Calendars;
