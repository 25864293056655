import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
export const useLogin = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { dispatch } = useAuthContext();
  const logout = () => {
    // Clear the user from local storage
    localStorage.removeItem("user");

    // Update the auth context
    dispatch({ type: "LOGOUT" });
  };
  const login = async (username, password) => {
    setIsLoading(true);
    setError(null);

    const response = await fetch("/api/user/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username, password }),
    });
    const userData = await response.json();
    // console.log(userData);
    if (!response.ok) {
      setIsLoading(false);
      setError(userData);
    }
    if (response.ok) {
      // save the user to local storage
      localStorage.setItem("user", JSON.stringify(userData));

      // update the auth context
      dispatch({ type: "LOGIN", payload: userData });
      // Set timeout to automatically log out after 3 hours
      // setTimeout(logout, 10000);
      // update loading state
      setIsLoading(false);
    }
  };

  return { login, isLoading, error, setError };
};
