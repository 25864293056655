import { useContext } from "react";
import { BreakingNewContext } from "../Context/BreakingNewContext";

export const useBreakingNewContext = () => {
  const context = useContext(BreakingNewContext);

  if (!context) {
    throw Error(
      "useBreakingNewsContext must be used inside an BreakingNewsContextProvider"
    );
  }

  return context;
};
