import React, { useState } from "react";
import { Modal, Icon, Header } from "semantic-ui-react";
import AlertShare from "../AlertShare/AlertShare";

const AlertModal = () => {
  const [open, setOpen] = useState(false);
  const handleCloseModal = () => {
    setOpen(false);
  };
  return (
    <Modal
      onClose={() => setOpen(false)}
      // centered={false}
      closeIcon
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <div>
          <Icon name="alarm" size="big" style={{ color: "var(--alert)" }} />{" "}
          Alert
        </div>
      }
    >
      <AlertShare handleCloseModal={handleCloseModal} />
    </Modal>
  );
};

export default AlertModal;
