import React from "react";
import { Progress, Divider, Segment, Grid } from "semantic-ui-react";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useMetaData } from "../../Context/MetaDataContext";
import useFetch from "../../Hooks/FetchData";
import moment from "moment";
import { Link } from "react-router-dom";
// moment().format();
import "./Sentiment.css";
const Sentiment = () => {
  const { user } = useAuthContext();
  const { data: sentiments, error } = useFetch("/api/sentiment/one");
  const { metaData } = useMetaData();
  // console.log(sentiments);
  return (
    <div className="Sentiments">
      <div className="">
        <Divider horizontal>
          <Link to="/market-index">
            <span className="SentimentTitle">
              {metaData.CATEGORY.MARKET_SENTIMENTS_REPORT}
            </span>
          </Link>
        </Divider>
      </div>
      <div className="Sentiments">
        {sentiments &&
          sentiments.map((sentiment) => (
            <div>
              <div className="progress-bar">
                <div
                  className="progress bullish"
                  style={{ width: `${sentiment.bullish}%` }}
                >
                  {metaData.MARKET.BULL} {sentiment.bullish}%
                </div>
                <div
                  className="progress neutral"
                  style={{ width: `${sentiment.neutral}%` }}
                >
                  {metaData.MARKET.NEUTRAL} {sentiment.neutral}%
                </div>
                <div
                  className="progress bearish"
                  style={{ width: `${sentiment.bearish}%` }}
                >
                  {metaData.MARKET.BEAR} {sentiment.bearish}%
                </div>
              </div>
              <div className="Date">
                {moment(sentiment.date).format("MMM, D YYYY")}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Sentiment;

// import React from "react";
// import { useAuthContext } from "../../Hooks/useAuthContext";
// import useFetch from "../../Hooks/FetchData";
// import "./Sentiment.css";

// const Sentiment = () => {
//   const { user } = useAuthContext();
//   const { data: sentiments, error } = useFetch("/api/sentiment");

//   // Function to calculate the percentage of each sentiment
//   const calculatePercentage = (sentiment) => {
//     const total = sentiment.bullish + sentiment.neutral + sentiment.bearish;
//     const bullishPercentage = (sentiment.bullish / total) * 100;
//     const neutralPercentage = (sentiment.neutral / total) * 100;
//     const bearishPercentage = (sentiment.bearish / total) * 100;
//     return { bullishPercentage, neutralPercentage, bearishPercentage, total };
//   };

//   return (
//     <>
//       <div className="Sentiments">
//         {sentiments &&
//           sentiments.map((sentiment, index) => {
//             const {
//               bullishPercentage,
//               neutralPercentage,
//               bearishPercentage,
//               total,
//             } = calculatePercentage(sentiment);
//             return (
//               <div key={index} className="SentimentsGroup">
//                 <div className="progress-bar">
//                   <div
//                     className="progress bullish"
//                     style={{ width: `${sentiment.bullish}%` }}
//                   >
//                     bullish
//                   </div>
//                   <div
//                     className="progress neutral"
//                     style={{ width: `${sentiment.neutral}%` }}
//                   ></div>
//                   <div
//                     className="progress bearish"
//                     style={{ width: `${sentiment.bearish}%` }}
//                   ></div>
//                 </div>
//                 <div>Total: {total}%</div>
//               </div>
//             );
//           })}
//       </div>
//     </>
//   );
// };

// export default Sentiment;
