import React, { useState, useEffect } from "react";
import "../../Posts/Posts.css";
import { Divider, Card } from "semantic-ui-react";
import Alert from "../../Alerts/Alert/Alert";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import { useAlertsContext } from "../../../Hooks/useAlertsContext";
import { useMetaData } from "../../../Context/MetaDataContext";
import axios from "axios";
import YourAlert from "../../Alerts/Alert/YourAlert";
const ProfileAlerts = () => {
  const { profileAlerts, dispatch } = useAlertsContext();
  const [error, setError] = useState(null);
  const { metaData } = useMetaData();
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const { user } = useAuthContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/alert/${user.user._id}/search`);
        if (response.ok) {
          const json = await response.json();
          dispatch({ type: "FETCH_PROFILE_ALERT", payload: json });
        } else {
          console.error("error");
          // return;
          // throw new Error("Failed to fetch alerts");
        }
      } catch (error) {
        setError(error.message);
      }
    };
    fetchData();
  }, [dispatch]);

  // Like Handler
  const handleLike = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/alert/" + eventId + "/like", {
      method: "PUT",
      body: JSON.stringify({ username: user.user.username }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "ALERT_LIKE",
        payload: { id: eventId, user: user.user.username },
      });
    } else {
      console.error("error");
    }
  };
  // DisLike Handler
  const handleDisLike = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/alert/" + eventId + "/disLike", {
      method: "PUT",
      body: JSON.stringify({ username: user.user.username }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "ALERT_DISLIKE",
        payload: { id: eventId, user: user.user.username },
      });
    } else {
      console.error("error");
    }
  };
  // HandlePlayer
  const handlePlayer = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/alert/" + eventId + "/player", {
      method: "PUT",
      body: JSON.stringify({ username: user.user.username }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "ALERT_PLAYER",
        payload: { id: eventId, user: user.user.username },
      });
    } else {
      console.error("error");
    }
  };
  // handle Update
  const handleUpdate = async (eventId, alert) => {
    if (!user) {
      return;
    }
    dispatch({
      type: "ALERT_UPDATE",
      payload: { id: eventId, data: alert },
    });
  };
  // handle Delete
  const handleDelete = async (eventId) => {
    try {
      if (!user) {
        throw new Error("Please login");
      }
      const response = await fetch(`/api/alert/${eventId}`, {
        method: "DELETE",
        body: JSON.stringify({
          userId: user.user._id,
          userRole: user.user.role,
          isAdmin: user.user.isAdmin,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        dispatch({ type: "DELETE_ALERT", payload: json });
      } else {
        throw new Error("Failed to delete alert");
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  return (
    <>
      {error && <div>{error}</div>}
      {user &&
        (user.user.role === 1 ||
          user.user.role === 2 ||
          user.user.role === 3 ||
          user.user.role === 4 ||
          user.user.role === 5 ||
          user.user.role === 6 ||
          user.user.role === 10) &&
        profileAlerts &&
        profileAlerts.length > 0 && (
          <>
            <div className="Alert">
              <Divider horizontal>
                {metaData.CATEGORY.YOUR_SHARE_ALERTS}
              </Divider>
              <Card.Group centered>
                {profileAlerts
                  ? profileAlerts.map((alert) => (
                      <YourAlert
                        key={alert._id}
                        alert={alert}
                        onPlayer={handlePlayer}
                        onUpdate={handleUpdate}
                        onDelete={handleDelete}
                        onLike={handleLike}
                        onDisLike={handleDisLike}
                      />
                    ))
                  : "You don't have any alert yet"}
              </Card.Group>
            </div>
          </>
        )}
    </>
  );
};

export default ProfileAlerts;
