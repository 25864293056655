import React from "react";
import "./Coins.css";
import Coin from "../Coins/Coin/Coin";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useMetaData } from "../../Context/MetaDataContext";
import useFetch from "../../Hooks/FetchData";
import { Divider, Card, Segment } from "semantic-ui-react";
import { isMobile } from "react-device-detect";
const Coins = () => {
  const { user } = useAuthContext();
  const { metaData } = useMetaData();
  const { data: coins, error } = useFetch("/api/coins");
  return (
    <>
      {error && <div>{error}</div>}

      {!isMobile && (
        <>
          <div className="Coins">
            <Divider horizontal>
              <span className="cryptocurrencies">
                {metaData.CATEGORY.TOP_100_CRYPTOCURRENCIES}
              </span>
            </Divider>
          </div>
          <Card.Group
            centered
            style={{
              display: "flex",
              overflowY: "scroll",
              maxHeight: "420px",
            }}
          >
            {coins && coins.map((coin) => <Coin coin={coin} key={coin._id} />)}
          </Card.Group>
        </>
      )}
      {isMobile && (
        <div className="Coins">
          <Divider horizontal>
            <span className="cryptocurrencies">
              {metaData.CATEGORY.TOP_100_CRYPTOCURRENCIES}
            </span>
          </Divider>
          <Card.Group
            centered
            style={{
              display: "flex",
              overflowY: "scroll",
              maxHeight: "140px",
              // width:"100%"
            }}
          >
            {coins && coins.map((coin) => <Coin coin={coin} key={coin._id} />)}
          </Card.Group>
        </div>
      )}
    </>
  );
};

export default Coins;
