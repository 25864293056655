import React, { useState, useEffect } from "react";
import "./AlertOptions.css";
import AlertOption from "./AlertOption/AlertOption";
import { useAuthContext } from "../../Hooks/useAuthContext";
import useFetch from "../../Hooks/FetchData";
import { Divider, Card } from "semantic-ui-react";
import { useAlertsOptionContext } from "../../Hooks/useAlertsOptionContext";
const AlertOptions = () => {
  const { user } = useAuthContext();
  const [error, setError] = useState(null);
  const { alertOptions, dispatch } = useAlertsOptionContext();
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  // Get the Alert data from backend
  useEffect(() => {
    const fetchData = async (page) => {
      try {
        const response = await fetch(`/api/optionalert/today?page=${page}`);
        if (response.ok) {
          const json = await response.json();
          setTotalPages(json.totalPages);
          dispatch({ type: "FETCH_ALERT_OPTION", payload: json.alerts });
        } else {
          // throw new Error("Failed to fetch alerts");
        }
      } catch (error) {
        setError(error.message);
      }
    };
    // Function to fetch data and update state
    const fetchAndUpdateData = () => {
      fetchData(currentPage);
    };
    fetchAndUpdateData();
    // Set up an interval to fetch data every 15s
    const intervalId = setInterval(fetchAndUpdateData, 15000);
    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [dispatch, currentPage]);

  const handlePageChange = (event, data) => {
    const { activePage } = data;
    setCurrentPage(activePage);
  };
  const handleLoadMore = () => {
    if (currentPage === totalPages) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };
  // Like Handler
  const handleLike = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/optionalert/" + eventId + "/like", {
      method: "PUT",
      body: JSON.stringify({ username: user.user.username }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "ALERT_LIKE_OPTION",
        payload: { id: eventId, user: user.user.username },
      });
    } else {
      console.error("error");
    }
  };
  // DisLike Handler
  const handleDisLike = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/optionalert/" + eventId + "/disLike", {
      method: "PUT",
      body: JSON.stringify({ username: user.user.username }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "ALERT_DISLIKE_OPTION",
        payload: { id: eventId, user: user.user.username },
      });
    } else {
      console.error("error");
    }
  };
  // HandlePlayer
  const handlePlayer = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/optionalert/" + eventId + "/player", {
      method: "PUT",
      body: JSON.stringify({ userId: user.user._id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "OPTION_ALERT_PLAYER",
        payload: { id: eventId, user: user.user._id },
      });
    } else {
      console.error("error");
    }
  };
  // handle Update
  const handleOptionUpdate = async (eventId, alert) => {
    if (!user) {
      return;
    }
    dispatch({
      type: "ALERT_UPDATE_OPTION",
      payload: { id: eventId, data: alert },
    });
  };
  // handle Delete
  const handleDelete = async (eventId) => {
    try {
      if (!user) {
        throw new Error("Please login");
      }
      const response = await fetch(`/api/optionalert/${eventId}`, {
        method: "DELETE",
        body: JSON.stringify({
          userId: user.user._id,
          userRole: user.user.role,
          isAdmin: user.user.isAdmin,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        dispatch({ type: "DELETE_ALERT_OPTION", payload: json });
      } else {
        throw new Error("Failed to delete alert");
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  return (
    <>
      {error && <div> {error} </div>}
      {user &&
        (user.user.role === 1 ||
          user.user.role === 2 ||
          user.user.role === 3 ||
          user.user.role === 4 ||
          user.user.role === 5 ||
          user.user.role === 6 ||
          user.user.role === 10) &&
        alertOptions && (
          <div className="AlertOption">
            <Divider horizontal onClick={handleLoadMore}>
              <span className="AlertOption_Page">OPTION ALERTS</span>
            </Divider>
            <Card.Group centered>
              {alertOptions &&
                alertOptions.map((alertOption) => (
                  <AlertOption
                    alertOption={alertOption}
                    key={alertOption._id}
                    onPlayer={handlePlayer}
                    onOptionUpdate={handleOptionUpdate}
                    onDelete={handleDelete}
                    onLike={handleLike}
                    onDisLike={handleDisLike}
                  />
                ))}
            </Card.Group>
          </div>
        )}
    </>
  );
};

export default AlertOptions;
