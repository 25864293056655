import React, { createContext, useReducer } from "react";
// import { useAuthContext } from "../Hooks/useAuthContext";
export const WatchlistsContext = createContext();

export const watchlistsReducer = (state, action) => {
  let cloneWatchlists;
  switch (action.type) {
    case "FETCH_WATCHLIST":
      return {
        watchlists: action.payload,
      };
    case "CREATE_WATCHLIST":
      //   console.log(state.watchlists);
      //   console.log(action.payload);
      return {
        watchlists: {
          ...state.watchlists,
          [action.payload._id]: action.payload,
        },
      };
    case "WATCHLIST_UPDATE":
      cloneWatchlists = JSON.parse(JSON.stringify(state.watchlists));

      if (cloneWatchlists[action.payload.id]) {
        cloneWatchlists[action.payload.id] = action.payload.data;
      }
      return {
        watchlists: cloneWatchlists,
      };
    case "WATCHLIST_LIKE":
      cloneWatchlists = JSON.parse(JSON.stringify(state.watchlists));
      if (cloneWatchlists[action.payload.id]) {
        if (
          !cloneWatchlists[action.payload.id].like.includes(action.payload.user)
        ) {
          // if not exist then add in
          cloneWatchlists[action.payload.id].like.push(action.payload.user);
        } else {
          // If already exist then remove out
          cloneWatchlists[action.payload.id].like.splice(
            cloneWatchlists[action.payload.id].like.indexOf(action.payload.user)
          );
        }
      }
      return {
        watchlists: cloneWatchlists,
      };
    case "WATCHLIST_DISLIKE":
      cloneWatchlists = JSON.parse(JSON.stringify(state.watchlists));
      if (cloneWatchlists[action.payload.id]) {
        if (
          !cloneWatchlists[action.payload.id].disLike.includes(
            action.payload.user
          )
        ) {
          // if not exist then add in
          cloneWatchlists[action.payload.id].disLike.push(action.payload.user);
        } else {
          // If already exist then remove out
          cloneWatchlists[action.payload.id].disLike.splice(
            cloneWatchlists[action.payload.id].disLike.indexOf(
              action.payload.user
            )
          );
        }
      }
      return {
        watchlists: cloneWatchlists,
      };
    case "DELETE_WATCHLIST":
      cloneWatchlists = JSON.parse(JSON.stringify(state.watchlists));
      delete cloneWatchlists[action.payload];

      return {
        watchlists: cloneWatchlists,
      };
    default:
      return state;
  }
};

export const WatchlistsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(watchlistsReducer, {
    watchlists: null,
  });
  // console.log("watchlistContext state:", state);
  return (
    <WatchlistsContext.Provider value={{ ...state, dispatch }}>
      {children}
    </WatchlistsContext.Provider>
  );
};
