import React, { useState, useContext, useEffect } from "react";
import Posts from "../Posts/Posts";
import PostShare from "../PostShare/PostShare";
import "./PostContainer.css";
import { Divider, Card, List, Tab } from "semantic-ui-react";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { SearchTickerContext } from "../../Context/SearchTickerContext";
import PostShareModal from "../Modal/PostShareModal";
import { isMobile } from "react-device-detect";
import TradingPlan from "../Posts/TradingPlan";
import GainLoss from "../Posts/GainLoss";
import Learning from "../Posts/Learning";
import Analysis from "../Posts/Analysis";
import CustomTabMenu from "./CustomTabMenu";
import Laugh from "../Posts/Laugh";
import TradingJournal from "../Posts/TradingJournal";
import { useMetaData } from "../../Context/MetaDataContext";
const SmartTradingHub = () => {
  const { user } = useAuthContext();
  const { metaData } = useMetaData();
  const { searchResults, setSearchResults } = useContext(SearchTickerContext);
  const handleClear = () => {
    setSearchResults([]);
  };
  const panes = [
    {
      menuItem: {
        key: "discuss",
        icon: "users",
        content: `${metaData.MENU.DISCUSS}`,
      },
      render: () => (
        <Tab.Pane attached={false}>
          <Posts />
        </Tab.Pane>
      ),
    },
    {
      // menuItem: "Trading Plan",
      menuItem: {
        key: "tradingplan",
        icon: "idea",
        content: `${metaData.MENU.TRADING_PLAN}`,
      },
      render: () => (
        <Tab.Pane attached={false}>
          <TradingPlan />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: "tradingjournal",
        icon: "hdd",
        content: `${metaData.MENU.TRADING_JOURNAL}`,
      },
      render: () => (
        <Tab.Pane attached={false}>
          <TradingJournal />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: "technical",
        icon: "chart line",
        content: `${metaData.MENU.TECHNICAL_LEARNING}`,
      },
      render: () => (
        <Tab.Pane attached={false}>
          <Learning />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: "analysis",
        icon: "newspaper outline",
        content: `${metaData.MENU.ANALYSIS}`,
      },
      render: () => (
        <Tab.Pane attached={false}>
          <Analysis />
        </Tab.Pane>
      ),
    },
    {
      // menuItem: "Gain & Loss",
      menuItem: {
        key: "gainloss",
        icon: "dollar",
        content: `${metaData.MENU.GAIN_LOSS}`,
      },
      render: () => (
        <Tab.Pane attached={false}>
          <GainLoss />
        </Tab.Pane>
      ),
    },
    {
      // menuItem: "Gain & Loss",
      menuItem: {
        key: "lol",
        icon: "smile",
        content: `${metaData.MENU.LAUGH}`,
      },
      render: () => (
        <Tab.Pane attached={false}>
          <Laugh />
        </Tab.Pane>
      ),
    },
  ];
  return (
    <div className="SmartTradingHub">
      {!isMobile && (
        <>
          <div className="">
            <Divider horizontal>{metaData.WELCOME.NAME}</Divider>
          </div>
        </>
      )}

      {!isMobile ? <PostShare /> : <PostShareModal />}

      <CustomTabMenu panes={panes} />
    </div>
  );
};

export default SmartTradingHub;
