import React, { useState, useEffect } from "react";
import "./WatchLists.css";
import WatchList from "./WatchList/WatchList";
import { List, Divider } from "semantic-ui-react";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useWatchlistsContext } from "../../Hooks/useWatchlistContext";
import { useMetaData } from "../../Context/MetaDataContext";
import moment from "moment";
moment().format();
const WatchLists = () => {
  const { user } = useAuthContext();
  const { metaData } = useMetaData();
  const [error, setError] = useState(null);
  const { watchlists, dispatch } = useWatchlistsContext();
  // Get the watchlist data from backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/wl/custom`);
        if (response.ok) {
          const json = await response.json();
          // console.log({ json });
          dispatch({ type: "FETCH_WATCHLIST", payload: json });
        } else {
          // throw new Error("Failed to fetch watchlist");
        }
      } catch (error) {
        setError(error.message);
      }
    };
    // Function to fetch data and update state
    const fetchAndUpdateData = () => {
      fetchData();
    };

    // Fetch data initially
    fetchAndUpdateData();

    // Set up an interval to fetch data every 3 seconds
    const intervalId = setInterval(fetchAndUpdateData, 1000 * 60);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [dispatch]);

  const handleLike = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/wl/" + eventId + "/like", {
      method: "PUT",
      body: JSON.stringify({ userId: user.user._id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "WATCHLIST_LIKE",
        payload: { id: eventId, user: user.user._id },
      });
    } else {
      console.error("error");
    }
  };
  // DisLike Handler
  const handleDisLike = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/wl/" + eventId + "/disLike", {
      method: "PUT",
      body: JSON.stringify({ userId: user.user._id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "WATCHLIST_DISLIKE",
        payload: { id: eventId, user: user.user.username },
      });
    } else {
      console.error("error");
    }
  };
  // handle Update
  const handleUpdate = async (eventId, watchlist) => {
    if (!user) {
      return;
    }
    dispatch({
      type: "WATCHLIST_UPDATE",
      payload: { id: eventId, data: watchlist },
    });
  };
  // handle Delete
  const handleDelete = async (eventId) => {
    try {
      if (!user) {
        throw new Error("Please login");
      }
      const response = await fetch(`/api/wl/${eventId}`, {
        method: "DELETE",
        body: JSON.stringify({
          userId: user.user._id,
          userRole: user.user.role,
          isAdmin: user.user.isAdmin,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        dispatch({ type: "DELETE_WATCHLIST", payload: eventId });
      } else {
        throw new Error("Failed to delete alert");
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  return (
    <>
      {error && <> {error} </>}
      {watchlists && (
        <div className="WatchLists">
          {Object.values(watchlists).some(
            (watchlist) => watchlist.term === "DAILY"
          ) && (
            <>
              <Divider horizontal>
                <span className="watchlists_today">
                  {metaData.CATEGORY.TODAY_WATCHLIST}
                </span>
              </Divider>
              <List className="List_Item" horizontal>
                {Object.values(watchlists)
                  .filter((watchlist) => watchlist.term === "DAILY")
                  .map((watchlist) => (
                    <WatchList
                      watchlist={watchlist}
                      key={watchlist._id}
                      // onPlayer={handlePlayer}
                      onUpdate={handleUpdate}
                      onDelete={handleDelete}
                      onLike={handleLike}
                      onDisLike={handleDisLike}
                    />
                  ))}
              </List>
            </>
          )}
        </div>
      )}
      {watchlists && (
        <div className="WatchLists">
          {Object.values(watchlists).some(
            (watchlist) => watchlist.term === "WEEKLY"
          ) && (
            <>
              <Divider horizontal>
                <span className="watchlists_weekly">
                  {metaData.CATEGORY.WEEKLY_WATCHLIST}
                </span>
              </Divider>

              <List className="List_Item" horizontal>
                {Object.values(watchlists)
                  .filter((watchlist) => watchlist.term === "WEEKLY")
                  .map((watchlist) => (
                    <WatchList
                      watchlist={watchlist}
                      key={watchlist._id}
                      // onPlayer={handlePlayer}
                      onUpdate={handleUpdate}
                      onDelete={handleDelete}
                      onLike={handleLike}
                      onDisLike={handleDisLike}
                    />
                  ))}
              </List>
            </>
          )}
        </div>
      )}
      {watchlists && (
        <div className="WatchLists">
          {Object.values(watchlists).some(
            (watchlist) => watchlist.term === "MONTHLY"
          ) && (
            <>
              <Divider horizontal>
                <span className="watchlists_monthly">
                  {metaData.CATEGORY.MONTHLY_WATCHLIST}
                </span>
              </Divider>

              <List className="List_Item" horizontal>
                {Object.values(watchlists)
                  .filter((watchlist) => watchlist.term === "MONTHLY")
                  .map((watchlist) => (
                    <WatchList
                      watchlist={watchlist}
                      key={watchlist._id}
                      // onPlayer={handlePlayer}
                      onUpdate={handleUpdate}
                      onDelete={handleDelete}
                      onLike={handleLike}
                      onDisLike={handleDisLike}
                    />
                  ))}
              </List>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default WatchLists;
