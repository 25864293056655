import React, { useState, useEffect, useRef, useContext } from "react";
import { usePostsContext } from "../../../Hooks/usePostsContext";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import ReactHtmlParser from "html-react-parser";
import { SearchTickerContext } from "../../../Context/SearchTickerContext";
import { useMetaData } from "../../../Context/MetaDataContext";
import "./Post.css";
import noAvatar from "../../../assets/img/noAvatar.png";
import {
  Button,
  Comment,
  Icon,
  Form,
  Image,
  Divider,
  Feed,
  Label,
  Input,
  Modal,
} from "semantic-ui-react";
import moment from "moment";
import YouTube from "react-youtube";
import ReactQuill from "react-quill";
import ReactPlayer from "react-player";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import UserProfile from "../../UserProfile/UserProfile";
// import "react-quill/dist/quill.snow.css";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
moment().format();

const Post = ({ post, onDelete, onLike, onDisLike, onUpdate }) => {
  const { user } = useAuthContext();
  const { metaData } = useMetaData();
  const { dispatch } = usePostsContext();
  const [commentBody, setCommentBody] = useState();
  const [replyBody, setReplyBody] = useState();
  const [body, setBody] = useState();
  const [tag, setTag] = useState();
  const [image, setImage] = useState(null);
  const [youtubeId, setYoutubeId] = useState();
  const [tradingviewImage, setTradingviewImage] = useState();
  const [chart, setChart] = useState(false);
  const [video, setVideo] = useState(false);
  const imageRef = useRef();
  const [showComment, setShowComment] = useState(false);
  const [showCommentForm, setShowCommentForm] = useState(false);
  const [showReply, setShowReply] = useState(false);
  const [clickedCommentId, setClickedCommentId] = useState(null);
  const [update, setUpdate] = useState(false);
  const [error, setError] = useState(null);
  const [isFocused, setIsFocused] = useState(null);
  const [open, setOpen] = useState(false);
  const [openUserProfile, setOpenUserProfile] = useState(false);
  const { handleTickerClick } = useContext(SearchTickerContext);
  const currentDomain = window.location.origin;
  const tradingviewLinkRegex =
    /^https:\/\/www\.tradingview\.com\/x\/[a-zA-Z0-9]+\/$/;
  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      setImage(img);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shareableLink, setShareableLink] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  //WYSIWYG editor

  const modules = isMobile
    ? { toolbar: [[{ color: [] }]] }
    : {
        toolbar: [
          [{ color: [] }, { background: [] }],
          // [{ font: [] }],
          ["bold", "italic", "underline", "strike"],
          [{ align: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["link"],
          ["clean"],
        ],
      };
  useEffect(() => {
    if (post) {
      setBody(post.body);
      if (post.youtubeId) {
        setVideo(true);
        setYoutubeId(post.youtubeId);
      }
      if (post.tradingviewImage) {
        setChart(true);
        setTradingviewImage(post.tradingviewImage);
      }
    }
  }, []);
  const onShare = (postId) => {
    const link = `${currentDomain}/p/${postId}`;
    setShareableLink(link);
    setIsCopied(false);
    setIsModalOpen(true); // Open the modal
  };

  const convertHtmlToPlainText = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const copyToClipboard = () => {
    const input = document.getElementById("shareableLink");
    input.select();
    document.execCommand("copy");
    setIsCopied(true);
  };
  const shareOnFacebook = () => {
    window.FB.ui({
      method: "share",
      href: shareableLink,
    });
  };

  const shareOnTwitter = () => {
    const plainTextBody = convertHtmlToPlainText(post.body);
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      `${plainTextBody}
👉More Detail: ${shareableLink}
@smarttradinghub $
`
    )}`;
    window.open(twitterShareUrl, "_blank");
  };
  // HANDLE UPDATE POST
  const handleUpdtatePost = async (e) => {
    if (!user) {
      setError("You must be logged in");
      return;
    }
    e.preventDefault();
    const updateAlertBody = JSON.stringify({
      userId: user.user._id,
      username: user.user.username,
      body,
      youtubeId,
      tradingviewImage,
      image,
      tag,
    });
    const response = await fetch("/api/posts/" + post._id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: updateAlertBody,
    });
    const json = await response.json();
    if (!response.ok) {
      setError(json.message);
    }
    if (response.ok) {
      setError(null);
      let deepClonePrev = JSON.parse(JSON.stringify(post));
      deepClonePrev = {
        ...deepClonePrev,
        body,
        youtubeId,
        tradingviewImage,
        image,
        tag,
      };
      onUpdate(post._id, deepClonePrev);
      setUpdate(false);
    } else {
      console.log("error");
    }
  };

  // HANDLE ADD COMMENT
  const handleComment = async (eventId) => {
    if (!user) {
      setError("You must be logged in");
      return;
    }
    // FETCH THE API TO ADD THE COMMENT
    const response = await fetch("/api/posts/" + eventId + "/comment", {
      method: "PUT",
      body: JSON.stringify({
        userId: user.user._id,
        username: user.user.username,
        postId: eventId,
        comment_body: commentBody,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });
    const json = await response.json();
    // console.log("Response data:", json);
    if (!response.ok) {
      setError(json.message);
    }
    if (response.ok) {
      setError(null);
      setCommentBody("");
      dispatch({
        type: "CREATE_POST_COMMENT",
        payload: {
          postId: eventId,
          comment: json,
        },
      });
    }
  };
  // HANDEL LIKE COMMENT
  const handleLikeComment = async (postId, commentId) => {
    if (!user) {
      setError("You must be logged in");
      return;
    }

    const response = await fetch(
      `/api/posts/${postId}/comment/${commentId}/like`,
      {
        method: "PUT",
        body: JSON.stringify({ username: user.user.username }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const json = await response.json();

    if (!response.ok) {
      setError(json.message);
    }

    if (response.ok) {
      dispatch({
        type: "LIKE_POST_COMMENT",
        payload: {
          postId,
          commentId,
          username: user.user.username,
        },
      });
      setError(null);
    }
  };
  // HANDEL LIKE COMMENT
  const handleDisLikeComment = async (postId, commentId) => {
    if (!user) {
      setError("You must be logged in");
      return;
    }

    const response = await fetch(
      `/api/posts/${postId}/comment/${commentId}/disLike`,
      {
        method: "PUT",
        body: JSON.stringify({ username: user.user.username }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const json = await response.json();

    if (!response.ok) {
      setError(json.message);
    }

    if (response.ok) {
      dispatch({
        type: "DISLIKE_POST_COMMENT",
        payload: {
          postId,
          commentId,
          username: user.user.username,
        },
      });
      setError(null);
    }
  };
  // HANDLE DELETE COMMENT
  const handleDeleteComment = async (postId, commentId) => {
    try {
      if (!user) {
        throw new Error("Please login");
      }
      const response = await fetch(
        `/api/posts/${postId}/comment/${commentId}`,
        {
          method: "DELETE",
          body: JSON.stringify({
            userId: user.user._id,
            userRole: user.user.role,
            isAdmin: user.user.isAdmin,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();
      if (response.ok) {
        dispatch({ type: "DELETE_POST_COMMENT", payload: json });
      } else {
        throw new Error("Failed to delete comment");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // HANDLE REPLY
  const handleReply = async (postId, commentId) => {
    if (!user) {
      setError("You must be logged in");
      return;
    }

    try {
      const response = await fetch(
        `/api/posts/${postId}/comment/${commentId}/reply`,
        {
          method: "PUT",
          body: JSON.stringify({
            userId: user.user._id,
            username: user.user.username,
            reply_body: replyBody,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();
      // console.log("Response data:", json);
      if (!response.ok) {
        throw new Error(json.message);
      }
      setError(null);
      setReplyBody("");
      dispatch({
        type: "CREATE_COMMENT_REPLY",
        payload: {
          postId: postId,
          commentId: commentId,
          reply: json,
        },
      });
    } catch (error) {
      console.error(error.message);
      setError(error.message);
    }
  };

  // HANDLE REPLY DELETE
  const handleReplyDelete = async (postId, commentId, replyId) => {
    if (!user) {
      setError("You must be logged in");
      return;
    }

    try {
      const response = await fetch(
        `/api/posts/${postId}/comment/${commentId}/reply/${replyId}`,
        {
          method: "DELETE",
          body: JSON.stringify({
            userId: user.user._id,
            userRole: user.user.role,
            isAdmin: user.user.isAdmin,
          }),
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const json = await response.json();
      console.log("Response data:", json);
      if (!response.ok) {
        throw new Error(json.message);
      }
      setError(null);
      dispatch({
        type: "DELETE_COMMENT_REPLY",
        payload: {
          postId: postId,
          commentId: commentId,
          replyId: replyId,
        },
      });
    } catch (error) {
      console.error(error.message);
      setError(error.message);
    }
  };

  const fetchUser = async (userId) => {
    try {
      const response = await fetch(`api/user/${userId}`);
      const postUser = await response.json();
      return postUser;
    } catch (error) {
      console.error(error);
    }
  };

  // Hook to fetch the user object associated with the post userId
  const [postUser, setPostUser] = useState(null);
  const [postCommentUser, setPostCommentUser] = useState(null);
  useEffect(() => {
    const getUser = async () => {
      const user = await fetchUser(post.userId);
      setPostUser(user);
    };
    getUser();
  }, [post.userId]);
  // Effect hook to fetch the user objects associated with each comment and reply in the post
  useEffect(() => {
    async function getUsers() {
      const usersObj = {};
      const comments = post.comment;

      // Fetch user objects associated with each comment in the post
      for (let i = 0; i < comments.length; i++) {
        const commentUserId = comments[i].userId;
        if (!usersObj[commentUserId]) {
          const user = await fetchUser(commentUserId);
          usersObj[commentUserId] = user;
        }
      }

      // Fetch user objects associated with each reply in the post
      for (let i = 0; i < comments.length; i++) {
        const replies = comments[i].reply;
        if (!replies) continue;
        for (let j = 0; j < replies.length; j++) {
          const replyUserId = replies[j].userId;
          if (!usersObj[replyUserId]) {
            const user = await fetchUser(replyUserId);
            usersObj[replyUserId] = user;
          }
        }
      }

      setPostCommentUser(usersObj);
      // console.log(setPostCommentUser);
    }
    getUsers();
  }, [post.comment]);
  return (
    <>
      {post && (
        <>
          <Feed.Event className="Post" key={post._id}>
            {postUser && (
              <Feed.Label
                image={
                  postUser.avatarPicture === "noAvatar.png"
                    ? noAvatar
                    : postUser.avatarPicture
                }
              />
            )}

            <Feed.Content>
              <Feed.Summary>
                {metaData.MARKET.POSTED_BY}{" "}
                <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                  <Modal.Header>Share Link</Modal.Header>
                  <Modal.Content>
                    <Input
                      fluid
                      id="shareableLink"
                      value={shareableLink}
                      readOnly
                      action={
                        <>
                          {" "}
                          <Button onClick={copyToClipboard} disabled={isCopied}>
                            {isCopied ? "Copied" : "Copy"}
                          </Button>
                          <Button onClick={shareOnTwitter} icon="twitter" />
                          <Button onClick={shareOnFacebook} icon="facebook" />
                        </>
                      }
                    />
                  </Modal.Content>
                </Modal>
                <Modal
                  closeIcon
                  dimmer="blurring"
                  size="large"
                  onClose={() => setOpenUserProfile(false)}
                  onOpen={() => setOpenUserProfile(true)}
                  open={openUserProfile}
                  trigger={<span>{post.username}</span>}
                >
                  <Modal.Content>
                    <UserProfile postUserId={post.userId} />
                  </Modal.Content>
                </Modal>
                {/* <Link to={`/user/${post.userId}`}>
                  <span>{post.username}</span>
                </Link> */}
                <Feed.Date>
                  {moment(post.createdAt).format("MMM, D YY h:mm A")} (
                  {moment(post.createdAt).startOf("minutes").fromNow()})
                </Feed.Date>
              </Feed.Summary>
              <Feed.Extra>
                {/* <div
                  className="post-image"
                  dangerouslySetInnerHTML={{ __html: post.body }}
                /> */}
                {post.tag && (
                  <strong>
                    {" "}
                    <span onClick={() => handleTickerClick(post.tag)}>
                      {post.tag.toUpperCase()}
                    </span>
                  </strong>
                )}
                <div className="modal-content-container">
                  {ReactHtmlParser(post.body)}
                  {/* {post.body &&
                    post.body
                      .match(/(https?:\/\/.*\.(?:png|jpg|jpeg|gif|webp))/g)
                      ?.map((imageUrl, index) => (
                        <Image key={index} fluid src={imageUrl} alt="" />
                      ))} */}
                </div>

                {post.tradingviewImage && (
                  <>
                    <Modal
                      closeIcon
                      dimmer="blurring"
                      size="fullscreen"
                      onClose={() => setOpen(false)}
                      onOpen={() => setOpen(true)}
                      open={open}
                      trigger={
                        <Image
                          size="huge"
                          src={post.tradingviewImage}
                          rounded
                        />
                      }
                    >
                      <TransformWrapper>
                        <TransformComponent>
                          <Modal.Header>{`Posted by ${post.username} ${moment(
                            post.createdAt
                          )
                            .startOf("minutes")
                            .fromNow()}`}</Modal.Header>
                          <Image
                            src={post.tradingviewImage}
                            alt={`Posted by ${post.username} ${moment(
                              post.createdAt
                            ).format("h:mm:ss A MMM, D YYYY")}`}
                          />
                        </TransformComponent>
                      </TransformWrapper>
                    </Modal>
                  </>
                )}
                {post.youtubeId && (
                  <ReactPlayer
                    url={post.youtubeId}
                    controls={true}
                    width="70%"
                    // height="100%"
                  />
                )}

                {/* // <YouTube videoId={post.url} />} */}
                {post.image && (
                  <Image rounded size="huge" src={post.image} alt="" />
                )}
              </Feed.Extra>
              {/* button like, comment, edit & delete */}
              <Feed.Meta>
                <div className="post_button">
                  <Feed.Like>
                    {user && (
                      <div onClick={() => onLike(post._id)}>
                        <Icon
                          name="heart"
                          color={
                            post.like.includes(user.user._id) ? "red" : "grey"
                          }
                        />
                        {/* <Icon
                          name="heart"
                          className={
                            post.like.includes(user.user._id)
                              ? "heart-icon liked"
                              : "heart-icon"
                          }
                        /> */}
                        <strong>{post.like.length}</strong>
                      </div>
                    )}
                  </Feed.Like>
                  {/* <Feed.Like>
                    {user && post && (
                      <div onClick={() => onDisLike(post._id)}>
                        <Icon name="thumbs down" />
                        <strong> {post.disLike.length}</strong>
                      </div>
                    )}
                  </Feed.Like> */}
                  <Feed.Like>
                    {user && post && (
                      <div
                        onClick={() => {
                          setShowComment((prevValue) => !prevValue);
                          setShowCommentForm(true);
                        }}
                      >
                        <Icon name="comment" />
                        <strong>{post.comment.length}</strong>
                      </div>
                    )}
                  </Feed.Like>
                  <Feed.Like>
                    {user && user.user._id === post.userId && (
                      <div
                        onClick={() => {
                          setUpdate((prevValue) => !prevValue);
                        }}
                      >
                        <Icon name="edit" />
                      </div>
                    )}
                  </Feed.Like>
                  <Feed.Like>
                    {user &&
                      (user.user.role === 1 ||
                        user.user.role === 2 ||
                        user.user._id === post.userId) && (
                        <div onClick={() => onDelete(post._id)}>
                          <Icon name="delete" />
                        </div>
                      )}
                  </Feed.Like>
                  <Feed.Like>
                    <div onClick={() => onShare(post._id)}>
                      <Icon name="share" />
                    </div>
                  </Feed.Like>
                  <Modal
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                  >
                    <Modal.Header>Share Link</Modal.Header>
                    <Modal.Content>
                      <Input
                        fluid
                        id="shareableLink"
                        value={shareableLink}
                        readOnly
                        action={
                          <>
                            {" "}
                            <Button
                              onClick={copyToClipboard}
                              disabled={isCopied}
                            >
                              {isCopied ? "Copied" : "Copy"}
                            </Button>
                            <Button onClick={shareOnTwitter} icon="twitter" />
                            <Button onClick={shareOnFacebook} icon="facebook" />
                          </>
                        }
                      />
                    </Modal.Content>
                  </Modal>
                </div>
              </Feed.Meta>
              {/* Update post */}

              {update && (
                <>
                  <div className="PostShare">
                    {user.user.avatarPicture === "noAvatar.png" ? (
                      <img src={noAvatar} alt="" />
                    ) : (
                      <img src={user.user.avatarPicture} alt="" />
                    )}
                    <div>
                      <Form.Field>
                        {isFocused === "body" && (
                          <Label pointing="below">
                            update your idea here...
                          </Label>
                        )}
                        <div>
                          <ReactQuill
                            className="my-custom-class"
                            onFocus={() => setIsFocused("body")}
                            onBlur={() => setIsFocused(null)}
                            placeholder="* Share your trade plan here."
                            onChange={(newValue) => setBody(newValue)}
                            value={body}
                            modules={modules}
                          />
                        </div>
                      </Form.Field>
                      {error && <div className="error">{error}</div>}
                      {chart && (
                        <Form.Field>
                          {isFocused === "tradingviewImage" && (
                            <Label pointing="below">
                              Tradingview chart url
                            </Label>
                          )}
                          <Input
                            type="text"
                            placeholder="* Please paste tradingview images url only"
                            onChange={(e) =>
                              setTradingviewImage(e.target.value)
                            }
                            value={tradingviewImage}
                            fluid
                            onFocus={() => setIsFocused("tradingviewImage")}
                            onBlur={() => setIsFocused(null)}
                          />
                        </Form.Field>
                      )}
                      {video && (
                        <Form.Field>
                          {isFocused === "youtubeId" && (
                            <Label pointing="below">
                              Please Paste Youtube URL here
                            </Label>
                          )}
                          <Input
                            type="text"
                            placeholder="* Youtube, Vimeo,DailyMotion..."
                            onChange={(e) => setYoutubeId(e.target.value)}
                            value={youtubeId}
                            fluid
                            onFocus={() => setIsFocused("youtubeId")}
                            onBlur={() => setIsFocused(null)}
                          />
                        </Form.Field>
                      )}
                      <div className="postOption">
                        <div
                          className="otpion"
                          onClick={() => imageRef.current.click()}
                        >
                          <Icon
                            name="images"
                            size="big"
                            style={{ color: "var(--photo)" }}
                          />
                          Photo
                        </div>
                        <div
                          className="otpion"
                          onClick={() => {
                            setVideo(false);
                            setChart((prevValue) => !prevValue);
                            setError(false);
                          }}
                        >
                          <Icon
                            name="chart line"
                            size="big"
                            style={{ color: "var(--chart)" }}
                          />
                          Chart
                        </div>
                        <div
                          className="otpion"
                          onClick={() => {
                            setVideo((prevValue) => !prevValue);
                            setChart(false);
                            setError(false);
                          }}
                        >
                          <Icon
                            name="youtube"
                            size="big"
                            style={{ color: "var(--source)" }}
                          />
                          Video
                        </div>
                        <form
                          encType="multipart/form-data"
                          onSubmit={handleUpdtatePost}
                        >
                          <div
                            onClick={handleUpdtatePost}
                            style={{ cursor: "pointer" }}
                          >
                            <Icon
                              name="share square"
                              size="big"
                              style={{ color: "var(--blue)" }}
                            />
                            Update
                          </div>
                        </form>
                        {/* hidden the chooise file input */}
                        <div style={{ display: "none" }}>
                          <input
                            type="file"
                            filename="image"
                            name="image"
                            ref={imageRef}
                            onChange={onImageChange}
                          />
                        </div>
                      </div>
                      {image && (
                        <div className="previewImage">
                          <Icon
                            className="icon"
                            name="close"
                            size="big"
                            style={{ color: "var(--blue)" }}
                            onClick={() => setImage(null)}
                          />
                          <img src={URL.createObjectURL(image)} alt="" />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
              {/* comment reply box */}
              <Feed.Extra>
                {user && showComment && (
                  <>
                    <Comment.Group>
                      {post.comment &&
                        post.comment.map((comment, i) => (
                          <Comment key={comment._id}>
                            {postCommentUser && (
                              <Comment.Avatar
                                className="circular-avatar"
                                src={
                                  postCommentUser[comment.userId]
                                    ?.avatarPicture || noAvatar
                                }
                              />
                            )}
                            <Comment.Content>
                              <Comment.Author>
                                {comment.username}
                              </Comment.Author>
                              <Comment.Metadata>
                                <div>
                                  {moment(comment.createdAt)
                                    .startOf("minutes")
                                    .fromNow()}{" "}
                                  {user &&
                                    (user.user.role === 1 ||
                                      user.user.role === 2 ||
                                      user.user._id === comment.userId) && (
                                      <Icon
                                        name="delete"
                                        onClick={() =>
                                          handleDeleteComment(
                                            post._id,
                                            comment._id
                                          )
                                        }
                                      />
                                    )}
                                </div>
                              </Comment.Metadata>
                              <Comment.Text>
                                {comment.comment_body}
                              </Comment.Text>
                              <Comment.Actions>
                                <Comment.Action
                                  onClick={() => {
                                    setShowReply((prevValue) => !prevValue);
                                    setClickedCommentId(comment._id);
                                    setShowCommentForm(
                                      (prevValue) => !prevValue
                                    );
                                  }}
                                >
                                  Reply ({comment.reply && comment.reply.length}
                                  )
                                </Comment.Action>{" "}
                                <Comment.Action
                                  onClick={() => {
                                    handleLikeComment(post._id, comment._id);
                                  }}
                                >
                                  Like ({comment.like && comment.like.length})
                                </Comment.Action>{" "}
                                {/* <Comment.Action
                                  onClick={() => {
                                    handleDisLikeComment(post._id, comment._id);
                                  }}
                                >
                                  DisLike (
                                  {comment.disLike && comment.disLike.length})
                                </Comment.Action> */}
                              </Comment.Actions>
                              {/*  reply box */}
                              <Feed.Extra>
                                {user &&
                                  showReply &&
                                  clickedCommentId === comment._id && (
                                    <>
                                      <Comment.Group>
                                        {comment.reply.map((reply, i) => (
                                          <Comment key={reply._id}>
                                            <Comment.Avatar
                                              className="circular-avatar"
                                              src={
                                                postCommentUser[reply.userId]
                                                  ?.avatarPicture || noAvatar
                                              }
                                            />
                                            <Comment.Content>
                                              <Comment.Author>
                                                {reply.username}
                                              </Comment.Author>
                                              <Comment.Metadata>
                                                <div>
                                                  {moment(reply.createdAt)
                                                    .startOf("minutes")
                                                    .fromNow()}{" "}
                                                  {user &&
                                                    (user.user.role === 1 ||
                                                      user.user.role === 2 ||
                                                      user.user._id ===
                                                        reply.userId) && (
                                                      <Icon
                                                        name="delete"
                                                        onClick={() =>
                                                          handleReplyDelete(
                                                            post._id,
                                                            comment._id,
                                                            reply._id
                                                          )
                                                        }
                                                      />
                                                    )}
                                                </div>
                                              </Comment.Metadata>
                                              <Comment.Text>
                                                {reply.reply_body}
                                              </Comment.Text>
                                            </Comment.Content>
                                          </Comment>
                                        ))}
                                        <Form>
                                          <Form.TextArea
                                            style={{
                                              maxHeight: "50px",
                                              // maxWidth: "200px",
                                            }}
                                            className="comment-post-container"
                                            type="text"
                                            placeholder="reply..."
                                            onChange={(event) =>
                                              setReplyBody(event.target.value)
                                            }
                                            value={replyBody}
                                          />
                                          {error && (
                                            <div className="error">{error}</div>
                                          )}
                                          <Button
                                            onClick={() =>
                                              handleReply(post._id, comment._id)
                                            }
                                            content="Reply"
                                            icon="send"
                                            floated="right"
                                          />
                                        </Form>
                                      </Comment.Group>
                                    </>
                                  )}
                              </Feed.Extra>
                            </Comment.Content>
                          </Comment>
                        ))}
                      {showCommentForm && (
                        <>
                          <Form reply>
                            <Form.TextArea
                              style={{ maxHeight: "50px" }}
                              widths="50"
                              className="comment-post-container"
                              type="text"
                              placeholder="Share your comment here..."
                              onChange={(event) =>
                                setCommentBody(event.target.value)
                              }
                              value={commentBody}
                            />
                            {error && <div className="error">{error}</div>}
                            <Button
                              onClick={() => handleComment(post._id)}
                              content="Add"
                              // labelPosition="center"
                              icon="send"
                              // primary
                              floated="right"
                            />
                          </Form>
                        </>
                      )}
                    </Comment.Group>
                  </>
                )}
              </Feed.Extra>
            </Feed.Content>
          </Feed.Event>
        </>
      )}
      <Divider section />
    </>
  );
};

export default Post;
