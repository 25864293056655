import React from "react";
import { Progress, Divider } from "semantic-ui-react";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useMetaData } from "../../Context/MetaDataContext";
import useFetch from "../../Hooks/FetchData";
import moment from "moment";
import "./FearAndGreed.css";
import { Link } from "react-router-dom";

const getSentimentStatement = (value) => {
  if (value >= 75 && value <= 100) {
    return { statement: "Extreme Greed", color: "green" };
  } else if (value >= 55 && value <= 75) {
    return { statement: "Greed", color: "olive" };
  } else if (value >= 45 && value <= 50) {
    return { statement: "Neutral", color: "teal" };
  } else if (value >= 25 && value <= 45) {
    return { statement: "Fear", color: "red" };
  } else {
    return { statement: "Extreme Fear", color: "violet" };
  }
};
const FearAndGreed = () => {
  const { user } = useAuthContext();
  const { data: fearAndGreeds, error } = useFetch("/api/fearandgreed");
  const { metaData } = useMetaData();
  // console.log(fearAndGreeds);
  return (
    <div className="FearAndGreed">
      <div className="">
        <Divider horizontal>
          <Link to="/market-index">
            <span className="FearAndGreedTitle">
              {metaData.CATEGORY.FEAR_GREED_INDEX}
            </span>
          </Link>
        </Divider>
      </div>
      <div className="">
        {fearAndGreeds &&
          fearAndGreeds.map((fearAndGreed) => (
            <div key={fearAndGreed._id}>
              <Progress
                size="mini"
                value={fearAndGreed.fgi.now.value}
                progress="value"
                active
                total={100}
                color={getSentimentStatement(fearAndGreed.fgi.now.value).color}
              >
                <span>
                  {moment(fearAndGreed.lastUpdated.humanDate).format(
                    "MMM, D YYYY"
                  )}{" "}
                  {fearAndGreed.fgi.now.valueText}
                </span>
              </Progress>
            </div>
          ))}
      </div>
    </div>
  );
};

export default FearAndGreed;
