import React from "react";
import Posts from "../../Posts/Posts";
import PlayedAlerts from "../ProfileAlerts/PlayedAlerts";
import PlayedOptionAlerts from "../ProfileAlerts/PlayedOptionAlerts";
import ProfilePosts from "../ProfilePosts/ProfilePosts";
import "./ProfileContainer.css";
const ProfileContainer = () => {
  return (
    <div className="ProfileContainer">
      <PlayedAlerts />
      <PlayedOptionAlerts />
      <ProfilePosts />
    </div>
  );
};

export default ProfileContainer;
