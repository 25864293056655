import React, { useEffect } from "react";
import "./TradingView.css";

const TechnicalAnalysis = ({ symbol }) => {
  useEffect(() => {
    if (symbol) {
      const script = document.createElement("script");
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js";
      script.async = true;
      script.innerHTML = `
        {
          "interval": "1M",
          "width": "100%",
          "isTransparent": false,
          "height": "450",
          "symbol": "${symbol}",
          "showIntervalTabs": true,
          "locale": "en",
          "colorTheme": "light"
        }
      `;

      const container = document.querySelector(".TechnicalAnalysis-Container");
      if (container) {
        container.appendChild(script);
      }
    }

    return () => {
      const container = document.querySelector(".TechnicalAnalysis-Container");
      if (container) {
        container.innerHTML = "";
      }
    };
  }, [symbol]);

  return (
    <>
      <div className="TechnicalAnalysis-Container"></div>
    </>
  );
};

export default TechnicalAnalysis;
