import React, { useEffect } from "react";

const FundamentalData = ({ symbol }) => {
  useEffect(() => {
    if (symbol) {
      const script = document.createElement("script");
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-financials.js";
      script.async = true;
      script.innerHTML = `
        {
          "colorTheme": "light",
          "isTransparent": false,
          "largeChartUrl": "",
          "displayMode": "compact",
          "width": "100%",
          "height": "490",
          "symbol": "${symbol}",
          "locale": "en"
        }
      `;

      const element = document.querySelector(".FundamentalData");
      if (element) {
        element.appendChild(script);
      }
    }

    return () => {
      const element = document.querySelector(".FundamentalData");
      if (element) {
        element.innerHTML = "";
      }
    };
  }, [symbol]);

  return (
    <div>
      <div className="FundamentalData"></div>
    </div>
  );
};

export default FundamentalData;
