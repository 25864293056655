import { useContext } from "react";
import { AlertOptionContext } from "../Context/AlertOptionContext";

export const useAlertsOptionContext = () => {
  const context = useContext(AlertOptionContext);

  if (!context) {
    throw Error(
      "useAlertsContext must be used inside an AlertsContextProvider"
    );
  }

  return context;
};
