import React, { useState, useRef } from "react";
import "./PostShare.css";
import {
  Icon,
  Form,
  Input,
  Label,
  Message,
  Button,
  Dropdown,
} from "semantic-ui-react";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { usePostsContext } from "../../Hooks/usePostsContext";
import { useMetaData } from "../../Context/MetaDataContext";
import { useBreakingNewContext } from "../../Hooks/useBreakingNewContext";
import { useCalendarContext } from "../../Hooks/useCalendarContext";
import axios from "axios";
import moment from "moment";
import WatchListModal from "../Modal/WatchLishModal";
import EarningsModal from "../Modal/EarningModal";
import AlertModal from "../Modal/AlertModal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isMobile } from "react-device-detect";
moment().format();

const PostShare = (props) => {
  const { user } = useAuthContext();
  const { metaData } = useMetaData();
  const { dispatch: postsDispatch } = usePostsContext();
  const { dispatch: breakingNewsDispatch } = useBreakingNewContext();
  const { dispatch: calendarsDispatch } = useCalendarContext();
  const [isFocused, setIsFocused] = useState(null);
  const [title, setTitle] = useState();
  const [body, setBody] = useState();
  const [category, setCategory] = useState("Discuss");

  const categoryOptions = [
    {
      key: "discuss",
      text: `${metaData.MENU.DISCUSS}`,
      icon: "users",
      value: "Discuss",
    },
    {
      key: "tradingplan",
      text: `${metaData.MENU.TRADING_PLAN}`,
      icon: "idea",
      value: "Trading Plan",
    },
    {
      key: "learning",
      icon: "chart line",
      text: `${metaData.MENU.TECHNICAL_LEARNING}`,
      value: "Technical Learning",
    },
    {
      key: "analysis",
      icon: "newspaper outline",
      text: `${metaData.MENU.ANALYSIS}`,
      value: "Analysis",
    },
    {
      key: "gainloss",
      icon: "dollar",
      text: `${metaData.MENU.GAIN_LOSS}`,
      value: "Gain Loss",
    },
    {
      key: "tradingjournal",
      icon: "hdd",
      text: `${metaData.MENU.TRADING_JOURNAL}`,
      value: "Trading Journal",
    },
    {
      key: "laugh",
      icon: "smile",
      text: `${metaData.MENU.LAUGH}`,
      value: "Laugh",
    },
  ];
  const categoryOptionsMember = [
    { key: "discuss", text: "Discussion", icon: "users", value: "Discuss" },
    {
      key: "tradingjournal",
      text: `${metaData.MENU.TRADING_JOURNAL}`,
      icon: "hdd",
      value: "Trading Journal",
    },
    {
      key: "gainloss",
      icon: "dollar",
      text: `${metaData.MENU.GAIN_LOSS}`,
      value: "Gain Loss",
    },
    {
      key: "laugh",
      icon: "smile",
      text: `${metaData.MENU.LAUGH}`,
      value: "Laugh",
    },
  ];
  const [source, setSource] = useState();
  const [link, setLink] = useState();
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState();
  const [topic, setTopic] = useState();
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  const [youtubeId, setYoutubeId] = useState();
  const [tradingviewImage, setTradingviewImage] = useState();
  const tradingviewLinkRegex =
    /^https:\/\/www\.tradingview\.com\/x\/[a-zA-Z0-9]+\/$/;
  const [marketNew, setMarketNew] = useState(false);
  const [analysis, setanalysis] = useState(false);
  const [calendar, setCalendar] = useState(false);
  const [sentiment, setSentiment] = useState(false);
  const [chart, setChart] = useState(false);
  const [video, setVideo] = useState(false);
  const [post, setPost] = useState(true);
  const imageRef = useRef();
  const resetForm = () => {
    // setDate(null);
    setBullish("");
    setBearish("");
    setNeutral("");
    setActual("");
    setEstimate("");
    setPrevious("");
    setNote("");
    setTags("");
    setTag("");
    setTradingviewImage("");
    setError(null);
    setBody("");
    setSource("");
    setLink("");
    setTags();
    setImage();
    setYoutubeId();
    setTitle();
    setEvent();
  };
  // calendar useState
  const [date, setDate] = useState(new Date());
  const [event, setEvent] = useState();
  const [period, setperiod] = useState();
  const [estimate, setEstimate] = useState();
  const [previous, setPrevious] = useState();
  const [actual, setActual] = useState();
  const [note, setNote] = useState();
  const [bullish, setBullish] = useState();
  const [bearish, setBearish] = useState();
  const [neutral, setNeutral] = useState();
  //WYSIWYG editor
  const modules = isMobile
    ? { toolbar: [[{ color: [] }], ["bold", "italic"], ["clean"]] }
    : {
        toolbar: [
          [{ color: [] }, { background: [] }],
          // [{ font: [] }],
          ["bold", "italic", "underline", "strike"],
          [{ align: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["clean"],
        ],
        // handlers: {
        //   image: handleClick,
        // },
      };
  // const handleClick = () => {
  //   imageRef.current.click();
  // };
  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      setImage(img);
    }
  };
  // HANDLE SUBMIT NEW POST
  const handleSubmit = async (e) => {
    if (!user) {
      setError("You must be logged in");
      return;
    }
    e.preventDefault();

    if (!body) {
      setError("Please enter a message & must be at least 5 character");
      return;
    }

    if (user) {
      const formData = new FormData();
      formData.append("username", user.user.username);
      formData.append("userId", user.user._id);
      formData.append("role", user.user.role);
      formData.append("body", body);
      formData.append("category", category);
      if (chart) {
        if (!tradingviewImage) {
          setError("Please paste your tradingview url link");
          return;
        }
        if (
          chart &&
          tradingviewImage &&
          !tradingviewLinkRegex.test(tradingviewImage)
        ) {
          // handle error if chart is selected, tradingviewImage is provided, but it is not a valid TradingView link
          setError("Invalid TradingView URL link");
          return;
        }
        formData.append("tradingviewImage", tradingviewImage);
        formData.append("tag", tag);
      }
      if (video) {
        if (!youtubeId) {
          setError("Please paste youtube ID only not whole youtube link");
          return;
        }
        formData.append("youtubeId", youtubeId);
      } else if (image) {
        const newImageName = Date.now() + "." + image.name.split(".").pop();
        formData.append("image", image);
        formData.append("image", newImageName);
      }
      try {
        const res = await axios.post("/api/posts", formData, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${user.token}`,
          },
        });
        resetForm();
        postsDispatch({ type: "CREATE_POST", payload: res.data });
        setChart(false);
        setVideo(false);
        {
          isMobile && props.handleCloseModal();
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          setError(err.response.data.message);
        } else {
          setError("An error occurred while creating the post.");
        }
        console.log(err);
      }
    }
  };

  // HANDLE SUBMIT CALENDAR
  const handleCalendarSubmit = async (e) => {
    if (!user) {
      setError("You must be logged in");
      return;
    }
    e.preventDefault();
    const CalToAdd = JSON.stringify({
      userId: user.user._id,
      username: user.user.username,
      date: date,
      event: event,
      actual: actual,
      estimate: estimate,
      previous: previous,
      note: note,
      tags: tags,
    });
    const response = await fetch("/api/calendar", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: CalToAdd,
    });
    const json = await response.json();
    if (!response.ok) {
      setError(json.message);
    }
    if (response.ok) {
      resetForm();
      calendarsDispatch({ type: "CREATE_CALENDAR", payload: json });
      setCalendar(false);
    } else {
      console.error("error");
    }
  };
  // HANDLE SUBMIT SENTIMENT
  const handleSentimentSubmit = async (e) => {
    if (!user) {
      setError("You must be logged in");
      return;
    }
    e.preventDefault();
    const SentimentToAdd = JSON.stringify({
      date: date,
      bullish: bullish,
      bearish: bearish,
      neutral: neutral,
    });
    const response = await fetch("/api/sentiment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: SentimentToAdd,
    });
    const json = await response.json();
    if (!response.ok) {
      setError(json.message);
    }
    if (response.ok) {
      resetForm();
      setSentiment(false);
    } else {
      console.error("error");
    }
  };
  // HANDLE SUBMIT BREAKINGNEW
  const handleBreakingNewSubmit = async (e) => {
    if (!user) {
      setError("You must be logged in");
      return;
    }
    e.preventDefault();
    const newToAdd = JSON.stringify({
      userId: user.user._id,
      username: user.user.username,
      title: title,
      body: body,
      source: source,
      link: link,
      tags: tags,
    });
    const response = await fetch("/api/new", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: newToAdd,
    });
    const json = await response.json();
    if (!response.ok) {
      setError(json.message);
    }
    if (response.ok) {
      resetForm();
      breakingNewsDispatch({ type: "CREATE_BREAKINGNEW", payload: json });
      setMarketNew(false);
    } else {
      console.error("error");
    }
  };
  const editorStyle = {
    backgroundColor: "transparent",
    border: "1px",
    // height: "300px",
  };
  return (
    <>
      {user && (
        <div className="PostShare">
          {/* <img src={user.user.avatarPicture} alt="" /> */}
          <div>
            <div className="postOption">
              <>
                <div
                  className="otpion"
                  onClick={() => {
                    {
                      imageRef.current.click();
                    }
                  }}
                >
                  <Icon
                    name="images"
                    size="big"
                    style={{ color: "var(--photo)" }}
                  />
                  Photo
                </div>

                <>
                  <div
                    className="otpion"
                    onClick={() => {
                      setVideo(false);
                      setMarketNew(false);
                      setCalendar(false);
                      setPost(false);
                      setChart((prevValue) => !prevValue);
                      setError(false);
                      resetForm();
                    }}
                  >
                    <Icon
                      name="chart line"
                      size="big"
                      style={{ color: "var(--chart)" }}
                    />
                    Chart
                  </div>
                </>
              </>

              {(user.user.isAdmin === true || user.user.role === 1) && (
                <>
                  <div
                    className="otpion"
                    onClick={() => {
                      setVideo((prevValue) => !prevValue);
                      setChart(false);
                      setMarketNew(false);
                      setCalendar(false);
                      setPost(false);
                      setError(false);
                      resetForm();
                    }}
                  >
                    <Icon
                      name="youtube"
                      size="big"
                      style={{ color: "var(--source)" }}
                    />
                    Video
                  </div>
                </>
              )}
              {(user.user.isAdmin === true ||
                user.user.role === 1 ||
                user.user.role === 2 ||
                user.user.role === 3) && (
                <>
                  <div
                    className="otpion"
                    onClick={() => {
                      setMarketNew((prevValue) => !prevValue);
                      setChart(false);
                      setVideo(false);
                      setPost(false);
                      setError(false);
                      setCalendar(false);
                      resetForm();
                    }}
                  >
                    <Icon
                      name="newspaper"
                      size="big"
                      style={{ color: "var(--new)" }}
                    />
                    New
                  </div>
                  <div className="otpion">
                    <AlertModal />
                  </div>
                  <div className="otpion">
                    <WatchListModal />
                  </div>
                  {/* <div className="otpion">
                    <EarningsModal />
                  </div> */}
                </>
              )}
              {/* Calendar Not Using Anymore...but still working Great */}
              {/* {user.user.isAdmin === true && (
                <div
                  className="otpion"
                  onClick={() => {
                    setCalendar((prevValue) => !prevValue);
                    setChart(false);
                    setMarketNew(false);
                    setVideo(false);
                    setPost(false);
                    setError(false);
                    resetForm();
                  }}
                >
                  <Icon
                    name="newspaper"
                    size="big"
                    style={{ color: "var(--source)" }}
                  />
                  Cal
                </div>
              )} */}
              {user.user.isAdmin === true && (
                <div
                  className="otpion"
                  onClick={() => {
                    setSentiment((prevValue) => !prevValue);
                    setCalendar(false);
                    setChart(false);
                    setMarketNew(false);
                    setVideo(false);
                    setPost(false);
                    setError(false);
                    resetForm();
                  }}
                >
                  <Icon
                    name="newspaper"
                    size="big"
                    style={{ color: "var(--source)" }}
                  />
                  Ratio
                </div>
              )}
              {/* hidden the chooise file input */}
              <div style={{ display: "none" }}>
                <input
                  type="file"
                  filename="image"
                  name="image"
                  ref={imageRef}
                  onChange={onImageChange}
                />
              </div>
            </div>
            {chart && (
              <>
                {error && (
                  <Message size="mini" color="red">
                    {error}
                  </Message>
                )}
                {(user.user.isAdmin === true ||
                  user.user.role === 1 ||
                  user.user.role === 2 ||
                  user.user.role === 3) && (
                  <Dropdown
                    placeholder="Select category"
                    selection
                    options={categoryOptions}
                    value={category}
                    onChange={(event, { value }) => setCategory(value)}
                  />
                )}
                <Form.Field>
                  {isFocused === "body" && (
                    <Label pointing="below">Share your idea here...</Label>
                  )}
                  <div>
                    <ReactQuill
                      className="my-custom-class"
                      onFocus={() => setIsFocused("body")}
                      onBlur={() => setIsFocused(null)}
                      placeholder="* Share your trade plan here."
                      onChange={(newValue) => setBody(newValue)}
                      value={body}
                      modules={modules}
                    />
                  </div>
                </Form.Field>
                <Form.Field>
                  {isFocused === "tradingviewImage" && (
                    <Label pointing="below">Tradingview chart url</Label>
                  )}
                  <Input
                    type="text"
                    placeholder="* Please paste tradingview images url only"
                    onChange={(e) => setTradingviewImage(e.target.value)}
                    value={tradingviewImage}
                    fluid
                    onFocus={() => setIsFocused("tradingviewImage")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
                <Form.Field>
                  {isFocused === "tag" && (
                    <Label pointing="below">
                      Please Enter Ticker (Optional)
                    </Label>
                  )}
                  <Input
                    type="text"
                    placeholder="Related ticker for the post"
                    onChange={(e) => setTag(e.target.value)}
                    value={tag}
                    fluid
                    onFocus={() => setIsFocused("tag")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
                {body && tradingviewImage && (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <Button
                        color="blue"
                        onClick={handleSubmit}
                        style={{ cursor: "pointer" }}
                      >
                        SHARE
                      </Button>
                    </div>
                  </>
                )}
              </>
            )}
            {video && (
              <>
                {error && (
                  <Message size="mini" color="red">
                    {error}
                  </Message>
                )}
                <Form.Field>
                  {isFocused === "body" && (
                    <Label pointing="below">Write something here...</Label>
                  )}
                  <div>
                    <ReactQuill
                      className="my-custom-class"
                      onFocus={() => setIsFocused("body")}
                      onBlur={() => setIsFocused(null)}
                      placeholder="* Write something here..."
                      onChange={(newValue) => setBody(newValue)}
                      value={body}
                      modules={modules}
                    />
                  </div>
                </Form.Field>
                <Form.Field>
                  {isFocused === "youtubeId" && (
                    <Label pointing="below">
                      Please Paste Youtube URL link here
                    </Label>
                  )}
                  <Input
                    type="text"
                    placeholder="* Youtube URL link here"
                    onChange={(e) => setYoutubeId(e.target.value)}
                    value={youtubeId}
                    fluid
                    onFocus={() => setIsFocused("youtubeId")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
                {body && youtubeId && (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <Button
                        color="blue"
                        onClick={handleSubmit}
                        style={{ cursor: "pointer" }}
                      >
                        SHARE
                      </Button>
                    </div>
                  </>
                )}
              </>
            )}

            {marketNew && (
              <>
                {error && (
                  <Message size="mini" color="red">
                    {error}
                  </Message>
                )}
                <Form.Field>
                  {isFocused === "title" && (
                    <Label pointing="below">The breakingnew title</Label>
                  )}
                  <Input
                    type="text"
                    placeholder="* The breakingnew title"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    fluid
                    onFocus={() => setIsFocused("title")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
                <Form.Field>
                  {isFocused === "body" && (
                    <Label pointing="below">
                      Please write your breaking new
                    </Label>
                  )}
                  <ReactQuill
                    className="my-custom-class"
                    onFocus={() => setIsFocused("body")}
                    onBlur={() => setIsFocused(null)}
                    placeholder="* Your Breaking New here..."
                    onChange={(newValue) => setBody(newValue)}
                    value={body}
                    modules={modules}
                  />
                </Form.Field>
                <Form.Field>
                  {isFocused === "link" && (
                    <Label pointing="below">Source URL New</Label>
                  )}
                  <Input
                    type="text"
                    placeholder="Source URL"
                    onChange={(e) => setLink(e.target.value)}
                    value={link}
                    fluid
                    onFocus={() => setIsFocused("link")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
                <Form.Field>
                  {isFocused === "source" && (
                    <Label pointing="below">The Source</Label>
                  )}
                  <Input
                    type="text"
                    placeholder="* Source (FOX, NBC, CNN...)"
                    onChange={(e) => setSource(e.target.value)}
                    value={source}
                    fluid
                    onFocus={() => setIsFocused("source")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
                <Form.Field>
                  {isFocused === "tag" && (
                    <Label pointing="below">Tags (SPY, AAPL, META, TSLA)</Label>
                  )}
                  <Input
                    type="text"
                    placeholder="Ticker Relate (SPY, AAPL, META, TSLA...)"
                    fluid
                    onFocus={() => setIsFocused("tag")}
                    onBlur={() => setIsFocused(null)}
                    onKeyDown={(e) => {
                      if (e.key === " ") {
                        e.preventDefault(); // prevent the space from being typed
                        setTags((prevTags) => [...prevTags, " "]); // add an empty tag
                      } else if (
                        e.key === "Backspace" &&
                        tags[tags.length - 1] === ""
                      ) {
                        e.preventDefault(); // prevent the backspace from navigating back
                        setTags((prevTags) => prevTags.slice(0, -1)); // remove the last empty tag
                      }
                    }}
                    onChange={(e) =>
                      setTags(e.target.value.toUpperCase().split(","))
                    }
                    value={tags}
                  />
                </Form.Field>
                {title && body && source && (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <Button
                        color="blue"
                        onClick={handleBreakingNewSubmit}
                        style={{ cursor: "pointer" }}
                      >
                        POST
                      </Button>
                    </div>
                  </>
                )}
              </>
            )}
            {post && (
              <>
                {error && (
                  <Message size="mini" color="red">
                    {error}
                  </Message>
                )}
                {user.user.isAdmin === true ||
                user.user.role === 1 ||
                user.user.role === 2 ? (
                  <>
                    <Dropdown
                      placeholder="Select category"
                      selection
                      options={categoryOptions}
                      value={category}
                      onChange={(event, { value }) => setCategory(value)}
                    />{" "}
                    {(category === "Technical Learning" ||
                      category === "Analysis") && (
                      <Form.Field>
                        {isFocused === "topic" && (
                          <Label pointing="below">Topic</Label>
                        )}
                        <Input
                          fluid
                          onFocus={() => setIsFocused("topic")}
                          onBlur={() => setIsFocused(null)}
                          type="text"
                          placeholder="Topic"
                          onChange={(e) => setTopic(e.target.value)}
                          value={topic}
                          required
                        />
                      </Form.Field>
                    )}
                  </>
                ) : (
                  <Dropdown
                    placeholder="Select category"
                    selection
                    options={categoryOptionsMember}
                    value={category}
                    onChange={(event, { value }) => setCategory(value)}
                  />
                )}

                <Form.Field>
                  {isFocused === "post" && (
                    <Label pointing="below">Share your ideas here...</Label>
                  )}
                  <div>
                    <ReactQuill
                      className="my-custom-class"
                      onFocus={() => setIsFocused("post")}
                      onBlur={() => setIsFocused(null)}
                      placeholder={metaData.MESSAGE.SHARING_SOMETHING}
                      onChange={(newValue) => setBody(newValue)}
                      value={body}
                      modules={modules}
                    />
                  </div>
                </Form.Field>
                {isFocused === "post" && (
                  <div style={{ textAlign: "center" }}>
                    <Button
                      color="blue"
                      onClick={handleSubmit}
                      style={{ cursor: "pointer" }}
                    >
                      Share
                    </Button>
                  </div>
                )}
              </>
            )}

            {calendar && (
              <>
                <Form.Field>
                  {isFocused === "event" && (
                    <Label pointing="below">
                      Event Title FOMC, GDP, PCE...
                    </Label>
                  )}
                  <Input
                    fluid
                    onFocus={() => setIsFocused("event")}
                    onBlur={() => setIsFocused(null)}
                    type="text"
                    placeholder="Event title"
                    onChange={(e) => setEvent(e.target.value)}
                    value={event}
                    required
                  />
                </Form.Field>

                <Form.Field>
                  {isFocused === "date" && (
                    <Label pointing="below">Please Pick Event Date</Label>
                  )}
                  <div className="cal-date">
                    <DatePicker
                      showTimeSelect
                      dateFormat="Pp"
                      selected={date}
                      onChange={(date) => setDate(date)}
                      onFocus={() => setIsFocused("date")}
                      onBlur={() => setIsFocused(null)}
                    />
                  </div>
                </Form.Field>
                <Form.Field>
                  {isFocused === "estimate" && (
                    <Label pointing="below">Estimate Report</Label>
                  )}
                  <Input
                    fluid
                    onFocus={() => setIsFocused("estimate")}
                    onBlur={() => setIsFocused(null)}
                    type="text"
                    placeholder="Estimate report"
                    onChange={(e) => setEstimate(e.target.value)}
                    value={estimate}
                    required
                  />
                </Form.Field>
                <Form.Field>
                  {isFocused === "previous" && (
                    <Label pointing="below">Last Report</Label>
                  )}
                  <Input
                    fluid
                    onFocus={() => setIsFocused("previous")}
                    onBlur={() => setIsFocused(null)}
                    type="text"
                    placeholder="Previous Report"
                    onChange={(e) => setPrevious(e.target.value)}
                    value={previous}
                    required
                  />
                </Form.Field>
                <Form.Field>
                  {isFocused === "actual" && (
                    <Label pointing="below">Actual Report</Label>
                  )}
                  <Input
                    fluid
                    onFocus={() => setIsFocused("actual")}
                    onBlur={() => setIsFocused(null)}
                    type="text"
                    placeholder="Actual report"
                    onChange={(e) => setActual(e.target.value)}
                    value={actual}
                    required
                  />
                </Form.Field>
                <Form.Field>
                  {isFocused === "note" && (
                    <Label pointing="below">Import Note</Label>
                  )}
                  <Input
                    fluid
                    onFocus={() => setIsFocused("note")}
                    onBlur={() => setIsFocused(null)}
                    type="text"
                    placeholder="import note"
                    onChange={(e) => setNote(e.target.value)}
                    value={note}
                    required
                  />
                </Form.Field>
                <Form.Field>
                  {isFocused === "tags" && (
                    <Label pointing="below">Tags your ticker here...</Label>
                  )}
                  <Input
                    fluid
                    onFocus={() => setIsFocused("tags")}
                    onBlur={() => setIsFocused(null)}
                    type="text"
                    placeholder="Ticker Relate (SPY, AAPL, META, TSLA...)"
                    onKeyDown={(e) => {
                      if (e.key === " ") {
                        e.preventDefault(); // prevent the space from being typed
                        setTags((prevTags) => [...prevTags, " "]); // add an empty tag
                      } else if (
                        e.key === "Backspace" &&
                        tags[tags.length - 1] === ""
                      ) {
                        e.preventDefault(); // prevent the backspace from navigating back
                        setTags((prevTags) => prevTags.slice(0, -1)); // remove the last empty tag
                      }
                    }}
                    onChange={(e) =>
                      setTags(e.target.value.toUpperCase().split(","))
                    }
                    value={tags}
                  />
                </Form.Field>
                {error && <div className="error">{error}</div>}

                <form>
                  <Button onClick={handleCalendarSubmit}>POST</Button>
                </form>
              </>
            )}

            {/* sentiment */}
            {sentiment && (
              <>
                <Form.Field>
                  {isFocused === "date" && (
                    <Label pointing="below">Please Pick Report Date</Label>
                  )}
                  <div className="cal-date">
                    <DatePicker
                      // showTimeSelect
                      // dateFormat="Pp"
                      selected={date}
                      onChange={(date) => setDate(date)}
                      onFocus={() => setIsFocused("date")}
                      onBlur={() => setIsFocused(null)}
                    />
                  </div>
                </Form.Field>
                <Form.Field>
                  {isFocused === "bullish" && (
                    <Label pointing="below">Bullish %</Label>
                  )}
                  <Input
                    fluid
                    onFocus={() => setIsFocused("bullish")}
                    onBlur={() => setIsFocused(null)}
                    type="text"
                    placeholder="Bullish report"
                    onChange={(e) => setBullish(e.target.value)}
                    value={bullish}
                    required
                  />
                </Form.Field>
                <Form.Field>
                  {isFocused === "bearish" && (
                    <Label pointing="below">Bearish %</Label>
                  )}
                  <Input
                    fluid
                    onFocus={() => setIsFocused("bearish")}
                    onBlur={() => setIsFocused(null)}
                    type="text"
                    placeholder="Bearish report"
                    onChange={(e) => setBearish(e.target.value)}
                    value={bearish}
                    required
                  />
                </Form.Field>

                <Form.Field>
                  {isFocused === "neutral" && (
                    <Label pointing="below">Neutral %</Label>
                  )}
                  <Input
                    fluid
                    onFocus={() => setIsFocused("neutral")}
                    onBlur={() => setIsFocused(null)}
                    type="text"
                    placeholder="Neutral report"
                    onChange={(e) => setNeutral(e.target.value)}
                    value={neutral}
                    required
                  />
                </Form.Field>
                {error && <div className="error">{error}</div>}

                <form>
                  <Button onClick={handleSentimentSubmit}>POST</Button>
                </form>
              </>
            )}

            {image && (
              <div className="previewImage">
                <Icon
                  className="icon"
                  name="close"
                  size="big"
                  style={{ color: "var(--blue)" }}
                  onClick={() => setImage(null)}
                />
                <img
                  style={{ width: "50%", maxhHeight: "300px" }}
                  src={URL.createObjectURL(image)}
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PostShare;
