import React from "react";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import "./Ipo.css";
import { Popup, Button, Icon, Image, Card } from "semantic-ui-react";
import moment from "moment";
moment().format();
const Ipo = ({ ipo }) => {
  const { user } = useAuthContext();
  return (
    <>
      <Card>
        <Card.Content>
          {/* <Image avatar floated="right" size="large" src={ipo.iconUrl} /> */}
          <Card.Header>{ipo.symbol}</Card.Header>

          <Card.Meta>{ipo.name}</Card.Meta>
          <Card.Description>
            <Icon name="dollar sign" />
            <strong>Price: </strong> {ipo.price ? ipo.price : "N/A"}
            <br />
            <Icon name="globe" />
            <strong>Exchange: </strong> {ipo.exchange}
            <br />
            <Icon name="ticket alternate" />
            <strong>NumberOfShares: </strong> {ipo.numberOfShares}
            <br />
            <Icon name="usd" />
            <strong>SharesValue: </strong> {ipo.totalSharesValue}
            <br />
            <Icon name="calendar alternate outline" />
            <strong>Date: </strong>
            {moment(ipo.date).format("MMM, D YYYY")}
            <br />
          </Card.Description>
          <Card.Meta></Card.Meta>
        </Card.Content>
      </Card>
    </>
  );
};

export default Ipo;
