import React, { useState, useEffect, useContext } from "react";
import { useBreakingNewContext } from "../../../Hooks/useBreakingNewContext";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import { useMetaData } from "../../../Context/MetaDataContext";
import "./BreakingNew.css";
import { isMobile } from "react-device-detect";
import { Icon, Card, Modal, Button, Input, Image } from "semantic-ui-react";
import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import moment from "moment";
import BreakingNewUpdateModal from "../../Modal/BreakingNewUpdateModal";
import { SearchTickerContext } from "../../../Context/SearchTickerContext";
moment().format();

const BreakingNew2 = ({
  breakingNew,
  onDelete,
  onLike,
  onDisLike,
  onUpdate,
}) => {
  const { user } = useAuthContext();
  const [isExpanded, setIsExpanded] = useState(false);
  const { metaData } = useMetaData();
  const [color, setColor] = useState("red");
  const [open, setOpen] = useState(false);
  const { handleTickerClick } = useContext(SearchTickerContext);
  const [translatedContent, setTranslatedContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shareableLink, setShareableLink] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const currentDomain = window.location.origin;
  const onShare = () => {
    const link = `${currentDomain}/breakingnew/${breakingNew._id}`;
    setShareableLink(link);
    setIsCopied(false);
    setIsModalOpen(true);
  };
  const unRegister = () => {
    setTranslatedContent("Bạn cần đăng nhập để đọc tiếng Việt");
  };
  const unRegister2 = () => {
    setTranslatedContent("You need to login to read in English");
  };
  const copyToClipboard = () => {
    const input = document.getElementById("shareableLink");
    input.select();
    document.execCommand("copy");
    setIsCopied(true);
  };
  const shareOnFacebook = () => {
    window.FB.ui({
      method: "share",
      href: shareableLink,
    });
  };
  const shareOnTwitter = () => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      shareableLink
    )}`;
    window.open(twitterShareUrl, "_blank");
  };
  const handleDoubleClick = () => {
    setOpen(false);
  };
  const translateToVietnamese = async () => {
    const response = await fetch(
      `https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from=en&to=vi`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key":
            process.env.REACT_APP_MSFT_SUBSCRIPTION_ID,
          "Ocp-Apim-Subscription-Region": process.env.REACT_APP_YOUR_REGION,
        },
        body: JSON.stringify([{ Text: breakingNew.body }]),
      }
    );
    const data = await response.json();
    setTranslatedContent(data[0].translations[0].text);
  };
  const translateToEnglish = async () => {
    const response = await fetch(
      `https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from=vi&to=en`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key":
            process.env.REACT_APP_MSFT_SUBSCRIPTION_ID,
          "Ocp-Apim-Subscription-Region": process.env.REACT_APP_YOUR_REGION,
        },
        body: JSON.stringify([{ Text: breakingNew.body }]),
      }
    );
    const data = await response.json();
    setTranslatedContent(data[0].translations[0].text);
  };
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  const tagsWithColors = breakingNew.tags.map((tag) => ({
    tag,
    color: getRandomColor(),
  }));
  //WYSIWYG editor
  const modules = {
    toolbar: [
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["link"],
      ["clean"],
    ],
  };

  const MAX_DESCRIPTION_WORDS = 20;
  const truncatedDescription = breakingNew.body
    .split(" ")
    .slice(0, MAX_DESCRIPTION_WORDS)
    .join(" ");
  const [isRead, setIsRead] = useState(false);
  const cardColor = isRead ? "red" : "green";
  const handleReadMoreClick = () => {
    setOpen(false);
    // setIsExpanded((prevValue) => !prevValue);
    setIsRead(true);
  };
  const description = isExpanded
    ? breakingNew.body
    : `${truncatedDescription}...`;

  return (
    <>
      {breakingNew && (
        <>
          <Card color={cardColor} style={{ minWidth: "300px" }}>
            <Card.Content>
              <Card.Meta>
                {moment(breakingNew.createdAt).startOf("minutes").fromNow()}
              </Card.Meta>
              <Card.Header>
                <Modal
                  dimmer="blurring"
                  centered={false}
                  closeIcon
                  onClose={handleReadMoreClick}
                  onOpen={() => setOpen(true)}
                  open={open}
                  trigger={
                    <p
                      style={
                        !isMobile ? { width: " 100%" } : { width: " 250px" }
                      }
                    >
                      {breakingNew.title}...
                    </p>
                  }
                >
                  {user &&
                  (user.user.role === 1 ||
                    user.user.role === 2 ||
                    user.user.role === 3 ||
                    user.user.role === 4 ||
                    user.user.role === 5 ||
                    user.user.role === 6 ||
                    user.user.role === 10) ? (
                    <>
                      <Modal.Content
                        style={{ fontSize: "20px" }}
                        onDoubleClick={handleDoubleClick}
                      >
                        <>
                          <Button
                            basic
                            color="red"
                            onClick={translateToVietnamese}
                          >
                            VN
                          </Button>
                          <Button
                            basic
                            color="blue"
                            onClick={translateToEnglish}
                          >
                            ENG
                          </Button>
                          <p key="source">Posted by {breakingNew.source}</p>
                          <hr />
                          <div
                            className="modal-content-container"
                            dangerouslySetInnerHTML={{
                              __html: translatedContent || breakingNew.body,
                            }}
                          />
                          {breakingNew.image && (
                            <Image
                              src={breakingNew.image}
                              href={breakingNew.image}
                              target="_blank"
                              centered
                              fluid
                            />
                          )}
                          {breakingNew.link && (
                            <a
                              href={breakingNew.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Read more...
                            </a>
                          )}

                          <hr />
                          <Card.Meta className="button-group">
                            {moment(breakingNew.createdAt).format(
                              "MMM, D YYYY | h:mm:ss A"
                            )}
                            {user &&
                              (user.user.isAdmin === true ||
                                user.user.role === 1 ||
                                user.user.role === 2 ||
                                user.user.role === 3 ||
                                user.user._id === breakingNew.userId) && (
                                <>
                                  <div
                                    onClick={() => onDelete(breakingNew._id)}
                                  >
                                    <Icon name="delete" />
                                  </div>
                                  <BreakingNewUpdateModal
                                    breakingNew={breakingNew}
                                    onUpdate={onUpdate}
                                  />
                                </>
                              )}
                            <div onClick={() => onShare(breakingNew._id)}>
                              <Icon name="share" />
                            </div>
                            <Modal
                              open={isModalOpen}
                              onClose={() => setIsModalOpen(false)}
                            >
                              <Modal.Header>Share Link</Modal.Header>
                              <Modal.Content>
                                <Input
                                  fluid
                                  id="shareableLink"
                                  value={shareableLink}
                                  readOnly
                                  action={
                                    <>
                                      {" "}
                                      <Button
                                        onClick={copyToClipboard}
                                        disabled={isCopied}
                                      >
                                        {isCopied ? "Copied" : "Copy"}
                                      </Button>
                                      <Button
                                        onClick={shareOnTwitter}
                                        icon="twitter"
                                      />
                                      <Button
                                        onClick={shareOnFacebook}
                                        icon="facebook"
                                      />
                                    </>
                                  }
                                />
                              </Modal.Content>
                            </Modal>
                          </Card.Meta>
                          <hr />
                          <Card.Meta className="button-group">
                            {tagsWithColors.map(({ tag, color }) => (
                              <span
                                key={tag}
                                style={{ color: color }}
                                onClick={() => handleTickerClick(tag)}
                              >
                                {tag}
                              </span>
                            ))}
                          </Card.Meta>
                        </>
                      </Modal.Content>
                    </>
                  ) : (
                    <>
                      <Modal.Content>
                        {metaData.MESSAGE.PLEASE_LOGIN_BREAKING_NEW}
                      </Modal.Content>
                    </>
                  )}
                </Modal>
              </Card.Header>
              <Card.Meta className="button-group">
                {tagsWithColors.map(({ tag, color }) => (
                  <span
                    key={tag}
                    style={{ color: color }}
                    onClick={() => handleTickerClick(tag)}
                  >
                    {tag}
                  </span>
                ))}
              </Card.Meta>
            </Card.Content>
          </Card>
        </>
      )}
    </>
  );
};

export default BreakingNew2;
