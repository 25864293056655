import React, { useState, useEffect } from "react";
import "./Posts.css";
import { Link } from "react-router-dom";
import { Message, Feed, Divider, Button, Icon } from "semantic-ui-react";
import Post from "./Post/Post";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { usePostsContext } from "../../Hooks/usePostsContext";
import { isMobile } from "react-device-detect";
import noAvatar from "../../assets/img/noAvatar.png";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
const PostSingleView = () => {
  const { post, dispatch } = usePostsContext();
  const [error, setError] = useState(null);
  const { user } = useAuthContext();
  const { id } = useParams();
  // GET THE POSTS DATA FROM BACKEND
  useEffect(
    () => {
      const fetchData = async () => {
        try {
          const response = await fetch(`/api/posts/${id}`);
          if (response.ok) {
            const json = await response.json();
            dispatch({ type: "FETCH_SINGLE_POST", payload: json });
          }
        } catch (error) {
          setError(error.message);
        }
      };
      fetchData();
    },
    [dispatch],
    id
  );
  // LIKE HANDLER
  const handleLike = async (eventId) => {
    if (!user || !user.user || !user.user.username) {
      return;
    }

    const response = await fetch("/api/posts/" + eventId + "/like", {
      method: "PUT",
      body: JSON.stringify({ username: user.user.username }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      //   dispatch({
      //     type: "POST_LIKE",
      //     payload: { id: eventId, user: user.user.username },
      //   });
      window.location.href = `/p/${eventId}`;
    } else {
      console.error("Error liking post");
    }
  };
  // DISLIKE HANDLER
  const handleDisLike = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/posts/" + eventId + "/disLike", {
      method: "PUT",
      body: JSON.stringify({ username: user.user.username }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      //   dispatch({
      //     type: "POST_DISLIKE",
      //     payload: { id: eventId, user: user.user.username },
      //   });
      window.location.href = `/p/${eventId}`;
    } else {
      console.error("error");
    }
  };
  // handle Update
  const handleUpdate = async (eventId, post) => {
    if (!user) {
      return;
    }
    // dispatch({
    //   type: "POST_UPDATE",
    //   payload: { id: eventId, data: post },
    // });
    window.location.href = `/p/${eventId}`;
  };
  // HANDLE DELETE
  const handleDelete = async (eventId) => {
    try {
      if (!user) {
        throw new Error("Please login");
      }
      const response = await fetch(`/api/posts/${eventId}`, {
        method: "DELETE",
        body: JSON.stringify({
          userId: user.user._id,
          userRole: user.user.role,
          isAdmin: user.user.isAdmin,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        // dispatch({ type: "DELETE_POST", payload: json });
        window.location.href = `/p/${eventId}`;
      } else {
        throw new Error("Failed to delete post");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>Smart Trading Plan</title>
        <meta
          name="description"
          content="Explore and share trading plans, strategies, and setups with the Smart Trading Plan community. Join us to enhance your trading skills and insights."
        />
        <meta
          name="keywords"
          content="insider transactions, stock market, trading activities, data analysis"
        />
        <meta name="author" content="Smart Trading Hub" />
      </Helmet>
      {user &&
      (user.user.role === 1 ||
        user.user.role === 2 ||
        user.user.role === 3 ||
        user.user.role === 4 ||
        user.user.role === 5 ||
        user.user.role === 6 ||
        user.user.role === 10) &&
      post ? (
        <div className="Posts">
          {error && <div>{error}</div>}
          <Divider horizontal>
            <Link to="/hub">
              <span className="hub">SMART TRADING PLAN</span>
            </Link>
          </Divider>
          <Feed size="large">
            <Post
              post={post}
              key={post._id}
              onUpdate={handleUpdate}
              onDelete={handleDelete}
              onLike={handleLike}
              onDisLike={handleDisLike}
            />
          </Feed>
        </div>
      ) : (
        <h1>SORRY NO PAGE WAS FOUND</h1>
      )}
    </>
  );
};

export default PostSingleView;
