import React, { useState, useEffect } from "react";
import "./AlertOption.css";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import { Divider, Card } from "semantic-ui-react";
import { useAlertsOptionContext } from "../../../Hooks/useAlertsOptionContext";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import AlertOption from "./AlertOption";
function AlertOptionPage() {
  <Helmet>
    <title>Testing</title>
  </Helmet>;
  const { user } = useAuthContext();
  const [error, setError] = useState(null);
  const { alertOption, dispatch } = useAlertsOptionContext();
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  // const [alert, setAlert] = useState(null);
  const { id } = useParams();

  const handleCloseModal = () => {
    setOpen(false);
  };
  // Get the Alert data from backend
  useEffect(
    () => {
      const fetchData = async () => {
        try {
          const response = await fetch(`/api/optionalert/${id}`);
          if (response.ok) {
            const json = await response.json();
            dispatch({ type: "FETCH_SINGLE_ALERT_OPTION", payload: json });
          }
        } catch (error) {
          setError(error.message);
        }
      };
      fetchData();
    },
    [dispatch],
    id
  );

  const handleLoadMore = () => {
    if (currentPage === totalPages) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };
  // Like Handler
  const handleLike = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/optionalert/" + eventId + "/like", {
      method: "PUT",
      body: JSON.stringify({ username: user.user.username }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      // dispatch({
      //   type: "ALERT_LIKE",
      //   payload: { id: eventId, user: user.user.username },
      // });
      window.location.href = `/optionalert/${id}`;
    } else {
      console.error("error");
    }
  };
  // DisLike Handler
  const handleDisLike = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/optionalert/" + eventId + "/disLike", {
      method: "PUT",
      body: JSON.stringify({ username: user.user.username }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      // dispatch({
      //   type: "ALERT_DISLIKE",
      //   payload: { id: eventId, user: user.user.username },
      // });
      window.location.href = `/optionalert/${id}`;
    } else {
      console.error("error");
    }
  };
  // HandlePlayer
  const handlePlayer = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/optionalert/" + eventId + "/player", {
      method: "PUT",
      body: JSON.stringify({ userId: user.user._id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      // dispatch({
      //   type: "ALERT_PLAYER",
      //   payload: { id: eventId, user: user.user._id },
      // });
      window.location.href = `/optionalert/${id}`;
    } else {
      console.error("error");
    }
  };
  // handle Update
  const handleUpdate = async (eventId, alert) => {
    if (!user) {
      return;
    }
    // dispatch({
    //   type: "ALERT_UPDATE",
    //   payload: { id: eventId, data: alert },
    // });
    window.location.href = `/optionalert/${id}`;
  };
  // handle Delete
  const handleDelete = async (eventId) => {
    try {
      if (!user) {
        throw new Error("Please login");
      }
      const response = await fetch(`/api/optionalert/${eventId}`, {
        method: "DELETE",
        body: JSON.stringify({
          userId: user.user._id,
          userRole: user.user.role,
          isAdmin: user.user.isAdmin,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        // dispatch({ type: "DELETE_ALERT", payload: json });
        window.location.href = `/optionalert/${id}`;
      } else {
        throw new Error("Failed to delete alert");
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  return (
    <>
      {error && <div>{error}</div>}
      {alertOption ? (
        <>
          {user &&
            (user.user.role === 1 ||
              user.user.role === 2 ||
              user.user.role === 3 ||
              user.user.role === 4 ||
              user.user.role === 5 ||
              user.user.role === 10 ||
              user.user.role === 6) && (
              <div className="AlertOption">
                <Divider horizontal onClick={handleLoadMore}>
                  <span className="AlertOption_Page">
                    {alertOption.ticker}'s ALERT
                  </span>
                </Divider>
                {/* <Card>
              <Card.Content>
                {alert.ticker}
                <Card.Header>{alert.ticker}</Card.Header>
              </Card.Content>
            </Card> */}
                <Card.Group centered>
                  <AlertOption
                    key={alertOption._id}
                    alertOption={alertOption}
                    onPlayer={handlePlayer}
                    onUpdate={handleUpdate}
                    onDelete={handleDelete}
                    onLike={handleLike}
                    onDisLike={handleDisLike}
                    handleCloseModal={handleCloseModal}
                  />
                </Card.Group>
              </div>
            )}
        </>
      ) : (
        <h1>NO ALERT FOUND</h1>
      )}
    </>
  );
}

export default AlertOptionPage;
