import React, { createContext, useReducer } from "react";
// import { useAuthContext } from "../Hooks/useAuthContext";
export const GeneralNewContext = createContext();

export const GeneralNewReducer = (state, action) => {
  let cloneGeneralNews;
  switch (action.type) {
    case "FETCH_GENERALNEW":
      return {
        generalNews: action.payload,
      };
    case "FETCH_SINGLE_GENERALNEW":
      return {
        generalNew: action.payload,
      };
    case "CREATE_GENERALNEW":
      return {
        generalNews: [action.payload, ...state.generalNews],
      };
    case "GENERALNEW_UPDATE":
      cloneGeneralNews = JSON.parse(JSON.stringify(state.generalNews));
      return {
        generalNews: cloneGeneralNews.map((generalNew) =>
          generalNew._id === action.payload.id
            ? action.payload.data
            : generalNew
        ),
      };
    case "GENERALNEW_BULL":
      cloneGeneralNews = JSON.parse(JSON.stringify(state.generalNews));
      cloneGeneralNews.forEach((generalNew) => {
        if (generalNew._id === action.payload.id) {
          if (!generalNew.bull.includes(action.payload.user)) {
            // if not exist then add in
            generalNew.bull.push(action.payload.user);
          } else {
            // If already exist then remove out
            generalNew.bull.splice(
              generalNew.bull.indexOf(action.payload.user)
            );
          }
        }
      });
      return {
        generalNews: cloneGeneralNews,
      };
    case "GENERALNEW_BEAR":
      cloneGeneralNews = JSON.parse(JSON.stringify(state.generalNews));
      cloneGeneralNews.forEach((generalNew) => {
        if (generalNew._id === action.payload.id) {
          if (!generalNew.bear.includes(action.payload.user)) {
            // if not exist then add in
            generalNew.bear.push(action.payload.user);
          } else {
            // If already exist then remove out
            generalNew.bear.splice(
              generalNew.bear.indexOf(action.payload.user)
            );
          }
        }
      });
      return {
        generalNews: cloneGeneralNews,
      };
    case "GENERALNEW_NEUTRAL":
      cloneGeneralNews = JSON.parse(JSON.stringify(state.generalNews));
      cloneGeneralNews.forEach((generalNew) => {
        if (generalNew._id === action.payload.id) {
          if (!generalNew.neutral.includes(action.payload.user)) {
            // if not exist then add in
            generalNew.neutral.push(action.payload.user);
          } else {
            // If already exist then remove out
            generalNew.neutral.splice(
              generalNew.neutral.indexOf(action.payload.user)
            );
          }
        }
      });
      return {
        generalNews: cloneGeneralNews,
      };
    case "GENERALNEW_PLAYER":
      cloneGeneralNews = JSON.parse(JSON.stringify(state.generalNews));
      cloneGeneralNews.forEach((generalNew) => {
        if (generalNew._id === action.payload.id) {
          if (!generalNew.player.includes(action.payload.user)) {
            // if not exist then add in
            generalNew.player.push(action.payload.user);
          } else {
            // If already exist then remove out
            generalNew.player.splice(
              generalNew.player.indexOf(action.payload.user)
            );
          }
        }
      });
      return {
        generalNews: cloneGeneralNews,
      };
    case "DELETE_GENERALNEW":
      return {
        generalNews: state.generalNews.filter(
          (generalNew) => generalNew._id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export const GeneralNewContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(GeneralNewReducer, {
    genralNews: null,
  });
  // console.log("generalNewContext state:", state);
  return (
    <GeneralNewContext.Provider value={{ ...state, dispatch }}>
      {children}
    </GeneralNewContext.Provider>
  );
};
