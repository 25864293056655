import React, { useState } from "react";
import "./TickerShare.css";
import {
  Button,
  Form,
  Label,
  Input,
  Message,
  TextArea,
  Checkbox,
} from "semantic-ui-react";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useWatchlistsContext } from "../../Hooks/useWatchlistContext";
const TickerShare = (props) => {
  const { user } = useAuthContext();
  const { dispatch } = useWatchlistsContext();
  const [ticker, setTicker] = useState();
  const [name, setName] = useState("");
  const [description, setDescription] = useState();
  const [country, setCountry] = useState();
  const [industry, setIndustry] = useState();
  const [sector, setSector] = useState();
  const [exchange, setExchange] = useState();
  const [ipo_date, setIpo_date] = useState();
  const [charts, setCharts] = useState();
  const [error, setError] = useState(null);
  const [term, setTerm] = useState("DAILY");
  const [isFocused, setIsFocused] = useState(null);
  const tradingviewLinkRegex =
    /^https:\/\/www\.tradingview\.com\/x\/[a-zA-Z0-9]+\/$/;

  const [support, setSupport] = useState([""]);
  const [resistance, setResistance] = useState([""]);
  const [analysts, setAnalysts] = useState();
  const [chart, setChart] = useState();
  const [call, setCall] = useState([""]);
  const [put, setPut] = useState([""]);
  const handleTickerSubmit = async (e) => {
    if (!user) {
      setError("You must be logged in");
      return;
    }
    e.preventDefault();
    if (chart && !tradingviewLinkRegex.test(chart)) {
      setError("Invalid TradingView URL link");
      return;
    }
    const formData = JSON.stringify({
      userId: user.user._id,
      username: user.user.username,
      ticker,
      name,
      description,
      country,
      industry,
      sector,
      ipo_date,
      charts,
    });
    const response = await fetch("/api/ticker", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: formData,
    });
    const json = await response.json();
    if (!response.ok) {
      setError(json.message);
    }
    if (response.ok) {
      setError(null);
      dispatch({ type: "CREATE_WATCHLIST", payload: json });
      props.handleCloseModal();
    } else {
      console.error("error");
    }
  };

  return (
    <>
      {user && (
        <div className="TickerShare">
          <img src={user.user.avatarPicture} alt={user.user.username} />
          <div className="watchListPost">
            {error && (
              <Message size="mini" color="red">
                {error}
              </Message>
            )}
            <Form.Field>
              {isFocused === "ticker" && (
                <Label pointing="below">Ticker Required</Label>
              )}
              <Input
                type="text"
                placeholder="* Ticker"
                onChange={(e) => setTicker(e.target.value)}
                value={ticker}
                fluid
                onFocus={() => setIsFocused("ticker")}
                onBlur={() => setIsFocused(null)}
              />
            </Form.Field>
            <Form.Field>
              {isFocused === "name" && (
                <Label pointing="below">Full name company</Label>
              )}
              <Input
                type="text"
                placeholder="* Name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                fluid
                onFocus={() => setIsFocused("name")}
                onBlur={() => setIsFocused(null)}
              />
            </Form.Field>
            <Form.Field>
              {isFocused === "description" && (
                <Label pointing="below">Description for this Ticker</Label>
              )}
              <TextArea
                style={{ width: "100%", height: "75px" }}
                type="text"
                placeholder="Description ..."
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                fluid
                onFocus={() => setIsFocused("description")}
                onBlur={() => setIsFocused(null)}
              />
            </Form.Field>
            <Form.Field>
              {isFocused === "country" && (
                <Label pointing="below">Full country company</Label>
              )}
              <Input
                type="text"
                placeholder="* Country"
                onChange={(e) => setCountry(e.target.value)}
                value={country}
                fluid
                onFocus={() => setIsFocused("country")}
                onBlur={() => setIsFocused(null)}
              />
            </Form.Field>
            <Form.Field>
              {isFocused === "industry" && (
                <Label pointing="below">industry</Label>
              )}
              <Input
                type="text"
                placeholder="* Industry"
                onChange={(e) => setIndustry(e.target.value)}
                value={industry}
                fluid
                onFocus={() => setIsFocused("industry")}
                onBlur={() => setIsFocused(null)}
              />
            </Form.Field>
            <Form.Field>
              {isFocused === "sector" && <Label pointing="below">sector</Label>}
              <Input
                type="text"
                placeholder="* Sector"
                onChange={(e) => setSector(e.target.value)}
                value={sector}
                fluid
                onFocus={() => setIsFocused("sector")}
                onBlur={() => setIsFocused(null)}
              />
            </Form.Field>
            <Form.Field>
              {isFocused === "exchange" && (
                <Label pointing="below">exchange</Label>
              )}
              <Input
                type="text"
                placeholder="* Exchange"
                onChange={(e) => setExchange(e.target.value)}
                value={exchange}
                fluid
                onFocus={() => setIsFocused("exchange")}
                onBlur={() => setIsFocused(null)}
              />
            </Form.Field>
            {/* <Form.Field>
              {isFocused === "chart" && (
                <Label pointing="below">Tradingview URL Chart Only</Label>
              )}
              <Input
                type="text"
                placeholder="Tradingview URL Chart Only"
                onChange={(e) => setChart(e.target.value)}
                value={chart}
                fluid
                onFocus={() => setIsFocused("chart")}
                onBlur={() => setIsFocused(null)}
              />
            </Form.Field> */}

            <div className="postOption">
              <Button
                className="share-button"
                style={{ color: "var(--blue)" }}
                onClick={handleTickerSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TickerShare;
