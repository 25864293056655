import React, { useState, useEffect } from "react";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import "./BreakingNew.css";
import { Icon, Form, Input, Label, Message, Button } from "semantic-ui-react";
import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import moment from "moment";
import "../../PostShare/PostShare.css";
moment().format();

const BreakingNew = ({ breakingNew, onUpdate, handleCloseModal }) => {
  const { user } = useAuthContext();
  const [title, setTitle] = useState();
  const [body, setBody] = useState();
  const [source, setSource] = useState();
  const [link, setLink] = useState();
  const [error, setError] = useState(null);
  const [tags, setTags] = useState([]);
   const [isFocused, setIsFocused] = useState(null);
  //WYSIWYG editor
  const modules = {
    toolbar: [
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["link"],
      ["clean"],
    ],
  };
  useEffect(() => {
    if (breakingNew) {
      setTitle(breakingNew.title);
      setBody(breakingNew.body);
      setSource(breakingNew.source);
      setLink(breakingNew.link);
      setTags(breakingNew.tags);
    }
  }, []);

  // HANDLE UPDATE POST
  const handleUpdtateNew = async (e) => {
    if (!user) {
      setError("You must be logged in");
      return;
    }
    e.preventDefault();
    const updateNewBody = JSON.stringify({
      userId: user.user._id,
      userRole: user.user.role,
      isAdmin: user.user.isAdmin,
      username: user.user.username,
      title,
      body,
      source,
      link,
      tags,
    });
    const response = await fetch("/api/new/" + breakingNew._id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: updateNewBody,
    });
    const json = await response.json();
    if (!response.ok) {
      setError(json.message);
    }
    if (response.ok) {
      setError(null);
      let deepClonePrev = JSON.parse(JSON.stringify(breakingNew));
      deepClonePrev = {
        ...deepClonePrev,
        title,
        body,
        source,
        link,
        tags,
      };
      onUpdate(breakingNew._id, deepClonePrev);
      handleCloseModal();
    } else {
      console.log("error");
    }
  };

  return (
    <div className="Update">
      {error && (
        <Message size="mini" color="red">
          {error}
        </Message>
      )}
      <Form.Field>
        {isFocused === "title" && (
          <Label pointing="below">The breakingnew title</Label>
        )}
        <Input
          type="text"
          placeholder="* The breakingnew title"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          fluid
          onFocus={() => setIsFocused("title")}
          onBlur={() => setIsFocused(null)}
        />
      </Form.Field>
      <Form.Field>
        {isFocused === "body" && (
          <Label pointing="below">Please write your breaking new</Label>
        )}
        <ReactQuill
          className="my-custom-class"
          onFocus={() => setIsFocused("body")}
          onBlur={() => setIsFocused(null)}
          placeholder="* Your Breaking New here..."
          onChange={(newValue) => setBody(newValue)}
          value={body}
          modules={modules}
        />
      </Form.Field>
      <Form.Field>
        {isFocused === "link" && <Label pointing="below">Source URL New</Label>}
        <Input
          type="text"
          placeholder="Source URL"
          onChange={(e) => setLink(e.target.value)}
          value={link}
          fluid
          onFocus={() => setIsFocused("link")}
          onBlur={() => setIsFocused(null)}
        />
      </Form.Field>
      <Form.Field>
        {isFocused === "source" && <Label pointing="below">The Source</Label>}
        <Input
          type="text"
          placeholder="* Source (FOX, NBC, CNN...)"
          onChange={(e) => setSource(e.target.value)}
          value={source}
          fluid
          onFocus={() => setIsFocused("source")}
          onBlur={() => setIsFocused(null)}
        />
      </Form.Field>
      <Form.Field>
        {isFocused === "tag" && (
          <Label pointing="below">Tags (SPY, AAPL, META, TSLA)</Label>
        )}
        <Input
          type="text"
          placeholder="Ticker Relate (SPY, AAPL, META, TSLA...)"
          fluid
          onFocus={() => setIsFocused("tag")}
          onBlur={() => setIsFocused(null)}
          onKeyDown={(e) => {
            if (e.key === " ") {
              e.preventDefault(); // prevent the space from being typed
              setTags((prevTags) => [...prevTags, " "]); // add an empty tag
            } else if (e.key === "Backspace" && tags[tags.length - 1] === "") {
              e.preventDefault(); // prevent the backspace from navigating back
              setTags((prevTags) => prevTags.slice(0, -1)); // remove the last empty tag
            }
          }}
          onChange={(e) => setTags(e.target.value.toUpperCase().split(","))}
          value={tags}
        />
      </Form.Field>
      <Button className="button" positive onClick={handleUpdtateNew}>
        Update
      </Button>
    </div>
  );
};

export default BreakingNew;
