import React from "react";
import { Modal, Icon, Button } from "semantic-ui-react";
import UpdateBreakingNew from "../BreakingNews/BreakingNew/UpdateBreakingNew.js";

const BreakingNewUpdateModal = ({ breakingNew, onUpdate }) => {
  const [open, setOpen] = React.useState(false);
  const handleCloseModal = () => {
    setOpen(false);
  };
  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      centered={false}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <div>
          {/* <Button style={{ borderRadius: 0 }} basic color="yellow"> */}
          <Icon name="edit" />
          {/* </Button> */}
        </div>
      }
    >
      <UpdateBreakingNew
        breakingNew={breakingNew}
        onUpdate={onUpdate}
        // alertOption={alertOption}
        // onUpdateOption={onUpdateOption}
        handleCloseModal={handleCloseModal}
      />
    </Modal>
  );
};

export default BreakingNewUpdateModal;
