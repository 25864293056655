import React, { useState, useEffect } from "react";
import "./Auth.css";
import { useRegister } from "../../Hooks/useRegister";
import { useLogin } from "../../Hooks/useLogin";
import { Icon, Message, Form, Button, Image, Modal } from "semantic-ui-react";
import logo from "../../assets/img/Banner.png";
import axios from "axios";
import { GoogleLogin } from "@react-oauth/google";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useMetaData } from "../../Context/MetaDataContext";
const Login = () => {
  const [isRegister, setIsRegister] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [confirmPass, setConfirmPass] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const { metaData } = useMetaData();
  const [googleCredential, setGoogleCredential] = useState(null);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleShowTermsModal = () => {
    setShowTermsModal(true);
  };

  const handleCloseTermsModal = () => {
    setShowTermsModal(false);
  };
  // Add your terms content here
  const termsContent = (
    <div>
      <h3>Refund and Return Policy</h3>
      <p>
        All sales are final, and that is why we provide a 14 day free trial to
        allow users the ability to understand and know exactly what they are
        getting before they make a purchase to continue. Refunds are only
        available in the cases of duplicate payments or overcharges. Your
        payment data is encrypted and secure. All amounts shown are in USD.
      </p>
      <h3>Additional terms for monthly and annual recurring subscriptions</h3>
      <p>
        You will automatically be charged at your renewal date unless cancelled.
        Cancellations must be made or requested before the renewal date and
        time. Renewal payments occur on the same date of the month and time of
        the day as your initial sign-up date and time.
      </p>
      <h3>TERMS AND CONDITIONS</h3>
      <p>
        The website(s) located at www.smarttradinghub.net is operated by SMART
        TRADING HUB LLC. These terms and conditions apply to your visit to and
        use of the website(s) as well as the social medias operated by SMART
        TRADING HUB LLC. and discord servers. The terms and conditions of use
        should be read carefully before you use the website(s), social medias,
        and/or discord servers. Your use of the website(s), social medias,
        and/or discord server constitutes your acceptance of the terms and
        conditions, as well as our privacy policy.
      </p>
      <h3>1. USE CONTENT AT USER’S OWN RISK</h3>
      <p>
        SMART TRADING HUB does not provide investment or financial advice. Nor
        does SMART TRADING HUB provide legal, tax or accounting advice-or advice
        regarding the suitability or profitability of a security or investment.
        The content on the website(s)s, discord servers, and/or social medias
        are solely for educational purposes and entertainment purposes only. The
        information on the website(s) is published generally—and is not personal
        to you, nor does it take into account your personal circumstances. Your
        use of the content on the website(s), social medias, and/or discord
        server is entirely at your own risk and it is your sole responsibility
        to evaluate the accuracy, completeness and usefulness of the content.
        SMART TRADING HUB encourages you to seek the advice of a qualified
        financial advisor before making financial decisions or investments.
      </p>
      <h3>2. NO WARRANTY / NO GUARANTEE</h3>
      <p>
        The information on the website(s), social medias, and/or discord server
        should not be intended to provide investment, tax or other financial
        related advice. The information on the website(s), social medias, and/or
        discord server should not be considered a substitute for professional
        financial advice. If you choose to engage in transactions based upon the
        content found on the website(s), social medias, and/or discord server,
        those decisions and transactions and any consequences flowing therefrom
        are your sole responsibility. SMART TRADING HUB does not provide
        investment advice directly, indirectly, implicitly, or in any manner
        whatsoever. You should use any information found on the website(s),
        social medias, and/or discord server merely as a starting point for your
        own independent research. SMART TRADING HUB does not guarantee the
        accuracy of data found on the website(s), social medias, and/or discord
        server. The material available on the website(s), social medias, and/or
        discord server has been produced by multiple independent sources. Any
        opinions or recommendations expressed in any third-party link, are
        solely those of the independent providers and are not the opinions or
        recommendations of SMART TRADING HUB Although SMART TRADING HUB makes
        every effort to ensure the accuracy of information on the website(s),
        social medias, and/or discord server, such accuracy is not guaranteed.
        You should always verify the accuracy of data by analyzing a company’s
        SEC filings before making any investment decisions.
      </p>
      <h3>3. LIMITATION OF LIABILITY</h3>
      <p>
        Under no circumstances shall SMART TRADING HUB be liable for any
        indirect, direct, incidental, special, punitive or consequential damages
        that result in any way from your use, non-use, or reliance upon the
        content on the website(s), social medias, and/or discord server. The
        content on the website(s) and/or discord server may contain links to
        other website(s)s. SMART TRADING HUB does not endorse the content on
        those website(s)s and will not be held responsible for the content on
        those website(s)s. The content on the website(s) and/or discord server
        is for general information only and is not intended to be relied upon by
        users in either making or not making specific investment decisions. the
        website(s), social medias, and/or discord server may contain
        advertisements that may direct you to other third- party website(s)s. X
        TRADES LLC is not affiliated with those third-party website(s)s. The
        display of advertisements on the website(s), social medias, and/or
        discord server does not constitute an endorsement by SMART TRADING HUB
        of the products or the services offered by these third parties. The
        website(s), social medias, and/or discord server may contain links to
        articles or commentary. This is provided solely as a convenience. The
        information contained in these articles or commentary is not endorsed by
        SMART TRADING HUB Nor is the accuracy guaranteed by SMART TRADING HUB As
        such, X TRADES, LLC. is not responsible for the content in such
        commentary or articles.Any information contained in these articles does
        not constitute a recommendation by SMART TRADING HUB to buy, sell or
        hold any security, or financial product or financial instrument
        discussed in the articles. Additionally, the information contained in
        these articles does not constitute an “offer” or a “solicitation of an
        offer”. Information found on the website(s), social medias, and/or
        discord server may have been obtained from published sources which were
        prepared by third parties. While it is believed that such sources are
        reliable, SMART TRADING HUB does not assume any responsibility for the
        accuracy or completeness of such information.
      </p>
      <h3>4. NO ASSURANCE OR EXPECTATIONS</h3>
      <p>
        All statements other than historical fact statements which include words
        such as “will,” “should,” “estimate,” “believe,” “may,” “anticipate,”
        “expect,” etc. must be considered as forward-looking statements. SMART
        TRADING HUB believes that the expectations reflected in these
        forward-looking statements are reasonable. SMART TRADING HUB, can give
        no assurance as to the correctness or accuracy of these expectations.
        Many factors may affect actual results and some individuals may have
        materially different investment results.
      </p>
    </div>
  );

  const {
    register,
    error: registerError,
    setError: setRegisterError,
    isLoading: registerIsLoading,
  } = useRegister();
  const {
    login,
    error: loginError,
    setError: setLoginError,
    isLoading: loginIsLoading,
  } = useLogin();
  const { dispatch } = useAuthContext();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isRegister) {
      await register(firstname, lastname, email, username, password);
      if (password === confirmPass) {
        setConfirmPass(false);
      }
    } else {
      await login(username, password);
    }
  };

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setUserName("");
    setEmail("");
    setPassword("");
    setConfirmPass("");
  };
  useEffect(() => {
    const fetchDiscordUser = async () => {
      const urlSearchParams = new URLSearchParams(
        window.location.hash.slice(1)
      );
      const accessToken = urlSearchParams.get("access_token");
      if (!accessToken) {
        // console.error("Access token is missing");
        return;
      }
      try {
        const response = await fetch(
          `/api/user/discord/login?token=${accessToken}`
        );
        if (response.ok) {
          const userData = await response.json();
          localStorage.setItem("user", JSON.stringify(userData));
          dispatch({ type: "LOGIN", payload: userData });
        } else {
          console.error(`API request failed with status ${response.status}`);
        }
      } catch (error) {
        console.error(`API request failed: ${error.message}`);
      }
    };
    fetchDiscordUser();
  }, []);
  const handleLogin = () => {
    const redirectUri = encodeURIComponent(
      "https://www.smarttradinghub.net/login"
    );
    const url = `https://discord.com/api/oauth2/authorize?client_id=1083608990132490250&redirect_uri=${redirectUri}&response_type=token&scope=identify%20email`;
    window.location.href = url;
  };
  return (
    <div className="Auth">
      <Image
        centered
        // avatar
        // size="mini"
        src={logo}
        alt="Stock & Crypto Hub"
        style={{ height: "45px", marginTop: "10px" }}
      />
      <Message
        attached
        header={metaData.LOGIN.WELCOME}
        content={
          isRegister
            ? `${metaData.LOGIN.REGISTER_CONTENT}`
            : `${metaData.LOGIN.LOGIN_CONTENT}`
        }
      />

      <Form size="big" className="attached fluid segment">
        <h1>
          {" "}
          {isRegister
            ? `${metaData.LOGIN.REGISTER}`
            : `${metaData.LOGIN.LOGIN}`}
        </h1>

        {isRegister && (
          <>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder={metaData.LOGIN.FIRST_NAME}
              name="firstname"
              value={firstname}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder={metaData.LOGIN.LAST_NAME}
              name="lastname"
              value={lastname}
              onChange={(e) => setLastName(e.target.value)}
            />
          </>
        )}

        <Form.Input
          fluid
          icon="user"
          iconPosition="left"
          placeholder={metaData.LOGIN.USERNAME}
          name="username"
          value={username}
          onChange={(e) => setUserName(e.target.value)}
        />
        {isRegister && (
          <Form.Input
            fluid
            icon="mail"
            iconPosition="left"
            placeholder={metaData.LOGIN.EMAIL}
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        )}

        <Form.Input
          fluid
          icon="lock"
          iconPosition="left"
          placeholder={metaData.LOGIN.PASSWORD}
          type="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {isRegister && (
          <Form.Input
            fluid
            icon="lock"
            iconPosition="left"
            placeholder={metaData.LOGIN.CONFIRM_PASSWORD}
            type="password"
            name="confirmpass"
            onChange={(e) => setConfirmPass(e.target.value)}
          />
        )}
        {isRegister && confirmPass && confirmPass !== password ? (
          <span className="confirmPass">*Confirm password is not match</span>
        ) : null}
        {registerError && <div className="error">{registerError.message}</div>}
        {loginError && <div className="error">{loginError.message}</div>}
        {isRegister ? (
          <>
            <Form.Checkbox
              label={metaData.LOGIN.AGREE}
              checked={isChecked}
              onChange={handleCheckboxChange}
              onClick={handleShowTermsModal}
            />
            <Modal
              open={showTermsModal}
              onClose={handleCloseTermsModal}
              size="small"
            >
              <Modal.Header>Terms of Service & Privacy Policy</Modal.Header>
              <Modal.Content>{termsContent}</Modal.Content>
              <Modal.Actions>
                <Button onClick={handleCloseTermsModal} negative>
                  {metaData.LOGIN.CANCEL}
                </Button>
                <Button onClick={handleCloseTermsModal} positive>
                  {metaData.LOGIN.ACCEPT}
                </Button>
              </Modal.Actions>
            </Modal>
            <Button
              color="blue"
              type="submit"
              disabled={!isChecked}
              onClick={handleSubmit}
            >
              {metaData.LOGIN.SIGNUP}
            </Button>
          </>
        ) : (
          <>
            <Button color="blue" type="submit" onClick={handleSubmit}>
              {metaData.LOGIN.LOGIN2}
            </Button>
          </>
        )}
        {isRegister ? (
          <>
            <Button
              style={{
                backgroundColor: "#5865F2",
                color: "#FFFFFF",
                minWidth: "200px",
                paddingLeft: "0.5em",
                paddingRight: "0.5em",
                marginBottom: "1em",
              }}
              onClick={handleLogin}
            >
              <Icon name="discord" /> {metaData.LOGIN.REGISTER_DISCORD}
            </Button>
          </>
        ) : (
          <>
            <Button
              style={{
                backgroundColor: "#5865F2",
                color: "#FFFFFF",
                minWidth: "200px",
                paddingLeft: "0.5em",
                paddingRight: "0.5em",
                marginBottom: "1em",
              }}
              onClick={handleLogin}
            >
              <Icon name="discord" /> {metaData.LOGIN.LOGIN_DISCORD}
            </Button>
          </>
        )}
      </Form>
      <Message attached="bottom" warning>
        {/* <Icon name="help" /> */}
        <span
          style={{
            fontSize: "12px",
            cursor: "pointer",
            // textDecoration: "underline",
            textAlign: "center",
          }}
          onClick={() => {
            setIsRegister((prev) => !prev);
            resetForm();
            setRegisterError();
            setLoginError();
          }}
        >
          {isRegister
            ? `${metaData.LOGIN.IS_LOGIN}`
            : `${metaData.LOGIN.IS_REGISTER}`}
        </span>
      </Message>
    </div>
  );
};

export default Login;
