import React, { useState, useEffect, useContext } from "react";
import { useCalendarContext } from "../../../Hooks/useCalendarContext";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import "./Calendar.css";
import {
  Icon,
  Card,
  Modal,
  Label,
  Button,
  Form,
  Input,
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import moment from "moment";
// import calendarUpdateModal from "../../Modal/calendarUpdateModal";
import { SearchTickerContext } from "../../../Context/SearchTickerContext";
moment().format();

const Calendar = ({ calendar, onDelete, onLike, onDisLike, onUpdate }) => {
  const { user } = useAuthContext();
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(new Date());
  const [event, setEvent] = useState();
  const [period, setPeriod] = useState();
  const [estimate, setEstimate] = useState();
  const [previous, setPrevious] = useState();
  const [actual, setActual] = useState();
  const [note, setNote] = useState();
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(null);
  const [update, setUpdate] = useState(false);
  const [isFocused, setIsFocused] = useState(null);
  const today = moment().format("YYYY-MM-DD");
  // console.log(today);
  useEffect(() => {
    if (calendar) {
      setEvent(calendar.event);
      setPeriod(calendar.period);
      setEstimate(calendar.estimate);
      setPrevious(calendar.previous);
      setActual(calendar.actual);
      setNote(calendar.analysts);
      setDate(new Date(Date.parse(calendar.date)));
    }
  }, [calendar]);
  // HANDLE UPDATE SHARES ALERT
  const handleCalEdit = async (e) => {
    if (!user) {
      setError("You must be logged in");
      return;
    }
    e.preventDefault();
    const updateCalBody = JSON.stringify({
      userId: user.user._id,
      userRole: user.user.role,
      isAdmin: user.user.isAdmin,
      username: user.user.username,
      event,
      period,
      estimate,
      previous,
      actual,
      note,
      date,
      tags,
    });
    const response = await fetch("/api/calendar/" + calendar._id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: updateCalBody,
    });
    const json = await response.json();
    if (!response.ok) {
      setError(json.message);
    }
    if (response.ok) {
      setError(null);
      let deepClonePrev = JSON.parse(JSON.stringify(calendar));
      deepClonePrev = {
        ...deepClonePrev,
        event,
        period,
        estimate,
        date,
        previous,
        note,
        tags,
      };
      onUpdate(calendar._id, deepClonePrev);
      setUpdate(false);
      setOpen(false);
    } else {
      console.log("error");
    }
  };
  return (
    <>
      {calendar && (
        <>
          <Modal
            dimmer="blurring"
            closeIcon
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size="large"
            trigger={
              <Card
                style={{
                  marginLeft: "20px",
                }}
              >
                <Card.Content>
                  <Label
                    color={
                      moment(calendar.date).format("YYYY-MM-DD") === today
                        ? "pink"
                        : "teal"
                    }
                    ribbon
                  >
                    <strong>{moment(calendar.date).format("LT")}</strong>
                  </Label>
                  {calendar.estimate && (
                    <Label color="red" floating>
                      {calendar.estimate && calendar.estimate}
                    </Label>
                  )}

                  {calendar.event && calendar.event.toUpperCase()}
                </Card.Content>
              </Card>
            }
          >
            {user ? (
              <>
                <Modal.Content
                  scrolling
                  style={{
                    fontSize: "18px",
                    height: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <Card.Content>
                    <Label color="teal" ribbon>
                      <strong>{moment(calendar.date).format("LT")}</strong>
                    </Label>
                    <Card.Header>
                      <strong>Event Title:</strong>{" "}
                      {calendar.event && calendar.event.toUpperCase()}{" "}
                      <span style={{ color: "red" }}>
                        ({calendar.indicator})
                      </span>
                    </Card.Header>
                    {calendar.note && (
                      <Card.Description>
                        <strong>Description:</strong> {calendar.note}
                      </Card.Description>
                    )}
                    {calendar.period && (
                      <Card.Description>
                        <strong>Period:</strong> {calendar.period}
                      </Card.Description>
                    )}
                    {calendar.estimate && (
                      <Card.Description>
                        <strong>Estimate Report:</strong> {calendar.estimate}
                      </Card.Description>
                    )}
                    {calendar.actual && (
                      <Card.Description>
                        <strong>Actual Report:</strong> {calendar.actual}
                      </Card.Description>
                    )}
                    {calendar.previous && (
                      <Card.Description>
                        <strong>Last Report:</strong> {calendar.previous}
                      </Card.Description>
                    )}
                    {calendar.source && (
                      <Card.Description>
                        <strong>Source:</strong> {calendar.source}
                      </Card.Description>
                    )}

                    {/* update */}
                    <div>
                      {update && (
                        <>
                          <Form.Field>
                            {isFocused === "event" && (
                              <Label pointing="below">
                                Event Title FOMC, GDP, PCE...
                              </Label>
                            )}
                            <Input
                              fluid
                              onFocus={() => setIsFocused("event")}
                              onBlur={() => setIsFocused(null)}
                              type="text"
                              placeholder="Event title"
                              onChange={(e) => setEvent(e.target.value)}
                              value={event}
                              required
                            />
                          </Form.Field>

                          <Form.Field>
                            {isFocused === "date" && (
                              <Label pointing="below">
                                Please Pick Event Date
                              </Label>
                            )}
                            <div className="cal-date">
                              <DatePicker
                                showTimeSelect
                                dateFormat="Pp"
                                selected={date}
                                onChange={(date) => setDate(date)}
                                onFocus={() => setIsFocused("date")}
                                onBlur={() => setIsFocused(null)}
                              />
                            </div>
                          </Form.Field>
                          <Form.Field>
                            {isFocused === "estimate" && (
                              <Label pointing="below">Estimate Report</Label>
                            )}
                            <Input
                              fluid
                              onFocus={() => setIsFocused("estimate")}
                              onBlur={() => setIsFocused(null)}
                              type="text"
                              placeholder="Estimate report"
                              onChange={(e) => setEstimate(e.target.value)}
                              value={estimate}
                              required
                            />
                          </Form.Field>
                          <Form.Field>
                            {isFocused === "previous" && (
                              <Label pointing="below">Last Report</Label>
                            )}
                            <Input
                              fluid
                              onFocus={() => setIsFocused("previous")}
                              onBlur={() => setIsFocused(null)}
                              type="text"
                              placeholder="Previous Report"
                              onChange={(e) => setPrevious(e.target.value)}
                              value={previous}
                              required
                            />
                          </Form.Field>
                          <Form.Field>
                            {isFocused === "actual" && (
                              <Label pointing="below">Actual Report</Label>
                            )}
                            <Input
                              fluid
                              onFocus={() => setIsFocused("actual")}
                              onBlur={() => setIsFocused(null)}
                              type="text"
                              placeholder="Actual report"
                              onChange={(e) => setActual(e.target.value)}
                              value={actual}
                              required
                            />
                          </Form.Field>
                          <Form.Field>
                            {isFocused === "note" && (
                              <Label pointing="below">Import Note</Label>
                            )}
                            <Input
                              fluid
                              onFocus={() => setIsFocused("note")}
                              onBlur={() => setIsFocused(null)}
                              type="text"
                              placeholder="import note"
                              onChange={(e) => setNote(e.target.value)}
                              value={note}
                              required
                            />
                          </Form.Field>
                          <Form.Field>
                            {isFocused === "tags" && (
                              <Label pointing="below">
                                Tags your ticker here...
                              </Label>
                            )}
                            <Input
                              fluid
                              onFocus={() => setIsFocused("tags")}
                              onBlur={() => setIsFocused(null)}
                              type="text"
                              placeholder="Ticker Relate (SPY, AAPL, META, TSLA...)"
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault(); // prevent the space from being typed
                                  setTags((prevTags) => [...prevTags, " "]); // add an empty tag
                                } else if (
                                  e.key === "Backspace" &&
                                  tags[tags.length - 1] === ""
                                ) {
                                  e.preventDefault(); // prevent the backspace from navigating back
                                  setTags((prevTags) => prevTags.slice(0, -1)); // remove the last empty tag
                                }
                              }}
                              onChange={(e) =>
                                setTags(e.target.value.toUpperCase().split(","))
                              }
                              value={tags}
                            />
                          </Form.Field>
                          {error && <div className="error">{error}</div>}

                          <form>
                            <Button onClick={handleCalEdit}>UPDATE</Button>
                          </form>
                        </>
                      )}
                    </div>
                  </Card.Content>
                  {user &&
                    (user.user.isAdmin === true ||
                      user.user.role === 1 ||
                      user.user.role === 2 ||
                      user.user._id === calendar.userId) && (
                      <>
                        <Card.Content extra>
                          <div className="ui two buttons">
                            <Button
                              basic
                              color="green"
                              onClick={() =>
                                setUpdate((prevValue) => !prevValue)
                              }
                            >
                              Edit
                            </Button>
                            <Button
                              basic
                              color="red"
                              onClick={() => onDelete(calendar._id)}
                            >
                              Delete
                            </Button>
                          </div>
                        </Card.Content>
                      </>
                    )}
                </Modal.Content>
              </>
            ) : (
              <>
                <Modal.Content>PLEASE LOGIN TO READ MORE DETAIL</Modal.Content>
              </>
            )}
          </Modal>
        </>
      )}
    </>
  );
};

export default Calendar;
