import React, { useState, useEffect } from "react";
import "./Posts.css";
import { Link } from "react-router-dom";
import { Message, Feed, Divider, Button, Icon } from "semantic-ui-react";
import Post from "./Post/Post";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { usePostsContext } from "../../Hooks/usePostsContext";
import { useMetaData } from "../../Context/MetaDataContext";
import { isMobile } from "react-device-detect";
import noAvatar from "../../assets/img/noAvatar.png";
import { Helmet } from "react-helmet";
const Laugh = () => {
  const { posts, dispatch } = usePostsContext();
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const { user } = useAuthContext();
  const { metaData } = useMetaData();
  // GET THE POSTS DATA FROM BACKEND
  useEffect(() => {
    const fetchData = async (page) => {
      try {
        const response = await fetch(`/api/posts?category=Laugh&page=${page}`);

        if (response.ok) {
          const json = await response.json();
          //   console.log(json);
          setTotalPages(json.totalPages); // add this
          dispatch({ type: "FETCH_POST", payload: json.posts });
        } else {
          throw new Error("Failed to fetch posts");
        }
      } catch (error) {
        setError(error.message);
      }
    };
    fetchData(currentPage); // replace 1 with currentPage
  }, [dispatch, currentPage]);
  // LIKE HANDLER
  const handleLike = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/posts/" + eventId + "/like", {
      method: "PUT",
      body: JSON.stringify({ userId: user.user._id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "POST_LIKE",
        payload: { id: eventId, user: user.user._id },
      });
    } else {
      console.error("error");
    }
  };
  // DISLIKE HANDLER
  const handleDisLike = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/posts/" + eventId + "/disLike", {
      method: "PUT",
      body: JSON.stringify({ userId: user.user._id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "POST_DISLIKE",
        payload: { id: eventId, user: user.user._id },
      });
    } else {
      console.error("error");
    }
  };
  // handle Update
  const handleUpdate = async (eventId, post) => {
    if (!user) {
      return;
    }
    dispatch({
      type: "POST_UPDATE",
      payload: { id: eventId, data: post },
    });
  };
  // HANDLE DELETE
  const handleDelete = async (eventId) => {
    try {
      if (!user) {
        throw new Error("Please login");
      }
      const response = await fetch(`/api/posts/${eventId}`, {
        method: "DELETE",
        body: JSON.stringify({
          userId: user.user._id,
          userRole: user.user.role,
          isAdmin: user.user.isAdmin,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        dispatch({ type: "DELETE_POST", payload: json });
      } else {
        throw new Error("Failed to delete post");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handlePageChange = (event, data) => {
    const { activePage } = data;
    setCurrentPage(activePage);
  };

  const handleLoadMore = () => {
    if (currentPage === totalPages) {
      setCurrentPage(1); // Reset to the first page if currently on the last page
    } else if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1); // Increment the current page by 1
    }
  };
  const handleGoBack = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1); // Decrement the current page by 1
    }
  };
  return (
    <>
      {/* <Helmet>
        <title>Trading Plan - Smart Trading Hub</title>
      </Helmet> */}
      {user &&
      (user.user.role === 0 ||
        user.user.role === 1 ||
        user.user.role === 2 ||
        user.user.role === 3 ||
        user.user.role === 4 ||
        user.user.role === 5 ||
        user.user.role === 6 ||
        user.user.role === 10) &&
      posts ? (
        <div className="Posts">
          {error && <div>{error}</div>}
          <Divider horizontal>
            <Icon name="left arrow" onClick={handleGoBack} />
            <Link to="/hub">
              <span className="hub">{metaData.CATEGORY.LAUGH}</span>
            </Link>{" "}
            <Icon name="right arrow" onClick={handleLoadMore} />
          </Divider>
          <Feed size="large">
            {posts &&
              posts.map((post) => (
                <Post
                  post={post}
                  key={post._id}
                  onUpdate={handleUpdate}
                  onDelete={handleDelete}
                  onLike={handleLike}
                  onDisLike={handleDisLike}
                />
              ))}
          </Feed>
        </div>
      ) : (
        <div className="Posts">
          <Divider horizontal>
            <span className="hub">{metaData.CATEGORY.LAUGH}</span>
          </Divider>
          <Message info>
            <Message.Header>
              <a href="/login">{metaData.PERMIT.LOGIN_TO_VIEW}</a>
            </Message.Header>
          </Message>
        </div>
      )}
    </>
  );
};

export default Laugh;
