import React, { useState, useEffect } from "react";
import "../../Posts/Posts.css";
import {
  Pagination,
  Feed,
  Divider,
  Button,
  Icon,
  Card,
} from "semantic-ui-react";
import YourAlertOption from "../../AlertOptions/AlertOption/YourAlertOption";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import { useAlertsOptionContext } from "../../../Hooks/useAlertsOptionContext";
import axios from "axios";
const ProfileOptionAlerts = () => {
  const { profileOptionAlerts, dispatch } = useAlertsOptionContext();
  const [error, setError] = useState(null);
  // const [posts, setPosts] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const { user } = useAuthContext();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `/api/optionalert/${user.user._id}/searchByUserId`
        );
        if (response.ok) {
          const json = await response.json();
          dispatch({ type: "FETCH_PROFILE_OPTION_ALERT", payload: json });
        } else {
          // throw new Error("Failed to fetch alerts");
          // return;
          console.error("error");
        }
      } catch (error) {
        setError(error.message);
      }
    };
    fetchData();
  }, [dispatch]);

  // Like Handler
  const handleLike = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/optionalert/" + eventId + "/like", {
      method: "PUT",
      body: JSON.stringify({ username: user.user.username }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "ALERT_LIKE_OPTION",
        payload: { id: eventId, user: user.user.username },
      });
    } else {
      console.error("error");
    }
  };
  // DisLike Handler
  const handleDisLike = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/optionalert/" + eventId + "/disLike", {
      method: "PUT",
      body: JSON.stringify({ username: user.user.username }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "ALERT_DISLIKE_OPTION",
        payload: { id: eventId, user: user.user.username },
      });
    } else {
      console.error("error");
    }
  };
  // HandlePlayer
  const handlePlayer = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/optionalert/" + eventId + "/player", {
      method: "PUT",
      body: JSON.stringify({ username: user.user.username }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "OPTION_ALERT_PLAYER",
        payload: { id: eventId, user: user.user.username },
      });
    } else {
      console.error("error");
    }
  };
  // handle Update
  const handleOptionUpdate = async (eventId, alert) => {
    if (!user) {
      return;
    }
    dispatch({
      type: "ALERT_UPDATE_OPTION",
      payload: { id: eventId, data: alert },
    });
  };
  // handle Delete
  const handleDelete = async (eventId) => {
    try {
      if (!user) {
        throw new Error("Please login");
      }
      const response = await fetch(`/api/optionalert/${eventId}`, {
        method: "DELETE",
        body: JSON.stringify({ userId: user.user._id }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        dispatch({ type: "DELETE_ALERT_OPTION", payload: json });
      } else {
        throw new Error("Failed to delete alert");
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  return (
    <>
      {error && <div> {error} </div>}
      {user &&
        (user.user.role === 1 ||
          user.user.role === 2 ||
          user.user.role === 3 ||
          user.user.role === 4 ||
          user.user.role === 5 ||
          user.user.role === 6 ||
          user.user.role === 10) &&
        profileOptionAlerts &&
        profileOptionAlerts.length > 0 && (
          <>
            <div className="AlertOption">
              <Divider horizontal>YOUR OPTION ALERTS</Divider>
              <Card.Group centered>
                {profileOptionAlerts &&
                  profileOptionAlerts.map((alertOption) => (
                    <YourAlertOption
                      alertOption={alertOption}
                      key={alertOption._id}
                      onPlayer={handlePlayer}
                      onOptionUpdate={handleOptionUpdate}
                      onDelete={handleDelete}
                      onLike={handleLike}
                      onDisLike={handleDisLike}
                    />
                  ))}
              </Card.Group>
            </div>
          </>
        )}
    </>
  );
};

export default ProfileOptionAlerts;
