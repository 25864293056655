import React from "react";
import Earnings from "../Earnings/Earnings";
import "./ListContainerRight.css";
import AlertOptions from "../AlertOptions/AlertOptions";
import BreakingNews from "../BreakingNews/BreakingNews";
import Coins from "../Coins/Coins";
import Ipos from "../IPOs/Ipos";
import MarketOverview from "../TradingView/MarketOverview";
const ListContainerRight = () => {
  return (
    <div className="ListContainerRight">
      <>
        <Earnings />
        {/* <MarketOverview /> */}
        <AlertOptions />
        <Coins />
        <Ipos />
        {/* <BreakingNews /> */}
      </>
    </div>
  );
};

export default ListContainerRight;
