import React, { useState } from "react";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { Form, Dropdown } from "semantic-ui-react";
function UpdateRole({ userId }) {
  const [role, setRole] = useState("");
  const [error, setError] = useState(null);
  const roles = [
    { key: 1, text: "Non Active Member", value: 0 },
    { key: 2, text: "Member", value: 4 },
    { key: 3, text: "Gold Member", value: 5 },
    { key: 4, text: "Premium Member", value: 6 },
    { key: 5, text: "Moderator", value: 2 },
    { key: 6, text: "Admin", value: 1 },
    { key: 7, text: "Alert", value: 3 },
    { key: 8, text: "Trail", value: 10 },
    { key: 9, text: "Banned", value: 7 },
  ];
  const rolesMod = [{ key: 1, text: "Banned", value: 7 }];
  const { user } = useAuthContext();
  const handleUpdateRole = async (e) => {
    if (!user) {
      setError("You must be logged in");
      return;
    }
    e.preventDefault();

    const response = await fetch("/api/user/role/" + userId, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        role,
        userRole: user.user.role,
      }),
    });
    const userData = await response.json();

    if (!response.ok) {
      setError(userData.message);
    }
    if (response.ok) {
      window.location.href = `/`;
      // console.log("updated");
    }
  };
  return (
    <div className="ProfileInfo">
      <Form>
        {user.user.role === 1 ? (
          <h3>UPDATE ROLE</h3>
        ) : (
          <h3>BANNED THIS USER</h3>
        )}
        {user.user.role === 1 ? (
          <Dropdown
            placeholder="Update Role"
            selection
            options={roles}
            onChange={(event, { value }) => setRole(value)}
          />
        ) : (
          <Dropdown
            placeholder="Banned This User"
            selection
            options={rolesMod}
            onChange={(event, { value }) => setRole(value)}
          />
        )}
        <button className="button infoButton" onClick={handleUpdateRole}>
          {user.user.role === 1 ? "Update" : "Submit"}
        </button>
      </Form>
    </div>
  );
}

export default UpdateRole;
