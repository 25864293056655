import React, { useState, useEffect, useContext } from "react";
import {
  Pagination,
  Feed,
  Divider,
  Button,
  Icon,
  Card,
  Dropdown,
} from "semantic-ui-react";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useTickerContext } from "../../Hooks/useTickerContext.js";
import { SearchTickerContext } from "../../Context/SearchTickerContext.js";

const FavoriteTickers = ({ handleClear }) => {
  const { favoriteTickers, dispatch } = useTickerContext();
  const [error, setError] = useState(null);
  const { user } = useAuthContext();
  const { handleTickerClick } = useContext(SearchTickerContext);
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const [sortBy, setSortBy] = useState("name"); // Default sort by name

  const handleSortChange = (e, { value }) => {
    setSortBy(value);
  };

  const options = [
    { key: "date", text: "By Date Added", value: "date" },
    { key: "name", text: "By Name", value: "name" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `/api/ticker/${user.user._id}/getAllFavoriteByUserId?sortBy=${sortBy}`
        ); // Pass the sortBy as a query parameter in the API request

        if (response.ok) {
          const json = await response.json();
          dispatch({ type: "FETCH_FAVORITE_TICKER", payload: json });
        } else {
          throw new Error("Failed to fetch alerts");
        }
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, [dispatch, user.user._id, sortBy]); // Add sortBy as a dependency

  return (
    <>
      <div className="Alert">
        <Divider horizontal>YOUR WATCHLIST</Divider>
        <Dropdown
          selection
          options={options}
          value={sortBy}
          onChange={handleSortChange}
        />
        <Card.Group centered>
          {favoriteTickers &&
            favoriteTickers.map((ticker) => (
              <Card
                key={ticker._id}
                style={{
                  width: "100px",
                  height: "40px",
                  alignItems: "center",
                  flex: "0 0 auto",
                }}
              >
                <Card.Content>
                  <Card.Header
                    style={{ color: getRandomColor() }}
                    onClick={() => handleTickerClick(ticker.ticker)}
                  >
                    {ticker.ticker}
                  </Card.Header>
                </Card.Content>
              </Card>
            ))}
        </Card.Group>
      </div>
    </>
  );
};

export default FavoriteTickers;
