import React, { useState, useContext, useEffect } from "react";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import { useTickerContext } from "../../../Hooks/useTickerContext.js";
import TechnicalAnalysis from "../../TradingView/TechnicalAnalysis";
import {
  Popup,
  Icon,
  Image,
  Card,
  List,
  Modal,
  Button,
  Form,
  Input,
  Label,
  TextArea,
  Divider,
  Table,
  Tab,
} from "semantic-ui-react";
import moment from "moment";
import { isMobile } from "react-device-detect";
import StockChartMini from "../../TradingView/StockChart";
import FundamentalData from "../../TradingView/FundamentalData";
import BigChart from "../../TradingView/BigChart";
import FavoriteTickers from "../../Tickers/FavoriteTickers";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "./SearchResult.css";
const SearchResult = ({
  tickerResult,
  alertResult,
  optionAlertResult,
  breakingNewResult,
  handleClear,
  metaData,
}) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (user && tickerResult && tickerResult.ticker) {
      fetch(`/api/companyNews?ticker=${tickerResult.ticker}`)
        .then((response) => response.json())
        .then((data) => setData(data))
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [tickerResult]);
  // console.log(data);
  const { user } = useAuthContext();
  const { dispatch } = useTickerContext();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const tradingviewLinkRegex =
    /^https:\/\/www\.tradingview\.com\/x\/[a-zA-Z0-9]+\/$/;
  const [time_frame, setTime_frame] = useState();
  const [signal, setSignal] = useState();
  const [term, setTerm] = useState();
  const [charts, setCharts] = useState([""]);
  const [note, setNote] = useState();
  const [resistance1, setResistance1] = useState();
  const [resistance2, setResistance2] = useState();
  const [resistance3, setResistance3] = useState();
  const [support1, setSupport1] = useState();
  const [support2, setSupport2] = useState();
  const [support3, setSupport3] = useState();
  const [update, setUpdate] = useState(false);
  const [isFocused, setIsFocused] = useState(null);
  // State to track the active time frame tab
  const [activeTab, setActiveTab] = useState("D");

  // Function to handle tab changes
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const now = new Date();
  // Convert to Eastern Time
  const currentHourUTC = now.getUTCHours();
  const currentMinute = now.getUTCMinutes();
  const today = moment().startOf("day");
  // console.log(moment().startOf("day"));
  // Convert to Eastern Time by subtracting 4 hours
  let currentHour = currentHourUTC - 4;
  if (currentHour < 0) {
    currentHour += 24; // Add 24 hours to wrap around to the previous day
  }

  // console.log("Current Eastern Time Hour:", currentHour);
  // console.log("Current Eastern Time Minute:", currentMinute);
  // tradingview chart

  const handleUpdateTicker = async (e) => {
    if (!user) {
      setError("You must be logged in");
      return;
    }
    e.preventDefault();
    if (charts && !tradingviewLinkRegex.test(charts)) {
      setError("Invalid TradingView URL link");
      return;
    }
    if (!note) {
      setError("Please write about this setup");
      return;
    }
    const updateAlertBody = JSON.stringify({
      userId: user.user._id,
      username: user.user.username,
      userRole: user.user.role,
      isAdmin: user.user.isAdmin,
      charts: [
        {
          chartUrl: charts,
          userId: user.user._id,
          username: user.user.username,
          note,
          term,
          signal,
          time_frame,
          resistance1,
          resistance2,
          resistance3,
          support1,
          support2,
          support3,
        },
      ],
    });
    const response = await fetch("/api/ticker/" + tickerResult._id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: updateAlertBody,
    });
    const json = await response.json();
    if (!response.ok) {
      setError(json.message);
    }
    if (response.ok) {
      // setCharts([""]);
      setUpdate(false);
      window.location.href = "/";
    } else {
      console.log("error");
    }
  };
  // handle Delete
  const handleDelete = async (eventId) => {
    try {
      if (!user) {
        throw new Error("Please login");
      }
      const response = await fetch(`/api/ticker/${eventId}`, {
        method: "DELETE",
        body: JSON.stringify({
          userId: user.user._id,
          userRole: user.user.role,
          isAdmin: user.user.isAdmin,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        // dispatch({ type: "DELETE_ALERT", payload: json });
        window.location.href = "/";
      } else {
        throw new Error("Failed to delete alert");
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  const handleFavorite = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/ticker/" + eventId + "/favorite", {
      method: "PUT",
      body: JSON.stringify({ userId: user.user._id }), // Send the userId along with the request body
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      // dispatch({
      //   type: "TICKER_FAVORITE",
      //   payload: { id: eventId, userId: user.user._id }, // Include the userId in the payload
      // });
      setOpen(true);
    } else {
      console.error("error");
    }
  };
  // HANDLE DELETE COMMENT
  const handleDeleteChart = async (tickerId, chartId) => {
    try {
      if (!user) {
        throw new Error("Please login");
      }
      const response = await fetch(`/api/ticker/${tickerId}/chart/${chartId}`, {
        method: "DELETE",
        body: JSON.stringify({
          userId: user.user._id,
          userRole: user.user.role,
          isAdmin: user.user.isAdmin,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      // const json = await response.json();
      if (response.ok) {
        window.location.href = "/";
      } else {
        throw new Error("Failed to delete comment");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      {/* TICKER */}
      {user && tickerResult && (
        <>
          <Card>
            <Card.Content>
              <Card.Header>
                {user && (
                  <Icon
                    name="star"
                    size="minimun"
                    color={
                      tickerResult.favorite.some(
                        (fav) => fav.userId === user.user._id
                      )
                        ? "yellow"
                        : "black"
                    }
                    onClick={() => handleFavorite(tickerResult._id)}
                  />
                )}
                <Modal
                  onClose={() => setOpen(false)}
                  centered={false}
                  closeIcon
                  onOpen={() => setOpen(true)}
                  open={open}
                  trigger={null} // No need to provide a trigger as the modal is triggered by handleFavorite
                >
                  <FavoriteTickers handleClear={handleClear} />
                </Modal>

                <Popup
                  content={`PreClose:${tickerResult.regularMarketPreviousClose}
                  OpenPrice:${tickerResult.regularMarketOpen}
                  `}
                  trigger={
                    <span>
                      {tickerResult.ticker}{" "}
                      {tickerResult.currentPrice &&
                        `$${tickerResult.currentPrice}`}{" "}
                    </span>
                  }
                />

                {/* at {moment(tickerResult.regularMarketTime).format("h:mm:ss A")} */}
                {tickerResult.logo ? (
                  <Image
                    avatar
                    floated="right"
                    size="large"
                    src={tickerResult.logo}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                      backgroundColor: "gray",
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "12px",
                      position: "absolute",
                      top: 29,
                      right: 10,
                      // left:0,
                    }}
                  >
                    {tickerResult.ticker}
                  </div>
                )}
              </Card.Header>
              <Card.Meta>{tickerResult.name}</Card.Meta>

              {tickerResult.regularMarketChange && (
                <>
                  <Card.Header>
                    <span
                      style={{
                        color:
                          tickerResult.regularMarketChange < 0
                            ? "red"
                            : tickerResult.regularMarketChange > 0
                            ? "green"
                            : "black",
                      }}
                    >
                      {tickerResult.regularMarketChange.toFixed(2)} (
                      {(tickerResult.regularMarketChangePercent * 100).toFixed(
                        2
                      )}
                      %)
                    </span>
                  </Card.Header>
                </>
              )}
              <Card.Description>
                {tickerResult.description && (
                  <>
                    <Icon name="sticky note" />
                    Description: {tickerResult.description}
                    <br />
                  </>
                )}
                {((currentHour === 4 && currentMinute >= 0) || // 4:00 AM or later
                  (currentHour > 4 && currentHour < 9) || // Between 4:01 AM and 8:59 AM
                  (currentHour === 9 && currentMinute <= 29)) &&
                  tickerResult.preMarketPrice && (
                    <>
                      <hr />
                      <Icon name="sun" />
                      Pre-Market @
                      {moment(tickerResult.preMarketTime).format("h:mm")}: $
                      {tickerResult.preMarketPrice} (
                      <span
                        style={{
                          color:
                            tickerResult.preMarketPrice -
                              tickerResult.currentPrice <
                            0
                              ? "red"
                              : "green",
                        }}
                      >
                        {(
                          tickerResult.preMarketPrice -
                          tickerResult.currentPrice
                        ).toFixed(2)}
                      </span>
                      )
                      <hr />
                    </>
                  )}
                {((currentHour >= 16 && currentHour <= 23) ||
                  (currentHour >= 0 && currentHour < 6)) &&
                  tickerResult.postMarketPrice && (
                    <>
                      <hr />
                      <Icon name="moon" />
                      After-Market @
                      {moment(tickerResult.postMarketTime).format("h:mm")}: $
                      {tickerResult.postMarketPrice} (
                      <span
                        style={{
                          color:
                            tickerResult.postMarketPrice -
                              tickerResult.currentPrice <
                            0
                              ? "red"
                              : "green",
                        }}
                      >
                        {(
                          tickerResult.postMarketPrice -
                          tickerResult.currentPrice
                        ).toFixed(2)}
                      </span>
                      )
                      <hr />
                    </>
                  )}
                <StockChartMini symbol={tickerResult.ticker} />
                {/* <Tab menu={{ secondary: true, pointing: true }} panes={panes} /> */}

                {/* Time frame tabs */}
                <div className="tab-container">
                  {tickerResult &&
                    tickerResult.supportResistance.some(
                      (level) => level.time_frame === "1m"
                    ) && (
                      <button
                        onClick={() => handleTabChange("1m")}
                        className={`tab-button ${
                          activeTab === "1m" ? "active" : ""
                        }`}
                      >
                        1m
                      </button>
                    )}
                  {tickerResult &&
                    tickerResult.supportResistance.some(
                      (level) => level.time_frame === "5m"
                    ) && (
                      <button
                        onClick={() => handleTabChange("5m")}
                        className={`tab-button ${
                          activeTab === "5m" ? "active" : ""
                        }`}
                      >
                        5m
                      </button>
                    )}
                  {tickerResult &&
                    tickerResult.supportResistance.some(
                      (level) => level.time_frame === "15m"
                    ) && (
                      <button
                        onClick={() => handleTabChange("15m")}
                        className={`tab-button ${
                          activeTab === "15m" ? "active" : ""
                        }`}
                      >
                        15m
                      </button>
                    )}
                  {tickerResult &&
                    tickerResult.supportResistance.some(
                      (level) => level.time_frame === "1H"
                    ) && (
                      <button
                        onClick={() => handleTabChange("1H")}
                        className={`tab-button ${
                          activeTab === "1H" ? "active" : ""
                        }`}
                      >
                        1H
                      </button>
                    )}
                  {tickerResult &&
                    tickerResult.supportResistance.some(
                      (level) => level.time_frame === "4H"
                    ) && (
                      <button
                        onClick={() => handleTabChange("4H")}
                        className={`tab-button ${
                          activeTab === "4H" ? "active" : ""
                        }`}
                      >
                        4H
                      </button>
                    )}
                  {tickerResult &&
                    tickerResult.supportResistance.some(
                      (level) => level.time_frame === "D"
                    ) && (
                      <button
                        onClick={() => handleTabChange("D")}
                        className={`tab-button ${
                          activeTab === "D" ? "active" : ""
                        }`}
                      >
                        D
                      </button>
                    )}
                  {tickerResult &&
                    tickerResult.supportResistance.some(
                      (level) => level.time_frame === "W"
                    ) && (
                      <button
                        onClick={() => handleTabChange("W")}
                        className={`tab-button ${
                          activeTab === "W" ? "active" : ""
                        }`}
                      >
                        W
                      </button>
                    )}
                  {tickerResult &&
                    tickerResult.supportResistance.some(
                      (level) => level.time_frame === "M"
                    ) && (
                      <button
                        onClick={() => handleTabChange("M")}
                        className={`tab-button ${
                          activeTab === "M" ? "active" : ""
                        }`}
                      >
                        M
                      </button>
                    )}
                </div>
                {/* Display levels based on the active tab */}
                {activeTab === "1m" && (
                  <>
                    {tickerResult.supportResistance.some(
                      (level) => level.time_frame === "1m"
                    )
                      ? tickerResult.supportResistance.map((level, index) =>
                          level.time_frame === "1m" ? (
                            <Card key={index} fluid color="blue">
                              <Card.Header
                                style={{
                                  display: "block",
                                  textAlign: "center",
                                  fontWeight: "Bold",
                                }}
                              >
                                1 Min Levels (
                                {moment(level.createdAt)
                                  .startOf("minutes")
                                  .fromNow()}
                                )
                              </Card.Header>
                              {level.note && (
                                <Card.Content extra>
                                  <Icon name="comment" />
                                  {level.note}
                                </Card.Content>
                              )}
                              {level.resistance1 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret up" color="green" />
                                    Resistance 1: ${level.resistance1}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.resistance2 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret up" color="green" />
                                    Resistance 2: ${level.resistance2}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.resistance3 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret up" color="green" />
                                    Resistance 3: ${level.resistance3}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.support1 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret down" color="red" />
                                    Support 1: ${level.support1}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.support2 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret down" color="red" />
                                    Support 2: ${level.support2}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.support3 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret down" color="red" />
                                    Support 3: ${level.support3}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.fibonacci382 && (
                                <>
                                  <Card.Description>
                                    <Icon name="sort" color="blue" />
                                    Fibonacci 38.2%: ${level.fibonacci382}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.fibonacci50 && (
                                <>
                                  <Card.Description>
                                    <Icon name="sort" color="blue" />
                                    Fibonacci 50%: ${level.fibonacci50}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.fibonacci618 && (
                                <>
                                  <Card.Description>
                                    <Icon name="sort" color="blue" />
                                    Fibonacci 61.8%: ${level.fibonacci618}{" "}
                                  </Card.Description>
                                </>
                              )}
                              <Card.Meta
                                style={{ display: "block", textAlign: "right" }}
                              >
                                <Icon name="clock" color="purple" /> Last
                                Updated{" "}
                                {moment(level.createdAt).format(
                                  "MMM D, YY | h:mm A"
                                )}
                              </Card.Meta>
                            </Card>
                          ) : null
                        )
                      : ""}
                  </>
                )}

                {activeTab === "5m" && (
                  <>
                    {tickerResult.supportResistance.some(
                      (level) => level.time_frame === "5m"
                    )
                      ? tickerResult.supportResistance.map((level, index) =>
                          level.time_frame === "5m" ? (
                            <Card key={index} fluid color="blue">
                              <Card.Header
                                style={{
                                  display: "block",
                                  textAlign: "center",
                                  fontWeight: "Bold",
                                }}
                              >
                                5Mins Levels (
                                {moment(level.createdAt)
                                  .startOf("minutes")
                                  .fromNow()}
                                )
                              </Card.Header>
                              {level.note && (
                                <Card.Content extra>
                                  <Icon name="comment" />
                                  {level.note}
                                </Card.Content>
                              )}
                              {level.resistance1 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret up" color="green" />
                                    Resistance 1: ${level.resistance1}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.resistance2 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret up" color="green" />
                                    Resistance 2: ${level.resistance2}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.resistance3 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret up" color="green" />
                                    Resistance 3: ${level.resistance3}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.support1 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret down" color="red" />
                                    Support 1: ${level.support1}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.support2 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret down" color="red" />
                                    Support 2: ${level.support2}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.support3 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret down" color="red" />
                                    Support 3: ${level.support3}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.fibonacci382 && (
                                <>
                                  <Card.Description>
                                    <Icon name="sort" color="blue" />
                                    Fibonacci 38.2%: ${level.fibonacci382}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.fibonacci50 && (
                                <>
                                  <Card.Description>
                                    <Icon name="sort" color="blue" />
                                    Fibonacci 50%: ${level.fibonacci50}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.fibonacci618 && (
                                <>
                                  <Card.Description>
                                    <Icon name="sort" color="blue" />
                                    Fibonacci 61.8%: ${level.fibonacci618}{" "}
                                  </Card.Description>
                                </>
                              )}
                              <Card.Meta
                                style={{ display: "block", textAlign: "right" }}
                              >
                                <Icon name="clock" color="purple" /> Last
                                Updated{" "}
                                {moment(level.createdAt).format(
                                  "MMM D, YY | h:mm A"
                                )}
                              </Card.Meta>
                            </Card>
                          ) : null
                        )
                      : ""}
                  </>
                )}

                {activeTab === "15m" && (
                  <>
                    {tickerResult.supportResistance.some(
                      (level) => level.time_frame === "15m"
                    )
                      ? tickerResult.supportResistance.map((level, index) =>
                          level.time_frame === "15m" ? (
                            <Card key={index} fluid color="blue">
                              <Card.Header
                                style={{
                                  display: "block",
                                  textAlign: "center",
                                  fontWeight: "Bold",
                                }}
                              >
                                15Mins Levels (
                                {moment(level.createdAt)
                                  .startOf("minutes")
                                  .fromNow()}
                                )
                              </Card.Header>
                              {level.note && (
                                <Card.Content extra>
                                  <Icon name="comment" />
                                  {level.note}
                                </Card.Content>
                              )}
                              {level.resistance1 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret up" color="green" />
                                    Resistance 1: ${level.resistance1}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.resistance2 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret up" color="green" />
                                    Resistance 2: ${level.resistance2}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.resistance3 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret up" color="green" />
                                    Resistance 3: ${level.resistance3}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.support1 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret down" color="red" />
                                    Support 1: ${level.support1}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.support2 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret down" color="red" />
                                    Support 2: ${level.support2}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.support3 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret down" color="red" />
                                    Support 3: ${level.support3}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.fibonacci382 && (
                                <>
                                  <Card.Description>
                                    <Icon name="sort" color="blue" />
                                    Fibonacci 38.2%: ${level.fibonacci382}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.fibonacci50 && (
                                <>
                                  <Card.Description>
                                    <Icon name="sort" color="blue" />
                                    Fibonacci 50%: ${level.fibonacci50}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.fibonacci618 && (
                                <>
                                  <Card.Description>
                                    <Icon name="sort" color="blue" />
                                    Fibonacci 61.8%: ${level.fibonacci618}{" "}
                                  </Card.Description>
                                </>
                              )}
                              <Card.Meta
                                style={{ display: "block", textAlign: "right" }}
                              >
                                <Icon name="clock" color="purple" /> Last
                                Updated{" "}
                                {moment(level.createdAt).format(
                                  "MMM D, YY | h:mm A"
                                )}
                              </Card.Meta>
                            </Card>
                          ) : null
                        )
                      : ""}
                  </>
                )}

                {activeTab === "1H" && (
                  <>
                    {tickerResult.supportResistance.some(
                      (level) => level.time_frame === "1H"
                    )
                      ? tickerResult.supportResistance.map((level, index) =>
                          level.time_frame === "1H" ? (
                            <Card key={index} fluid color="blue">
                              <Card.Header
                                style={{
                                  display: "block",
                                  textAlign: "center",
                                  fontWeight: "Bold",
                                }}
                              >
                                1 Hour Levels (
                                {moment(level.createdAt)
                                  .startOf("minutes")
                                  .fromNow()}
                                )
                              </Card.Header>
                              {level.note && (
                                <Card.Content extra>
                                  <Icon name="comment" />
                                  {level.note}
                                </Card.Content>
                              )}
                              {level.resistance1 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret up" color="green" />
                                    Resistance 1: ${level.resistance1}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.resistance2 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret up" color="green" />
                                    Resistance 2: ${level.resistance2}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.resistance3 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret up" color="green" />
                                    Resistance 3: ${level.resistance3}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.support1 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret down" color="red" />
                                    Support 1: ${level.support1}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.support2 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret down" color="red" />
                                    Support 2: ${level.support2}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.support3 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret down" color="red" />
                                    Support 3: ${level.support3}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.fibonacci382 && (
                                <>
                                  <Card.Description>
                                    <Icon name="sort" color="blue" />
                                    Fibonacci 38.2%: ${level.fibonacci382}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.fibonacci50 && (
                                <>
                                  <Card.Description>
                                    <Icon name="sort" color="blue" />
                                    Fibonacci 50%: ${level.fibonacci50}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.fibonacci618 && (
                                <>
                                  <Card.Description>
                                    <Icon name="sort" color="blue" />
                                    Fibonacci 61.8%: ${level.fibonacci618}{" "}
                                  </Card.Description>
                                </>
                              )}
                              <Card.Meta
                                style={{ display: "block", textAlign: "right" }}
                              >
                                <Icon name="clock" color="purple" /> Last
                                Updated{" "}
                                {moment(level.createdAt).format(
                                  "MMM D, YY | h:mm A"
                                )}
                              </Card.Meta>
                            </Card>
                          ) : null
                        )
                      : ""}
                  </>
                )}

                {activeTab === "4H" && (
                  <>
                    {tickerResult.supportResistance.some(
                      (level) => level.time_frame === "4H"
                    )
                      ? tickerResult.supportResistance.map((level, index) =>
                          level.time_frame === "4H" ? (
                            <Card key={index} fluid color="blue">
                              <Card.Header
                                style={{
                                  display: "block",
                                  textAlign: "center",
                                  fontWeight: "Bold",
                                }}
                              >
                                4 Hours Levels (
                                {moment(level.createdAt)
                                  .startOf("minutes")
                                  .fromNow()}
                                )
                              </Card.Header>
                              {level.note && (
                                <Card.Content extra>
                                  <Icon name="comment" />
                                  {level.note}
                                </Card.Content>
                              )}
                              {level.resistance1 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret up" color="green" />
                                    Resistance 1: ${level.resistance1}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.resistance2 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret up" color="green" />
                                    Resistance 2: ${level.resistance2}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.resistance3 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret up" color="green" />
                                    Resistance 3: ${level.resistance3}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.support1 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret down" color="red" />
                                    Support 1: ${level.support1}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.support2 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret down" color="red" />
                                    Support 2: ${level.support2}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.support3 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret down" color="red" />
                                    Support 3: ${level.support3}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.fibonacci382 && (
                                <>
                                  <Card.Description>
                                    <Icon name="sort" color="blue" />
                                    Fibonacci 38.2%: ${level.fibonacci382}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.fibonacci50 && (
                                <>
                                  <Card.Description>
                                    <Icon name="sort" color="blue" />
                                    Fibonacci 50%: ${level.fibonacci50}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.fibonacci618 && (
                                <>
                                  <Card.Description>
                                    <Icon name="sort" color="blue" />
                                    Fibonacci 61.8%: ${level.fibonacci618}{" "}
                                  </Card.Description>
                                </>
                              )}
                              <Card.Meta
                                style={{ display: "block", textAlign: "right" }}
                              >
                                <Icon name="clock" color="purple" /> Last
                                Updated{" "}
                                {moment(level.createdAt).format(
                                  "MMM D, YY | h:mm A"
                                )}
                              </Card.Meta>
                            </Card>
                          ) : null
                        )
                      : ""}
                  </>
                )}
                {activeTab === "D" && (
                  <>
                    {tickerResult.supportResistance.some(
                      (level) => level.time_frame === "D"
                    )
                      ? tickerResult.supportResistance.map((level, index) =>
                          level.time_frame === "D" ? (
                            <Card key={index} fluid color="blue">
                              <Card.Header
                                style={{
                                  display: "block",
                                  textAlign: "center",
                                  fontWeight: "Bold",
                                }}
                              >
                                Daily Levels (
                                {moment(level.createdAt)
                                  .startOf("minutes")
                                  .fromNow()}
                                )
                              </Card.Header>
                              {level.note && (
                                <Card.Content extra>
                                  <Icon name="comment" />
                                  {level.note}
                                </Card.Content>
                              )}
                              {level.resistance1 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret up" color="green" />
                                    Resistance 1: ${level.resistance1}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.resistance2 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret up" color="green" />
                                    Resistance 2: ${level.resistance2}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.resistance3 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret up" color="green" />
                                    Resistance 3: ${level.resistance3}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.support1 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret down" color="red" />
                                    Support 1: ${level.support1}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.support2 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret down" color="red" />
                                    Support 2: ${level.support2}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.support3 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret down" color="red" />
                                    Support 3: ${level.support3}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.fibonacci382 && (
                                <>
                                  <Card.Description>
                                    <Icon name="sort" color="blue" />
                                    Fibonacci 38.2%: ${level.fibonacci382}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.fibonacci50 && (
                                <>
                                  <Card.Description>
                                    <Icon name="sort" color="blue" />
                                    Fibonacci 50%: ${level.fibonacci50}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.fibonacci618 && (
                                <>
                                  <Card.Description>
                                    <Icon name="sort" color="blue" />
                                    Fibonacci 61.8%: ${level.fibonacci618}{" "}
                                  </Card.Description>
                                </>
                              )}
                              <Card.Meta
                                style={{ display: "block", textAlign: "right" }}
                              >
                                <Icon name="clock" color="purple" /> Last
                                Updated{" "}
                                {moment(level.createdAt).format(
                                  "MMM D, YY | h:mm A"
                                )}
                              </Card.Meta>
                            </Card>
                          ) : null
                        )
                      : ""}
                  </>
                )}
                {activeTab === "W" && (
                  <>
                    {tickerResult.supportResistance.some(
                      (level) => level.time_frame === "W"
                    )
                      ? tickerResult.supportResistance.map((level, index) =>
                          level.time_frame === "W" ? (
                            <Card key={index} fluid color="blue">
                              <Card.Header
                                style={{
                                  display: "block",
                                  textAlign: "center",
                                  fontWeight: "Bold",
                                }}
                              >
                                Weekly Levels (
                                {moment(level.createdAt)
                                  .startOf("minutes")
                                  .fromNow()}
                                )
                              </Card.Header>
                              {level.note && (
                                <Card.Content extra>
                                  <Icon name="comment" />
                                  {level.note}
                                </Card.Content>
                              )}
                              {level.resistance1 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret up" color="green" />
                                    Resistance 1: ${level.resistance1}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.resistance2 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret up" color="green" />
                                    Resistance 2: ${level.resistance2}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.resistance3 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret up" color="green" />
                                    Resistance 3: ${level.resistance3}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.support1 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret down" color="red" />
                                    Support 1: ${level.support1}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.support2 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret down" color="red" />
                                    Support 2: ${level.support2}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.support3 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret down" color="red" />
                                    Support 3: ${level.support3}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.fibonacci382 && (
                                <>
                                  <Card.Description>
                                    <Icon name="sort" color="blue" />
                                    Fibonacci 38.2%: ${level.fibonacci382}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.fibonacci50 && (
                                <>
                                  <Card.Description>
                                    <Icon name="sort" color="blue" />
                                    Fibonacci 50%: ${level.fibonacci50}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.fibonacci618 && (
                                <>
                                  <Card.Description>
                                    <Icon name="sort" color="blue" />
                                    Fibonacci 61.8%: ${level.fibonacci618}{" "}
                                  </Card.Description>
                                </>
                              )}
                              <Card.Meta
                                style={{ display: "block", textAlign: "right" }}
                              >
                                <Icon name="clock" color="purple" /> Last
                                Updated{" "}
                                {moment(level.createdAt).format(
                                  "MMM D, YY | h:mm A"
                                )}
                              </Card.Meta>
                            </Card>
                          ) : null
                        )
                      : ""}
                  </>
                )}
                {activeTab === "M" && (
                  <>
                    {tickerResult.supportResistance.some(
                      (level) => level.time_frame === "M"
                    )
                      ? tickerResult.supportResistance.map((level, index) =>
                          level.time_frame === "M" ? (
                            <Card key={index} fluid color="blue">
                              <Card.Header
                                style={{
                                  display: "block",
                                  textAlign: "center",
                                  fontWeight: "Bold",
                                }}
                              >
                                Monthly Levels (
                                {moment(level.createdAt)
                                  .startOf("minutes")
                                  .fromNow()}
                                )
                              </Card.Header>
                              {level.note && (
                                <Card.Content extra>
                                  <Icon name="comment" />
                                  {level.note}
                                </Card.Content>
                              )}
                              {level.resistance1 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret up" color="green" />
                                    Resistance 1: ${level.resistance1}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.resistance2 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret up" color="green" />
                                    Resistance 2: ${level.resistance2}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.resistance3 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret up" color="green" />
                                    Resistance 3: ${level.resistance3}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.support1 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret down" color="red" />
                                    Support 1: ${level.support1}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.support2 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret down" color="red" />
                                    Support 2: ${level.support2}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.support3 && (
                                <>
                                  <Card.Description>
                                    <Icon name="caret down" color="red" />
                                    Support 3: ${level.support3}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.fibonacci382 && (
                                <>
                                  <Card.Description>
                                    <Icon name="sort" color="blue" />
                                    Fibonacci 38.2%: ${level.fibonacci382}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.fibonacci50 && (
                                <>
                                  <Card.Description>
                                    <Icon name="sort" color="blue" />
                                    Fibonacci 50%: ${level.fibonacci50}{" "}
                                  </Card.Description>
                                </>
                              )}
                              {level.fibonacci618 && (
                                <>
                                  <Card.Description>
                                    <Icon name="sort" color="blue" />
                                    Fibonacci 61.8%: ${level.fibonacci618}{" "}
                                  </Card.Description>
                                </>
                              )}
                              <Card.Meta
                                style={{ display: "block", textAlign: "right" }}
                              >
                                <Icon name="clock" color="purple" /> Last
                                Updated{" "}
                                {moment(level.createdAt).format(
                                  "MMM D, YY | h:mm A"
                                )}
                              </Card.Meta>
                            </Card>
                          ) : null
                        )
                      : ""}
                  </>
                )}
                {/* END LEVEL */}
                {tickerResult.min_max_CreatedAt &&
                  moment(tickerResult.min_max_CreatedAt).isSame(
                    today,
                    "month"
                  ) && (
                    <>
                      <span style={{ display: "block", textAlign: "center" }}>
                        <Icon name="clock" />
                        Sentiment on{" "}
                        {moment(tickerResult.min_max_CreatedAt).format(
                          "MMM D, YY h:mm:ss A"
                        )}
                      </span>
                      <hr />
                      {moment(tickerResult.min_max_CreatedAt).isSame(
                        today,
                        "d"
                      ) &&
                        tickerResult.day_min && (
                          <>
                            Daily:{" "}
                            <span
                              style={{
                                color:
                                  tickerResult.day_sen === "BEARISH"
                                    ? "#FF2600"
                                    : tickerResult.day_sen === "BULLISH"
                                    ? "#008F00"
                                    : "#76D6FF",
                              }}
                            >
                              {tickerResult.day_sen}
                            </span>
                            <br />
                            <Icon name="battery low" color="red" />
                            D-Min: ${tickerResult.day_min} |{" "}
                            <Icon name="battery full" color="green" />
                            D-Max: ${tickerResult.day_max} <br />
                            <hr />
                          </>
                        )}
                      {moment(tickerResult.min_max_CreatedAt).isSame(
                        today,
                        "week"
                      ) &&
                        tickerResult.wk_min && (
                          <>
                            Weekly:{" "}
                            <span
                              style={{
                                color:
                                  tickerResult.wk_sen === "BEARISH"
                                    ? "#FF2600"
                                    : tickerResult.wk_sen === "BULLISH"
                                    ? "#008F00"
                                    : "#76D6FF",
                              }}
                            >
                              {tickerResult.wk_sen}
                            </span>
                            <br />
                            <Icon name="battery low" color="red" />
                            W-Min: ${tickerResult.wk_min} |{" "}
                            <Icon name="battery full" color="green" />
                            W-Max: ${tickerResult.wk_max} <br />
                            <hr />
                          </>
                        )}
                      {moment(tickerResult.min_max_CreatedAt).isSame(
                        today,
                        "month"
                      ) &&
                        tickerResult.mo_min && (
                          <>
                            Monthly:{" "}
                            <span
                              style={{
                                color:
                                  tickerResult.mo_sen === "BEARISH"
                                    ? "#FF2600"
                                    : tickerResult.mo_sen === "BULLISH"
                                    ? "#008F00"
                                    : "#76D6FF",
                              }}
                            >
                              {tickerResult.mo_sen}
                            </span>
                            <br />
                            <Icon name="battery low" color="red" />
                            M-Min: ${tickerResult.mo_min} |{" "}
                            <Icon name="battery full" color="green" />
                            M-Max: ${tickerResult.mo_max} <br />
                          </>
                        )}
                      <hr />
                    </>
                  )}
                {tickerResult.regularMarketDayHigh && (
                  <>
                    <Icon name="battery low" />
                    Low: ${tickerResult.regularMarketDayLow} |{" "}
                    <Icon name="battery full" />
                    High: ${tickerResult.regularMarketDayHigh} <br />
                  </>
                )}
                {tickerResult.regularMarketVolume && (
                  <>
                    <Icon name="clone" />
                    Current Vol:{" "}
                    {tickerResult.regularMarketVolume.toLocaleString()}
                    <br />
                  </>
                )}
                {tickerResult.averageVolume && (
                  <>
                    <Icon name="inbox" />
                    Avg Vol: {tickerResult.averageVolume.toLocaleString()}
                    <br />
                  </>
                )}
                {tickerResult.averageDailyVolume10Day && (
                  <>
                    <Icon name="inbox" />
                    Avg Vol(10D):{" "}
                    {tickerResult.averageDailyVolume10Day.toLocaleString()}
                    <br />
                  </>
                )}
                {tickerResult.marketCap && (
                  <>
                    <Icon name="dollar" />
                    MarketCap: ${tickerResult.marketCap.toLocaleString()}
                    <br />
                  </>
                )}
                {tickerResult.fiftyTwoWeekLow &&
                  tickerResult.fiftyTwoWeekHigh && (
                    <>
                      <Icon name="sort amount down" />
                      52W L: ${tickerResult.fiftyTwoWeekLow} |{" "}
                      <Icon name="sort amount up" />
                      52W H: ${tickerResult.fiftyTwoWeekHigh}
                      <hr />
                    </>
                  )}
                {tickerResult.country && (
                  <>
                    <Icon name="flag" />
                    Country: {tickerResult.country}
                    <br />
                  </>
                )}
                {tickerResult.industry && (
                  <>
                    <Icon name="industry" />
                    Industry: {tickerResult.industry}
                    <br />
                  </>
                )}
                {tickerResult.sector && (
                  <>
                    <Icon name="folder" />
                    Sector: {tickerResult.sector}
                    <br />
                  </>
                )}
                {tickerResult.ipo_date && (
                  <>
                    <Icon name="clock" />
                    IPO Date:
                    {moment(tickerResult.ipo_date).format("MMM/D/YYYY")}
                    <br />
                  </>
                )}
                {tickerResult.weburl && (
                  <>
                    <Icon name="globe" />
                    Web:{" "}
                    <a href={tickerResult.weburl} target="_blank">
                      {tickerResult.weburl}
                    </a>
                    <br />
                  </>
                )}
                {tickerResult.exchange && (
                  <>
                    <Icon name="globe" />
                    Exchange: {tickerResult.exchange}
                    <br />
                  </>
                )}
                {tickerResult.earning_date && (
                  <>
                    <hr />
                    <Icon name="clock" />
                    Earning Date:{" "}
                    {moment(tickerResult.earning_date).format("MMM, D YYYY")}
                    <br />
                  </>
                )}
                {tickerResult.earning_hour &&
                  tickerResult.earning_hour === "amc" && (
                    <>
                      <Icon name="moon" /> After Market Closed
                      <br />
                    </>
                  )}
                {tickerResult.earning_hour &&
                  tickerResult.earning_hour === "bmo" && (
                    <>
                      <Icon name="sun" /> Before Market Open
                      <br />
                    </>
                  )}
                {tickerResult.earning_quarter && (
                  <>
                    <Icon name="phone volume" />
                    Earning Quarter: {tickerResult.earning_quarter}
                    <br />
                  </>
                )}
                {tickerResult.earning_estimate && (
                  <>
                    <Icon name="random" />
                    Earning Estimate: {tickerResult.earning_estimate}
                    <br />
                  </>
                )}
                {tickerResult.earnning_Actual && (
                  <>
                    <Icon name="bell" />
                    Earnning Actual: {tickerResult.earnning_Actual}
                    <br />
                  </>
                )}
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              <div className="ui two buttons">
                {user &&
                  (user.user.isAdmin === true ||
                    user.user.role === 1 ||
                    user.user.role === 2 ||
                    user.user._id === alert.userId) && (
                    <>
                      <Button
                        basic
                        color="green"
                        onClick={() => setUpdate((prevValue) => !prevValue)}
                      >
                        <Icon color="yellow" name="chart bar" />
                        Chart
                      </Button>
                    </>
                  )}
                {user &&
                  (user.user.isAdmin === true ||
                    user.user.role === 1 ||
                    user.user._id === alert.userId) && (
                    <>
                      <Button
                        basic
                        color="red"
                        onClick={() => handleDelete(tickerResult._id)}
                      >
                        <Icon color="red" name="delete" />
                        Del
                      </Button>
                    </>
                  )}
              </div>
            </Card.Content>
            {update && (
              <>
                <Form.Field>
                  {isFocused === "chart" && (
                    <Label pointing="below">Tradingview URL Chart Only</Label>
                  )}
                  <Input
                    type="text"
                    placeholder="*Tradingview URL Chart Only"
                    onChange={(e) => setCharts(e.target.value)}
                    value={charts}
                    fluid
                    onFocus={() => setIsFocused("chart")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
                <Form.Field>
                  <Label>Time Frame</Label>
                  <select
                    onChange={(e) => setTime_frame(e.target.value)}
                    value={time_frame}
                  >
                    <option value="1 Min">1 Min</option>
                    <option value="5 Mins">5 Mins</option>
                    <option value="15 Mins">15 Mins</option>
                    <option value="30 Mins">30 Mins</option>
                    <option value="1 Hour">1 Hour</option>
                    <option value="2 Hours">2 Hours</option>
                    <option value="4 Hours">4 Hours</option>
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Yearly">Yearly</option>
                  </select>
                </Form.Field>
                <Form.Field>
                  <Label>Term</Label>
                  <select
                    onChange={(e) => setTerm(e.target.value)}
                    value={term}
                  >
                    <option value="DAILY">DAILY</option>
                    <option value="WEEKLY">WEEKLY</option>
                    <option value="MONTHLY">MONTHLY</option>
                  </select>
                </Form.Field>
                <Form.Field>
                  <Label>Signal</Label>
                  <select
                    onChange={(e) => setSignal(e.target.value)}
                    value={signal}
                  >
                    <option value="Unknow">UNKNOW</option>
                    <option value="Bullish">BULLISH</option>
                    <option value="Bearish">BEARISH</option>
                    <option value="Sideway">SIDEWAY</option>
                    <option value="MakeItOrBreakIt">MAKE IT OR BREAK IT</option>
                  </select>
                </Form.Field>
                <Form.Field>
                  {isFocused === "note" && (
                    <Label pointing="below">Explain your setup</Label>
                  )}
                  <TextArea
                    placeholder="Write Your Setup Here..."
                    onChange={(e) => setNote(e.target.value)}
                    value={note}
                    onFocus={() => setIsFocused("note")}
                    onBlur={() => setIsFocused(null)}
                    style={{
                      minHeight: "50px",
                      width: "100%",
                      border: "none",
                    }} // You can adjust the height as needed
                  />
                </Form.Field>
                <Form.Field>
                  {isFocused === "resistance1" && (
                    <Label pointing="below">Resistance 1 Number Only</Label>
                  )}
                  <Input
                    type="text"
                    placeholder="Resistance 1"
                    onChange={(e) => setResistance1(e.target.value)}
                    value={resistance1}
                    fluid
                    onFocus={() => setIsFocused("resistance1")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
                <Form.Field>
                  {isFocused === "resistance2" && (
                    <Label pointing="below">Resistance 2 Number Only</Label>
                  )}
                  <Input
                    type="text"
                    placeholder="Resistance 2"
                    onChange={(e) => setResistance2(e.target.value)}
                    value={resistance2}
                    fluid
                    onFocus={() => setIsFocused("resistance2")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
                <Form.Field>
                  {isFocused === "resistance3" && (
                    <Label pointing="below">Resistance 3 Number Only</Label>
                  )}
                  <Input
                    type="text"
                    placeholder="Resistance 3"
                    onChange={(e) => setResistance3(e.target.value)}
                    value={resistance3}
                    fluid
                    onFocus={() => setIsFocused("resistance3")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
                <Form.Field>
                  {isFocused === "support1" && (
                    <Label pointing="below">Support 1 Number Only</Label>
                  )}
                  <Input
                    type="text"
                    placeholder="Support 1"
                    onChange={(e) => setSupport1(e.target.value)}
                    value={support1}
                    fluid
                    onFocus={() => setIsFocused("support1")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
                <Form.Field>
                  {isFocused === "support2" && (
                    <Label pointing="below">Support 2 Number Only</Label>
                  )}
                  <Input
                    type="text"
                    placeholder="Support 2"
                    onChange={(e) => setSupport2(e.target.value)}
                    value={support2}
                    fluid
                    onFocus={() => setIsFocused("support2")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
                <Form.Field>
                  {isFocused === "support3" && (
                    <Label pointing="below">Support 3 Number Only</Label>
                  )}
                  <Input
                    type="text"
                    placeholder="Support 3"
                    onChange={(e) => setSupport3(e.target.value)}
                    value={support3}
                    fluid
                    onFocus={() => setIsFocused("support3")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
                {error && <div className="error">{error}</div>}

                <form>
                  <Button onClick={handleUpdateTicker}>Submit</Button>
                </form>
              </>
            )}
          </Card>
        </>
      )}
      {/* BREAKING NEW START */}
      {/* {user && breakingNewResult && (
        <List.Item>
          <Modal
            closeIcon
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size="large"
            trigger={<List.Header>{breakingNewResult.title}</List.Header>}
          >
            <Modal.Content scrolling style={{ fontSize: "20px" }}>
              <>
                <List.Header href={breakingNewResult.link} target="_blank">
                  {breakingNewResult.title}
                </List.Header>
                <div
                  dangerouslySetInnerHTML={{
                    __html: breakingNewResult.body,
                  }}
                />
              </>
            </Modal.Content>
          </Modal>

          <span key="date">
            {moment(breakingNewResult.createdAt).startOf("minutes").fromNow()}
          </span>
          <span key="source"> by {breakingNewResult.source}</span>
        </List.Item>
      )} */}
      {/* BREAKING NEW END */}

      {/* TICKER END  */}

      {/* HEADLINE START */}

      {user &&
        ((tickerResult && tickerResult.breakingnew) ||
          (data && data.length > 0) ||
          (tickerResult && tickerResult.ta_image) ||
          (tickerResult && tickerResult.scapling_chart)) && (
          <>
            <Card>
              {user && tickerResult && tickerResult.breakingnew && (
                <>
                  <span style={{ display: "block", textAlign: "center" }}>
                    🚨Lastest New (
                    {moment(tickerResult.breakingnew_CreatedAt)
                      .startOf("minutes")
                      .fromNow()}
                    )
                  </span>
                  <strong>{tickerResult.breakingnew}</strong>
                  <span style={{ display: "block", textAlign: "right" }}>
                    🗓️
                    {moment(tickerResult.breakingnew_CreatedAt).format(
                      "MMM, D YYYY, h:mm A "
                    )}
                  </span>
                </>
              )}
              {user && tickerResult && tickerResult.scapling_chart && (
                <Card>
                  <Divider horizontal>
                    <span className="headline">
                      {tickerResult.ticker}'s DAY TRADE SETUP
                    </span>
                  </Divider>
                  {tickerResult && tickerResult.scapling_note && (
                    <>{tickerResult.scapling_note}</>
                  )}
                  <Modal
                    closeIcon
                    dimmer="blurring"
                    size="fullscreen"
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    open={open}
                    trigger={
                      <Image size="huge" src={tickerResult.scapling_chart} />
                    }
                  >
                    <TransformWrapper>
                      <TransformComponent>
                        <Image
                          src={tickerResult.scapling_chart}
                          alt={`Smart Trading Hub`}
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </Modal>
                  Updated:{" "}
                  {moment
                    .utc(tickerResult.scapling_CreatedAt)
                    .startOf("minutes")
                    .fromNow()}{" "}
                  {moment(tickerResult.scapling_CreatedAt).format(
                    "MMM, D YY h:mm A"
                  )}
                </Card>
              )}
              {user && tickerResult && tickerResult.ta_image && (
                <Card>
                  <Divider horizontal>
                    <span className="headline">
                      {tickerResult.ticker}'s SWING TRADE SETUP
                    </span>
                  </Divider>
                  {tickerResult && tickerResult.ta_image_note && (
                    <>{tickerResult.ta_image_note}</>
                  )}
                  <Modal
                    closeIcon
                    dimmer="blurring"
                    size="fullscreen"
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    open={open}
                    trigger={<Image size="huge" src={tickerResult.ta_image} />}
                  >
                    <TransformWrapper>
                      <TransformComponent>
                        <Image
                          src={tickerResult.ta_image}
                          alt={`Smart Trading Hub`}
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </Modal>
                  Updated:{" "}
                  {moment
                    .utc(tickerResult.ta_imageCreatedAt)
                    .startOf("minutes")
                    .fromNow()}{" "}
                  {moment(tickerResult.ta_imageCreatedAt).format(
                    "MMM, D YY h:mm A"
                  )}
                </Card>
              )}
              {user && data && data.length > 0 && (
                <>
                  <Divider horizontal>
                    <span className="headline">
                      {tickerResult.ticker}'s HEADLINE
                    </span>
                  </Divider>
                  <div
                    style={{
                      display: "flex",
                      overflowY: "scroll",
                      maxHeight: "250px",
                    }}
                  >
                    <List divided>
                      {data &&
                        data.map((news) => (
                          <>
                            <List.Item key={news.id}>
                              <List.Content>
                                <List.Header as="a">
                                  <a
                                    href={user ? news.url : undefined}
                                    target="_blank"
                                  >
                                    {news.headline}
                                  </a>
                                </List.Header>
                                <List.Description>
                                  {" "}
                                  <span key="author">
                                    {" "}
                                    Source by {news.source}{" "}
                                  </span>
                                  <span>
                                    {moment
                                      .unix(news.datetime)
                                      .format("MMMM, DD YYYY HH:mm")}{" "}
                                    (
                                    {moment
                                      .unix(news.datetime)
                                      .startOf("minutes")
                                      .fromNow()}
                                    )
                                  </span>
                                </List.Description>
                              </List.Content>
                            </List.Item>
                          </>
                        ))}
                    </List>
                  </div>
                </>
              )}
            </Card>
          </>
        )}

      {/* HEADLINE END  */}
      {user &&
      (user.user.role === 1 ||
        user.user.role === 2 ||
        user.user.role === 3 ||
        user.user.role === 4 ||
        user.user.role === 5 ||
        user.user.role === 6 ||
        user.user.role === 10)
        ? ""
        : `Please sigup membership to see alerts, chart update, trading plan, insider transaction and more... `}
      {/* CHART UPDATE START */}
      {user &&
        (user.user.role === 1 ||
          user.user.role === 2 ||
          user.user.role === 3 ||
          user.user.role === 4 ||
          user.user.role === 5 ||
          user.user.role === 6 ||
          user.user.role === 10) &&
        tickerResult &&
        tickerResult.charts &&
        tickerResult.charts.length > 0 && (
          <>
            {tickerResult.charts
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .slice(0, 3)
              .map((chart) => (
                <>
                  <Card color="orange" fluid>
                    <Card.Content>
                      <Card.Header>
                        Chart update by {chart.username}{" "}
                        {user &&
                          (user.user.role === 1 ||
                            user.user.role === 2 ||
                            user.user._id === chart.userId) && (
                            <Icon
                              name="delete"
                              onClick={() =>
                                handleDeleteChart(tickerResult._id, chart._id)
                              }
                            />
                          )}
                      </Card.Header>
                      <Card.Meta>
                        {moment(chart.createdAt).format(
                          "h:mm:ss A MMM, D YYYY"
                        )}{" "}
                        ({moment(chart.createdAt).startOf("minutes").fromNow()})
                      </Card.Meta>
                      <Card.Description>{chart.note}</Card.Description>
                      <Popup
                        wide="very"
                        content={
                          <Image
                            src={chart.chartUrl}
                            size="huge"
                            rounded
                            centered
                          />
                        }
                        trigger={
                          <Image
                            src={chart.chartUrl}
                            // size="large"
                            rounded
                            as="a"
                            href={chart.chartUrl}
                            target="_blank"
                            centered
                          />
                        }
                      />
                    </Card.Content>
                  </Card>
                </>
              ))}
          </>
        )}
      {/* CHART UPDATE END */}

      {/* LIVE TRADING VIEW CHART START */}
      {tickerResult && tickerResult.ticker && (
        <Card fluid color="olive">
          <BigChart symbol={tickerResult.ticker} />
        </Card>
      )}
      {/* LIVE TRADING VIEW CHART END */}

      {/* INSIDER TRANSACTION */}
      {user &&
        (user.user.role === 1 ||
          user.user.role === 2 ||
          user.user.role === 3 ||
          user.user.role === 4 ||
          user.user.role === 5 ||
          user.user.role === 6 ||
          user.user.role === 10) &&
        tickerResult &&
        tickerResult.insider &&
        tickerResult.insider.length > 0 && (
          <>
            <div style={{ overflowX: "auto" }}>
              <span
                style={{
                  fontWeight: "bold",
                  color: "blue",
                  textAlign: "center",
                }}
              >
                LATEST INSIDER TRANSACTION
              </span>

              <Table color="blue" singleLine unstackable>
                <Table.Header>
                  <Table.Row>
                    {/* <Table.HeaderCell>Insider Trades</Table.HeaderCell> */}
                    <Table.HeaderCell>Buyer</Table.HeaderCell>
                    <Table.HeaderCell>Code</Table.HeaderCell>
                    <Table.HeaderCell>#Shares</Table.HeaderCell>
                    <Table.HeaderCell>Ave Cost($)</Table.HeaderCell>
                    <Table.HeaderCell>Total Value($)</Table.HeaderCell>
                    <Table.HeaderCell>#Share Own</Table.HeaderCell>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                {tickerResult.insider
                  .sort(
                    (a, b) =>
                      new Date(b.insider_fill_date) -
                      new Date(a.insider_fill_date)
                  )
                  .map((inside) => (
                    <>
                      {/* <Card color="orange" fluid> */}
                      <Table.Body>
                        <Table.Row>
                          {/* <Table.Cell>Apples</Table.Cell> */}
                          <Table.Cell>
                            <a
                              href={inside.linkToFilingDetails}
                              target="_blank"
                            >
                              {inside.relationship}
                            </a>
                          </Table.Cell>
                          <Table.Cell>{inside.transactionCode}</Table.Cell>
                          <Table.Cell>
                            {inside.sum_transactionShares.toLocaleString()}
                          </Table.Cell>
                          <Table.Cell>{inside.ave_pricePerShare}</Table.Cell>
                          <Table.Cell>
                            {inside.total_value.toLocaleString()}
                          </Table.Cell>
                          <Table.Cell>
                            {inside.num_shares_own.toLocaleString()}
                          </Table.Cell>
                          <Table.Cell>
                            {moment(inside.insider_fill_date).format(
                              "MMM, D y"
                            )}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </>
                  ))}
              </Table>
              <Table color="yellow">
                Code
                <br />
                * P (Buy) - Open market or private purchase of non-derivative or
                derivative security.
                <br />* S (Sell) - Open market or private sale of non-derivative
                or derivative security.
                <br />
                * F - Payment of exercise price or tax liability by delivering
                or withholding securities incident to the receipt, exercise or
                vesting of a security issued in accordance with Rule 16b-3.
                <br />* M - Exercise or conversion of derivative security
                exempted pursuant to Rule 16b-3.
              </Table>
            </div>
          </>
        )}
      {/* INSIDER TRANSACTION END */}

      {/* TECHNICALANALYSIS END */}
      {tickerResult && tickerResult.ticker && (
        <Card fluid color="pink">
          <TechnicalAnalysis symbol={tickerResult.ticker} />
        </Card>
      )}
      {/* TECHNICALANALYSIS  END */}

      {/* FUNDAMENTALDATA  END */}
      {tickerResult && tickerResult.ticker && (
        <Card fluid color="olive">
          <FundamentalData symbol={tickerResult.ticker} />
        </Card>
      )}
      {/* FUNDAMENTALDATA END */}
    </>
  );
};

export default SearchResult;
