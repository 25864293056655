import React from "react";
import { Modal, Image, Input, Form } from "semantic-ui-react";
import PostShare from "../PostShare/PostShare";
import { useAuthContext } from "../../Hooks/useAuthContext";
import noAvatar from "../../assets/img/noAvatar.png";
import "./Modal.css";
const PostShareModal = () => {
  const [open, setOpen] = React.useState(false);
  const handleInputClick = () => {
    setOpen(true);
  };
  const { user } = useAuthContext();
  const handleCloseModal = () => {
    setOpen(false);
  };
  return (
    <Modal
      onClose={() => setOpen(false)}
      //   centered={false}
      closeIcon
      onOpen={() => setOpen(true)}
      open={open}
      size="huge"
      trigger={
        <div className="PostShareModal">
          {user && (
            <>
              {user.user.avatarPicture === "noAvatar.png" ? (
                <Image className="post-image" src={noAvatar} alt="Avatar" />
              ) : (
                <Image
                  className="post-image"
                  src={user.user.avatarPicture}
                  alt="Avatar"
                />
              )}
            </>
          )}
          {/* <Image className="post-image" src={user.user.avatarPicture} /> */}
          <Input
            className="PostInput"
            fluid
            onClick={handleInputClick}
            placeholder={`What ticker would you like to discuss?`}
          />
        </div>
      }
    >
      <PostShare handleCloseModal={handleCloseModal} />
    </Modal>
  );
};

export default PostShareModal;
