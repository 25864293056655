import React, { createContext, useReducer } from "react";
// import { useAuthContext } from "../Hooks/useAuthContext";
export const AlertOptionContext = createContext();

export const alertOptionReducer = (state, action) => {
  let cloneAlerts;
  switch (action.type) {
    case "FETCH_ALERT_OPTION":
      return {
        ...state,
        alertOptions: action.payload,
      };
    case "FETCH_SINGLE_ALERT_OPTION":
      return {
        ...state,
        alertOption: action.payload,
      };
    case "FETCH_ALERT_OPTION_CLOSED":
      return {
        ...state,
        alertOptionsClosed: action.payload,
      };
    case "FETCH_PROFILE_OPTION_ALERT":
      return {
        ...state,
        profileOptionAlerts: action.payload,
      };
    case "FETCH_PLAYED_OPTION_ALERT":
      return {
        ...state,
        playedOptionAlerts: action.payload,
      };
    case "CREATE_ALERT_OPTION":
      return {
        alertOptions: [action.payload, ...state.alertOptions],
      };
    case "ALERT_UPDATE_OPTION":
      cloneAlerts = JSON.parse(JSON.stringify(state.alertOptions));
      return {
        alertOptions: cloneAlerts.map((alert) =>
          alert._id === action.payload.id ? action.payload.data : alert
        ),
      };
    case "ALERT_LIKE_OPTION":
      cloneAlerts = JSON.parse(JSON.stringify(state.alertOptions));

      cloneAlerts.forEach((alert) => {
        if (alert._id === action.payload.id) {
          if (!alert.like.includes(action.payload.user)) {
            // if not exist then add in
            alert.like.push(action.payload.user);
          } else {
            // If already exist then remove out
            alert.like.splice(alert.like.indexOf(action.payload.user));
          }
        }
      });
      return {
        alertOptions: cloneAlerts,
      };
    case "ALERT_DISLIKE_OPTION":
      cloneAlerts = JSON.parse(JSON.stringify(state.alertOptions));
      cloneAlerts.forEach((alert) => {
        if (alert._id === action.payload.id) {
          if (!alert.disLike.includes(action.payload.user)) {
            // if not exist then add in
            alert.disLike.push(action.payload.user);
          } else {
            // If already exist then remove out
            alert.disLike.splice(alert.disLike.indexOf(action.payload.user));
          }
        }
      });
      return {
        alertOptions: cloneAlerts,
      };
    case "OPTION_ALERT_PLAYER":
      cloneAlerts = JSON.parse(JSON.stringify(state.alertOptions));
      cloneAlerts.forEach((alert) => {
        if (alert._id === action.payload.id) {
          if (!alert.player.includes(action.payload.user)) {
            // if not exist then add in
            alert.player.push(action.payload.user);
          } else {
            // If already exist then remove out
            alert.player.splice(alert.player.indexOf(action.payload.user));
          }
        }
      });
      return {
        alertOptions: cloneAlerts,
      };
    case "DELETE_ALERT_OPTION":
      return {
        alertOptions: state.alertOptions.filter(
          (alert) => alert._id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export const AlertOptionContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(alertOptionReducer, {
    alerts: null,
  });
  // console.log("AlertOptionContext state:", state);
  return (
    <AlertOptionContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AlertOptionContext.Provider>
  );
};
