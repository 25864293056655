import React, { createContext, useReducer } from "react";
import { useAuthContext } from "../Hooks/useAuthContext";
export const BreakingNewContext = createContext();

export const bearkingNewsReducer = (state, action) => {
  let cloneBreakingNews;
  switch (action.type) {
    case "FETCH_BREAKINGNEW":
      return {
        ...state,
        breakingNews: action.payload,
        user: action.user,
      };
    case "FETCH_SINGLE_BREAKINGNEW":
      return {
        ...state,
        breakingNew: action.payload,
        user: action.user,
      };
    case "FETCH_BREAKINGNEWONE":
      return {
        ...state,
        breakingNew: action.payload,
        user: action.user,
      };
    case "CREATE_BREAKINGNEW":
      return {
        ...state,
        breakingNews: [action.payload, ...state.breakingNews],
      };
    case "BREAKINGNEW_UPDATE":
      cloneBreakingNews = state.breakingNews.map((breakingNew) =>
        breakingNew._id === action.payload.id
          ? action.payload.data
          : breakingNew
      );
      return {
        ...state,
        breakingNews: cloneBreakingNews,
      };
    case "DELETE_BREAKINGNEW":
      return {
        breakingNews: state.breakingNews.filter(
          (breakingNew) => breakingNew._id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export const BreakingNewContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(bearkingNewsReducer, {
    breakingNews: null,
    breakingNew: null,
    user: null,
  });
  // console.log("breakingNewContext state:", state);
  return (
    <BreakingNewContext.Provider value={{ ...state, dispatch }}>
      {children}
    </BreakingNewContext.Provider>
  );
};
