import React, { useState, useEffect } from "react";
import "./AlertShare.css";
import noAvatar from "../../assets/img/noAvatar.png";
import {
  Button,
  Form,
  Label,
  Input,
  Message,
  TextArea,
  Checkbox,
} from "semantic-ui-react";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useAlertsContext } from "../../Hooks/useAlertsContext";
import { useAlertsOptionContext } from "../../Hooks/useAlertsOptionContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
moment().format();
const AlertShare = ({
  alert,
  onUpdate,
  handleCloseModal,
  alertOption,
  onOptionUpdate,
}) => {
  const { user } = useAuthContext();
  const { dispatch: alertsDispatch } = useAlertsContext();
  const { dispatch: alertsOptionDispatch } = useAlertsOptionContext();
  const [ticker, setTicker] = useState();
  const [optionAlert, setOptionAlert] = useState(false);
  const [sharesAlert, setSharesAlert] = useState(false);
  const [closedAlert, setClosedAlert] = useState(false);
  const [longOrShort, setLongOrShort] = useState("");
  const [optionCommand, setOptionCommand] = useState("");
  const [entry, setEntry] = useState("");
  const [strikes, setStrikes] = useState("");
  const [strikes2, setStrikes2] = useState("");
  const [strikes3, setStrikes3] = useState("");
  const [strikes4, setStrikes4] = useState("");
  const [expiryDate, setExpiryDate] = useState(new Date());
  const [stopLoss, setStopLoss] = useState("");
  const [target, setTarget] = useState("");
  const [closedPrice, setClosedPrice] = useState("");
  const [chart, setChart] = useState("");
  const [analyst, setAnalyst] = useState("");
  const [status, setStatus] = useState("");
  const [error, setError] = useState(null);
  const [isFocused, setIsFocused] = useState(null);
  const tradingviewLinkRegex =
    /^https:\/\/www\.tradingview\.com\/x\/[a-zA-Z0-9]+\/$/;
  useEffect(() => {
    if (alertOption) {
      setOptionAlert(true);
      setTicker(alertOption.ticker);
      setStrikes(alertOption.strikes);
      setStrikes2(alertOption.strikes2);
      setStrikes3(alertOption.strikes3);
      setExpiryDate(new Date(Date.parse(alertOption.expiry_date)));
      setEntry(alertOption.entry);
      setTarget(alertOption.target);
      setStopLoss(alertOption.stoploss);
      setChart(alertOption.chart);
      setAnalyst(alertOption.analyst);
      setStatus(alertOption.status);
      setClosedPrice(alertOption.closed_price);
      setOptionCommand(alertOption.option);
    }
    if (alert) {
      setSharesAlert(true);
      setTicker(alert.ticker);
      setEntry(alert.entry);
      setTarget(alert.target);
      setStopLoss(alert.stoploss);
      setChart(alert.chart);
      setAnalyst(alert.analyst);
      setLongOrShort(alert.short_long);
      setClosedPrice(alert.closed_price);
      setStatus(alert.status);
    }
  }, [alert, alertOption]);
  //   SHARE ALERT HANDLE
  const handleShareSubmit = async (e) => {
    if (!user) {
      setError("You must be logged in");
      return;
    }
    e.preventDefault();
    if (chart && !tradingviewLinkRegex.test(chart)) {
      setError("Invalid TradingView URL link");
      return;
    }
    const alertToAdd = JSON.stringify({
      userId: user.user._id,
      username: user.user.username,
      ticker,
      short_long: longOrShort,
      entry,
      stoploss: stopLoss,
      target,
      chart,
      analyst,
    });
    const response = await fetch("/api/alert", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: alertToAdd,
    });
    const json = await response.json();
    if (!response.ok) {
      setError(json.message);
    }
    if (response.ok) {
      setError(null);
      alertsDispatch({ type: "CREATE_ALERT", payload: json });
      handleCloseModal();
    } else {
      console.error("error");
    }
  };

  // HANDLE UPDATE SHARES ALERT
  const handleShareEdit = async (e) => {
    if (!user) {
      setError("You must be logged in");
      return;
    }
    e.preventDefault();
    if (chart && !tradingviewLinkRegex.test(chart)) {
      setError("Invalid TradingView URL link");
      return;
    }
    const updateAlertBody = JSON.stringify({
      userId: user.user._id,
      username: user.user.username,
      userRole: user.user.role,
      isAdmin: user.user.isAdmin,
      ticker,
      short_long: longOrShort,
      entry,
      stoploss: stopLoss,
      target,
      chart,
      analyst,
      closed_price: closedPrice,
      status,
    });
    const response = await fetch("/api/alert/" + alert._id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: updateAlertBody,
    });
    const json = await response.json();
    if (!response.ok) {
      setError(json.message);
    }
    if (response.ok) {
      setError(null);
      let deepClonePrev = JSON.parse(JSON.stringify(alert));
      deepClonePrev = {
        ...deepClonePrev,
        ticker,
        short_long: longOrShort,
        entry,
        stoploss: stopLoss,
        target,
        chart,
        analyst,
        closed_price: closedPrice,
        status,
      };
      onUpdate(alert._id, deepClonePrev);
      handleCloseModal();
    } else {
      console.log("error");
    }
  };

  //   OPTION ALERT HANDLE
  const handleOptionSubmit = async (e) => {
    if (!user) {
      setError("You must be logged in");
      return;
    }
    e.preventDefault();
    if (chart && !tradingviewLinkRegex.test(chart)) {
      setError("Invalid TradingView URL link");
      return;
    }
    const optionAlertToAdd = JSON.stringify({
      userId: user.user._id,
      username: user.user.username,
      ticker: ticker,
      option: optionCommand,
      entry: entry,
      strikes: strikes,
      strikes2: strikes2,
      strikes3: strikes3,
      strikes4: strikes4,
      expiry_date: expiryDate,
      stoploss: stopLoss,
      target: target,
      chart: chart,
      analyst: analyst,
    });
    const response = await fetch("/api/optionalert", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: optionAlertToAdd,
    });
    const json = await response.json();
    if (!response.ok) {
      setError(json.message);
    }
    if (response.ok) {
      setError(null);
      alertsOptionDispatch({ type: "CREATE_ALERT_OPTION", payload: json });
      handleCloseModal();
    } else {
      console.error("error");
    }
  };

  // HANDLE UPDATE OPTION ALERT
  const handleOptionEdit = async (e) => {
    if (!user) {
      setError("You must be logged in");
      return;
    }
    e.preventDefault();
    if (chart && !tradingviewLinkRegex.test(chart)) {
      setError("Invalid TradingView URL link");
      return;
    }
    const updateOptionAlertBody = JSON.stringify({
      userId: user.user._id,
      username: user.user.username,
      userRole: user.user.role,
      isAdmin: user.user.isAdmin,
      ticker: ticker,
      option: optionCommand,
      entry: entry,
      strikes: strikes,
      strikes2: strikes2,
      strikes3: strikes3,
      strikes4: strikes4,
      expiry_date: expiryDate,
      stoploss: stopLoss,
      target: target,
      chart: chart,
      analyst: analyst,
      closed_price: closedPrice,
      status: status,
    });
    const response = await fetch("/api/optionalert/" + alertOption._id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: updateOptionAlertBody,
    });
    const json = await response.json();
    if (!response.ok) {
      setError(json.message);
    }
    if (response.ok) {
      setError(null);
      let deepClonePrev = JSON.parse(JSON.stringify(alertOption));
      deepClonePrev = {
        ...deepClonePrev,
        ticker,
        option: optionCommand,
        strikes,
        strikes2,
        strikes3,
        strikes4,
        expiry_date: expiryDate,
        entry,
        stoploss: stopLoss,
        target,
        chart,
        analyst,
        closed_price: closedPrice,
        status: status,
      };
      onOptionUpdate(alertOption._id, deepClonePrev);
      handleCloseModal();
    } else {
      console.log("error");
    }
  };
  return (
    <>
      {user && (
        <div className="AlertShare">
          {user.user.avatarPicture === "noAvatar.png" ? (
            <img src={noAvatar} alt="" />
          ) : (
            <img src={user.user.avatarPicture} alt="" />
          )}
          <div className="earningPost">
            {!alert && !alertOption && (
              <div className="alertButton">
                <Button
                  color="blue"
                  onClick={() => {
                    setSharesAlert(true);
                    setOptionAlert(false);
                  }}
                >
                  SHARES
                </Button>
                <Button
                  color="yellow"
                  onClick={() => {
                    setOptionAlert(true);
                    setSharesAlert(false);
                  }}
                >
                  OPTION
                </Button>
              </div>
            )}

            {/* Share Alert Input & Submit */}
            {sharesAlert && (
              <>
                {error && (
                  <Message size="mini" color="red">
                    {error}
                  </Message>
                )}
                <Form.Field>
                  {isFocused === "ticker" && (
                    <Label pointing="below">Ticker Required</Label>
                  )}
                  <Input
                    type="text"
                    placeholder="* Ticker"
                    onChange={(e) => setTicker(e.target.value)}
                    value={ticker}
                    fluid
                    onFocus={() => setIsFocused("ticker")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
                <div className="checkbox-group">
                  <Checkbox
                    radio
                    label="LONG SHARE"
                    value="LONG"
                    checked={longOrShort === "LONG"}
                    onChange={(event, { value }) => setLongOrShort(value)}
                  />
                  <Checkbox
                    radio
                    label="SHORT SHARE"
                    value="SHORT"
                    checked={longOrShort === "SHORT"}
                    onChange={(event, { value }) => setLongOrShort(value)}
                  />
                </div>
                <Form.Field>
                  {isFocused === "entry" && (
                    <Label pointing="below">Entry Required & Number Only</Label>
                  )}
                  <Input
                    type="text"
                    placeholder="* Entry price"
                    onChange={(e) => setEntry(e.target.value)}
                    value={entry}
                    fluid
                    onFocus={() => setIsFocused("entry")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
                <Form.Field>
                  {isFocused === "target" && (
                    <Label pointing="below">
                      Target Required & Number Only
                    </Label>
                  )}
                  <Input
                    type="text"
                    placeholder="* Target price"
                    onChange={(e) => setTarget(e.target.value)}
                    value={target}
                    fluid
                    onFocus={() => setIsFocused("target")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
                <Form.Field>
                  {isFocused === "stopLoss" && (
                    <Label pointing="below">
                      StopLoss Required & Number Only
                    </Label>
                  )}
                  <Input
                    type="text"
                    placeholder="* StopLoss price"
                    onChange={(e) => setStopLoss(e.target.value)}
                    value={stopLoss}
                    fluid
                    onFocus={() => setIsFocused("stopLoss")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
                <Form.Field>
                  {isFocused === "chart" && (
                    <Label pointing="below">Tradingview URL Chart Only</Label>
                  )}
                  <Input
                    type="text"
                    placeholder="Tradingview URL Chart Only"
                    onChange={(e) => setChart(e.target.value)}
                    value={chart}
                    fluid
                    onFocus={() => setIsFocused("chart")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>

                <Form.Field>
                  {isFocused === "analyst" && (
                    <Label pointing="below">
                      Analyst or Note of this alert
                    </Label>
                  )}
                  <TextArea
                    style={{ width: "100%", height: "75px" }}
                    type="text"
                    placeholder="Analyst or Note of this alert..."
                    onChange={(e) => setAnalyst(e.target.value)}
                    value={analyst}
                    fluid
                    onFocus={() => setIsFocused("analyst")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
                {(closedAlert || (alert && alert.status === "CLOSED")) && (
                  <>
                    <Form.Field>
                      {isFocused === "closedPrice" && (
                        <Label pointing="below">
                          Closed Price Required & Number Only
                        </Label>
                      )}
                      <Input
                        type="text"
                        placeholder="* Close price"
                        onChange={(e) => setClosedPrice(e.target.value)}
                        value={closedPrice}
                        fluid
                        onFocus={() => setIsFocused("closedPrice")}
                        onBlur={() => setIsFocused(null)}
                      />
                    </Form.Field>
                  </>
                )}
                <Form.Field>
                  {alert && alert.status !== "CLOSED" && (
                    <>
                      <Button
                        className="Button"
                        positive
                        onClick={() => {
                          setClosedAlert((prevValue) => !prevValue);
                          setStatus("CLOSED");
                        }}
                      >
                        CLOSE
                      </Button>
                    </>
                  )}
                  <Button
                    negative
                    onClick={alert ? handleShareEdit : handleShareSubmit}
                  >
                    {alert ? "UPDATE" : "SUBMIT"}
                  </Button>
                </Form.Field>
              </>
            )}

            {/* Option Alert Input & Submit*/}
            {optionAlert && (
              <>
                {error && (
                  <Message size="mini" color="red">
                    {error}
                  </Message>
                )}
                <Form.Field>
                  {isFocused === "ticker" && (
                    <Label pointing="below">Ticker Required</Label>
                  )}
                  <Input
                    type="text"
                    placeholder="* Ticker"
                    onChange={(e) => setTicker(e.target.value)}
                    value={ticker}
                    fluid
                    onFocus={() => setIsFocused("ticker")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
                <div className="checkbox-group">
                  <Checkbox
                    radio
                    label="BUY CALL"
                    value="BUY CALL"
                    checked={optionCommand === "BUY CALL"}
                    onChange={(event, { value }) => setOptionCommand(value)}
                  />
                  <Checkbox
                    radio
                    label="BUY PUT"
                    value="BUY PUT"
                    checked={optionCommand === "BUY PUT"}
                    onChange={(event, { value }) => setOptionCommand(value)}
                  />
                  <Checkbox
                    radio
                    label="SELL CALL"
                    value="SELL CALL"
                    checked={optionCommand === "SELL CALL"}
                    onChange={(event, { value }) => setOptionCommand(value)}
                  />
                  <Checkbox
                    radio
                    label="SELL PUT"
                    value="SELL PUT"
                    checked={optionCommand === "SELL PUT"}
                    onChange={(event, { value }) => setOptionCommand(value)}
                  />
                </div>
                <div className="checkbox-group">
                  <Checkbox
                    radio
                    label="DEBIT CALL"
                    value="DEBIT CALL"
                    checked={optionCommand === "DEBIT CALL"}
                    onChange={(event, { value }) => setOptionCommand(value)}
                  />
                  <Checkbox
                    radio
                    label="DEBIT PUT"
                    value="DEBIT PUT"
                    checked={optionCommand === "DEBIT PUT"}
                    onChange={(event, { value }) => setOptionCommand(value)}
                  />
                  <Checkbox
                    radio
                    label="CREDIT CALL"
                    value="CREDIT CALL"
                    checked={optionCommand === "CREDIT CALL"}
                    onChange={(event, { value }) => setOptionCommand(value)}
                  />
                  <Checkbox
                    radio
                    label="CREDIT PUT"
                    value="CREDIT PUT"
                    checked={optionCommand === "CREDIT PUT"}
                    onChange={(event, { value }) => setOptionCommand(value)}
                  />
                </div>

                <div className="checkbox-group">
                  <Checkbox
                    radio
                    label="BUTTERFLY"
                    value="BUTTERFLY"
                    checked={optionCommand === "BUTTERFLY"}
                    onChange={(event, { value }) => setOptionCommand(value)}
                  />
                  <Checkbox
                    radio
                    label="IRON CONDOR"
                    value="IRON CONDOR"
                    checked={optionCommand === "IRON CONDOR"}
                    onChange={(event, { value }) => setOptionCommand(value)}
                  />
                </div>

                {optionCommand === "BUY CALL" && (
                  <>
                    <Form.Field>
                      <Input
                        type="text"
                        placeholder="* Buy Call Strike"
                        onChange={(e) => setStrikes(e.target.value)}
                        value={strikes}
                        fluid
                        label={{ content: "Buy Call Strike", color: "green" }}
                        labelPosition="left"
                      />
                    </Form.Field>
                    <Form.Field>
                      {isFocused === "date" && (
                        <Label pointing="below">Please Pick Expiry Date</Label>
                      )}
                      <div className="expiry-date">
                        <DatePicker
                          selected={expiryDate}
                          onChange={(date) => setExpiryDate(date)}
                          onFocus={() => setIsFocused("date")}
                          onBlur={() => setIsFocused(null)}
                        />
                      </div>
                    </Form.Field>
                  </>
                )}
                {optionCommand === "BUY PUT" && (
                  <>
                    <Form.Field>
                      <Input
                        type="text"
                        placeholder="* Buy Put Strike"
                        onChange={(e) => setStrikes(e.target.value)}
                        value={strikes}
                        fluid
                        label={{ content: "Buy Put Strike", color: "red" }}
                        labelPosition="left"
                      />
                    </Form.Field>
                    <Form.Field>
                      {isFocused === "date" && (
                        <Label pointing="below">Please Pick Expiry Date</Label>
                      )}
                      <div className="expiry-date">
                        <DatePicker
                          selected={expiryDate}
                          onChange={(date) => setExpiryDate(date)}
                          onFocus={() => setIsFocused("date")}
                          onBlur={() => setIsFocused(null)}
                        />
                      </div>
                    </Form.Field>
                  </>
                )}
                {optionCommand === "SELL CALL" && (
                  <>
                    <Form.Field>
                      <Input
                        type="text"
                        placeholder="* Sell Call Strike"
                        onChange={(e) => setStrikes(e.target.value)}
                        value={strikes}
                        fluid
                        label={{ content: "Sell Call Strike", color: "red" }}
                        labelPosition="left"
                      />
                    </Form.Field>
                    <Form.Field>
                      {isFocused === "date" && (
                        <Label pointing="below">Please Pick Expiry Date</Label>
                      )}
                      <div className="expiry-date">
                        <DatePicker
                          selected={expiryDate}
                          onChange={(date) => setExpiryDate(date)}
                          onFocus={() => setIsFocused("date")}
                          onBlur={() => setIsFocused(null)}
                        />
                      </div>
                    </Form.Field>
                  </>
                )}
                {optionCommand === "SELL PUT" && (
                  <>
                    <Form.Field>
                      <Input
                        type="text"
                        placeholder="* Sell Put Strike"
                        onChange={(e) => setStrikes(e.target.value)}
                        value={strikes}
                        fluid
                        label={{ content: "Sell Put Strike", color: "green" }}
                        labelPosition="left"
                      />
                    </Form.Field>
                    <Form.Field>
                      {isFocused === "date" && (
                        <Label pointing="below">Please Pick Expiry Date</Label>
                      )}
                      <div className="expiry-date">
                        <DatePicker
                          selected={expiryDate}
                          onChange={(date) => setExpiryDate(date)}
                          onFocus={() => setIsFocused("date")}
                          onBlur={() => setIsFocused(null)}
                        />
                      </div>
                    </Form.Field>
                  </>
                )}
                {(optionCommand === "DEBIT CALL" ||
                  optionCommand === "CREDIT CALL") && (
                  <>
                    <Form.Field>
                      <Input
                        type="text"
                        placeholder="* Buy Call Strike"
                        onChange={(e) => setStrikes(e.target.value)}
                        value={strikes}
                        fluid
                        label={{ content: "Buy Call Strike", color: "green" }}
                        labelPosition="left"
                      />
                    </Form.Field>
                    <Form.Field>
                      <Input
                        type="text"
                        placeholder="* Sell Call Strike"
                        onChange={(e) => setStrikes2(e.target.value)}
                        value={strikes2}
                        fluid
                        label={{ content: "Sell Call Strike", color: "red" }}
                        labelPosition="left"
                      />
                    </Form.Field>
                    <Form.Field>
                      {isFocused === "date" && (
                        <Label pointing="below">Please Pick Expiry Date</Label>
                      )}
                      <div className="expiry-date">
                        <DatePicker
                          selected={expiryDate}
                          onChange={(date) => setExpiryDate(date)}
                          onFocus={() => setIsFocused("date")}
                          onBlur={() => setIsFocused(null)}
                        />
                      </div>
                    </Form.Field>
                  </>
                )}
                {(optionCommand === "DEBIT PUT" ||
                  optionCommand === "CREDIT PUT") && (
                  <>
                    <Form.Field>
                      <Input
                        type="text"
                        placeholder="* Buy Put Strike"
                        onChange={(e) => setStrikes(e.target.value)}
                        value={strikes}
                        fluid
                        label={{ content: "Buy Put Strike", color: "red" }}
                        labelPosition="left"
                      />
                    </Form.Field>
                    <Form.Field>
                      <Input
                        type="text"
                        placeholder="* Sell Put Strike"
                        onChange={(e) => setStrikes2(e.target.value)}
                        value={strikes2}
                        fluid
                        label={{ content: "Sell Put Strike", color: "green" }}
                        labelPosition="left"
                      />
                    </Form.Field>
                    <Form.Field>
                      {isFocused === "date" && (
                        <Label pointing="below">Please Pick Expiry Date</Label>
                      )}
                      <div className="expiry-date">
                        <DatePicker
                          selected={expiryDate}
                          onChange={(date) => setExpiryDate(date)}
                          onFocus={() => setIsFocused("date")}
                          onBlur={() => setIsFocused(null)}
                        />
                      </div>
                    </Form.Field>
                  </>
                )}
                {(optionCommand === "BUTTERFLY" ||
                  optionCommand === "IRON CONDOR") && (
                  <>
                    <Form.Field>
                      <Input
                        type="text"
                        placeholder="* Write Detail Strikes Here. Can Be Text"
                        onChange={(e) => setStrikes3(e.target.value)}
                        value={strikes3}
                        fluid
                        label={{ content: "Option Strike" }}
                        labelPosition="left"
                      />
                    </Form.Field>

                    <Form.Field>
                      {isFocused === "date" && (
                        <Label pointing="below">Please Pick Expiry Date</Label>
                      )}
                      <div className="expiry-date">
                        <DatePicker
                          selected={expiryDate}
                          onChange={(date) => setExpiryDate(date)}
                          onFocus={() => setIsFocused("date")}
                          onBlur={() => setIsFocused(null)}
                        />
                      </div>
                    </Form.Field>
                  </>
                )}

                <Form.Field>
                  {isFocused === "entry" && (
                    <Label pointing="below">
                      Option Entry Required (Not Ticker Price)
                    </Label>
                  )}
                  <Input
                    type="text"
                    placeholder="* Option entry price"
                    onChange={(e) => setEntry(e.target.value)}
                    value={entry}
                    fluid
                    onFocus={() => setIsFocused("entry")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
                <Form.Field>
                  {isFocused === "target" && (
                    <Label pointing="below">
                      Target Required & Number Only
                    </Label>
                  )}
                  <Input
                    type="text"
                    placeholder="* Target price"
                    onChange={(e) => setTarget(e.target.value)}
                    value={target}
                    fluid
                    onFocus={() => setIsFocused("target")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
                <Form.Field>
                  {isFocused === "stopLoss" && (
                    <Label pointing="below">
                      StopLoss Required & Number Only
                    </Label>
                  )}
                  <Input
                    type="text"
                    placeholder="* StopLoss price"
                    onChange={(e) => setStopLoss(e.target.value)}
                    value={stopLoss}
                    fluid
                    onFocus={() => setIsFocused("stopLoss")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
                <Form.Field>
                  {isFocused === "chart" && (
                    <Label pointing="below">Tradingview URL Chart Only</Label>
                  )}
                  <Input
                    type="text"
                    placeholder="Tradingview URL Chart Only"
                    onChange={(e) => setChart(e.target.value)}
                    value={chart}
                    fluid
                    onFocus={() => setIsFocused("chart")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>

                <Form.Field>
                  {isFocused === "analyst" && (
                    <Label pointing="below">
                      Analyst or Note of this alert
                    </Label>
                  )}
                  <TextArea
                    style={{ width: "100%", height: "75px" }}
                    type="text"
                    placeholder="Analyst or Note of this alert..."
                    onChange={(e) => setAnalyst(e.target.value)}
                    value={analyst}
                    fluid
                    onFocus={() => setIsFocused("analyst")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
                {(closedAlert ||
                  (alertOption && alertOption.status === "CLOSED")) && (
                  <>
                    <Form.Field>
                      {isFocused === "closedPrice" && (
                        <Label pointing="below">
                          Closed Price (Number Only)
                        </Label>
                      )}
                      <Input
                        type="text"
                        placeholder="Closed Price"
                        onChange={(e) => setClosedPrice(e.target.value)}
                        value={closedPrice}
                        fluid
                        onFocus={() => setIsFocused("closedPrice")}
                        onBlur={() => setIsFocused(null)}
                      />
                    </Form.Field>
                  </>
                )}
                <Form.Field>
                  {alertOption && alertOption.status !== "CLOSED" && (
                    <>
                      <Button
                        className="Button"
                        positive
                        onClick={() => {
                          setClosedAlert((prevValue) => !prevValue);
                          setStatus("CLOSED");
                        }}
                      >
                        CLOSE
                      </Button>
                    </>
                  )}
                  <Button
                    negative
                    onClick={
                      alertOption ? handleOptionEdit : handleOptionSubmit
                    }
                  >
                    {alertOption ? "UPDATE" : "SUBMIT"}
                  </Button>
                </Form.Field>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AlertShare;
