import { useContext } from 'react';
import { EarningsContext } from '../Context/EarningContext'

export const useEarningsContext = () => {
	const context = useContext(EarningsContext);

	if (!context) {
		throw Error(
			'useEarningsContext must be used inside an EarningsContextProvider'
		);
	}

	return context;
};
