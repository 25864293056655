import React, { useEffect } from "react";

const BigChart = ({ symbol }) => {
  useEffect(() => {
    if (symbol) {
      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/tv.js";
      script.async = true;
      script.onload = () => {
        new window.TradingView.widget({
          autosize: false,
          width: "100%",
          heigh: "610",
          symbol: symbol,
          interval: "D",
          timezone: "Etc/UTC",
          theme: "dark",
          style: "1",
          locale: "en",
          toolbar_bg: "#f1f3f6",
          enable_publishing: true,
          hide_legend: true,
          hide_side_toolbar: false,
          allow_symbol_change: true,
          watchlist: ["AMEX:SPY", "NASDAQ:QQQ", "NASDAQ:TSLA", "NASDAQ:AAPL"],
          details: true,
          studies: ["STD;RSI"],
          show_popup_button: true,
          popup_width: "1000",
          popup_height: "650",
          container_id: "tradingview_e3914",
        });
      };

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [symbol]);

  return (
    <div className="BigChart">
      <div id="tradingview_e3914"></div>
    </div>
  );
};

export default BigChart;
