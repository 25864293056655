import React from "react";
import { Progress, Divider, Card, Image } from "semantic-ui-react";
import { useAuthContext } from "../../Hooks/useAuthContext";
import useFetch from "../../Hooks/FetchData";
import { Helmet } from "react-helmet";
import marketindex from "../../assets/img/FearGreedIndex.png";
import moment from "moment";
import "./FearAndGreed.css";
import StockHeatMap from "../TradingView/StockHeatMap";
import CryptoHeatMap from "../TradingView/CryptoHeatMap";
import { useMetaData } from "../../Context/MetaDataContext";
// const getSentimentStatement = (value) => {
//   if (value < 25) {
//     return { statement: "Extreme Fear", color: "red" };
//   } else if (value < 50) {
//     return { statement: "Fear", color: "" };
//   } else if (value < 55) {
//     return { statement: "Neutral", color: "black" };
//   } else if (value < 75) {
//     return { statement: "Greed", color: "blue" };
//   } else {
//     return { statement: "Extreme Greed", color: "green" };
//   }
// };
const getSentimentStatement = (value) => {
  if (value > 75 && value < 100) {
    return { statement: "Extreme Greed", color: "green" };
  } else if (value > 55 && value < 75) {
    return { statement: "Greed", color: "olive" };
  } else if (value > 45 && value < 50) {
    return { statement: "Neutral", color: "teal" };
  } else if (value > 25 && value < 45) {
    return { statement: "Fear", color: "red" };
  } else {
    return { statement: "Extreme Fear", color: "violet" };
  }
};
const MarketIndex = () => {
  const { user } = useAuthContext();
  // Fetch fear and greed data
  const { data: fearAndGreeds, error: fearAndGreedError } =
    useFetch("/api/fearandgreed");

  // Fetch sentiment data
  const { data: sentiments, error: sentimentError } =
    useFetch("/api/sentiment/");
  // console.log(sentiments);

  const { metaData } = useMetaData();
  return (
    <>
      <Helmet>
        <title>Market Sentiments - Smart Trading Hub</title>
        <meta
          name="description"
          content="Explore market sentiments and trends on Smart Trading Hub. Stay updated with real-time data and insights."
        />
      </Helmet>
      <div className="MarketIndex">
        {/* <Card.Group centered style={{width: "100%"}}> */}
        <Card fluid>
          <Divider horizontal>
            <span className="FearAndGreedTitle">
              {metaData.CATEGORY.FEAR_GREED_INDEX}
            </span>
          </Divider>
          <Image size="medium" src={marketindex} centered />
          {fearAndGreeds &&
            fearAndGreeds.map((fearAndGreed) => (
              <div className="FearAndGreed" key={fearAndGreed._id}>
                <Progress
                  size="mini"
                  value={fearAndGreed.fgi.now.value}
                  progress="value"
                  active
                  total={100}
                  color={
                    getSentimentStatement(fearAndGreed.fgi.now.value).color
                  }
                >
                  <span>
                    {moment(fearAndGreed.lastUpdated.humanDate).format(
                      "MMM, D YYYY"
                    )}{" "}
                    {fearAndGreed.fgi.now.valueText}
                  </span>
                </Progress>
                <Progress
                  size="mini"
                  value={fearAndGreed.fgi.previousClose.value}
                  progress="value"
                  total={100}
                  color={
                    getSentimentStatement(fearAndGreed.fgi.previousClose.value)
                      .color
                  }
                >
                  <span>
                    Yesterday: {fearAndGreed.fgi.previousClose.valueText}
                  </span>
                </Progress>
                <Progress
                  size="mini"
                  value={fearAndGreed.fgi.oneWeekAgo.value}
                  progress="value"
                  total={100}
                  color={
                    getSentimentStatement(fearAndGreed.fgi.oneWeekAgo.value)
                      .color
                  }
                >
                  <span>Week Ago: {fearAndGreed.fgi.oneWeekAgo.valueText}</span>
                </Progress>
                <Progress
                  size="mini"
                  value={fearAndGreed.fgi.oneMonthAgo.value}
                  progress="value"
                  total={100}
                  color={
                    getSentimentStatement(fearAndGreed.fgi.oneMonthAgo.value)
                      .color
                  }
                >
                  <span>
                    Month Ago: {fearAndGreed.fgi.oneMonthAgo.valueText}
                  </span>
                </Progress>
                <Progress
                  size="mini"
                  value={fearAndGreed.fgi.oneYearAgo.value}
                  progress="value"
                  total={100}
                  color={
                    getSentimentStatement(fearAndGreed.fgi.oneYearAgo.value)
                      .color
                  }
                >
                  <span>Year Ago: {fearAndGreed.fgi.oneYearAgo.valueText}</span>
                </Progress>
              </div>
            ))}
        </Card>
        {/* Sentiments */}
        <Card fluid>
          <Divider horizontal>
            <span className="SentimentTitle">
              {metaData.CATEGORY.MARKET_SENTIMENTS_REPORT}
            </span>
          </Divider>
          {sentiments &&
            sentiments.map((sentiment) => (
              <div>
                <div className="progress-bar">
                  <div
                    className="progress bullish"
                    style={{ width: `${sentiment.bullish}%` }}
                  >
                    Bull {sentiment.bullish}%
                  </div>
                  <div
                    className="progress neutral"
                    style={{ width: `${sentiment.neutral}%` }}
                  >
                    Neutral {sentiment.neutral}%
                  </div>
                  <div
                    className="progress bearish"
                    style={{ width: `${sentiment.bearish}%` }}
                  >
                    Bear {sentiment.bearish}%
                  </div>
                </div>
                <div className="Date">
                  {moment(sentiment.date).format("MMM, D YYYY")}
                </div>
              </div>
            ))}
        </Card>
        {/* end */}
        {/* </Card.Group> */}
        <StockHeatMap />
        <CryptoHeatMap />
      </div>
    </>
  );
};

export default MarketIndex;
