import React from "react";
import EditProfileModal from "../../Modal/EditProfileModal";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import "./ProfileCard.css";
import noAvatar from "../../../assets/img/noAvatar.png";
import ProfileBanner from "../../../assets/img/ProfileBanner.png";
import { Card, Icon, Image, Modal } from "semantic-ui-react";
import moment from "moment";
import ProfileNews from "../ProfileNews/ProfileNews";
import ProfileAlerts from "../ProfileAlerts/ProfileAlerts";
import ProfileOptionAlerts from "../ProfileAlerts/ProfileOptionAlerts";
import PlayedAlerts from "../ProfileAlerts/PlayedAlerts";
import Benefit from "../../Auth/Benefit";
moment().format();
const ProfileCard = () => {
  const { user } = useAuthContext();
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <div className="ProfileCard">
        <div className="ProfileImages">
          {user.user.profileImage === "ProfileBanner.png" ? (
            <img src={ProfileBanner} alt="" />
          ) : (
            <img src={user.user.ProfileBanner} alt="" />
          )}
          {user.user.avatarPicture === "noAvatar.png" ? (
            <img src={noAvatar} alt="" />
          ) : (
            <img src={user.user.avatarPicture} alt="" />
          )}
        </div>

        <div className="ProfileName">
          <span>
            {user.user.role === 0 && "Member"}
            {user.user.role === 1 && "Admin"}
            {user.user.role === 2 && "Moderator"}
            {user.user.role === 3 && "Alert"}
            {user.user.role === 4 && "Silver Member"}
            {user.user.role === 5 && "Gold Member"}
            {user.user.role === 6 && "Premium Member"}
            {user.user.role === 10 && "Trail Member"}
          </span>
        </div>
        <div className="ProfileMe">
          <Card fluid>
            <Card.Content>
              <Card.Header>{user.user.username}</Card.Header>
              <Card.Meta>
                <span className="date">
                  Joined since{" "}
                  {moment(user.user.createdAt).format("MMMM, YYYY")}
                </span>
              </Card.Meta>
              {user.user.firstname && (
                <Card.Description>
                  First Name: {user.user.firstname}
                </Card.Description>
              )}
              {user.user.lastname && (
                <Card.Description>
                  Last Name: {user.user.lastname}
                </Card.Description>
              )}
              {user.user.livesin && (
                <Card.Description>From {user.user.livesin}</Card.Description>
              )}
              {user.user.worksAt && (
                <Card.Description>Work at {user.user.worksAt}</Card.Description>
              )}
              {user.user.phone && (
                <Card.Description>Zelle#: {user.user.phone}</Card.Description>
              )}
              {user.user.email && (
                <Card.Description>Email: {user.user.email}</Card.Description>
              )}
              {user.user.about && (
                <Card.Description>Bio: {user.user.about}</Card.Description>
              )}
            </Card.Content>
            <Card.Content extra>
              {user.user.following && user.user.following.length > 0 && (
                <>
                  <Icon name="user" />
                  {user.user.following.length} Following
                </>
              )}{" "}
              {user && (user.user.role === 1 || user.user.role === 2) && (
                <EditProfileModal />
              )}
              {user &&
                (user.user.role === 10 ||
                  user.user.role === 0 ||
                  user.user.role === 4) && <Benefit />}
            </Card.Content>
          </Card>
        </div>
        {/* <ProfileNews /> */}
        {/* <PlayedAlerts /> */}
        <ProfileAlerts />
        <ProfileOptionAlerts />
      </div>
    </>
  );
};

export default ProfileCard;
