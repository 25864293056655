import React, { useEffect } from "react";
import "./TradingView.css";

const StockChartMini = ({ symbol }) => {
  useEffect(() => {
    if (symbol) {
      const script = document.createElement("script");
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js";
      script.async = true;
      script.innerHTML = `
        {
         "symbol": "${symbol}",
          "width": "100%",
          "height": "100%",
          "locale": "en",
          "dateRange": "1D",
          "colorTheme": "light",
          "isTransparent": true,
          "autosize": true,
          "largeChartUrl": "",
          "chartOnly": true,
          "noTimeScale": true
        }
      `;

      const container = document.querySelector(
        ".tradingview-widget-chart-mini__widget"
      );
      if (container) {
        container.appendChild(script);
      }
    }

    return () => {
      const container = document.querySelector(
        ".tradingview-widget-chart-mini__widget"
      );
      if (container) {
        container.innerHTML = "";
      }
    };
  }, [symbol]);

  return (
    <div className="tradingview-widget-chart-container">
      <div className="tradingview-widget-chart-mini__widget"></div>
    </div>
  );
};

export default StockChartMini;
