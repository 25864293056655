import React, { useEffect, useState, useContext } from "react";
import ListContainer from "../components/ListContainer/ListContainer";
import ListContainerRight from "../components/ListContainerRight/ListContainerRight";
import PostContainer from "../components/PostContainer/PostContainer";
import { useAuthContext } from "../Hooks/useAuthContext";
import axios from "axios";
import "../index.css";
import { isMobile } from "react-device-detect";
import { Card, Image, Button } from "semantic-ui-react";
import { useTickerContext } from "../Hooks/useTickerContext";
import { SearchTickerContext } from "../Context/SearchTickerContext";
import { useMetaData } from "../Context/MetaDataContext";
import BreakingNew from "../components/BreakingNews/BreakingNew/UpdateBreakingNew";
import BreakingNews from "../components/BreakingNews/BreakingNews";
import BreakingNews2 from "../components/BreakingNews/BreakingNews2";
import ScrollToTopButton from "../components/Button/ScrollToTopButton";
import { Helmet } from "react-helmet";
import META from "../Constants/en_US.json";
// import TickerTape from "../components/TradingView/TickerTape";
const Home = () => {
  const { user } = useAuthContext();
  const { metaData } = useMetaData();
  const [isHovered, setIsHovered] = useState(false);
  const { tickers, dispatch } = useTickerContext();
  const [error, setError] = useState(null);
  const { handleTickerClick } = useContext(SearchTickerContext);
  const [topTickers, setTopTickers] = useState(null);
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  // get all the new update ticker
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/ticker/`);
        if (response.ok) {
          const json = await response.json();
          dispatch({ type: "FETCH_TICKER", payload: json });
        } else {
          throw new Error("Failed to fetch tickers");
        }
      } catch (error) {
        setError(error.message);
      }
    };
    fetchData();
  }, [dispatch]);

  // get polular ticker
  useEffect(() => {
    const fetchTickerData = async () => {
      try {
        const now = new Date();
        const currentHour = now.getUTCHours() - 4; // Adjust for Eastern Time (UTC-4)
        const isMarketOpen =
          currentHour >= 7 &&
          currentHour < 20 &&
          now.getUTCDay() >= 1 &&
          now.getUTCDay() <= 5;

        let tickerSymbols = [];
        if (isMarketOpen) {
          tickerSymbols = [
            "SPY",
            "QQQ",
            "DIA",
            "IWM",
            "^VIX",
            "AAPL",
            "MSFT",
            "TSLA",
            "NFLX",
            "NVDA",
            "AMZN",
            "META",
          ];
          // tickerSymbols = ["ES=F", "NQ=F", "YM=F", "RTY=F", "CL=F"];
        } else {
          tickerSymbols = ["ES=F", "NQ=F", "YM=F", "RTY=F", "CL=F"];
        }
        const requests = tickerSymbols.map((symbol) =>
          axios.get(`/api/ticker/search-by-name?name=${symbol}`)
        );

        const responses = await Promise.all(requests);
        const tickerData = responses.map((response) => response.data);
        setTopTickers(tickerData);
        // console.log(tickerData);
      } catch (error) {
        console.error(error);
      }
      // finally {
      //   // Make the next request after a short delay
      //   setTimeout(fetchTickerData, 15000); // Request every 10 seconds (adjust the interval as needed)
      // }
    };

    fetchTickerData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Smart Trading Hub</title>
        <meta
          name="description"
          content="Stay updated with our market calendar for trading events, earnings reports, and more. Smart Trading Hub provides valuable insights for successful trading."
        />
        <meta
          name="keywords"
          content="market calendar, trading events, earnings reports, insights"
        />
        <meta name="author" content="Smart Trading Hub" />
      </Helmet>
      <Card.Group
        centered
        style={{
          padding: "5px",
        }}
      >
        <BreakingNews2 />
      </Card.Group>

      {/* <TickerTape /> */}
      {isMobile ? (
        <>
          {" "}
          <Card.Group
            centered
            style={{
              ...(isMobile && {
                display: "flex",
                flexWrap: "nowrap",
                overflowX: "scroll",
                maxWidth: "100%",
                justifyContent: "flex-start",
                paddingLeft: "10px",
              }),
            }}
          >
            {topTickers &&
              topTickers.map((tickerArray) => {
                const ticker = tickerArray[0]; // Access the first element of each ticker array
                return (
                  <Card
                    style={{
                      width: "50%",
                      // height: "100%",
                      alignItems: "left",
                      flexShrink: 0,
                      flex: "0 0 auto",
                      // paddingLeft: "-50px",
                    }}
                    key={ticker._id}
                    color={ticker.regularMarketChange > 0 ? "green" : "red"}
                  >
                    <Card.Content
                      onClick={() => handleTickerClick(ticker.ticker)}
                    >
                      <Card.Meta>{ticker.name}</Card.Meta>
                      <Card.Header>
                        {ticker.ticker} ${ticker.currentPrice}{" "}
                      </Card.Header>{" "}
                      <Card.Meta>
                        <span
                          style={{
                            color:
                              ticker.regularMarketChange < 0
                                ? "red"
                                : ticker.regularMarketChange > 0
                                ? "green"
                                : "black",
                          }}
                        >
                          {ticker.regularMarketChange.toFixed(2)} (
                          {(ticker.regularMarketChangePercent * 100).toFixed(2)}
                          %)
                        </span>
                      </Card.Meta>
                    </Card.Content>
                  </Card>
                );
              })}
          </Card.Group>
        </>
      ) : (
        <>
          {" "}
          <Card.Group
            centered
            style={{
              display: "flex",
              flexWrap: "nowrap",
              overflowX: "scroll",
              maxWidth: "100%",
              justifyContent: "flex-start",
              paddingLeft: "10px",
            }}
          >
            {topTickers &&
              topTickers.map((tickerArray) => {
                const ticker = tickerArray[0]; // Access the first element of each ticker array
                return (
                  <Card
                    style={{
                      minWidth: "20%",
                      // height: "100%",
                      alignItems: "left",
                      flexShrink: 0,
                      flex: "0 0 auto",
                      // paddingLeft: "-50px",
                    }}
                    key={ticker._id}
                    color={ticker.regularMarketChange > 0 ? "green" : "red"}
                  >
                    <Card.Content
                      onClick={() =>
                        handleTickerClick(user ? ticker.ticker : "Please Login")
                      }
                    >
                      {ticker.logo ? (
                        <Image
                          avatar
                          floated="right"
                          size="large"
                          src={ticker.logo}
                        />
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "35px",
                            height: "35px",
                            borderRadius: "50%",
                            backgroundColor: "gray",
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "12px",
                            position: "absolute",
                            top: 15,
                            right: 10,
                            // left:0,
                          }}
                        >
                          {ticker.ticker}
                        </div>
                      )}
                      <Card.Header>
                        {ticker.ticker} {ticker.currentPrice}{" "}
                        <span
                          style={{
                            color:
                              ticker.regularMarketChange < 0
                                ? "red"
                                : ticker.regularMarketChange > 0
                                ? "green"
                                : "black",
                          }}
                        >
                          {ticker.regularMarketChange.toFixed(2)} (
                          {(ticker.regularMarketChangePercent * 100).toFixed(2)}
                          %)
                        </span>
                      </Card.Header>
                      <Card.Meta>{ticker.name}</Card.Meta>
                    </Card.Content>
                  </Card>
                );
              })}
          </Card.Group>
        </>
      )}

      <Card.Group
        style={{
          display: "flex",
          flexWrap: "nowrap",
          overflowX: "scroll",
          maxWidth: "100%",
          justifyContent: "flex-start", // Updated alignment to flex-start
          // justifyContent: isMobile ? "flex-start" : "center",
          paddingLeft: "10px", // Added left padding to create spacing
        }}
      >
        {tickers &&
          tickers.map((ticker) => (
            <Card
              key={ticker._id}
              style={{
                width: "100px",
                height: "40px",
                alignItems: "center",
                // flexShrink: 0,
                flex: "0 0 auto",
              }}
            >
              <Card.Content
                onClick={() =>
                  handleTickerClick(user ? ticker.ticker : "Please Login")
                }
              >
                <Card.Header style={{ color: getRandomColor() }}>
                  {ticker.ticker}
                </Card.Header>
              </Card.Content>
            </Card>
          ))}
      </Card.Group>

      <div className="Home">
        <ListContainer />
        <PostContainer />
        {!isMobile && <ListContainerRight />}
      </div>
      <ScrollToTopButton />
    </>
  );
};

export default Home;
