import React, { useState, useEffect, useContext } from "react";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import "./Earning.css";
import Login from "../../Auth/Login";
import Benefit from "../../Auth/Benefit";
import {
  Label,
  Card,
  Button,
  Image,
  Icon,
  List,
  Modal,
  Input,
  Message,
  TextArea,
  Form,
  Menu,
  Grid,
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SearchTickerContext } from "../../../Context/SearchTickerContext";
import moment from "moment";
moment().format();

const EarningToday = ({
  earning,
  onDelete,
  onBull,
  onBear,
  onNeutral,
  onUpdate,
  onPlayer,
}) => {
  const { user } = useAuthContext();
  const { handleTickerClick } = useContext(SearchTickerContext);
  const [update, setUpdate] = useState(false);
  const [ticker, setTicker] = useState();
  const [earningDate, setEarningDate] = useState(new Date());
  const [lastMove, setLastMove] = useState();
  const [estimatedMove, setEstimatedMove] = useState();
  const [actualMove, setActualMove] = useState();
  const [epsEstimate, setEpsEstimate] = useState();
  const [epsActual, setEpsActual] = useState();
  const [revenueEstimate, setRevenueEstimate] = useState();
  const [revenueActual, setRevenueActual] = useState();
  const [chart, setChart] = useState();
  const [iv, setIv] = useState();
  const [analysts, setAnalysts] = useState();
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(null);
  const tradingviewLinkRegex =
    /^https:\/\/www\.tradingview\.com\/x\/[a-zA-Z0-9]+\/$/;
  const today = moment().startOf("day");
  const tomorrow = moment().add(1, "days").startOf("day");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shareableLink, setShareableLink] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const currentDomain = window.location.origin;
  const onShare = () => {
    const link = `${currentDomain}/earning/${earning._id}`;
    setShareableLink(link);
    setIsCopied(false);
    setIsModalOpen(true);
  };

  const copyToClipboard = () => {
    const input = document.getElementById("shareableLink");
    input.select();
    document.execCommand("copy");
    setIsCopied(true);
  };
  const shareOnFacebook = () => {
    window.FB.ui({
      method: "share",
      href: shareableLink,
    });
  };
  const shareOnTwitter = () => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      `#${earning.ticker} ${
        earning.hour === "bmo"
          ? "Earning Before Market Open"
          : "Earning After Market Closed"
      }
🗓️Earning Date: ${moment.utc(earning.earningDate).format("ddd MMM, DD YYYY")}
👀IV Move: ${earning.iv ? `${earning.iv}%` : ``}
💫Estimate Move: ${earning.estimatedMove ? `${earning.estimatedMove}%` : ``}
✅Las tMove:${earning.lastMove ? `${earning.lastMove}%` : ``}
🪙Earning Estimate:  ${earning.epsEstimate.toFixed(2)}
💵Revenue Estimate: ${formatRevenue(earning.revenueEstimate)}
💬Analysts: ${shareableLink}
👉 @SMARTTRADINGHUB #EARNING #ER #ERLOTO #LOTO
`
    )}`;
    window.open(twitterShareUrl, "_blank");
  };
  useEffect(() => {
    if (earning) {
      setTicker(earning.ticker);
      setLastMove(earning.lastMove);
      setEstimatedMove(earning.estimatedMove);
      setLastMove(earning.lastMove);
      setActualMove(earning.actualMove);
      setEpsEstimate(earning.epsEstimate);
      setEpsActual(earning.epsActual);
      setRevenueEstimate(earning.revenueEstimate);
      setRevenueActual(earning.revenueActual);
      setAnalysts(earning.analysts);
      setChart(earning.chart);
      setIv(earning.iv);
      setEarningDate(new Date(Date.parse(earning.earningDate)));
    }
  }, [earning]);
  // Convert revenue
  const formatRevenue = (revenue) => {
    if (revenue < 0) {
      return formatRevenue(-revenue);
    } else if (revenue >= 1e12) {
      return (revenue / 1e12).toFixed(2) + "T";
    } else if (revenue >= 1e9) {
      return (revenue / 1e9).toFixed(2) + "B";
    } else if (revenue >= 1e6) {
      return (revenue / 1e6).toFixed(2) + "M";
    } else if (revenue >= 1e3) {
      return (revenue / 1e3).toFixed(2) + "K";
    } else {
      return revenue.toFixed(2);
    }
  };
  // HANDLE UPDATE SHARES ALERT
  const handleEarningEdit = async (e) => {
    if (!user) {
      setError("You must be logged in");
      return;
    }
    e.preventDefault();
    if (chart && !tradingviewLinkRegex.test(chart)) {
      setError("Invalid TradingView URL link");
      return;
    }
    const updateEarningBody = JSON.stringify({
      userId: user.user._id,
      userRole: user.user.role,
      isAdmin: user.user.isAdmin,
      username: user.user.username,
      ticker,
      analysts,
      earningDate,
      lastMove,
      chart,
      estimatedMove,
      actualMove,
      epsEstimate,
      epsActual,
      revenueEstimate,
      revenueActual,
      iv,
    });
    const response = await fetch("/api/er/" + earning._id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: updateEarningBody,
    });
    const json = await response.json();
    if (!response.ok) {
      setError(json.message);
    }
    if (response.ok) {
      setError(null);
      let deepClonePrev = JSON.parse(JSON.stringify(earning));
      deepClonePrev = {
        ...deepClonePrev,
        ticker,
        analysts,
        earningDate: earning.earningDate,
        lastMove,
        chart,
        estimatedMove,
        actualMove,
        epsEstimate,
        epsActual,
        revenueEstimate,
        revenueActual,
        iv,
      };
      onUpdate(earning._id, deepClonePrev);
      setUpdate(false);
      setOpen(false);
    } else {
      console.log("error");
    }
  };
  return (
    <>
      {/* <div className="earning"> */}
      {earning && moment.utc(earning.earningDate).isSame(today, "d") && (
        <List.Item>
          <Modal
            // dimmer="blurring"
            closeIcon
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size="large"
            trigger={
              <div className="menu compact">
                <Label className="menu-item" color="blue">
                  {earning.ticker}
                  <span className="floating-label-top">
                    {earning.hour === "bmo" ? (
                      <Icon color="red" name="sun" />
                    ) : (
                      <Icon color="yellow" name="moon" />
                    )}
                  </span>
                  <span className="floating-label-bottom">
                    +/-{estimatedMove}%
                  </span>
                </Label>
              </div>
            }
          >
            {user &&
            (user.user.role === 1 ||
              user.user.role === 2 ||
              user.user.role === 3 ||
              user.user.role === 4 ||
              user.user.role === 5 ||
              user.user.role === 6 ||
              user.user.role === 10) ? (
              <Modal.Content
                scrolling
                style={{ fontSize: "18px", height: "100%" }}
              >
                <Card
                  fluid
                  color={
                    earning.bull.length > earning.bear.length
                      ? "green"
                      : earning.bull.length < earning.bear.length
                      ? "red"
                      : "blue"
                  }
                >
                  <List.Content>
                    <>
                      {earning.ticker && (
                        <>
                          <span
                            onClick={() => handleTickerClick(earning.ticker)}
                          >
                            {" "}
                            <strong>{earning.ticker}</strong>
                          </span>{" "}
                          <br />
                        </>
                      )}
                      {earning.earningDate && (
                        <>
                          <Icon
                            color="purple"
                            name="calendar alternate outline"
                          />
                          <strong>Earning Date: </strong>
                          {moment.utc(earning.earningDate).format(
                            "dddd MMMM, DD YYYY"
                          )}
                          <br />
                        </>
                      )}
                      {earning.hour && (
                        <>
                          {earning.hour === "bmo" ? (
                            <>
                              <Icon color="yellow" name="sun" />
                              Earning Before Market Open{" "}
                            </>
                          ) : (
                            <>
                              <Icon color="yellow" name="moon" /> Earning After
                              Market Closed
                            </>
                          )}
                          {/* {moment(earning.earningDate).format("A") === "AM" ? (
                            <>
                              <Icon color="yellow" name="sun" /> Before Market
                              Open
                            </>
                          ) : (
                            <>
                              <Icon color="yellow" name="moon" /> After Market
                              Closed
                            </>
                          )} */}
                          <br />
                        </>
                      )}
                      {earning.quarter && (
                        <>
                          <Icon
                            color="purple"
                            name="calendar alternate outline"
                          />
                          <strong>Quarter: </strong>
                          {earning.quarter} of {earning.year}
                          <br />
                        </>
                      )}
                      {earning.iv && (
                        <>
                          <Icon color="red" name="signal" />
                          <strong>Implied volatility: </strong>
                          {earning.iv}%
                          <br />
                        </>
                      )}
                      <Icon color="red" name="chart line" />
                      <strong>Estimate % Move: </strong>
                      {earning.estimatedMove ? (
                        <>
                          +/- {earning.estimatedMove}% <br />
                        </>
                      ) : (
                        <>
                          Update soon... <br />
                        </>
                      )}
                      {earning.lastMove && (
                        <>
                          <Icon color="green" name="chart bar" />
                          <strong>Last % Move: </strong> {earning.lastMove}%
                          <br />
                        </>
                      )}
                      {earning.epsEstimate && (
                        <>
                          <Icon color="blue" name="bell slash" />
                          <strong>Earning Estimate: </strong>$
                          {earning.epsEstimate.toFixed(2)}
                          <br />
                        </>
                      )}
                      {earning.epsActual && (
                        <>
                          <Icon
                            color={
                              earning.epsEstimate > earning.epsActual
                                ? "red"
                                : "green"
                            }
                            name="bell"
                          />
                          <strong>Earning Actual: </strong>${earning.epsActual}{" "}
                          {earning.epsActual > earning.epsEstimate
                            ? "Beat"
                            : "Missed"}{" "}
                          ${" "}
                          {(earning.epsActual - earning.epsEstimate).toFixed(2)}
                          <br />
                        </>
                      )}
                      {earning.revenueEstimate && (
                        <>
                          <Icon color="blue" name="bell slash outline" />
                          <strong>Revenue Estimate: </strong>$
                          {formatRevenue(earning.revenueEstimate)}
                          <br />
                        </>
                      )}
                      {earning.revenueActual && (
                        <>
                          <Icon
                            color={
                              earning.revenueEstimate > earning.revenueActual
                                ? "red"
                                : "green"
                            }
                            name="bell outline"
                          />
                          <strong>Revenue Actual: </strong>$
                          {formatRevenue(earning.revenueActual)}{" "}
                          {earning.revenueActual > earning.revenueEstimate
                            ? "Beat"
                            : "Miss"}{" "}
                          $
                          {formatRevenue(
                            earning.revenueActual - earning.revenueEstimate
                          )}
                          <br />
                        </>
                      )}
                      {earning.analysts && (
                        <>
                          <Icon color="red" name="comment alternate" />
                          <strong>Analysts: </strong> {earning.analysts}
                          <br />
                        </>
                      )}
                      {earning.chart && (
                        <>
                          <Image
                            src={earning.chart}
                            as="a"
                            size="medium"
                            rounded
                            href={earning.chart}
                            target="_blank"
                          />
                          <br />
                        </>
                      )}
                      {/* {earning && (
                        <>
                          Players:{" "}
                          <Icon
                            name="users"
                            color="teal"
                            onClick={() => onPlayer(earning._id)}
                          />
                          {earning.player.length} <br /> Bull:{" "}
                          <Icon
                            name="arrow up"
                            color="green"
                            onClick={() => onBull(earning._id)}
                          />
                          {earning.bull.length} <br /> Neutral:{" "}
                          <Icon
                            name="arrows alternate horizontal"
                            color="blue"
                            onClick={() => onNeutral(earning._id)}
                          />
                          {earning.neutral.length} <br /> Bear:{" "}
                          <Icon
                            name="arrow down"
                            color="red"
                            onClick={() => onBear(earning._id)}
                          />
                          {earning.bear.length}
                          <br />
                        </>
                      )} */}
                      <Icon color="orange" name="bell" />
                      Posted by {earning.username} <br />
                      <Card.Meta>
                        <Icon
                          color="purple"
                          name="calendar alternate outline"
                        />
                        Estimated at{" "}
                        {moment.utc(earning.updatedAt).format("h:mm:ss A")} on{" "}
                        {moment.utc(earning.updatedAt).format("MMMM, DD YYYY")}
                      </Card.Meta>
                      <Icon
                        color="blue"
                        name="share"
                        onClick={() => onShare(alert._id)}
                      />
                      {user &&
                        (user.user.isAdmin === true ||
                          user.user.role === 1 ||
                          user.user.role === 2 ||
                          user.user._id === earning.userId) && (
                          <>
                            <Icon
                              name="edit"
                              basic
                              color="yellow"
                              onClick={() =>
                                setUpdate((prevValue) => !prevValue)
                              }
                            />
                            <Icon
                              name="delete"
                              basic
                              color="red"
                              onClick={() => onDelete(earning._id)}
                            />
                          </>
                        )}
                      <Modal
                        open={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                      >
                        <Modal.Header>Share Link</Modal.Header>
                        <Modal.Content>
                          <Input
                            fluid
                            id="shareableLink"
                            value={shareableLink}
                            readOnly
                            action={
                              <>
                                {" "}
                                <Button
                                  onClick={copyToClipboard}
                                  disabled={isCopied}
                                >
                                  {isCopied ? "Copied" : "Copy"}
                                </Button>
                                <Button
                                  onClick={shareOnTwitter}
                                  icon="twitter"
                                />
                                <Button
                                  onClick={shareOnFacebook}
                                  icon="facebook"
                                />
                              </>
                            }
                          />
                        </Modal.Content>
                      </Modal>
                      {update && (
                        <div className="EarningShare">
                          <div className="earningPost">
                            {error && (
                              <Message size="mini" color="red">
                                {error}
                              </Message>
                            )}
                            <Form.Field>
                              {isFocused === "ticker" && (
                                <Label pointing="below">Ticker Required</Label>
                              )}
                              <Input
                                type="text"
                                placeholder="* Ticker"
                                onChange={(e) => setTicker(e.target.value)}
                                value={ticker}
                                fluid
                                onFocus={() => setIsFocused("ticker")}
                                onBlur={() => setIsFocused(null)}
                              />
                            </Form.Field>
                            <Form.Field>
                              {isFocused === "date" && (
                                <Label pointing="below">EarningDate</Label>
                              )}
                              <div className="expiry-date">
                                <DatePicker
                                  showTimeSelect
                                  dateFormat="Pp"
                                  selected={earningDate}
                                  onChange={(date) => setEarningDate(date)}
                                  onFocus={() => setIsFocused("date")}
                                  onBlur={() => setIsFocused(null)}
                                />
                              </div>
                            </Form.Field>
                            <Form.Field>
                              {isFocused === "iv" && (
                                <Label pointing="below">IV moving</Label>
                              )}
                              <Input
                                type="text"
                                placeholder="* IV Moving"
                                onChange={(e) => setIv(e.target.value)}
                                value={iv}
                                fluid
                                onFocus={() => setIsFocused("iv")}
                                onBlur={() => setIsFocused(null)}
                              />
                            </Form.Field>

                            <Form.Field>
                              {isFocused === "estimatedMove" && (
                                <Label pointing="below">
                                  Estimated earning move +-number
                                </Label>
                              )}
                              <Input
                                type="text"
                                placeholder="* Estimated Move"
                                onChange={(e) =>
                                  setEstimatedMove(e.target.value)
                                }
                                value={estimatedMove}
                                fluid
                                onFocus={() => setIsFocused("estimatedMove")}
                                onBlur={() => setIsFocused(null)}
                              />
                            </Form.Field>
                            <Form.Field>
                              {isFocused === "lastMove" && (
                                <Label pointing="below">
                                  Last earning move number only
                                </Label>
                              )}
                              <Input
                                type="text"
                                placeholder="* Last moved was ?"
                                onChange={(e) => setLastMove(e.target.value)}
                                value={lastMove}
                                fluid
                                onFocus={() => setIsFocused("lastMove")}
                                onBlur={() => setIsFocused(null)}
                              />
                            </Form.Field>

                            <Form.Field>
                              {isFocused === "actualMove" && (
                                <Label pointing="below">
                                  Actual Move earning move +-number %
                                </Label>
                              )}
                              <Input
                                type="text"
                                placeholder="* Actual Move"
                                onChange={(e) => setActualMove(e.target.value)}
                                value={actualMove}
                                fluid
                                onFocus={() => setIsFocused("actualMove")}
                                onBlur={() => setIsFocused(null)}
                              />
                            </Form.Field>
                            <Form.Field>
                              {isFocused === "epsEstimate" && (
                                <Label pointing="below">eps Estimate</Label>
                              )}
                              <Input
                                type="text"
                                placeholder="* Estimated Eps"
                                onChange={(e) => setEpsEstimate(e.target.value)}
                                value={epsEstimate}
                                fluid
                                onFocus={() => setIsFocused("epsEstimate")}
                                onBlur={() => setIsFocused(null)}
                              />
                            </Form.Field>
                            <Form.Field>
                              {isFocused === "epsActual" && (
                                <Label pointing="below">eps Actual</Label>
                              )}
                              <Input
                                type="text"
                                placeholder="* Eps Actual"
                                onChange={(e) => setEpsActual(e.target.value)}
                                value={epsActual}
                                fluid
                                onFocus={() => setIsFocused("epsActual")}
                                onBlur={() => setIsFocused(null)}
                              />
                            </Form.Field>

                            <Form.Field>
                              {isFocused === "revenueEstimate" && (
                                <Label pointing="below">
                                  Actual Move earning move +-number
                                </Label>
                              )}
                              <Input
                                type="text"
                                placeholder="* Revenue Estimate"
                                onChange={(e) =>
                                  setRevenueEstimate(e.target.value)
                                }
                                value={revenueEstimate}
                                fluid
                                onFocus={() => setIsFocused("revenueEstimate")}
                                onBlur={() => setIsFocused(null)}
                              />
                            </Form.Field>
                            <Form.Field>
                              {isFocused === "revenueActual" && (
                                <Label pointing="below">Actual Revenue</Label>
                              )}
                              <Input
                                type="text"
                                placeholder="*Actual Revenue "
                                onChange={(e) =>
                                  setRevenueActual(e.target.value)
                                }
                                value={revenueActual}
                                fluid
                                onFocus={() => setIsFocused("revenueActual")}
                                onBlur={() => setIsFocused(null)}
                              />
                            </Form.Field>

                            <Form.Field>
                              {isFocused === "chart" && (
                                <Label pointing="below">
                                  Tradingview URL Chart Only
                                </Label>
                              )}
                              <Input
                                type="text"
                                placeholder="Tradingview URL Chart Only"
                                onChange={(e) => setChart(e.target.value)}
                                value={chart}
                                fluid
                                onFocus={() => setIsFocused("chart")}
                                onBlur={() => setIsFocused(null)}
                              />
                            </Form.Field>
                            <Form.Field>
                              {isFocused === "analysts" && (
                                <Label pointing="below">
                                  Analysts & import note for this earning
                                </Label>
                              )}
                              <TextArea
                                style={{ width: "100%", height: "75px" }}
                                type="text"
                                placeholder="Analysts & import note for your estimated"
                                onChange={(e) => setAnalysts(e.target.value)}
                                value={analysts}
                                fluid
                                onFocus={() => setIsFocused("analysts")}
                                onBlur={() => setIsFocused(null)}
                              />
                            </Form.Field>

                            <div className="postOption">
                              <Button
                                encType="multipart/form_data"
                                className="share-button"
                                style={{ color: "var(--blue)" }}
                                onClick={handleEarningEdit}
                              >
                                Update
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  </List.Content>

                  <Card.Content extra>
                    {user && earning && (
                      <>
                        <div className="ui seven buttons">
                          <Button
                            basic
                            color="teal"
                            onClick={() => onPlayer(earning._id)}
                          >
                            <Icon name="users" color="teal" />{" "}
                            {earning.player.length}
                          </Button>
                          <Button
                            basic
                            color="green"
                            onClick={() => onBull(earning._id)}
                          >
                            <Icon name="arrow up" color="green" />{" "}
                            {earning.bull.length}
                          </Button>
                          <Button
                            basic
                            color="blue"
                            onClick={() => onNeutral(earning._id)}
                          >
                            <Icon name="exchange" color="blue" />
                            {earning.neutral.length}
                          </Button>
                          <Button
                            basic
                            color="red"
                            onClick={() => onBear(earning._id)}
                          >
                            <Icon name="arrow down" color="red" />
                            {earning.bear.length}
                          </Button>
                        </div>
                      </>
                    )}
                  </Card.Content>
                </Card>
              </Modal.Content>
            ) : (
              <>
                <Grid columns={2} divided stackable="mobile">
                  <Grid.Row>
                    <Grid.Column>
                      <Login />
                    </Grid.Column>
                    <Grid.Column>
                      <Benefit />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </>
            )}
          </Modal>
        </List.Item>
      )}
    </>
  );
};

export default EarningToday;
