import React, { createContext, useState, useContext } from "react";
import { useAuthContext } from "../Hooks/useAuthContext";
export const SearchTickerContext = createContext();

export const SearchTickerProvider = ({ children }) => {
  const { user } = useAuthContext();
  const [value, setValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  // HANDLE SEARCH BY CLICK ON HANDLECLICK
  const handleSearchChange = async (event) => {
    setLoading(true);
    try {
      const role = user.user.role; // Get the user role from the context
      // Make API requests for ticker, alert, and option alert searches
      const [
        tickerDataResponse,
        alertDataResponse,
        optionAlertDataResponse,
        breakingNewDataResponse,
      ] = await Promise.all([
        fetch(`/api/ticker/search?ticker=${event}&role=${role}`),
        fetch(`/api/alert/search?ticker=${event}`),
        fetch(`/api/optionalert/search?ticker=${event}`),
        fetch(`/api/new/search?ticker=${event}`),
      ]);
      const [tickerData, alertData, optionAlertData, breakingNewData] =
        await Promise.all([
          tickerDataResponse.json(),
          alertDataResponse.json(),
          optionAlertDataResponse.json(),
          breakingNewDataResponse.json(),
        ]);
      // Combine the results of all searches and set the state
      const combinedSearchResults = {
        tickerData,
        alertData,
        optionAlertData,
        breakingNewData,
      };
      setSearchResults(combinedSearchResults);
      // console.log(searchResults);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  // HANDLE SEARCH ON HANDLEINPUT
  const handleSearchInput = async (event) => {
    setLoading(true);
    const { value } = event.target; // get the input value from the event object
    setValue(value);
    const searchKey = value.toUpperCase();
    const role = user.user.role;
    const userId = user.user._id;
    const userName = user.user.username;
    try {
      // Make API requests for ticker, alert, and option alert searches
      const [
        tickerDataResponse,
        alertDataResponse,
        optionAlertDataResponse,
        breakingNewDataResponse,
      ] = await Promise.all([
        fetch(
          `/api/ticker/search?ticker=${searchKey}&role=${role}&userId=${userId}&userName=${userName}`
        ),
        fetch(`/api/alert/search?ticker=${searchKey}`),
        fetch(`/api/optionalert/search?ticker=${searchKey}`),
        fetch(`/api/new/search?ticker=${event}`),
      ]);
      const [tickerData, alertData, optionAlertData, breakingNewData] =
        await Promise.all([
          tickerDataResponse.json(),
          alertDataResponse.json(),
          optionAlertDataResponse.json(),
          breakingNewDataResponse.json(),
        ]);
      // Combine the results of all searches and set the state
      const combinedSearchResults = {
        tickerData,
        alertData,
        optionAlertData,
        breakingNewData,
      };
      setSearchResults(combinedSearchResults);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleTickerClick = (ticker) => {
    setValue(ticker);
    handleSearchChange(ticker);
  };

  const handleTickerInput = (value) => {
    setValue(value);
    handleSearchInput(value);
  };

  return (
    <SearchTickerContext.Provider
      value={{
        value,
        setValue,
        searchResults,
        setSearchResults,
        handleTickerClick,
        handleTickerInput,
      }}
    >
      {children}
    </SearchTickerContext.Provider>
  );
};
