import React, { useContext, useState, useEffect } from "react";
import AlertModalUpdateOption from "../../Modal/AlertUpdateOptionModal";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import "./AlertOption.css";
import {
  Popup,
  Button,
  Icon,
  Image,
  Card,
  Modal,
  Input,
} from "semantic-ui-react";
import noAvatar from "../../../assets/img/noAvatar.png";
import { SearchTickerContext } from "../../../Context/SearchTickerContext";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import moment from "moment";
moment().format();
const AlertOptionClosed = ({
  alertOption,
  onDelete,
  onLike,
  onDisLike,
  onOptionUpdate,
  onPlayer,
}) => {
  const { user } = useAuthContext();
  const [open, setOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const { handleTickerClick } = useContext(SearchTickerContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shareableLink, setShareableLink] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const currentDomain = window.location.origin;
  const onShare = () => {
    const link = `${currentDomain}/optionalert/${alertOption._id}`;
    setShareableLink(link);
    setIsCopied(false);
    setIsModalOpen(true);
  };

  const copyToClipboard = () => {
    const input = document.getElementById("shareableLink");
    input.select();
    document.execCommand("copy");
    setIsCopied(true);
  };
  const shareOnFacebook = () => {
    window.FB.ui({
      method: "share",
      href: shareableLink,
    });
  };
  const percentChange = `${
    alertOption.option === "SELL CALL" || alertOption.option === "SELL PUT"
      ? (
          ((alertOption.entry - alertOption.closed_price) / alertOption.entry) *
          100
        ).toFixed(2)
      : (
          ((alertOption.closed_price - alertOption.entry) / alertOption.entry) *
          100
        ).toFixed(2)
  }%`;
  const shareOnTwitter = () => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      `${alertOption.option} #${alertOption.ticker} ${alertOption.strikes}
📊Option Entry Price: $${alertOption.entry} 
🎯Option Closed Price: $${alertOption.closed_price}
💰Percentage Change: ${percentChange}
👉Reason: @SMARTTRADINGHUB
${shareableLink}
$${alertOption.ticker} #optionalert #trading #smarttrading
`
    )}`;
    window.open(twitterShareUrl, "_blank");
  };
  const fetchUser = async (userId) => {
    try {
      const response = await fetch(`api/user/${userId}`);
      const postUser = await response.json();
      return postUser;
    } catch (error) {
      console.error(error);
    }
  };

  // Hook to fetch the user object associated with the post userId
  const [postUser, setPostUser] = useState(null);
  useEffect(() => {
    const getUser = async () => {
      const user = await fetchUser(alertOption.userId);
      setPostUser(user);
    };
    getUser();
  }, [alertOption.userId]);
  return (
    <>
      <Card>
        <Card.Content>
          {postUser && (
            <>
              {postUser.avatarPicture === "noAvatar.png" ? (
                <Image
                  avatar
                  floated="right"
                  size="large"
                  src={noAvatar}
                  alt="Avatar"
                />
              ) : (
                <Image
                  avatar
                  floated="right"
                  size="large"
                  src={postUser.avatarPicture}
                  alt="Avatar"
                />
              )}
            </>
          )}
          <Card.Header>
            {alertOption.status === "OPEN" ? (
              <>
                <Popup
                  content="Alert Open"
                  position="top center"
                  trigger={<Icon name="lock open" color="green" />}
                />
                <span
                  className=""
                  onClick={() => handleTickerClick(alertOption.ticker)}
                >
                  {" "}
                  {alertOption.ticker}
                </span>
              </>
            ) : (
              <>
                <Popup
                  content="Alert Closed"
                  position="top center"
                  trigger={<Icon name="lock" color="red" />}
                />
                <span
                  className=""
                  onClick={() => handleTickerClick(alertOption.ticker)}
                >
                  {" "}
                  {alertOption.ticker} ({percentChange})
                </span>
                <Icon color="red" name="checkmark" />
              </>
            )}
          </Card.Header>
          <Card.Meta>by {alertOption.username}</Card.Meta>
          <Card.Description>
            {(alertOption.option === "BUY CALL" ||
              alertOption.option === "SELL PUT" ||
              alertOption.option === "DEBIT CALL" ||
              alertOption.option === "CREDIT PUT") && (
              <>
                <Icon
                  color="green"
                  name="caret square up"
                  onClick={() => setIsShow((prevValue) => !prevValue)}
                />
                <strong onClick={() => setIsShow((prevValue) => !prevValue)}>
                  <span className="long">{alertOption.option} </span>
                </strong>
                {alertOption.ticker}
                <strong onClick={() => setIsShow((prevValue) => !prevValue)}>
                  {" "}
                  {alertOption.strikes}
                  {alertOption.strikes2 && `/${alertOption.strikes2}`}{" "}
                </strong>
                {moment.utc(alertOption.expiry_date).format("MMM, DD")}
                <strong> ${alertOption.entry}</strong>
              </>
            )}
            {(alertOption.option === "BUY PUT" ||
              alertOption.option === "SELL CALL" ||
              alertOption.option === "DEBIT PUT" ||
              alertOption.option === "CREDIT CALL") && (
              <>
                <Icon
                  color="red"
                  name="caret square down"
                  onClick={() => setIsShow((prevValue) => !prevValue)}
                />
                <strong onClick={() => setIsShow((prevValue) => !prevValue)}>
                  <span className="short">{alertOption.option} </span>
                </strong>
                {alertOption.ticker}
                <strong onClick={() => setIsShow((prevValue) => !prevValue)}>
                  {" "}
                  {alertOption.strikes}
                  {alertOption.strikes2 && `/${alertOption.strikes2}`}{" "}
                </strong>
                {moment.utc(alertOption.expiry_date).format("MMM, DD")}
                <strong> ${alertOption.entry}</strong>
              </>
            )}
            {isShow && (
              <>
                <br />
                <Icon color="violet" name="chart bar" />
                <strong>Strikes: </strong>
                <strong>
                  {alertOption.strikes}
                  {alertOption.strikes2 && `/${alertOption.strikes2} `}{" "}
                </strong>
                <br />
                <Icon color="pink" name="clock" />
                <strong>Expiry Date: </strong>
                <strong>
                  {moment.utc(alertOption.expiry_date).format("MMM, DD YYYY")}
                </strong>
                <br />
                <Icon color="yellow" name="play" />
                <strong>Entry: </strong>
                <strong>${alertOption.entry}</strong>
                <br />
                <Icon color="green" name="target" />
                <strong>Target: </strong>
                <strong>${alertOption.target}</strong>
                <br />
                <Icon color="red" name="stop circle" />
                <strong>Stoploss: </strong>
                <strong>${alertOption.stoploss}</strong>
                <br />
                {(alertOption.analyst || alertOption.chart) && (
                  <>
                    <Popup
                      size="large"
                      wide="very"
                      content={
                        <>
                          {alertOption.analyst && (
                            <>
                              <strong>Analyst:</strong> {alertOption.analyst}
                              <br />
                            </>
                          )}
                        </>
                      }
                      trigger={
                        <div>
                          <Icon name="comment" />
                          <strong>Analyst</strong>
                        </div>
                      }
                    />
                    <Modal
                      closeIcon
                      dimmer="blurring"
                      size="fullscreen"
                      onClose={() => setOpen(false)}
                      onOpen={() => setOpen(true)}
                      open={open}
                      trigger={
                        <div>
                          <Icon color="orange" name="chart bar" />
                          <strong>Chart: </strong>
                          <Image size="mini" src={alertOption.chart} rounded />
                        </div>
                      }
                    >
                      <TransformWrapper>
                        <TransformComponent>
                          <Modal.Description>
                            Analyst: {alertOption.analyst}
                          </Modal.Description>
                          <Image
                            src={alertOption.chart}
                            alt={`Chart update by ${
                              alertOption.username
                            } ${moment
                              .utc(alertOption.createdAt)
                              .format("h:mm:ss A MMM, D YYYY")}`}
                          />
                          <Modal.Header>{`Alert by ${
                            alertOption.username
                          } ${moment
                            .utc(alertOption.createdAt)
                            .startOf("minutes")
                            .fromNow()}`}</Modal.Header>
                        </TransformComponent>
                      </TransformWrapper>
                    </Modal>
                  </>
                )}

                <Icon
                  color="teal"
                  name="users"
                  // onClick={() => onPlayer(alertOption._id)}
                />
                <strong>Players: {alertOption.player.length} | </strong>
                <Icon
                  color="green"
                  name="thumbs up"
                  // onClick={() => onLike(alertOption._id)}
                />
                <strong>Like: {alertOption.like.length} | </strong>
                <Icon
                  color="red"
                  name="thumbs down"
                  // onClick={() => onDisLike(alertOption._id)}
                />
                <strong>DisLike: {alertOption.disLike.length}</strong>
                <br />
                <Card.Meta>
                  <Icon color="purple" name="calendar alternate outline" />
                  <strong>Alert Date: </strong>
                  {moment
                    .utc(alertOption.createdAt)
                    .format("MMM, DD YY | h:mm A")}
                </Card.Meta>
                {alertOption.option === "SELL CALL" ||
                alertOption.option === "SELL PUT" ? (
                  <>
                    {alertOption.closed_price &&
                      alertOption.status === "CLOSED" && (
                        <>
                          <hr />
                          <Icon color="red" name="checkmark" />
                          <strong>Closed Price: </strong>$
                          {alertOption.closed_price}
                          <br />
                          <strong>
                            <Icon color="red" name="money" />
                            Gain/Loss:{" "}
                            {(
                              ((alertOption.entry - alertOption.closed_price) /
                                alertOption.entry) *
                              100
                            ).toFixed(2)}
                            %
                          </strong>
                          <br />
                          <Card.Meta>
                            <Icon
                              color="purple"
                              name="calendar alternate outline"
                            />{" "}
                            <strong>Closed Date:</strong>{" "}
                            {moment
                              .utc(alertOption.updatedAt)
                              .format("MMM, DD YY | h:mm A")}
                          </Card.Meta>
                        </>
                      )}
                  </>
                ) : (
                  <>
                    {alertOption.closed_price &&
                      alertOption.status === "CLOSED" && (
                        <>
                          <hr />
                          <Icon color="red" name="checkmark" />
                          <strong>Closed Price: </strong>$
                          {alertOption.closed_price}
                          <br />
                          <strong>
                            <Icon color="red" name="money" />
                            Gain/Loss:{" "}
                            {(
                              ((alertOption.closed_price - alertOption.entry) /
                                alertOption.entry) *
                              100
                            ).toFixed(2)}
                            %
                          </strong>
                          <br />
                          <Card.Meta>
                            <Icon
                              color="purple"
                              name="calendar alternate outline"
                            />{" "}
                            <strong>Closed Date:</strong>{" "}
                            {moment
                              .utc(alertOption.updatedAt)
                              .format("MMM, DD YY | h:mm A")}
                          </Card.Meta>
                        </>
                      )}
                  </>
                )}
              </>
            )}
          </Card.Description>
        </Card.Content>
        {user &&
          (user.user.isAdmin === true ||
            user.user.role === 1 ||
            user.user._id === alertOption.userId) && (
            <Card.Content extra>
              <div className="ui buttons">
                <>
                  <AlertModalUpdateOption
                    alertOption={alertOption}
                    onOptionUpdate={onOptionUpdate}
                  />
                  <Button
                    basic
                    color="green"
                    onClick={() => onDelete(alertOption._id)}
                  >
                    <Icon color="red" name="delete" />
                  </Button>
                  <Button
                    basic
                    onClick={() => onShare(alert._id)}
                    color="black"
                  >
                    <Icon color="gray" name="share" />
                  </Button>
                  <Modal
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                  >
                    <Modal.Header>Share Link</Modal.Header>
                    <Modal.Content>
                      <Input
                        fluid
                        id="shareableLink"
                        value={shareableLink}
                        readOnly
                        action={
                          <>
                            {" "}
                            <Button
                              onClick={copyToClipboard}
                              disabled={isCopied}
                            >
                              {isCopied ? "Copied" : "Copy"}
                            </Button>
                            <Button onClick={shareOnTwitter} icon="twitter" />
                            <Button onClick={shareOnFacebook} icon="facebook" />
                          </>
                        }
                      />
                    </Modal.Content>
                  </Modal>
                </>
              </div>
            </Card.Content>
          )}
      </Card>
    </>
  );
};

export default AlertOptionClosed;
