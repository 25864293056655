import React, { useState, useEffect, useContext } from "react";
import { useBreakingNewContext } from "../../../Hooks/useBreakingNewContext";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import { useMetaData } from "../../../Context/MetaDataContext";
import "./GeneralNew.css";
import { Icon, Card, Modal, Button, Image, Input } from "semantic-ui-react";
import moment from "moment";
import BreakingNewUpdateModal from "../../Modal/BreakingNewUpdateModal";
import { SearchTickerContext } from "../../../Context/SearchTickerContext";
moment().format();

const GeneralNew = ({ generalNew, onDelete, onLike, onDisLike, onUpdate }) => {
  const { user } = useAuthContext();
  const { metaData } = useMetaData();
  const [isExpanded, setIsExpanded] = useState(false);
  const [color, setColor] = useState("red");
  const [open, setOpen] = useState(false);
  const { handleTickerClick } = useContext(SearchTickerContext);
  const [translatedContent, setTranslatedContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shareableLink, setShareableLink] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const currentDomain = window.location.origin;
  const onShare = () => {
    const link = `${currentDomain}/new/${generalNew._id}`;
    setShareableLink(link);
    setIsCopied(false);
    setIsModalOpen(true);
  };

  const copyToClipboard = () => {
    const input = document.getElementById("shareableLink");
    input.select();
    document.execCommand("copy");
    setIsCopied(true);
  };
  const translateToVietnamese = async () => {
    const response = await fetch(
      `https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from=en&to=vi`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key":
            process.env.REACT_APP_MSFT_SUBSCRIPTION_ID,
          "Ocp-Apim-Subscription-Region": process.env.REACT_APP_YOUR_REGION,
        },
        body: JSON.stringify([{ Text: generalNew.content }]),
      }
    );
    const data = await response.json();
    setTranslatedContent(data[0].translations[0].text);
  };
  const translateToEnglish = async () => {
    const response = await fetch(
      `https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from=vi&to=en`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key":
            process.env.REACT_APP_MSFT_SUBSCRIPTION_ID,
          "Ocp-Apim-Subscription-Region": process.env.REACT_APP_YOUR_REGION,
        },
        body: JSON.stringify([{ Text: generalNew.content }]),
      }
    );
    const data = await response.json();
    setTranslatedContent(data[0].translations[0].text);
  };
  const unRegister = () => {
    setTranslatedContent("Bạn cần đăng nhập để đọc tiếng Việt");
  };
  const unRegister2 = () => {
    setTranslatedContent("You need to login to read in English");
  };
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  const handleDoubleClick = () => {
    setOpen(false);
  };
  const tagsWithColors = generalNew.entities.map((entity) => ({
    entity,
    color: getRandomColor(),
  }));
  //WYSIWYG editor
  const modules = {
    toolbar: [
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["link"],
      ["clean"],
    ],
  };

  const MAX_DESCRIPTION_WORDS = 20;
  // const truncatedDescription = generalNew.body
  //   .split(" ")
  //   .slice(0, MAX_DESCRIPTION_WORDS)
  //   .join(" ");

  const handleReadMoreClick = () => {
    setIsExpanded((prevValue) => !prevValue);
    setColor("blue");
  };
  // const description = isExpanded
  //   ? generalNew.body
  //   : `${truncatedDescription}...`;

  return (
    <>
      {generalNew && (
        <>
          <Card fluid color="blue">
            <Card.Content>
              {/* <Image floated="left" size="avatar" src={generalNew.image} /> */}
              <Card.Header
              // href={user ? generalNew.link : undefined}
              // target="_blank"
              >
                {generalNew.title}
              </Card.Header>
              <Card.Meta className="button-group">
                <span key="date">
                  {moment(generalNew.createdAt).startOf("minutes").fromNow()}
                </span>
                <span key="author">
                  {metaData.MARKET.POSTED_BY} {generalNew.author}
                </span>
                {user &&
                  (user.user.isAdmin === true ||
                    user.user.role === 1 ||
                    user.user._id === generalNew.userId) && (
                    <>
                      <div onClick={() => onDelete(generalNew._id)}>
                        <Icon name="delete" />
                      </div>
                      <BreakingNewUpdateModal
                        generalNew={generalNew}
                        onUpdate={onUpdate}
                      />
                    </>
                  )}
                <div onClick={() => onShare(generalNew._id)}>
                  <Icon name="share" />
                </div>
                <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                  <Modal.Header>Share Link</Modal.Header>
                  <Modal.Content>
                    <Input
                      fluid
                      id="shareableLink"
                      value={shareableLink}
                      readOnly
                      action={
                        <Button onClick={copyToClipboard} disabled={isCopied}>
                          {isCopied ? "Copied" : "Copy"}
                        </Button>
                      }
                    />
                  </Modal.Content>
                </Modal>
              </Card.Meta>
              <Card.Meta className="button-group">
                <span key="author">
                  {" "}
                  {metaData.MARKET.SOURCE} {generalNew.publisher}
                </span>
              </Card.Meta>
              {generalNew.summary && (
                <>
                  <Card.Description>
                    <Modal
                      dimmer="blurring"
                      centered={false}
                      closeIcon
                      onClose={() => setOpen(false)}
                      onOpen={() => setOpen(true)}
                      open={open}
                      image
                      // scrolling
                      trigger={
                        <Card.Description>
                          {generalNew.summary}...
                        </Card.Description>
                      }
                    >
                      <Modal.Content
                        style={{ fontSize: "20px" }}
                        onDoubleClick={handleDoubleClick}
                      >
                        <>
                          {generalNew && generalNew.content && (
                            <>
                              <Button
                                basic
                                color="red"
                                onClick={
                                  user ? translateToVietnamese : unRegister
                                }
                              >
                                VN
                              </Button>
                              <Button
                                basic
                                color="blue"
                                onClick={
                                  user ? translateToEnglish : unRegister2
                                }
                              >
                                ENG
                              </Button>
                            </>
                          )}

                          <div
                            className="modal-content-container"
                            dangerouslySetInnerHTML={{
                              __html:
                                translatedContent ||
                                generalNew.content ||
                                (user
                                  ? `<p>Click the link to read more <a href="${generalNew.link}" target="_blank">${generalNew.title}</a></p>`
                                  : `<p>Please login to read more...</p>`),
                            }}
                          />
                        </>
                      </Modal.Content>
                    </Modal>
                    <Card.Meta className="button-group">
                      {/* {generalNew.entities.map((entity) => (
                    <li key={entity.term}>{entity.term}</li>
                  ))} */}
                      {tagsWithColors.map(({ entity, color }) => {
                        const termWithoutTicker = entity.term.split(":")[1];
                        return (
                          <span
                            key={entity.term}
                            style={{ color: color }}
                            onClick={() => handleTickerClick(termWithoutTicker)}
                          >
                            {termWithoutTicker}
                          </span>
                        );
                      })}
                    </Card.Meta>
                  </Card.Description>
                </>
              )}
            </Card.Content>
          </Card>
        </>
      )}
    </>
  );
};

export default GeneralNew;
