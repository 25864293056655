import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./BreakingNews.css";
import {
  Card,
  Container,
  Button,
  Divider,
  Icon,
  Modal,
  Input,
} from "semantic-ui-react";
import BreakingNew from "./BreakingNew/BreakingNew";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useBreakingNewContext } from "../../Hooks/useBreakingNewContext";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import moment from "moment";
moment().format();
const BreakingNewsSingleView = (handleNew) => {
  const { breakingNew, dispatch } = useBreakingNewContext();
  const [error, setError] = useState(null);
  const { user } = useAuthContext();
  const { id } = useParams();
  const [translatedContent, setTranslatedContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shareableLink, setShareableLink] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const currentDomain = window.location.origin;
  const onShare = (breakingNewId) => {
    const link = `${currentDomain}/breakingnew/${breakingNewId}`;
    setShareableLink(link);
    setIsCopied(false);
    setIsModalOpen(true);
  };

  const copyToClipboard = () => {
    const input = document.getElementById("shareableLink");
    input.select();
    document.execCommand("copy");
    setIsCopied(true);
  };
  const shareOnFacebook = () => {
    window.FB.ui({
      method: "share",
      href: shareableLink,
    });
  };
  const shareOnTwitter = () => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      shareableLink
    )}`;
    window.open(twitterShareUrl, "_blank");
  };

  // Get the breakingNews data from backend
  useEffect(
    () => {
      const fetchData = async () => {
        try {
          const response = await fetch(`/api/new/${id}`);
          if (response.ok) {
            const json = await response.json();
            dispatch({ type: "FETCH_SINGLE_BREAKINGNEW", payload: json });
          }
        } catch (error) {
          setError(error.message);
        }
      };
      fetchData();
    },
    [dispatch],
    id
  );
  const translateToVietnamese = async () => {
    const response = await fetch(
      `https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from=en&to=vi`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key":
            process.env.REACT_APP_MSFT_SUBSCRIPTION_ID,
          "Ocp-Apim-Subscription-Region": process.env.REACT_APP_YOUR_REGION,
        },
        body: JSON.stringify([{ Text: breakingNew.body }]),
      }
    );
    const data = await response.json();
    setTranslatedContent(data[0].translations[0].text);
  };
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const tagsWithColors =
    breakingNew?.tags?.map((tag) => ({
      tag,
      color: getRandomColor(),
    })) || [];

  // handle Update
  const handleUpdate = async (eventId, breadkingNew) => {
    if (!user) {
      return;
    }
    // dispatch({
    //   type: "BREAKINGNEW_UPDATE",
    //   payload: { id: eventId, data: breadkingNew },
    // });
    window.location.href = `/p/${eventId}`;
  };
  // handle Delete
  const handleDelete = async (eventId) => {
    try {
      if (!user) {
        throw new Error("Please login");
      }
      const response = await fetch(`/api/new/${eventId}`, {
        method: "DELETE",
        body: JSON.stringify({
          userId: user.user._id,
          userRole: user.user.role,
          isAdmin: user.user.isAdmin,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        // dispatch({ type: "DELETE_BREAKINGNEW", payload: json });
        window.location.href = `/breakingnew/${eventId}`;
      } else {
        throw new Error("Failed to delete breadkingNew");
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  return (
    <>
      <Helmet>{breakingNew && <title>{breakingNew.title}</title>}</Helmet>
      {breakingNew ? (
        <div className="BreakingNews">
          {error && <div>{error}</div>}
          <Divider horizontal>
            <Link to="/breaking">
              <span className="breaking-news">BREAKING NEWS</span>
            </Link>
          </Divider>
          <div>
            <Container>
              <h2>{breakingNew.title}</h2>
              <Button color="teal" onClick={translateToVietnamese}>
                TiếngViệt
              </Button>
              <p key="source">Sourced by {breakingNew.source}</p>
              <hr />
              <div
                className="breaking-news-page-view"
                dangerouslySetInnerHTML={{
                  __html: translatedContent || breakingNew.body,
                }}
              />
              <hr />
              {breakingNew && (
                <h5 className="button-group">
                  Ticker Relate:{" "}
                  {tagsWithColors.map(({ tag, color }) => (
                    <span
                      key={tag}
                      style={{ color: color }}
                      // onClick={() => handleTickerClick(tag)}
                    >
                      {tag}
                    </span>
                  ))}
                </h5>
              )}

              <Card.Meta className="button-group">
                Posted on{" "}
                {moment(breakingNew.createdAt).format(
                  "MMM, D YYYY | h:mm:ss A"
                )}{" "}
                by {breakingNew.username}
                {user &&
                  (user.user.isAdmin === true ||
                    user.user.role === 1 ||
                    user.user._id === breakingNew.userId) && (
                    <>
                      <div onClick={() => handleDelete(breakingNew._id)}>
                        <Icon name="delete" />
                      </div>
                    </>
                  )}
                <div onClick={() => onShare(breakingNew._id)}>
                  <Icon name="share" />
                </div>
                <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                  <Modal.Header>Share Link</Modal.Header>
                  <Modal.Content>
                    <Input
                      fluid
                      id="shareableLink"
                      value={shareableLink}
                      readOnly
                      action={
                        <>
                          {" "}
                          <Button onClick={copyToClipboard} disabled={isCopied}>
                            {isCopied ? "Copied" : "Copy"}
                          </Button>
                          <Button onClick={shareOnTwitter} icon="twitter" />
                          <Button onClick={shareOnFacebook} icon="facebook" />
                        </>
                      }
                    />
                  </Modal.Content>
                </Modal>
              </Card.Meta>
            </Container>
          </div>
        </div>
      ) : (
        <h1>SORRY NO PAGE WAS FOUND</h1>
      )}
    </>
  );
};

export default BreakingNewsSingleView;
