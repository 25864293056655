import { useContext } from "react";
import { TickerContext } from "../Context/TickerContext.js";

export const useTickerContext = () => {
  const context = useContext(TickerContext);

  if (!context) {
    throw Error(
      "useTickerContext must be used inside an TickerContextProvider"
    );
  }

  return context;
};
