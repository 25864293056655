import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./GeneralNews.css";
import { Pagination, Divider, Icon } from "semantic-ui-react";
import GeneralNew from "../GeneralNews/GeneralNew/GeneralNew";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useGeneralNewContext } from "../../Hooks/useGeneralNewContext";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { useMetaData } from "../../Context/MetaDataContext";
const GeneralNews = () => {
  const { generalNews, dispatch } = useGeneralNewContext();
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const { user } = useAuthContext();
  const { metaData } = useMetaData();

  const handlePageChange = (event, data) => {
    const { activePage } = data;
    setCurrentPage(activePage);
  };

  const handleLoadMore = () => {
    if (currentPage === totalPages) {
      setCurrentPage(1); // Reset to the first page if currently on the last page
    } else if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1); // Increment the current page by 1
    }
  };
  const handleGoBack = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1); // Decrement the current page by 1
    }
  };
  // Get the breakingNews data from backend
  useEffect(() => {
    const fetchData = async (page) => {
      try {
        const response = await fetch(`/api/news?page=${page}`);

        if (response.ok) {
          const json = await response.json();
          // console.log(json);
          setTotalPages(json.totalPages); // add this
          dispatch({ type: "FETCH_GENERALNEW", payload: json.generalNews });
        } else {
          throw new Error("Failed to fetch breakingNews");
        }
      } catch (error) {
        setError(error.message);
      }
    };
    // Function to fetch data and update state
    const fetchAndUpdateData = () => {
      fetchData(currentPage);
    };

    // Fetch data initially
    fetchAndUpdateData();

    // Set up an interval to fetch data every 5 mins
    const intervalId = setInterval(fetchAndUpdateData, 5 * 60 * 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [dispatch, currentPage]);
  // Like Handler
  const handleLike = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/new/" + eventId + "/like", {
      method: "PUT",
      body: JSON.stringify({ username: user.user.username }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "BREAKINGNEW_LIKE",
        payload: { id: eventId, user: user.user.username },
      });
    } else {
      console.error("error");
    }
  };
  // DisLike Handler
  const handleDisLike = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/new/" + eventId + "/disLike", {
      method: "PUT",
      body: JSON.stringify({ username: user.user.username }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "BREAKINGNEW_DISLIKE",
        payload: { id: eventId, user: user.user.username },
      });
    } else {
      console.error("error");
    }
  };
  // handle Update
  const handleUpdate = async (eventId, breadkingNew) => {
    if (!user) {
      return;
    }
    dispatch({
      type: "GENERALNEW_UPDATE",
      payload: { id: eventId, data: breadkingNew },
    });
  };
  // handle Delete
  const handleDelete = async (eventId) => {
    try {
      if (!user) {
        throw new Error("Please login");
      }
      const response = await fetch(`/api/news/${eventId}`, {
        method: "DELETE",
        body: JSON.stringify({
          userId: user.user._id,
          userRole: user.user.role,
          isAdmin: user.user.isAdmin,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        dispatch({ type: "DELETE_GENERALNEW", payload: json });
      } else {
        throw new Error("Failed to delete breadkingNew");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      {/* <Helmet>
        <title>Breaking New - Tin Tức Chứng Khoán Mỹ</title>
      </Helmet> */}
      <div className="GeneralNews">
        {error && <div>{error}</div>}
        <Divider horizontal>
          <Icon name="left arrow" onClick={handleGoBack} />
          <Link to="/general">
            <span className="general-news">
              {metaData.CATEGORY.GLOBAL_MARKET_NEWS}
            </span>
          </Link>{" "}
          <Icon name="right arrow" onClick={handleLoadMore} />
        </Divider>
        {/* <Divider horizontal>
            <Icon name="left arrow" onClick={handleGoBack} />{" "}
            <span className="general-news">GLOBAL MARKET NEWS</span>
            <Icon name="right arrow" onClick={handleLoadMore} />
          </Divider> */}
        <div>
          {generalNews &&
            generalNews.map((generalNew) => (
              <GeneralNew
                generalNew={generalNew}
                key={generalNew._id}
                onUpdate={handleUpdate}
                onDelete={handleDelete}
                onLike={handleLike}
                onDisLike={handleDisLike}
              />
            ))}
        </div>
        {/* {!isMobile && (
            <div className="breaking-news-page">
              <Pagination
                secondary
                siblingRange={0}
                boundaryRange={0}
                defaultActivePage={1}
                firstItem={false}
                lastItem={false}
                totalPages={totalPages}
                activePage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          )} */}
        {/* <div className="">
            {isMobile && currentPage < totalPages && (
              <span onClick={handleLoadMore}> Load more...</span>
            )}
          </div> */}
      </div>
    </>
  );
};

export default GeneralNews;
