import React, { useState, useEffect } from "react";
import "./Earnings.css";
import Earning from "./Earning/Earning";
import { Table, Menu, Divider, Icon, Label, List } from "semantic-ui-react";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useEarningsContext } from "../../Hooks/useEarningContext";
import EarningTomorrow from "./Earning/EarningTomorrow";
import EarningToday from "./Earning/EarningToday";
import { useMetaData } from "../../Context/MetaDataContext";
import moment from "moment";
moment().format();
const Earnings = () => {
  const { user } = useAuthContext();
  const { metaData } = useMetaData();
  const [error, setError] = useState(null);
  const { earnings, dispatch } = useEarningsContext();
  const today = moment().startOf("day");
  const tomorrow = moment().add(1, "days").startOf("day");
  // console.log({ tomorrow: tomorrow });
  const startOfWeek = moment().startOf("week");
  const endOfWeek = moment().endOf("week");
  // const thisMonth = moment().startOf("month").startOf("day");
  // Get the Alert data from backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/er");
        if (response.ok) {
          const json = await response.json();
          // console.log(json);
          dispatch({ type: "FETCH_EARNING", payload: json });
        } else {
          // throw new Error("Failed to fetch earning");
        }
      } catch (error) {
        setError(error.message);
      }
    };
    // Function to fetch data and update state
    const fetchAndUpdateData = () => {
      fetchData();
    };

    // Fetch data initially
    fetchAndUpdateData();

    // Set up an interval to fetch data every 3 seconds
    const intervalId = setInterval(fetchAndUpdateData, 1000 * 60);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [dispatch]);

  // Like Handler
  const handleBull = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/er/" + eventId + "/bull", {
      method: "PUT",
      body: JSON.stringify({ userId: user.user._id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "EARNING_BULL",
        payload: { id: eventId, user: user.user._id },
      });
    } else {
      console.error("error");
    }
  };
  // DisLike Handler
  const handleBear = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/er/" + eventId + "/bear", {
      method: "PUT",
      body: JSON.stringify({ userId: user.user._id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "EARNING_BEAR",
        payload: { id: eventId, user: user.user._id },
      });
    } else {
      console.error("error");
    }
  };
  // Neutral Handler
  const handleNeutral = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/er/" + eventId + "/neutral", {
      method: "PUT",
      body: JSON.stringify({ userId: user.user._id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "EARNING_NEUTRAL",
        payload: { id: eventId, user: user.user._id },
      });
    } else {
      console.error("error");
    }
  };

  // HandlePlayer
  const handlePlayer = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/er/" + eventId + "/player", {
      method: "PUT",
      body: JSON.stringify({ userId: user.user._id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "EARNING_PLAYER",
        payload: { id: eventId, user: user.user._id },
      });
    } else {
      console.error("error");
    }
  };
  // handle Update
  const handleUpdate = async (eventId, earning) => {
    if (!user) {
      return;
    }
    dispatch({
      type: "EARNING_UPDATE",
      payload: { id: eventId, data: earning },
    });
  };
  // handle Delete
  const handleDelete = async (eventId) => {
    try {
      if (!user) {
        throw new Error("Please login");
      }
      const response = await fetch(`/api/er/${eventId}`, {
        method: "DELETE",
        body: JSON.stringify({
          userId: user.user._id,
          userRole: user.user.role,
          isAdmin: user.user.isAdmin,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        dispatch({ type: "DELETE_EARNING", payload: json });
      } else {
        throw new Error("Failed to delete earning");
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  return (
    <>
      {error && <div> {error} </div>}

      {earnings &&
        earnings.filter((earning) =>
          moment.utc(earning.earningDate).isSame(today, "d")
        ).length > 0 && (
          <div className="Earnings">
            <Divider horizontal>
              <span className="Earnings_Today">
                {metaData.CATEGORY.EARNINGS_TODAY}
              </span>{" "}
            </Divider>
            <List className="List" horizontal>
              {earnings &&
                earnings.map((earning) => (
                  <EarningToday
                    earning={earning}
                    key={earning._id}
                    onPlayer={handlePlayer}
                    onUpdate={handleUpdate}
                    onDelete={handleDelete}
                    onBull={handleBull}
                    onBear={handleBear}
                    onNeutral={handleNeutral}
                  />
                ))}
            </List>
          </div>
        )}
      {earnings &&
        earnings.filter((earning) =>
          moment.utc(earning.earningDate).isSame(tomorrow, "day")
        ).length > 0 && (
          <div className="Earnings">
            <Divider horizontal>
              <span className="Earnings_Yesterday">
                {metaData.CATEGORY.EARNINGS_TOMORROW}
              </span>{" "}
            </Divider>
            <List className="List" horizontal>
              {earnings &&
                earnings.map((earning) => (
                  <EarningTomorrow
                    earning={earning}
                    key={earning._id}
                    onPlayer={handlePlayer}
                    onUpdate={handleUpdate}
                    onDelete={handleDelete}
                    onBull={handleBull}
                    onBear={handleBear}
                    onNeutral={handleNeutral}
                  />
                ))}
            </List>
          </div>
        )}
      {earnings &&
        earnings.filter((earning) =>
          moment
            .utc(earning.earningDate)
            .isBetween(startOfWeek, endOfWeek, null, "[]")
        ).length > 0 && (
          <div className="Earnings">
            <Divider horizontal>
              <span className="Earnings_Week">
                {metaData.CATEGORY.EARNINGS_THIS_WEEK}
              </span>
            </Divider>
            <List className="List" horizontal>
              {earnings &&
                earnings.map((earning) => (
                  <Earning
                    earning={earning}
                    key={earning._id}
                    onPlayer={handlePlayer}
                    onUpdate={handleUpdate}
                    onDelete={handleDelete}
                    onBull={handleBull}
                    onBear={handleBear}
                    onNeutral={handleNeutral}
                  />
                ))}
            </List>
          </div>
        )}
    </>
  );
};

export default Earnings;
