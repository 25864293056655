import React, { createContext, useReducer } from "react";
// import { useAuthContext } from "../Hooks/useAuthContext";
export const AlertsContext = createContext();

export const alertsReducer = (state, action) => {
  let cloneAlerts;
  switch (action.type) {
    case "FETCH_ALERT":
      return {
        ...state,
        alerts: action.payload,
      };
    case "FETCH_ALERT_CLOSED":
      return {
        ...state,
        alertsclosed: action.payload,
      };
    case "FETCH_SINGLE_ALERT":
      return {
        alert: action.payload,
      };
    case "FETCH_PROFILE_ALERT":
      return {
        ...state,
        profileAlerts: action.payload,
      };
    case "FETCH_PLAYED_ALERT":
      return {
        ...state,
        playedAlerts: action.payload,
      };
    case "CREATE_ALERT":
      return {
        alerts: [action.payload, ...state.alerts],
      };
    case "ALERT_UPDATE":
      cloneAlerts = JSON.parse(JSON.stringify(state.alerts));
      return {
        alerts: cloneAlerts.map((alert) =>
          alert._id === action.payload.id ? action.payload.data : alert
        ),
      };
    case "ALERT_LIKE":
      cloneAlerts = JSON.parse(JSON.stringify(state.alerts));
      cloneAlerts.forEach((alert) => {
        if (alert._id === action.payload.id) {
          if (!alert.like.includes(action.payload.user)) {
            // if not exist then add in
            alert.like.push(action.payload.user);
          } else {
            // If already exist then remove out
            alert.like.splice(alert.like.indexOf(action.payload.user));
          }
        }
      });
      return {
        alerts: cloneAlerts,
      };
    case "ALERT_DISLIKE":
      cloneAlerts = JSON.parse(JSON.stringify(state.alerts));
      cloneAlerts.forEach((alert) => {
        if (alert._id === action.payload.id) {
          if (!alert.disLike.includes(action.payload.user)) {
            // if not exist then add in
            alert.disLike.push(action.payload.user);
          } else {
            // If already exist then remove out
            alert.disLike.splice(alert.disLike.indexOf(action.payload.user));
          }
        }
      });
      return {
        alerts: cloneAlerts,
      };
    case "SINGLE_ALERT_DISLIKE":
      cloneAlerts = JSON.parse(JSON.stringify(state.alerts));
      cloneAlerts.forEach((alert) => {
        if (alert._id === action.payload.id) {
          if (!alert.disLike.includes(action.payload.user)) {
            // if not exist then add in
            alert.disLike.push(action.payload.user);
          } else {
            // If already exist then remove out
            alert.disLike.splice(alert.disLike.indexOf(action.payload.user));
          }
        }
      });
      return {
        alert: cloneAlerts,
      };
    case "ALERT_PLAYER":
      cloneAlerts = JSON.parse(JSON.stringify(state.alerts));
      cloneAlerts.forEach((alert) => {
        if (alert._id === action.payload.id) {
          if (!alert.player.includes(action.payload.user)) {
            // if not exist then add in
            alert.player.push(action.payload.user);
          } else {
            // If already exist then remove out
            alert.player.splice(alert.player.indexOf(action.payload.user));
          }
        }
      });
      return {
        alerts: cloneAlerts,
      };
    case "DELETE_ALERT":
      return {
        alerts: state.alerts.filter((alert) => alert._id !== action.payload),
      };
    default:
      return state;
  }
};

export const AlertsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(alertsReducer, {
    alerts: null,
  });
  // console.log("AlertContext state:", state);
  return (
    <AlertsContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AlertsContext.Provider>
  );
};
