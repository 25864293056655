import React from "react";
import ProfileCard from "../Profile/ProfileCard/ProfileCard";
import ProfileContainer from "./ProfileContainer/ProfileContainer";
import "./Profile.css";
// import { useAuthContext } from "../../Hooks/useAuthContext";
const Profile = () => {
  // const { user } = useAuthContext();
  return (
    <div className="Profile">
      <ProfileCard />
      <ProfileContainer />
    </div>
  );
};

export default Profile;
