import React, { createContext, useReducer } from "react";
// import { useAuthContext } from "../Hooks/useAuthContext";
export const TickerContext = createContext();
const initialState = {
  insidertickers: [],
  tickers: [],
  favoriteTickers: [],
};
export const tickerReducer = (state, action) => {
  let cloneTickers;
  switch (action.type) {
    case "FETCH_TICKER_INSIDER":
      return {
        ...state,
        insidertickers: action.payload,
      };
    case "FETCH_FAVORITE_TICKER":
      return {
        ...state,
        favoriteTickers: action.payload,
      };
    case "FETCH_TICKER":
      return {
        ...state,
        tickers: action.payload,
      };
    case "CREATE_TICKER":
      return {
        tickers: [action.payload, ...state.tickers],
      };
    case "TICKER_FAVORITE":
      // Check if the ticker exists in the favoriteTickers array
      const isFavorite = state.favoriteTickers.some(
        (ticker) => ticker._id === action.payload.id
      );

      if (isFavorite) {
        // If it's already a favorite, remove it from the favoriteTickers array
        return {
          ...state,
          favoriteTickers: state.favoriteTickers.filter(
            (ticker) => ticker._id !== action.payload.id
          ),
        };
      } else {
        // If it's not a favorite, add it to the favoriteTickers array and sort by favoriteAddedAt
        const newFavorite = {
          _id: action.payload.id,
          favoriteAddedAt: Date.now(),
        };

        const updatedFavoriteTickers = [
          ...state.favoriteTickers,
          newFavorite,
        ].sort((a, b) => a.favoriteAddedAt - b.favoriteAddedAt);

        return {
          ...state,
          favoriteTickers: updatedFavoriteTickers,
        };
      }
    case "TICKER_UPDATE":
      cloneTickers = JSON.parse(JSON.stringify(state.tickers));
      return {
        tickers: cloneTickers.map((ticker) =>
          ticker._id === action.payload.id ? action.payload.data : ticker
        ),
      };
    case "DELETE_TICKER":
      return {
        tickers: state.tickers.filter(
          (ticker) => ticker._id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export const TickerContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(tickerReducer, initialState);
  //   console.log("TickerContext state:", state);
  return (
    <TickerContext.Provider value={{ ...state, dispatch }}>
      {children}
    </TickerContext.Provider>
  );
};
