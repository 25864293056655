import React, { createContext, useReducer } from "react";
// import { useAuthContext } from "../Hooks/useAuthContext";
export const EarningsContext = createContext();

export const earningsReducer = (state, action) => {
  let cloneEarnings;
  switch (action.type) {
    case "FETCH_EARNING":
      return {
        earnings: action.payload,
      };
    case "FETCH_SINGLE_EARNING_OPTION":
      return {
        earning: action.payload,
      };
    case "CREATE_EARNING":
      return {
        earnings: [action.payload, ...state.earnings],
      };
    case "EARNING_UPDATE":
      cloneEarnings = JSON.parse(JSON.stringify(state.earnings));
      return {
        earnings: cloneEarnings.map((earning) =>
          earning._id === action.payload.id ? action.payload.data : earning
        ),
      };
    case "EARNING_BULL":
      cloneEarnings = JSON.parse(JSON.stringify(state.earnings));
      cloneEarnings.forEach((earning) => {
        if (earning._id === action.payload.id) {
          if (!earning.bull.includes(action.payload.user)) {
            // if not exist then add in
            earning.bull.push(action.payload.user);
          } else {
            // If already exist then remove out
            earning.bull.splice(earning.bull.indexOf(action.payload.user));
          }
        }
      });
      return {
        earnings: cloneEarnings,
      };
    case "EARNING_BEAR":
      cloneEarnings = JSON.parse(JSON.stringify(state.earnings));
      cloneEarnings.forEach((earning) => {
        if (earning._id === action.payload.id) {
          if (!earning.bear.includes(action.payload.user)) {
            // if not exist then add in
            earning.bear.push(action.payload.user);
          } else {
            // If already exist then remove out
            earning.bear.splice(earning.bear.indexOf(action.payload.user));
          }
        }
      });
      return {
        earnings: cloneEarnings,
      };
    case "EARNING_NEUTRAL":
      cloneEarnings = JSON.parse(JSON.stringify(state.earnings));
      cloneEarnings.forEach((earning) => {
        if (earning._id === action.payload.id) {
          if (!earning.neutral.includes(action.payload.user)) {
            // if not exist then add in
            earning.neutral.push(action.payload.user);
          } else {
            // If already exist then remove out
            earning.neutral.splice(
              earning.neutral.indexOf(action.payload.user)
            );
          }
        }
      });
      return {
        earnings: cloneEarnings,
      };
    case "EARNING_PLAYER":
      cloneEarnings = JSON.parse(JSON.stringify(state.earnings));
      cloneEarnings.forEach((earning) => {
        if (earning._id === action.payload.id) {
          if (!earning.player.includes(action.payload.user)) {
            // if not exist then add in
            earning.player.push(action.payload.user);
          } else {
            // If already exist then remove out
            earning.player.splice(earning.player.indexOf(action.payload.user));
          }
        }
      });
      return {
        earnings: cloneEarnings,
      };
    case "DELETE_EARNING":
      return {
        earnings: state.earnings.filter(
          (earning) => earning._id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export const EarningContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(earningsReducer, {
    earnings: null,
  });
  // console.log("earningContext state:", state);
  return (
    <EarningsContext.Provider value={{ ...state, dispatch }}>
      {children}
    </EarningsContext.Provider>
  );
};
