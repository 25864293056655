import React from "react";
import "./CustomTabMenu.css"; // Import your CSS file
import { Divider, Card, List, Tab } from "semantic-ui-react";
const CustomTabMenu = ({ panes }) => {
  return (
    <div>
      <Tab
        menu={{
          secondary: true,
          pointing: true,
          className: "tab-menu-container", // Apply a custom CSS class to the menu
        }}
        panes={panes}
      />
    </div>
  );
};

export default CustomTabMenu;
