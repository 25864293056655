import React, { useState, useEffect } from "react";
import "./Auth.css";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useMetaData } from "../../Context/MetaDataContext";
import {
  Icon,
  Step,
  Message,
  Form,
  Button,
  Image,
  Modal,
} from "semantic-ui-react";
import logo from "../../assets/img/Banner.png";
import { Link } from "react-router-dom";
import ChatChannel from "../Discords/ChatChannel/ChatChannel";

const Benefit = () => {
  const { dispatch } = useAuthContext();
  const { user } = useAuthContext();
  const { metaData } = useMetaData();
  useEffect(() => {
    const fetchDiscordUser = async () => {
      const urlSearchParams = new URLSearchParams(
        window.location.hash.slice(1)
      );
      const accessToken = urlSearchParams.get("access_token");
      if (!accessToken) {
        // console.error("Access token is missing");
        return;
      }
      try {
        const response = await fetch(
          `/api/user/discord/login?token=${accessToken}`
        );
        if (response.ok) {
          const userData = await response.json();
          localStorage.setItem("user", JSON.stringify(userData));
          dispatch({ type: "LOGIN", payload: userData });
        } else {
          console.error(`API request failed with status ${response.status}`);
        }
      } catch (error) {
        console.error(`API request failed: ${error.message}`);
      }
    };
    fetchDiscordUser();
  }, []);
  const handleLogin = () => {
    const redirectUri = encodeURIComponent(
      "https://www.smarttradinghub.net/login"
    );
    const url = `https://discord.com/api/oauth2/authorize?client_id=1083608990132490250&redirect_uri=${redirectUri}&response_type=token&scope=identify%20email`;
    window.location.href = url;
  };
  return (
    <div className="Auth">
      {/* <Image
        centered
        src={logo}
        alt="Stock & Crypto Hub"
        style={{ height: "45px", marginTop: "10px" }}
      /> */}
      {/* https://discord.gg/P8z9jReHJ4 */}
      <Message
        style={{ marginTop: "15px" }}
        Image={logo}
        header={metaData.BENEFIT.TITLE}
        list={[
          `${metaData.BENEFIT.BENEFIT_01}`,
          `${metaData.BENEFIT.BENEFIT_02}`,
          `${metaData.BENEFIT.BENEFIT_03}`,
          `${metaData.BENEFIT.BENEFIT_04}`,
          `${metaData.BENEFIT.BENEFIT_05}`,
          `${metaData.BENEFIT.BENEFIT_06}`,
          `${metaData.BENEFIT.BENEFIT_07}`,
          `${metaData.BENEFIT.BENEFIT_08}`,
          `${metaData.BENEFIT.BENEFIT_09}`,
          `${metaData.BENEFIT.BENEFIT_1O}`,
          `${metaData.BENEFIT.BENEFIT_MORE}`,
        ]}
      />
      <Step.Group
        fluid
        vertical
        size="small"
        style={{ marginTop: "15px", marginBottom: "15px" }}
      >
        <Step>
          <Icon name="discord" color="purple" />
          <Step.Content>
            <Step.Title>{metaData.BENEFIT.STEP1}</Step.Title>
            <Step.Description onClick={handleLogin}>
              <Icon name="discord" />
              <Link>{metaData.LOGIN.LOGIN_DISCORD}</Link>
            </Step.Description>
          </Step.Content>
        </Step>

        <Step>
          <Icon name="discord" color="blue" />
          <Step.Content>
            <Step.Title>{metaData.BENEFIT.STEP2}</Step.Title>
            <Step.Description>
              <Link target="blank" to="https://discord.gg/P8z9jReHJ4">
                <Icon name="discord" /> Discord Server
              </Link>
            </Step.Description>
          </Step.Content>
        </Step>

        <Step>
          <Icon name="dollar" size="tiny" color="green" />
          <Step.Content>
            <Step.Title>{metaData.BENEFIT.STEP3}</Step.Title>
            <Step.Description>
              <Link
                target="blank"
                to="https://launchpass.com/smarttradinghub/gold-member"
              >
                <Icon name="payment" /> Launchpass
              </Link>
            </Step.Description>
          </Step.Content>
        </Step>
        <Step>
          <Icon name="question" size="tiny" color="red" />
          <Step.Content>
            <Step.Title>{metaData.BENEFIT.STEP4}</Step.Title>
            <Step.Description>
              <ChatChannel />
            </Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>
    </div>
  );
};

export default Benefit;
