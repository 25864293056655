import React, { createContext, useReducer } from "react";
export const PostsContext = createContext();

export const postsReducer = (state, action) => {
  let clonePosts;
  switch (action.type) {
    case "FETCH_POST":
      return {
        posts: action.payload,
      };
    case "FETCH_SINGLE_POST":
      return {
        post: action.payload,
      };
    case "CREATE_POST":
      return {
        posts: [action.payload, ...state.posts],
      };
    case "CREATE_POST_COMMENT":
      console.log("HERE line 83");
      console.log(JSON.stringify(action.payload));
      console.log(`All POSTs ${JSON.stringify(state.posts)}`);
      clonePosts = JSON.parse(JSON.stringify(state.posts));
      return {
        posts: clonePosts.map((post) =>
          post._id === action.payload.postId
            ? {
                ...post,
                comment: [
                  ...post.comment,
                  {
                    ...action.payload.comment,
                    like: [],
                    disLike: [],
                    reply: [],
                  },
                ],
              }
            : post
        ),
      };
    case "LIKE_POST_COMMENT":
      clonePosts = JSON.parse(JSON.stringify(state.posts));
      clonePosts.forEach((post) => {
        if (post._id === action.payload.postId) {
          post.comment.forEach((c) => {
            if (c._id === action.payload.commentId) {
              if (!c.like.includes(action.payload.username)) {
                // if not exist then add in
                c.like.push(action.payload.username);
              } else {
                // If already exist then remove out
                c.like.splice(c.like.indexOf(action.payload.username), 1);
              }
            }
          });
        }
      });
      return {
        posts: clonePosts,
      };
    case "DISLIKE_POST_COMMENT":
      clonePosts = JSON.parse(JSON.stringify(state.posts));
      clonePosts.forEach((post) => {
        if (post._id === action.payload.postId) {
          post.comment.forEach((c) => {
            if (c._id === action.payload.commentId) {
              if (!c.disLike.includes(action.payload.username)) {
                // if not exist then add in
                c.disLike.push(action.payload.username);
              } else {
                // If already exist then remove out
                c.disLike.splice(c.like.indexOf(action.payload.username), 1);
              }
            }
          });
        }
      });
      return {
        posts: clonePosts,
      };
    case "DELETE_POST_COMMENT":
      clonePosts = JSON.parse(JSON.stringify(state.posts));
      return {
        posts: clonePosts.map((post) =>
          post._id === action.payload.postId
            ? {
                ...post,
                comment: post.comment.filter((c) => {
                  return c._id !== action.payload.commentId;
                }),
              }
            : post
        ),
      };
    case "CREATE_COMMENT_REPLY":
      return {
        ...state,
        posts: state.posts.map((post) => {
          if (post._id === action.payload.postId) {
            return {
              ...post,
              comment: post.comment.map((comment) => {
                if (comment._id === action.payload.commentId) {
                  return {
                    ...comment,
                    reply: [...comment.reply, action.payload.reply],
                  };
                }
                return comment;
              }),
            };
          }
          return post;
        }),
      };
    case "DELETE_COMMENT_REPLY":
      return {
        ...state,
        posts: state.posts.map((post) => {
          if (post._id === action.payload.postId) {
            return {
              ...post,
              comment: post.comment.map((comment) => {
                if (comment._id === action.payload.commentId) {
                  return {
                    ...comment,
                    reply: comment.reply.filter(
                      (reply) => reply._id !== action.payload.replyId
                    ),
                  };
                }
                return comment;
              }),
            };
          }
          return post;
        }),
      };
    case "POST_UPDATE":
      clonePosts = JSON.parse(JSON.stringify(state.posts));
      return {
        posts: clonePosts.map((post) =>
          post._id === action.payload.id ? action.payload.data : post
        ),
      };
    case "POST_LIKE":
      clonePosts = JSON.parse(JSON.stringify(state.posts));
      clonePosts.forEach((post) => {
        if (post._id === action.payload.id) {
          if (!post.like.includes(action.payload.user)) {
            // if not exist then add in
            post.like.push(action.payload.user);
          } else {
            // If already exist then remove out
            post.like.splice(post.like.indexOf(action.payload.user));
          }
        }
      });
      return {
        posts: clonePosts,
      };
    case "POST_DISLIKE":
      clonePosts = JSON.parse(JSON.stringify(state.posts));
      clonePosts.forEach((post) => {
        if (post._id === action.payload.id) {
          if (!post.disLike.includes(action.payload.user)) {
            // if not exist then add in
            post.disLike.push(action.payload.user);
          } else {
            // If already exist then remove out
            post.disLike.splice(post.disLike.indexOf(action.payload.user));
          }
        }
      });
      return {
        posts: clonePosts,
      };
    case "POST_PLAYER":
      clonePosts = JSON.parse(JSON.stringify(state.posts));
      clonePosts.forEach((post) => {
        if (post._id === action.payload.id) {
          if (!post.player.includes(action.payload.user)) {
            // if not exist then add in
            post.player.push(action.payload.user);
          } else {
            // If already exist then remove out
            post.player.splice(post.player.indexOf(action.payload.user));
          }
        }
      });
      return {
        posts: clonePosts,
      };
    case "DELETE_POST":
      return {
        posts: state.posts.filter((post) => post._id !== action.payload),
      };
    default:
      return state;
  }
};

export const PostsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(postsReducer, {
    posts: null,
  });
  return (
    <PostsContext.Provider value={{ ...state, dispatch }}>
      {children}
    </PostsContext.Provider>
  );
};
