import React from "react";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";
import AlertOptionsClosed from "./AlertOptionsClosed";
import AlertOptions from "./AlertOptions.js";
import StockHeatMap from "../TradingView/StockHeatMap";
const AlertOptionsPage = () => {
  const { user } = useAuthContext();
  return (
    <>
      {user ? (
        <>
          <AlertOptions />
        </>
      ) : (
        <div className="Alerts">
          <Divider horizontal>
            <Link to="/login">
              <span className="share_alerts">OPTION ALERTS OPEN</span>
            </Link>
          </Divider>
          <h1 style={{ textAlign: "center" }}>
            PLEASE LOGIN TO VIEW THE OPEN ALERTS
          </h1>
        </div>
      )}
      <AlertOptionsClosed />
      <StockHeatMap />
    </>
  );
};

export default AlertOptionsPage;
