import { useContext } from "react";
import { WatchlistsContext } from "../Context/WatchListContext";

export const useWatchlistsContext = () => {
  const context = useContext(WatchlistsContext);

  if (!context) {
    throw Error(
      "useWatchlistsContext must be used inside an WatchlistsContextProvider"
    );
  }

  return context;
};
