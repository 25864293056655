import React, { useState, useEffect } from "react";
import "../Earnings.css";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import {
  Label,
  Card,
  Button,
  Image,
  Icon,
  List,
  Modal,
  Input,
  Message,
  TextArea,
  Form,
  Menu,
  Divider,
} from "semantic-ui-react";
import { useEarningsContext } from "../../../Hooks/useEarningContext";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import moment from "moment";
moment().format();
function EarningPage() {
  <Helmet>
    <title>Earning</title>
  </Helmet>;
  const { user } = useAuthContext();
  const [error, setError] = useState(null);
  const { earning, dispatch } = useEarningsContext();
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  // const [alert, setAlert] = useState(null);
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shareableLink, setShareableLink] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const currentDomain = window.location.origin;
  const onShare = () => {
    const link = `${currentDomain}/earning/${earning._id}`;
    setShareableLink(link);
    setIsCopied(false);
    setIsModalOpen(true);
  };
  const formatRevenue = (revenue) => {
    if (revenue < 0) {
      return formatRevenue(-revenue);
    } else if (revenue >= 1e12) {
      return (revenue / 1e12).toFixed(2) + "T";
    } else if (revenue >= 1e9) {
      return (revenue / 1e9).toFixed(2) + "B";
    } else if (revenue >= 1e6) {
      return (revenue / 1e6).toFixed(2) + "M";
    } else if (revenue >= 1e3) {
      return (revenue / 1e3).toFixed(2) + "K";
    } else {
      return revenue.toFixed(2);
    }
  };
  const copyToClipboard = () => {
    const input = document.getElementById("shareableLink");
    input.select();
    document.execCommand("copy");
    setIsCopied(true);
  };
  const shareOnFacebook = () => {
    window.FB.ui({
      method: "share",
      href: shareableLink,
    });
  };
  const shareOnTwitter = () => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      `#${earning.ticker} ${
        earning.hour === "bmo"
          ? "Earning Before Market Open"
          : "Earning After Market Closed"
      }
🗓️Earning Date: ${moment.utc(earning.earningDate).format("ddd MMM, DD YYYY")}
👀IV Move: ${earning.iv ? `${earning.iv}%` : ``}
💫Estimate Move: ${earning.estimatedMove ? `${earning.estimatedMove}%` : ``}
✅Las tMove:${earning.lastMove ? `${earning.lastMove}%` : ``}
🪙Earning Estimate:  ${earning.epsEstimate.toFixed(2)}
💵Revenue Estimate: ${formatRevenue(earning.revenueEstimate)}
💬Analysts: ${shareableLink}
👉 @SMARTTRADINGHUB #EARNING #ER #ERLOTO #LOTO
`
    )}`;
    window.open(twitterShareUrl, "_blank");
  };

  const handleCloseModal = () => {
    setOpen(false);
  };
  // Get the Alert data from backend
  useEffect(
    () => {
      const fetchData = async () => {
        try {
          const response = await fetch(`/api/er/${id}`);
          if (response.ok) {
            const json = await response.json();
            dispatch({ type: "FETCH_SINGLE_EARNING_OPTION", payload: json });
          }
        } catch (error) {
          setError(error.message);
        }
      };
      fetchData();
    },
    [dispatch],
    id
  );

  const handleLoadMore = () => {
    if (currentPage === totalPages) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };
  // Like Handler
  const handelBull = async () => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/er/" + earning._id + "/bull", {
      method: "PUT",
      body: JSON.stringify({ userId: user.user._id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      // dispatch({
      //   type: "ALERT_LIKE",
      //   payload: { id: eventId, user: user.user.username },
      // });
      window.location.href = `/earning/${id}`;
    } else {
      console.error("error");
    }
  };
  // Bear Handler
  const handelBear = async () => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/er/" + earning._id + "/bear", {
      method: "PUT",
      body: JSON.stringify({ userId: user.user._id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      // dispatch({
      //   type: "ALERT_DISLIKE",
      //   payload: { id: eventId, user: user.user.username },
      // });
      window.location.href = `/earning/${id}`;
    } else {
      console.error("error");
    }
  };
  // DisLike Handler
  const handelNeutral = async () => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/er/" + earning._id + "/neutral", {
      method: "PUT",
      body: JSON.stringify({ userId: user.user._id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      // dispatch({
      //   type: "ALERT_DISLIKE",
      //   payload: { id: eventId, user: user.user.username },
      // });
      window.location.href = `/earning/${id}`;
    } else {
      console.error("error");
    }
  };
  // HandlePlayer
  const handelPlayer = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/er/" + eventId + "/player", {
      method: "PUT",
      body: JSON.stringify({ userId: user.user._id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      // dispatch({
      //   type: "ALERT_PLAYER",
      //   payload: { id: eventId, user: user.user._id },
      // });
      window.location.href = `/earning/${id}`;
    } else {
      console.error("error");
    }
  };

  // handle Delete
  const handleDelete = async () => {
    try {
      if (!user) {
        throw new Error("Please login");
      }
      const response = await fetch(`/api/er/${earning._id}`, {
        method: "DELETE",
        body: JSON.stringify({
          userId: user.user._id,
          userRole: user.user.role,
          isAdmin: user.user.isAdmin,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        // dispatch({ type: "DELETE_ALERT", payload: json });
        window.location.href = `/`;
      } else {
        throw new Error("Failed to delete alert");
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  return (
    <>
      {error && <div>{error}</div>}
      {earning ? (
        <>
          {user &&
            (user.user.role === 1 ||
              user.user.role === 2 ||
              user.user.role === 3 ||
              user.user.role === 4 ||
              user.user.role === 5 ||
              user.user.role === 10 ||
              user.user.role === 6) && (
              <div className="AlertOption">
                <Divider horizontal onClick={handleLoadMore}>
                  <span className="AlertOption_Page">
                    {earning.ticker}'s EARNING
                  </span>
                </Divider>
                {/* <Card>
              <Card.Content>
                {alert.ticker}
                <Card.Header>{alert.ticker}</Card.Header>
              </Card.Content>
            </Card> */}
                <Modal.Content
                  scrolling
                  style={{ fontSize: "18px", height: "100%" }}
                >
                  <Card
                    fluid
                    color={
                      earning.bull.length > earning.bear.length
                        ? "green"
                        : earning.bull.length < earning.bear.length
                        ? "red"
                        : "blue"
                    }
                  >
                    <List.Content>
                      <>
                        {earning.ticker && (
                          <>
                            <strong>{earning.ticker}</strong>
                            <br />
                          </>
                        )}
                        {earning.earningDate && (
                          <>
                            <Icon
                              color="purple"
                              name="calendar alternate outline"
                            />
                            <strong>Earning Date: </strong>
                            {moment.utc(earning.earningDate).format(
                              "dddd MMMM, DD YYYY"
                            )}
                            <br />
                          </>
                        )}
                        {earning.hour && (
                          <>
                            {earning.hour === "bmo" ? (
                              <>
                                <Icon color="yellow" name="sun" />
                                Earning Before Market Open{" "}
                              </>
                            ) : (
                              <>
                                <Icon color="yellow" name="moon" /> Earning
                                After Market Closed
                              </>
                            )}
                            <br />
                          </>
                        )}
                        {earning.quarter && (
                          <>
                            <Icon
                              color="purple"
                              name="calendar alternate outline"
                            />
                            <strong>Quarter: </strong>
                            {earning.quarter} of {earning.year}
                            <br />
                          </>
                        )}
                        {earning.iv && (
                          <>
                            <Icon color="red" name="signal" />
                            <strong>Implied volatility: </strong>
                            {earning.iv}%
                            <br />
                          </>
                        )}
                        <Icon color="red" name="chart line" />
                        <strong>Estimate % Move: </strong>
                        {earning.estimatedMove ? (
                          <>
                            +/- {earning.estimatedMove}% <br />
                          </>
                        ) : (
                          <>
                            Update soon... <br />
                          </>
                        )}
                        {earning.lastMove && (
                          <>
                            <Icon color="green" name="chart bar" />
                            <strong>Last % Move: </strong> {earning.lastMove}%
                            <br />
                          </>
                        )}
                        {earning.epsEstimate && (
                          <>
                            <Icon color="blue" name="bell slash" />
                            <strong>Earning Estimate: </strong>$
                            {earning.epsEstimate.toFixed(2)}
                            <br />
                          </>
                        )}
                        {earning.epsActual && (
                          <>
                            <Icon
                              color={
                                earning.epsEstimate > earning.epsActual
                                  ? "red"
                                  : "green"
                              }
                              name="bell"
                            />
                            <strong>Earning Actual: </strong>$
                            {earning.epsActual}{" "}
                            {earning.epsActual > earning.epsEstimate
                              ? "Beat"
                              : "Missed"}{" "}
                            ${" "}
                            {(earning.epsActual - earning.epsEstimate).toFixed(
                              2
                            )}
                            <br />
                          </>
                        )}
                        {earning.revenueEstimate && (
                          <>
                            <Icon color="blue" name="bell slash outline" />
                            <strong>Revenue Estimate: </strong>$
                            {formatRevenue(earning.revenueEstimate)}
                            {/* {earning.revenueEstimate.toLocaleString()} */}
                            <br />
                          </>
                        )}
                        {earning.revenueActual && (
                          <>
                            <Icon
                              color={
                                earning.revenueEstimate > earning.revenueActual
                                  ? "red"
                                  : "green"
                              }
                              name="bell outline"
                            />
                            <strong>Revenue Actual: </strong>$
                            {formatRevenue(earning.revenueActual)}{" "}
                            {earning.revenueActual > earning.revenueEstimate
                              ? "Beat"
                              : "Miss"}{" "}
                            $
                            {formatRevenue(
                              earning.revenueActual - earning.revenueEstimate
                            )}
                            <br />
                          </>
                        )}
                        {earning.analysts && (
                          <>
                            <Icon color="red" name="comment alternate" />
                            <strong>Analysts: </strong> {earning.analysts}
                            <br />
                          </>
                        )}
                        {earning.chart && (
                          <>
                            <Image
                              src={earning.chart}
                              as="a"
                              size="medium"
                              rounded
                              href={earning.chart}
                              target="_blank"
                            />
                            <br />
                          </>
                        )}
                        <Icon color="orange" name="bell" />
                        Posted by {earning.username} <br />
                        <Card.Meta>
                          <Icon
                            color="purple"
                            name="calendar alternate outline"
                          />
                          Estimated at{" "}
                          {moment.utc(earning.updatedAt).format("h:mm:ss A")} on{" "}
                          {moment.utc(earning.updatedAt).format("MMMM, DD YYYY")}
                        </Card.Meta>
                        <Icon
                          color="blue"
                          name="share"
                          onClick={() => onShare(alert._id)}
                        >
                          Sharing...
                        </Icon>
                        <Modal
                          open={isModalOpen}
                          onClose={() => setIsModalOpen(false)}
                        >
                          <Modal.Header>Share Link</Modal.Header>
                          <Modal.Content>
                            <Input
                              fluid
                              id="shareableLink"
                              value={shareableLink}
                              readOnly
                              action={
                                <>
                                  {" "}
                                  <Button
                                    onClick={copyToClipboard}
                                    disabled={isCopied}
                                  >
                                    {isCopied ? "Copied" : "Copy"}
                                  </Button>
                                  <Button
                                    onClick={shareOnTwitter}
                                    icon="twitter"
                                  />
                                  <Button
                                    onClick={shareOnFacebook}
                                    icon="facebook"
                                  />
                                </>
                              }
                            />
                          </Modal.Content>
                        </Modal>
                      </>
                    </List.Content>

                    <Card.Content extra>
                      {user && earning && (
                        <>
                          <>
                            <div className="ui four buttons">
                              <Button
                                basic
                                color="teal"
                                onClick={() => handelPlayer(earning._id)}
                              >
                                Playing{" "}
                              </Button>
                              <Button
                                basic
                                color="green"
                                onClick={() => handelBull(earning._id)}
                              >
                                Bullish
                              </Button>
                              <Button
                                basic
                                color="blue"
                                onClick={() => handelNeutral(earning._id)}
                              >
                                Neutral
                              </Button>
                              <Button
                                basic
                                color="red"
                                onClick={() => handelBear(earning._id)}
                              >
                                Bearish
                              </Button>
                            </div>
                          </>
                          <div className="ui seven buttons">
                            <Button
                              basic
                              color="teal"
                              onClick={() => handelPlayer(earning._id)}
                            >
                              <Icon name="users" color="teal" />{" "}
                              {earning.player.length}
                            </Button>
                            <Button
                              basic
                              color="green"
                              onClick={() => handelBull(earning._id)}
                            >
                              <Icon name="arrow up" color="green" />{" "}
                              {earning.bull.length}
                            </Button>
                            <Button
                              basic
                              color="blue"
                              onClick={() => handelNeutral(earning._id)}
                            >
                              <Icon name="exchange" color="blue" />
                              {earning.neutral.length}
                            </Button>
                            <Button
                              basic
                              color="red"
                              onClick={() => handelBear(earning._id)}
                            >
                              <Icon name="arrow down" color="red" />
                              {earning.bear.length}
                            </Button>

                            {user &&
                              (user.user.isAdmin === true ||
                                user.user.role === 1 ||
                                user.user.role === 2 ||
                                user.user._id === earning.userId) && (
                                <>
                                  <Button
                                    basic
                                    color="red"
                                    onClick={() => handleDelete(earning._id)}
                                  >
                                    <Icon color="red" name="delete" />
                                  </Button>
                                </>
                              )}
                          </div>
                        </>
                      )}
                    </Card.Content>
                  </Card>
                </Modal.Content>
              </div>
            )}
        </>
      ) : (
        <h1>NO PAGE FOUND</h1>
      )}
    </>
  );
}

export default EarningPage;
