import { useContext } from "react";
import { AlertsContext } from "../Context/AlertContext";

export const useAlertsContext = () => {
  const context = useContext(AlertsContext);

  if (!context) {
    throw Error(
      "useAlertsContext must be used inside an AlertsContextProvider"
    );
  }

  return context;
};
