import React, { useState, useEffect, useContext } from "react";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import "./WatchList.css";

import {
  Button,
  Image,
  Icon,
  List,
  Card,
  Modal,
  Checkbox,
  Form,
  Label,
  TextArea,
  Input,
  Message,
  Grid,
} from "semantic-ui-react";
import moment from "moment";
import { SearchTickerContext } from "../../../Context/SearchTickerContext";
import Login from "../../Auth/Login";
import Benefit from "../../Auth/Benefit";
moment().format();
const WatchList = ({ watchlist, onDelete, onLike, onDisLike, onUpdate }) => {
  const { user } = useAuthContext();
  const { handleTickerClick } = useContext(SearchTickerContext);
  const [ticker, setTicker] = useState();
  const [support, setSupport] = useState();
  const [resistance, setResistance] = useState();
  const [analysts, setAnalysts] = useState();
  const [chart, setChart] = useState();
  const [note, setNote] = useState();
  const [call, setCall] = useState();
  const [put, setPut] = useState();
  const [term, setTerm] = useState();
  const [signal, setSignal] = useState();
  const [weekMin, setWeekMin] = useState("");
  const [weekMax, setWeekMax] = useState("");
  const [monthMin, setMonthMin] = useState("");
  const [monthMax, setMonthMax] = useState("");
  const [update, setUpdate] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(null);
  const tradingviewLinkRegex =
    /^https:\/\/www\.tradingview\.com\/x\/[a-zA-Z0-9]+\/$/;
  useEffect(() => {
    if (watchlist) {
      setTicker(watchlist.ticker);
      setSupport(watchlist.support);
      setResistance(watchlist.resistance);
      setAnalysts(watchlist.analysts);
      setChart(watchlist.chart);
      setNote(watchlist.note);
      setCall(watchlist.call);
      setPut(watchlist.put);
      setTerm(watchlist.term);
      setSignal(watchlist.signal);
      setWeekMin(watchlist.weekMin);
      setWeekMax(watchlist.weekMax);
      setMonthMin(watchlist.monthMin);
      setMonthMax(watchlist.monthMax);
    }
  }, [watchlist]);
  // HANDLE UPDATE SHARES ALERT
  const handleWatchListEdit = async (e) => {
    if (!user) {
      setError("You must be logged in");
      return;
    }
    e.preventDefault();
    if (chart && !tradingviewLinkRegex.test(chart)) {
      setError("Invalid TradingView URL link");
      return;
    }
    const updateAlertBody = JSON.stringify({
      userId: user.user._id,
      userRole: user.user.role,
      isAdmin: user.user.isAdmin,
      username: user.user.username,
      ticker,
      note,
      support,
      resistance,
      analysts,
      call,
      chart,
      put,
      term,
      signal,
      weekMin,
      weekMax,
      monthMin,
      monthMax,
    });
    const response = await fetch("/api/wl/" + watchlist._id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: updateAlertBody,
    });
    const json = await response.json();
    if (!response.ok) {
      setError(json.message);
    }
    if (response.ok) {
      setError(null);
      let deepClonePrev = JSON.parse(JSON.stringify(watchlist));
      deepClonePrev = {
        ...deepClonePrev,
        ticker,
        note,
        support,
        resistance,
        analysts,
        call,
        chart,
        put,
        term,
        signal,
        weekMin,
        weekMax,
        monthMin,
        monthMax,
      };
      onUpdate(watchlist._id, deepClonePrev);
      setUpdate(false);
      setOpen(false);
    } else {
      console.log("error");
    }
  };
  return (
    <>
      {/* <div className="Watchlist"> */}
      {watchlist && (
        <List.Item>
          <Modal
            dimmer="blurring"
            // centered={false}
            closeIcon
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size="large"
            trigger={
              <div className="menu compact">
                <Label
                  // as="a"
                  className="menu-item"
                  color={
                    watchlist.signal === "Bullish"
                      ? "green"
                      : watchlist.signal === "Bearish"
                      ? "red"
                      : watchlist.signal === "Unknow"
                      ? "yellow"
                      : watchlist.signal === "MakeItOrBreakit"
                      ? "blue"
                      : "purple"
                  }
                >
                  {watchlist.ticker}
                  <span className="floating-label-top">
                    {watchlist.signal && watchlist.signal === "Bearish" && (
                      <Icon color="red" name="angle double down" />
                    )}
                    {watchlist.signal && watchlist.signal === "Bullish" && (
                      <Icon color="green" name="angle double up" />
                    )}
                    {watchlist.signal && watchlist.signal === "Sideway" && (
                      <Icon color="blue" name="angle double right" />
                    )}
                  </span>
                  <span className="floating-label-top-center">
                    {watchlist.weekMax && watchlist.weekMax}
                  </span>
                  <span className="floating-label-bottom-center">
                    {watchlist.weekMin && watchlist.weekMin}
                  </span>
                  <span className="floating-label-top-center">
                    {watchlist.monthMax && watchlist.monthMax}
                  </span>
                  <span className="floating-label-bottom-center">
                    {watchlist.monthMin && watchlist.monthMin}
                  </span>
                </Label>
              </div>
            }
          >
            {user &&
            (user.user.role === 1 ||
              user.user.role === 2 ||
              user.user.role === 3 ||
              user.user.role === 4 ||
              user.user.role === 5 ||
              user.user.role === 6 ||
              user.user.role === 10) ? (
              <Modal.Content
                // scrolling
                style={{ fontSize: "18px", height: "100%" }}
              >
                <Card
                  fluid
                  color={
                    watchlist.like.length > watchlist.disLike.length
                      ? "green"
                      : watchlist.like.length < watchlist.disLike.length
                      ? "red"
                      : "blue"
                  }
                >
                  <List.Content>
                    <>
                      {watchlist.ticker && (
                        <>
                          <span
                            onClick={() => {
                              handleTickerClick(watchlist.ticker);
                              setOpen(false);
                            }}
                          >
                            {" "}
                            <strong>{watchlist.ticker}</strong> (
                            {watchlist.signal && watchlist.signal})
                          </span>
                          <br />
                        </>
                      )}
                      {watchlist.analysts && (
                        <>
                          <Icon color="red" name="comment alternate" />
                          <strong>Note/Analysts:</strong> {watchlist.analysts}
                          <br />
                        </>
                      )}
                      {watchlist.resistance &&
                        watchlist.resistance.some(Boolean) && (
                          <>
                            <Icon color="red" name="chart line" />
                            <strong>Resistance Level:</strong>{" "}
                            {watchlist.resistance.join(", ")}
                            <br />
                          </>
                        )}
                      {watchlist.support && watchlist.support.some(Boolean) && (
                        <>
                          <Icon color="green" name="chart bar" />
                          <strong>Support Level:</strong>{" "}
                          {watchlist.support.join(", ")}
                          <br />
                        </>
                      )}
                      {watchlist.call && watchlist.call.some(Boolean) && (
                        <>
                          <Icon color="green" name="caret square up" />
                          <strong>Call Strikes On Watch:</strong>{" "}
                          {watchlist.call.join(", ")}
                          <br />
                        </>
                      )}
                      {watchlist.put && watchlist.put.some(Boolean) && (
                        <>
                          <Icon color="red" name="caret square down" />
                          <strong>Put Strikes On Watch:</strong>{" "}
                          {watchlist.put.join(", ")}
                          <br />
                        </>
                      )}
                      {watchlist.weekMin && (
                        <>
                          <Icon color="red" name="level down alternate" />
                          <strong>Week Min: </strong> ${watchlist.weekMin}
                          <br />
                        </>
                      )}
                      {watchlist.weekMax && (
                        <>
                          <Icon color="green" name="level up alternate" />
                          <strong>Week Max: </strong> ${watchlist.weekMax}
                          <br />
                        </>
                      )}
                      {watchlist.monthMin && (
                        <>
                          <Icon color="red" name="level down alternate" />
                          <strong>Month Min: </strong> ${watchlist.monthMin}
                          <br />
                        </>
                      )}
                      {watchlist.monthMax && (
                        <>
                          <Icon color="green" name="level up alternate" />
                          <strong>Month Max: </strong> ${watchlist.monthMax}
                          <br />
                        </>
                      )}
                      {watchlist.chart && (
                        <>
                          <Image
                            src={watchlist.chart}
                            as="a"
                            size="medium"
                            rounded
                            href={watchlist.chart}
                            target="_blank"
                          />
                          <br />
                        </>
                      )}
                      {watchlist.note && (
                        <>
                          <Icon color="red" name="hand point right outline" />
                          Note: {watchlist.note}
                          <br />
                        </>
                      )}
                      <Icon color="orange" name="bell" />
                      Posted by {watchlist.username} <br />
                      <Card.Meta>
                        <Icon
                          color="purple"
                          name="calendar alternate outline"
                        />
                        Added at {moment(watchlist.updatedAt).format("h:mm:ss")}{" "}
                        on {moment(watchlist.updatedAt).format("MM/DD/YYYY")}
                      </Card.Meta>
                      {/* update watchlist */}
                      {update && (
                        <>
                          <div className="WatchListShare">
                            <div className="watchListPost">
                              {error && (
                                <Message size="mini" color="red">
                                  {error}
                                </Message>
                              )}
                              <Form.Field>
                                {isFocused === "ticker" && (
                                  <Label pointing="below">
                                    Ticker Required
                                  </Label>
                                )}
                                <Input
                                  type="text"
                                  placeholder="* Ticker"
                                  onChange={(e) => setTicker(e.target.value)}
                                  value={ticker}
                                  fluid
                                  onFocus={() => setIsFocused("ticker")}
                                  onBlur={() => setIsFocused(null)}
                                />
                              </Form.Field>
                              <div className="checkbox-group">
                                {/* <Checkbox
                radio
                label="DEFAULT"
                value=""
                checked={signal === ""}
                onChange={(event, { value }) => setSignal(value)}
              /> */}
                                <Checkbox
                                  radio
                                  label="Bullish"
                                  value="Bullish"
                                  checked={signal === "Bullish"}
                                  onChange={(event, { value }) =>
                                    setSignal(value)
                                  }
                                />
                                <Checkbox
                                  radio
                                  label="Bearish"
                                  value="Bearish"
                                  checked={signal === "Bearish"}
                                  onChange={(event, { value }) =>
                                    setSignal(value)
                                  }
                                />
                                <Checkbox
                                  radio
                                  label="Sideway"
                                  value="Sideway"
                                  checked={signal === "Sideway"}
                                  onChange={(event, { value }) =>
                                    setSignal(value)
                                  }
                                />
                                <Checkbox
                                  radio
                                  label="Make It Or Break It"
                                  value="MakeItOrBreakIt"
                                  checked={signal === "MakeItOrBreakIt"}
                                  onChange={(event, { value }) =>
                                    setSignal(value)
                                  }
                                />
                                <Checkbox
                                  radio
                                  label="Unknow"
                                  value="Unknow"
                                  checked={signal === "Unknow"}
                                  onChange={(event, { value }) =>
                                    setSignal(value)
                                  }
                                />
                              </div>
                              <Form.Field>
                                {isFocused === "analysts" && (
                                  <Label pointing="below">
                                    Analyst or Note for this Ticker
                                  </Label>
                                )}
                                <TextArea
                                  style={{ width: "100%", height: "75px" }}
                                  type="text"
                                  placeholder="Analyst or Note of this alert..."
                                  onChange={(e) => setAnalysts(e.target.value)}
                                  value={analysts}
                                  fluid
                                  onFocus={() => setIsFocused("analysts")}
                                  onBlur={() => setIsFocused(null)}
                                />
                              </Form.Field>
                              <Form.Field>
                                {isFocused === "chart" && (
                                  <Label pointing="below">
                                    Tradingview URL Chart Only
                                  </Label>
                                )}
                                <Input
                                  type="text"
                                  placeholder="Tradingview URL Chart Only"
                                  onChange={(e) => setChart(e.target.value)}
                                  value={chart}
                                  fluid
                                  onFocus={() => setIsFocused("chart")}
                                  onBlur={() => setIsFocused(null)}
                                />
                              </Form.Field>
                              <Form.Field>
                                {isFocused === "resistance" && (
                                  <Label pointing="below">
                                    Resistance level press "Space" to add next
                                    level
                                  </Label>
                                )}
                                <Input
                                  type="text"
                                  placeholder="Resistance Level 1,2,3,4..."
                                  fluid
                                  onFocus={() => setIsFocused("resistance")}
                                  onBlur={() => setIsFocused(null)}
                                  onKeyDown={(e) => {
                                    if (e.key === " ") {
                                      e.preventDefault(); // prevent the space from being typed
                                      setResistance((prevResistance) => [
                                        ...prevResistance,
                                        " ",
                                      ]); // add an empty
                                    } else if (
                                      e.key === "Backspace" &&
                                      resistance[resistance.length - 1] === ""
                                    ) {
                                      e.preventDefault(); // prevent the backspace from navigating back
                                      setResistance((prevResistance) =>
                                        prevResistance.slice(0, -1)
                                      ); // remove the last empty
                                    }
                                  }}
                                  onChange={(e) =>
                                    setResistance(e.target.value.split(","))
                                  }
                                  value={resistance}
                                  required
                                />
                              </Form.Field>
                              <Form.Field>
                                {isFocused === "support" && (
                                  <Label pointing="below">
                                    Support level press "Space" to add next
                                    level
                                  </Label>
                                )}
                                <Input
                                  type="text"
                                  placeholder="Support Level 1,2,3,4..."
                                  fluid
                                  onFocus={() => setIsFocused("support")}
                                  onBlur={() => setIsFocused(null)}
                                  onKeyDown={(e) => {
                                    if (e.key === " ") {
                                      e.preventDefault(); // prevent the space from being typed
                                      setSupport((prevSupport) => [
                                        ...prevSupport,
                                        " ",
                                      ]); // add an empty string
                                    } else if (
                                      e.key === "Backspace" &&
                                      support.length > 0 &&
                                      support[support.length - 1] === ""
                                    ) {
                                      e.preventDefault(); // prevent the backspace from navigating back
                                      setSupport((prevSupport) =>
                                        prevSupport.slice(0, -1)
                                      ); // remove the last empty string
                                    }
                                  }}
                                  onChange={(e) =>
                                    setSupport(e.target.value.split(","))
                                  }
                                  value={support}
                                  required
                                />
                              </Form.Field>
                              <Form.Field>
                                {isFocused === "call" && (
                                  <Label pointing="below">
                                    Call option strikes press "Space" to add
                                    another strike
                                  </Label>
                                )}
                                <Input
                                  type="text"
                                  placeholder="Call option strikes recomendation 1, 2, 3..."
                                  fluid
                                  onFocus={() => setIsFocused("call")}
                                  onBlur={() => setIsFocused(null)}
                                  onKeyDown={(e) => {
                                    if (e.key === " ") {
                                      e.preventDefault(); // prevent the space from being typed
                                      setCall((prevCall) => [...prevCall, " "]); // add an empty
                                    } else if (
                                      e.key === "Backspace" &&
                                      call[call.length - 1] === ""
                                    ) {
                                      e.preventDefault(); // prevent the backspace from navigating back
                                      setCall((prevCall) =>
                                        prevCall.slice(0, -1)
                                      ); // remove the last empty
                                    }
                                  }}
                                  onChange={(e) =>
                                    setCall(e.target.value.split(","))
                                  }
                                  value={call}
                                />
                              </Form.Field>
                              <Form.Field>
                                {isFocused === "put" && (
                                  <Label pointing="below">
                                    Put option strikes press "Space" to add
                                    another strike
                                  </Label>
                                )}
                                <Input
                                  type="text"
                                  placeholder="Put option strikes recomendation 1, 2, 3..."
                                  fluid
                                  onFocus={() => setIsFocused("put")}
                                  onBlur={() => setIsFocused(null)}
                                  onKeyDown={(e) => {
                                    if (e.key === " ") {
                                      e.preventDefault(); // prevent the space from being typed
                                      setPut((prevPut) => [...prevPut, " "]); // add an empty
                                    } else if (
                                      e.key === "Backspace" &&
                                      put[put.length - 1] === ""
                                    ) {
                                      e.preventDefault(); // prevent the backspace from navigating back
                                      setPut((prevPut) => prevPut.slice(0, -1)); // remove the last empty
                                    }
                                  }}
                                  onChange={(e) =>
                                    setPut(e.target.value.split(","))
                                  }
                                  value={put}
                                />
                              </Form.Field>
                              <div className="checkbox-group">
                                <Checkbox
                                  radio
                                  label="DAILY"
                                  value="DAILY"
                                  checked={term === "DAILY"}
                                  onChange={(event, { value }) =>
                                    setTerm(value)
                                  }
                                />
                                <Checkbox
                                  radio
                                  label="WEEKLY"
                                  value="WEEKLY"
                                  checked={term === "WEEKLY"}
                                  onChange={(event, { value }) =>
                                    setTerm(value)
                                  }
                                />
                                <Checkbox
                                  radio
                                  label="MONTHLY"
                                  value="MONTHLY"
                                  checked={term === "MONTHLY"}
                                  onChange={(event, { value }) =>
                                    setTerm(value)
                                  }
                                />
                              </div>
                              {term === "WEEKLY" && (
                                <>
                                  <Form.Field>
                                    {isFocused === "weekMin" && (
                                      <Label pointing="below">
                                        Week Min & Number Only
                                      </Label>
                                    )}
                                    <Input
                                      type="text"
                                      placeholder="Optional Weekly Minimum Price"
                                      onChange={(e) =>
                                        setWeekMin(e.target.value)
                                      }
                                      value={weekMin}
                                      fluid
                                      onFocus={() => setIsFocused("weekMin")}
                                      onBlur={() => setIsFocused(null)}
                                    />
                                  </Form.Field>
                                  <Form.Field>
                                    {isFocused === "weekMax" && (
                                      <Label pointing="below">
                                        Week Max & Number Only
                                      </Label>
                                    )}
                                    <Input
                                      type="text"
                                      placeholder="Optional Weekly Maximum Price"
                                      onChange={(e) =>
                                        setWeekMax(e.target.value)
                                      }
                                      value={weekMax}
                                      fluid
                                      onFocus={() => setIsFocused("weekMax")}
                                      onBlur={() => setIsFocused(null)}
                                    />
                                  </Form.Field>
                                </>
                              )}
                              {term === "MONTHLY" && (
                                <>
                                  <Form.Field>
                                    {isFocused === "monthMin" && (
                                      <Label pointing="below">
                                        Month Min & Number Only
                                      </Label>
                                    )}
                                    <Input
                                      type="text"
                                      placeholder="Optional Monthly Minimum Price"
                                      onChange={(e) =>
                                        setMonthMin(e.target.value)
                                      }
                                      value={monthMin}
                                      fluid
                                      onFocus={() => setIsFocused("monthMin")}
                                      onBlur={() => setIsFocused(null)}
                                    />
                                  </Form.Field>
                                  <Form.Field>
                                    {isFocused === "monthMax" && (
                                      <Label pointing="below">
                                        Month Max & Number Only
                                      </Label>
                                    )}
                                    <Input
                                      type="text"
                                      placeholder="Optional Monthly Maxium Price"
                                      onChange={(e) =>
                                        setMonthMax(e.target.value)
                                      }
                                      value={monthMax}
                                      fluid
                                      onFocus={() => setIsFocused("monthMax")}
                                      onBlur={() => setIsFocused(null)}
                                    />
                                  </Form.Field>
                                </>
                              )}
                              <div className="postOption">
                                <Button
                                  className="share-button"
                                  style={{ color: "var(--blue)" }}
                                  onClick={handleWatchListEdit}
                                >
                                  Update
                                </Button>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  </List.Content>
                  <Card.Content extra>
                    {user && watchlist && (
                      <>
                        <div className="ui four buttons">
                          <Button
                            basic
                            color="green"
                            onClick={() => onLike(watchlist._id)}
                          >
                            <Icon
                              name="heart"
                              color={
                                watchlist.like.includes(user.user._id)
                                  ? "red"
                                  : "grey"
                              }
                            />{" "}
                            {watchlist.like.length}
                          </Button>
                          {/* <Button
                            basic
                            color="red"
                            onClick={() => onDisLike(watchlist._id)}
                          >
                            <Icon name="thumbs down" color="red" />
                            {watchlist.disLike.length}
                          </Button> */}

                          {user &&
                            (user.user.isAdmin === true ||
                              user.user.role === 1 ||
                              user.user.role === 2 ||
                              user.user._id === watchlist.userId) && (
                              <>
                                <Button
                                  basic
                                  color="green"
                                  onClick={() =>
                                    setUpdate((prevValue) => !prevValue)
                                  }
                                >
                                  <Icon color="yellow" name="edit" />
                                </Button>
                                <Button
                                  basic
                                  color="green"
                                  onClick={() => onDelete(watchlist._id)}
                                >
                                  <Icon color="red" name="delete" />
                                </Button>
                              </>
                            )}
                        </div>
                      </>
                    )}
                  </Card.Content>
                </Card>
              </Modal.Content>
            ) : (
              <>
                <Grid columns={2} divided stackable="mobile">
                  <Grid.Row>
                    <Grid.Column>
                      <Login />
                    </Grid.Column>
                    <Grid.Column>
                      <Benefit />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </>
            )}

            {/* <Modal.Actions>
              <Button color="green" onClick={() => onLike(watchlist._id)}>
                Like
              </Button>
              <Button color="red" onClick={() => onDisLike(watchlist._id)}>
                DisLike
              </Button>
              {user &&
                (user.user.isAdmin === true ||
                  user.user.role === 1 ||
                  user.user.role === 2 ||
                  user.user._id === watchlist.userId) && (
                  <>
                    <Button color="green" onClick={() => setUpdate(true)}>
                      Update
                    </Button>
                    <Button color="red" onClick={() => onDelete(watchlist._id)}>
                      Delete
                    </Button>
                  </>
                )}
            </Modal.Actions> */}
          </Modal>
        </List.Item>
      )}
    </>
  );
};

export default WatchList;
