import React, { useState } from "react";
import axios from "axios";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import { useMetaData } from "../../../Context/MetaDataContext";
import { Icon, Form, Input, Label, Message, Button } from "semantic-ui-react";
import moment from "moment";
moment().format();
const ChatChannel = () => {
  const { user } = useAuthContext();
  const { metaData } = useMetaData();
  const [message, setMessage] = useState("");
  const [inputText, setInputText] = useState("");
  // const questionChannel = process.env.REACT_APP_DISCORD_QUESTION_CHANNEL;
  // const webhookquestionChannel = process.env.REACT_APP_DISCORD_WEBHOOK;
  const questionChannel2 = process.env.REACT_APP_DISCORD_QUESTION_CHANNEL_VT2W;
  const webhookquestionChannel2 = process.env.REACT_APP_DISCORD_WEBHOOK_VT2W;
  const [isFocused, setIsFocused] = useState(null);
  const handleSubmit = (event) => {
    event.preventDefault();
    // Send the input text as a message to the Discord webhook
    // const webhookUrl = `https://discord.com/api/webhooks/${questionChannel}/${webhookquestionChannel}`;
    // axios
    //   .post(webhookUrl, {
    //     embeds: [
    //       {
    //         // title: "New Question Had Submited",
    //         color: 0x0077ff,
    //         description: `
    //     📧 Email: ${user.user.email}
    //     👤 Username: <@${
    //       user.user.discordId ? user.user.discordId : user.user.username
    //     }>
    //     ✍️ Question: ${inputText}
    //     <@&807434290920947772> <@&808171583976767508>`,
    //       },
    //     ],
    //   })
    //   .then((response) => {
    //     setMessage(`Your Question Had Been Submited`);
    //     // clear the message after 15 seconds
    //     setTimeout(() => {
    //       setMessage("");
    //     }, 2000);
    //     setInputText("");
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //     setMessage("Error sending message to Discord");
    //   });

    // Send the input text as a message to the Discord webhook
    const webhookUrl2 = `https://discord.com/api/webhooks/${questionChannel2}/${webhookquestionChannel2}`;
    axios
      .post(webhookUrl2, {
        embeds: [
          {
            // title: "New Question Had Submited",
            color: 0x0077ff,
            description: `
        📧 Email: ${user.user.email}
        👤 Username: <@${
          user.user.discordId ? user.user.discordId : user.user.username
        }> 
        ✍️ Question: ${inputText}
        <@&807434290920947772> <@&808171583976767508>`,
          },
        ],
      })
      .then((response) => {
        setMessage(`${metaData.TEXT.QUESTION_SUBMITED}`);
        // clear the message after 15 seconds
        setTimeout(() => {
          setMessage("");
        }, 2000);
        setInputText("");
      })
      .catch((error) => {
        console.error(error);
        setMessage(`${metaData.TEXT.QUESTION_ERROR}`);
      });
  };
  return (
    <>
      {user && (
        <>
          {/* <strong>Please don't hesitate to reach out with any questions</strong> */}
          <Form onSubmit={handleSubmit}>
            <Form.Field>
              {isFocused === "note" && (
                <Label pointing="below">{metaData.TEXT.QUESTION_FOOTER}</Label>
              )}
              <Input
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleSubmit(event);
                  }
                }}
                fluid
                onFocus={() => setIsFocused("note")}
                onBlur={() => setIsFocused(null)}
                type="text"
                placeholder={metaData.TEXT.QUESTION_FOOTER}
                onChange={(e) => setInputText(e.target.value)}
                value={inputText}
                required
                icon={<Icon name="send" circular link onClick={handleSubmit} />}
              />
              {message && (
                <Message positive size="tiny">
                  {message}
                </Message>
              )}
            </Form.Field>
          </Form>
        </>
      )}
    </>
  );
};

export default ChatChannel;
