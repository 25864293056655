import React, { useState, useContext } from "react";
import "./ListContainer.css";
import WatchLists from "../WatchLists/WatchLists";
import Alerts from "../Alerts/Alerts";
import {
  Input,
  List,
  Icon,
  Divider,
  Card,
  Button,
  Flag,
} from "semantic-ui-react";
import { SearchTickerContext } from "../../Context/SearchTickerContext";
import { useMetaData } from "../../Context/MetaDataContext";
import Earnings from "../Earnings/Earnings";
import { isMobile } from "react-device-detect";
import AlertOptions from "../AlertOptions/AlertOptions";
import SearchResult from "../Search/SearchResult/SearchResult";
import PostShareModal from "../Modal/PostShareModal";
import Calendars from "../Calendars/Calendars";
import MarketOverview from "../TradingView/MarketOverview";
import MarketMoving from "../TradingView/MarketMoving";
import TopMarkingMove from "../TradingView/TopMarkingMove";
import Sentiment from "../Sentiment/Sentiment";
import FearAndGreed from "../FearAndGreed/FearAndGreed";
const ListContainer = () => {
  const {
    value,
    setValue,
    searchResults,
    setSearchResults,
    handleTickerInput,
  } = useContext(SearchTickerContext);
  const { metaData, handleLanguageChange } = useMetaData();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const handleClear = () => {
    setValue("");
    setSearchResults([]);
  };
  return (
    <div className="ListContainer">
      {isMobile && (
        <>
          <div className="Search">
            <Input
              className="SearchInput"
              icon={
                value.length > 0 ? (
                  <Icon name="close" link onClick={handleClear} />
                ) : (
                  <Icon name="search" />
                )
              }
              type="text"
              placeholder={metaData.TEXT.SEARCH}
              value={value}
              onChange={(event) => handleTickerInput(event)}
              iconPosition="left"
            />

            {loading && <p>Loading...</p>}
            {error && <p>{error}</p>}
            {!loading &&
              !error &&
              searchResults.tickerData &&
              searchResults.tickerData.length > 0 && (
                <>
                  <List className="SearchResult">
                    {searchResults.tickerData.map((result) => (
                      <List.Item key={result.id} className="SearchItems">
                        {result.ticker} -{" have "}{" "}
                        {searchResults.alertData &&
                        searchResults.alertData.length < 1 ? (
                          " no"
                        ) : (
                          <>
                            {searchResults.alertData.length +
                              searchResults.optionAlertData.length}
                          </>
                        )}{" "}
                        {searchResults.alertData.length > 1
                          ? " alerts"
                          : " alert"}
                      </List.Item>
                    ))}
                  </List>
                </>
              )}
          </div>
          <>
            {isMobile && (
              <>
                {searchResults.tickerData &&
                  searchResults.tickerData.length > 0 && (
                    <div className="Alert">
                      <Divider horizontal>SEARCH RESULTS</Divider>
                      <Card.Group centered>
                        {searchResults.tickerData.map((tickerResult) => (
                          <SearchResult
                            tickerResult={tickerResult}
                            key={tickerResult._id}
                          />
                        ))}
                        {/* map the search alert result */}
                        {searchResults.alertData &&
                          searchResults.alertData.length > 0 && (
                            <>
                              {searchResults.alertData.map((alertResult) => (
                                <SearchResult
                                  alertResult={alertResult}
                                  key={alertResult._id}
                                />
                              ))}
                            </>
                          )}
                        {/* map the search option alert result */}
                        {searchResults.optionAlertData &&
                          searchResults.optionAlertData.length > 0 && (
                            <>
                              {searchResults.optionAlertData.map(
                                (optionAlertData) => (
                                  <SearchResult
                                    optionAlertResult={optionAlertData}
                                    key={optionAlertData._id}
                                  />
                                )
                              )}
                            </>
                          )}
                      </Card.Group>
                      <List celled>
                        {/* map the search breaking result */}
                        {searchResults.breakingNewData &&
                          searchResults.breakingNewData.length > 0 && (
                            <>
                              <Divider horizontal>
                                {searchResults.breakingNewData.length} BREAKING{" "}
                                {searchResults.breakingNewData.length <= 1
                                  ? "NEW"
                                  : "NEWS"}
                              </Divider>
                              {searchResults.breakingNewData.map(
                                (breakingNewData) => (
                                  <SearchResult
                                    breakingNewResult={breakingNewData}
                                    key={breakingNewData._id}
                                  />
                                )
                              )}
                            </>
                          )}
                      </List>
                    </div>
                  )}
              </>
            )}
          </>
        </>
      )}
      {/* <TopMarkingMove /> */}
      {/* <MarketMoving /> */}
      <div style={{ alignSelf: "center", marginTop: "5px" }}>
        <Button
          basic
          centered
          compact
          // color="red"
          onClick={() => handleLanguageChange("en_US")}
        >
          English
          {/* <Flag name="us" /> */}
        </Button>
        <Button
          basic
          centered
          compact
          // color="yellow"
          onClick={() => handleLanguageChange("en_VN")}
        >
          Tiếng Việt
          {/* <Flag name="vn" /> */}
        </Button>
      </div>

      <FearAndGreed />
      <Sentiment />
      <WatchLists />

      {isMobile && <Earnings />}
      <Alerts />
      {isMobile && <AlertOptions />}
      {/* {isMobile && <MarketOverview />} */}
      {/* <News /> */}
      {/* <TrendCard /> */}
      {/* {isMobile && <Calendars />} */}
    </div>
  );
};

export default ListContainer;
