import React, { useState } from "react";
import { FaArrowUp } from "react-icons/fa";
import "./ScrollToTopButton.css"; // Create a CSS file for styling the button

const ScrollToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    // Show the button when user scrolls down 300px or more
    if (window.scrollY > 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top smoothly
  };

  // Add event listener to window to monitor scroll position
  // When component mounts, start listening for scroll events
  // When component unmounts, remove the event listener
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {showButton && (
        <div className="scroll-to-top-button" onClick={handleScrollToTop}>
          <FaArrowUp />
        </div>
      )}
    </div>
  );
};

export default ScrollToTopButton;
