import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from "../../assets/img/Banner.png";
import Founder from "../../assets/img/LoGoFounder.png";
import {
  Icon,
  Input,
  Modal,
  Dropdown,
  Menu,
  Sidebar,
  Image,
} from "semantic-ui-react";
import { useLogout } from "../../Hooks/useLogout";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useMetaData } from "../../Context/MetaDataContext";
import { SearchTickerContext } from "../../Context/SearchTickerContext";
import { isMobile } from "react-device-detect";
import TickerModal from "../Modal/TickerModal";
import FavoriteTickers from "../Tickers/FavoriteTickers";
import CustomNavbarTabMenu from "./CustomNavbarTabMenu";
const NavBar = ({ handleLanguageChange }) => {
  const [selectedOption, setSelectedOption] = useState("ticker");
  const { logout } = useLogout();
  const { user } = useAuthContext();
  const { metaData } = useMetaData();
  const [open, setOpen] = useState(false);
  const panes = [
    {
      menuItem: {
        key: `${metaData.WELCOME.MARKET_HUB}`,
        icon: "chart line",
        content: <Link to="/hub">{metaData.WELCOME.MARKET_HUB}</Link>,
      },
    },
    {
      menuItem: {
        key: `${metaData.WELCOME.MARKET_SHARES}`,
        icon: "alarm",
        content: <Link to="/alerts">{metaData.WELCOME.MARKET_SHARES}</Link>,
      },
    },
    {
      menuItem: {
        key: `${metaData.WELCOME.MARKET_OPTION}`,
        icon: "bell",
        content: (
          <Link to="/optionalerts">{metaData.WELCOME.MARKET_OPTION}</Link>
        ),
      },
    },
    {
      menuItem: {
        key: `${metaData.WELCOME.MARKET_MAJOR_EVENT}`,
        icon: "calendar alternate",
        content: (
          <Link to="/calendar">{metaData.WELCOME.MARKET_MAJOR_EVENT}</Link>
        ),
      },
    },
    {
      menuItem: {
        key: `${metaData.WELCOME.WATCH_LISTS}`,
        icon: "like",
        content: <Link to="/watchlist">{metaData.WELCOME.WATCH_LISTS}</Link>,
      },
    },
    {
      menuItem: {
        key: `${metaData.WELCOME.EARNING_EST}`,
        icon: "sync alternate",
        content: <Link to="/earning">{metaData.WELCOME.EARNING_EST}</Link>,
      },
    },
    {
      menuItem: {
        key: `${metaData.WELCOME.MARKET_INSIDER}`,
        icon: "group",
        content: <Link to="/insider">{metaData.WELCOME.MARKET_INSIDER}</Link>,
      },
    },
    {
      menuItem: {
        key: `${metaData.WELCOME.MARKET_DATA_INDEX}`,
        icon: "info circle",
        content: (
          <Link to="/market-index">{metaData.WELCOME.MARKET_DATA_INDEX}</Link>
        ),
      },
    },
    {
      menuItem: {
        key: `${metaData.WELCOME.MARKET_BREAKING_NEWS}`,
        icon: "newspaper",
        content: (
          <Link to="/breaking">{metaData.WELCOME.MARKET_BREAKING_NEWS}</Link>
        ),
      },
    },
    {
      menuItem: {
        key: `${metaData.WELCOME.MARKET_GENERAL_NEWS}`,
        icon: "rss",
        content: (
          <Link to="/general">{metaData.WELCOME.MARKET_GENERAL_NEWS}</Link>
        ),
      },
    },
    {
      menuItem: {
        key: `${metaData.WELCOME.MARKET_FUTURE}`,
        icon: "chart area",
        content: <Link to="/future">{metaData.WELCOME.MARKET_FUTURE}</Link>,
      },
    },
  ];
  const {
    value,
    setValue,
    searchResults,
    setSearchResults,
    handleTickerInput,
  } = useContext(SearchTickerContext);
  const [visible, setVisible] = useState(false);
  //  state = {};
  const handleClear = () => {
    setValue("");
    setSearchResults([]);
  };
  const handleLogout = () => {
    logout();
  };
  const handleOptionChange = (event, data) => {
    setSelectedOption(data.value);
  };
  const handleSidebarHide = () => {
    setVisible(false);
  };
  const handleToggle = () => {
    setVisible(true);
  };
  const handleClick = () => {
    logout();
  };
  const handleItemClick = (e, { name }) => {
    setSelectedOption(name);
  };
  return (
    <>
      {/* <TickerTape /> */}
      <div className="Navbar">
        <Menu size="huge">
          {/* <Menu.Item> */}
          <Link to="/">
            <Image
              src={logo}
              alt="Stock & Crypto Hub"
              style={{ marginTop: "4px", marginBottom: "4px", height: "45px" }}
            />
          </Link>
          {/* </Menu.Item> */}
          {!isMobile && (
            <>
              <Menu.Item
                name="home"
                active={selectedOption === "home"}
                onClick={handleItemClick}
              >
                <Link to="/">{metaData.WELCOME.HOME}</Link>
              </Menu.Item>
              <Dropdown
                className="dropdown"
                item
                text={metaData.WELCOME.MARKET_ALERT}
                name="alert"
                active={selectedOption === "alert"}
              >
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <Link to="/alerts">{metaData.WELCOME.MARKET_SHARES}</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/optionalerts">
                      {metaData.WELCOME.MARKET_OPTION}
                    </Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown
                className="dropdown"
                item
                text={metaData.WELCOME.MARKET_HUB}
                name="market"
                active={selectedOption === "market"}
              >
                <Dropdown.Menu>
                  {/* <span className="dropdown-text">Alert</span> */}
                  <Dropdown.Item>
                    <Link to="/hub">{metaData.WELCOME.MARKET_HUB_DISCUSS}</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/market-index">
                      {metaData.WELCOME.MARKET_DATA_INDEX}
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/insider">{metaData.WELCOME.MARKET_INSIDER}</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/future">{metaData.WELCOME.MARKET_FUTURE}</Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown
                className="dropdown"
                item
                text={metaData.WELCOME.MARKET_NEWS}
                name="news"
                active={selectedOption === "news"}
              >
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <Link to="/breaking">
                      {metaData.WELCOME.MARKET_BREAKING_NEWS}
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/general">
                      {metaData.WELCOME.MARKET_GENERAL_NEWS}
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/calendar">
                      {metaData.WELCOME.MARKET_MAJOR_EVENT}
                    </Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {user && user.user.role === 1 && (
                <Dropdown item text="Admin">
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <TickerModal />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </>
          )}

          <Menu.Menu position="right">
            {!isMobile && (
              <Menu.Item
                name="search"
                active={selectedOption === `${metaData.TEXT.SEARCH}`}
                onClick={handleItemClick}
              >
                <Input
                  icon={
                    value.length > 0 ? (
                      <Icon name="close" link onClick={handleClear} />
                    ) : (
                      <Icon name="search" />
                    )
                  }
                  type="text"
                  placeholder={metaData.TEXT.SEARCH}
                  value={value}
                  onChange={handleTickerInput}
                  iconPosition="left"
                />
              </Menu.Item>
            )}
            {user && (
              <Menu.Item
                name="profile"
                active={selectedOption === "profile"}
                onClick={handleItemClick}
              >
                <Link to={`/user/${user.user.username}`}>
                  <Icon name="user circle" />
                </Link>
              </Menu.Item>
            )}
            {/* {user && (
            
            )} */}
            {user ? (
              <Menu.Item
                name="favorite"
                active={selectedOption === "favorite"}
                onClick={handleItemClick}
              >
                <Modal
                  onClose={() => setOpen(false)}
                  centered={false}
                  closeIcon
                  onOpen={() => setOpen(true)}
                  open={open}
                  trigger={
                    user && <Icon name="star" color="yellow" size="minimun" />
                  }
                >
                  <FavoriteTickers />
                </Modal>
              </Menu.Item>
            ) : (
              <Menu.Item as={Link} to="/login">
                <Icon name="sign in" />
              </Menu.Item>
            )}
            <Menu.Item
              onClick={handleToggle}
              name="menu"
              active={selectedOption === "menu"}
            >
              <Icon name="sidebar" />
            </Menu.Item>
          </Menu.Menu>
        </Menu>

        <div className="side-bar">
          <Sidebar
            as={Menu}
            animation="overlay"
            onHide={handleSidebarHide}
            visible={visible}
            direction="right"
            width="thin"
          >
            <Menu vertical>
              <Menu.Item onClick={handleSidebarHide}>
                <Icon name="close" />
              </Menu.Item>
              {user && (
                <>
                  <Menu.Item as={Link} to="/hub" onClick={handleSidebarHide}>
                    {metaData.WELCOME.MARKET_HUB}{" "}
                    <Icon name="chart line" color="green" />
                  </Menu.Item>
                  <Menu.Item
                    as={Link}
                    to="/watchlist"
                    onClick={handleSidebarHide}
                  >
                    {metaData.WELCOME.WATCH_LISTS}
                    <Icon name="like" color="red" />
                  </Menu.Item>
                  <Menu.Item as={Link} to="/alerts" onClick={handleSidebarHide}>
                    Share Alerts <Icon name="bell" color="blue" />
                  </Menu.Item>
                  <Menu.Item
                    as={Link}
                    to="/optionalerts"
                    onClick={handleSidebarHide}
                  >
                    Option Alerts <Icon name="bullhorn" color="orange" />
                  </Menu.Item>
                  <Menu.Item
                    as={Link}
                    to="/insider"
                    onClick={handleSidebarHide}
                  >
                    {metaData.WELCOME.MARKET_INSIDER}
                    <Icon name="group" color="pink" />
                  </Menu.Item>
                  <Menu.Item
                    as={Link}
                    to="/market-index"
                    onClick={handleSidebarHide}
                  >
                    {metaData.WELCOME.MARKET_DATA_INDEX}
                    <Icon name="info circle" color="purple" />
                  </Menu.Item>
                  <Menu.Item
                    as={Link}
                    to="/breaking"
                    onClick={handleSidebarHide}
                  >
                    {metaData.WELCOME.MARKET_BREAKING_NEWS}
                    <Icon name="newspaper" color="red" />
                  </Menu.Item>
                  <Menu.Item
                    as={Link}
                    to="/general"
                    onClick={handleSidebarHide}
                  >
                    {metaData.WELCOME.MARKET_GENERAL_NEWS}
                    <Icon name="rss" color="violet" />
                  </Menu.Item>
                  <Menu.Item
                    as={Link}
                    to="/calendar"
                    onClick={handleSidebarHide}
                  >
                    {metaData.WELCOME.MARKET_MAJOR_EVENT}
                    <Icon name="calendar alternate" />
                  </Menu.Item>
                  <Menu.Item
                    as={Link}
                    to="/earning"
                    onClick={handleSidebarHide}
                  >
                    {metaData.WELCOME.EARNING_EST}
                    <Icon name="sync alternate" color="blue" />
                  </Menu.Item>
                  <Menu.Item as={Link} to="/future" onClick={handleSidebarHide}>
                    {metaData.WELCOME.MARKET_FUTURE}
                    <Icon name="chart area" color="yellow" />
                  </Menu.Item>
                  <Menu.Item
                    as={Link}
                    to={`/user/${user.user.username}`}
                    onClick={handleSidebarHide}
                  >
                    {metaData.WELCOME.PROFILE} <Icon name="user" color="teal" />
                  </Menu.Item>

                  {user && user.user.role === 1 && (
                    <Menu.Item>
                      <TickerModal />
                    </Menu.Item>
                  )}
                  <Menu.Item as="a" onClick={handleLogout}>
                    {metaData.WELCOME.LOG_OUT}
                  </Menu.Item>
                </>
              )}

              {!user && (
                <Menu.Item as={Link} to="/login" onClick={handleSidebarHide}>
                  {metaData.WELCOME.LOGIN_REGISTER}
                </Menu.Item>
              )}
            </Menu>
          </Sidebar>
        </div>
      </div>
      <CustomNavbarTabMenu panes={panes} />
    </>
  );
};

export default NavBar;
