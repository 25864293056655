import React from "react";
import { useAuthContext } from "../Hooks/useAuthContext";
import "../index.css";
import { Message, Image } from "semantic-ui-react";
import BannedImage from "../assets/img/Banned.png";
const Banned = () => {
  const { user } = useAuthContext();

  return (
    <>
      <Message
        negative
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Message.Header>
          <h1>SORRY YOU ARE NOT WELCOME HERE. 😥 </h1>
        </Message.Header>
        <p>Your offer has expired</p>
        <Image src={BannedImage} />
      </Message>
    </>
  );
};

export default Banned;
