import React from "react";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import "./Coin.css";
import { Popup, Button, Icon, Image, Card } from "semantic-ui-react";
import moment from "moment";
moment().format();
const Coin = ({ coin }) => {
  const { user } = useAuthContext();
  return (
    <>
      {/* {user && ( */}
      <>
        <Card className="Coin">
          <Card.Content>
            <Image avatar floated="right" size="large" src={coin.iconUrl} />
            <Card.Header>
              {coin.symbol} {Number(coin.price).toFixed(2)}
            </Card.Header>

            <Card.Meta>{coin.name}</Card.Meta>
            <Card.Description>
              <Icon name="dollar" />
              <strong>MarketCap: </strong>
              <strong>
                {Number(coin.marketCap).toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}
              </strong>
              <br />
              <Icon name="bolt" />
              <strong>Volume (24hr): </strong>
              <strong>
                {Number(coin["24hVolume"]).toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}
              </strong>
              <br />
            </Card.Description>
            <Card.Meta></Card.Meta>
          </Card.Content>
        </Card>
      </>
      {/* )} */}
    </>
  );
};

export default Coin;
