import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./BreakingNews.css";
import { Pagination, Divider, Icon, Card } from "semantic-ui-react";
import BreakingNew from "./BreakingNew/BreakingNew";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useMetaData } from "../../Context/MetaDataContext";
import { useBreakingNewContext } from "../../Hooks/useBreakingNewContext";
import { isMobile } from "react-device-detect";
import BreakingNew2 from "./BreakingNew/BreakingNew2";
import moment from "moment";
moment().format();
const BreakingNews2 = (handleNew) => {
  const { breakingNews, dispatch } = useBreakingNewContext();
  const { metaData } = useMetaData();
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const { user } = useAuthContext();
  const today = moment().startOf("day");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shareableLink, setShareableLink] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const currentDomain = window.location.origin;
  const onShare = (breakingNewId) => {
    const link = `${currentDomain}/breakingnew/${breakingNewId}`;
    setShareableLink(link);
    setIsCopied(false);
    setIsModalOpen(true);
  };

  const copyToClipboard = () => {
    const input = document.getElementById("shareableLink");
    input.select();
    document.execCommand("copy");
    setIsCopied(true);
  };
  const shareOnFacebook = () => {
    window.FB.ui({
      method: "share",
      href: shareableLink,
    });
  };
  const shareOnTwitter = () => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      shareableLink
    )}`;
    window.open(twitterShareUrl, "_blank");
  };
  const handlePageChange = (event, data) => {
    const { activePage } = data;
    setCurrentPage(activePage);
  };

  const handleLoadMore = () => {
    if (currentPage === totalPages) {
      setCurrentPage(1); // Reset to the first page if currently on the last page
    } else if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1); // Increment the current page by 1
    }
  };
  const handleGoBack = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1); // Decrement the current page by 1
    }
  };
  // console.log(breakingNews);
  // Get the breakingNews data from backend
  useEffect(() => {
    const fetchData = async (page) => {
      try {
        const response = await fetch(`/api/new?page=${page}`);

        if (response.ok) {
          const json = await response.json();
          // console.log(json);
          setTotalPages(json.totalPages); // add this
          dispatch({ type: "FETCH_BREAKINGNEW", payload: json.breakingNews });
        } else {
          throw new Error("Failed to fetch breakingNews");
        }
      } catch (error) {
        setError(error.message);
      }
    };
    // Function to fetch data and update state
    const fetchAndUpdateData = () => {
      fetchData(currentPage);
    };

    // Fetch data initially
    fetchAndUpdateData();

    // Set up an interval to fetch data every 15 seconds
    const intervalId = setInterval(fetchAndUpdateData, 15000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [dispatch, currentPage]);
  // Like Handler
  const handleLike = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/new/" + eventId + "/like", {
      method: "PUT",
      body: JSON.stringify({ username: user.user.username }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "BREAKINGNEW_LIKE",
        payload: { id: eventId, user: user.user.username },
      });
    } else {
      console.error("error");
    }
  };
  // DisLike Handler
  const handleDisLike = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/new/" + eventId + "/disLike", {
      method: "PUT",
      body: JSON.stringify({ username: user.user.username }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "BREAKINGNEW_DISLIKE",
        payload: { id: eventId, user: user.user.username },
      });
    } else {
      console.error("error");
    }
  };
  // handle Update
  const handleUpdate = async (eventId, breadkingNew) => {
    if (!user) {
      return;
    }
    dispatch({
      type: "BREAKINGNEW_UPDATE",
      payload: { id: eventId, data: breadkingNew },
    });
  };
  // handle Delete
  const handleDelete = async (eventId) => {
    try {
      if (!user) {
        throw new Error("Please login");
      }
      const response = await fetch(`/api/new/${eventId}`, {
        method: "DELETE",
        body: JSON.stringify({
          userId: user.user._id,
          userRole: user.user.role,
          isAdmin: user.user.isAdmin,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        dispatch({ type: "DELETE_BREAKINGNEW", payload: json });
      } else {
        throw new Error("Failed to delete breadkingNew");
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  return (
    <>
      {breakingNews &&
        breakingNews.filter((breakingNew) =>
          moment(breakingNew.createdAt).isSame(today, "d")
        ).length > 0 && (
          <div className="BreakingNews">
            {error && <div>{error}</div>}
            <Divider horizontal>
              <Icon name="left arrow" onClick={handleGoBack} />
              <Link to="/breaking">
                <span className="breaking-news">
                  {metaData.CATEGORY.BREAKING_NEWS}
                </span>
              </Link>{" "}
              <Icon name="right arrow" onClick={handleLoadMore} />
            </Divider>
            <Card.Group
              centered
              style={{
                display: "flex",
                flexWrap: "nowrap",
                overflowX: "scroll",
                maxWidth: "100%",
                justifyContent: "flex-start",
                paddingLeft: "10px",
              }}
            >
              {breakingNews &&
                breakingNews
                  .filter((breakingNew) =>
                    moment(breakingNew.createdAt).isSame(today, "d")
                  )
                  .map((breakingNew) => (
                    <BreakingNew2
                      breakingNew={breakingNew}
                      key={breakingNew._id}
                      onUpdate={handleUpdate}
                      onDelete={handleDelete}
                      onLike={handleLike}
                      onDisLike={handleDisLike}
                    />
                  ))}
            </Card.Group>
          </div>
        )}
    </>
  );
};

export default BreakingNews2;
