import React from "react";
import "./Ipos.css";
import Ipo from "../IPOs/Ipo/Ipo";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useMetaData } from "../../Context/MetaDataContext";
import useFetch from "../../Hooks/FetchData";
import { Divider, Card } from "semantic-ui-react";
import { isMobile } from "react-device-detect";
const Ipos = () => {
  const { user } = useAuthContext();
  const { metaData } = useMetaData();
  const { data: ipos, error } = useFetch("/api/ipocalendar");
  return (
    <>
      {ipos && (
        <>
          {error && <div>{error}</div>}

          {!isMobile && (
            <>
              <div className="Ipos">
                <Divider horizontal>
                  <span className="ipos_Menu">
                    {metaData.CATEGORY.IPO_SCHEDULE}
                  </span>
                </Divider>

                <Card.Group
                  centered
                  style={{
                    display: "flex",
                    overflowY: "scroll",
                    maxHeight: "420px",
                  }}
                >
                  {ipos && ipos.map((ipo) => <Ipo ipo={ipo} key={ipo._id} />)}
                </Card.Group>
              </div>
            </>
          )}
          {isMobile && (
            <>
              <div className="Ipos">
                <Divider horizontal>
                  <span className="ipos_Menu">
                    {metaData.CATEGORY.IPO_SCHEDULE}
                  </span>
                </Divider>

                <Card.Group
                  centered
                  style={{
                    display: "flex",
                    overflowY: "scroll",
                    maxHeight: "180px",
                  }}
                >
                  {ipos && ipos.map((ipo) => <Ipo ipo={ipo} key={ipo._id} />)}
                </Card.Group>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Ipos;
