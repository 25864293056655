import React, { useEffect, useContext, useState } from "react";
import Home from "./pages/Home";
import Footer from "./components/Footer/Footer";
import NavBar from "./components/Navbar/Navbar";
import Profile from "./components/Profile/Profile";
import Login from "./components/Auth/Auth";
import { useAuthContext } from "./Hooks/useAuthContext";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Router,
} from "react-router-dom";
import WatchLists from "./components/WatchLists/WatchLists";
import Posts from "./components/Posts/Posts";
import Alerts from "./components/Alerts/Alerts";
import AlertOptions from "./components/AlertOptions/AlertOptions";
import AlertPage from "./components/Alerts/AlertPage";
import "./index.css";
import Future from "./components/Future/Future";
import Earnings from "./components/Earnings/Earnings";
import BreakingNews from "./components/BreakingNews/BreakingNews";
import GeneralNews from "./components/GeneralNews/GeneralNews";
import Calendars from "./components/Calendars/Calendars";
import Insiders from "./components/Tickers/Insiders";
import Journals from "./components/Journals/Journals";
import Visistor from "./components/Visitor/Visistor";
import MarketIndex from "./components/FearAndGreed/MarketIndex";
import { SearchTickerContext } from "./Context/SearchTickerContext";
import { useLogout } from "./Hooks/useLogout";
import PostSingleView from "./components/Posts/PostSingleView";
import BreakingNewsSingleView from "./components/BreakingNews/BreakingNewsSingleView";
import GeneralNewSingleView from "./components/GeneralNews/GeneralNewSingleView";
import UserProfile from "./components/UserProfile/UserProfile";
import AlertsPage from "./components/Alerts/AlertsPage";
import AlertOptionsPage from "./components/AlertOptions/AlertOptionsPage";
import AlertOptionPage from "./components/AlertOptions/AlertOption/AlertOptionPage";
import EarningPage from "./components/Earnings/Earning/EarningPage";
import SmartTradingHub from "./components/PostContainer/SmartTradingHub";
import Banned from "./pages/Banned";
function App() {
  const { user } = useAuthContext();
  const {
    value,
    setValue,
    searchResults,
    setSearchResults,
    handleTickerInput,
  } = useContext(SearchTickerContext);

  const handleClear = () => {
    setValue("");
    setSearchResults([]);
    // window.location.reload();
  };

  const { logout } = useLogout();
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      logout();
    }, 60000 * 180); // Logout after 1 hours
    return () => clearTimeout(timeoutId); // Clean up the timeout on unmount
  }, [logout]);

  return (
    <div className="App" onDoubleClick={handleClear}>
      <BrowserRouter>
        <NavBar />
        <div className="Home-Page">
          <Routes>
            {user && user.user.role === 7 ? (
              <Route path="*" element={<Banned />} />
            ) : (
              <>
                <Route exact path="/" element={<Home />} />
                {user && (
                  <Route
                    path={`/user/${user.user.username}`}
                    element={user ? <Profile /> : <Navigate to="/login" />}
                  />
                )}
                <Route
                  path="/watchlist"
                  element={user ? <WatchLists /> : <Navigate to="/login" />}
                />
                <Route
                  path="/earning"
                  element={user ? <Earnings /> : <Navigate to="/login" />}
                />
                <Route
                  path="/hub"
                  element={
                    user ? <SmartTradingHub /> : <Navigate to="/login" />
                  }
                />
                <Route path="/alerts" element={<AlertsPage />} />
                <Route path="/optionalerts" element={<AlertOptionsPage />} />
                <Route
                  path="/breaking"
                  element={user ? <BreakingNews /> : <Navigate to="/login" />}
                />
                <Route
                  path="/general"
                  element={user ? <GeneralNews /> : <Navigate to="/login" />}
                />
                <Route path="/insider" element={<Insiders />} />
                <Route
                  path="/market-index"
                  element={user ? <MarketIndex /> : <Navigate to="/login" />}
                />
                <Route path="/calendar" element={<Calendars />} />
                <Route
                  path="/login"
                  element={!user ? <Login /> : <Navigate to="/" />}
                />
                <Route
                  path="/alert/:id"
                  element={!user ? <Login /> : <AlertPage />}
                />
                <Route
                  path="/optionalert/:id"
                  element={!user ? <Login /> : <AlertOptionPage />}
                />
                <Route
                  path="/earning/:id"
                  element={!user ? <Login /> : <EarningPage />}
                />
                <Route
                  path="/p/:id"
                  element={!user ? <Login /> : <PostSingleView />}
                />
                <Route
                  path="/breakingnew/:id"
                  element={<BreakingNewsSingleView />}
                />
                <Route path="/new/:id" element={<GeneralNewSingleView />} />
                <Route
                  path="/user/:id"
                  element={!user ? <Login /> : <UserProfile />}
                />
                <Route
                  path="/future"
                  element={!user ? <Login /> : <Future />}
                />
              </>
            )}
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
