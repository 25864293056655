import { useContext } from "react";
import { GeneralNewContext } from "../Context/GeneralNewContext";

export const useGeneralNewContext = () => {
  const context = useContext(GeneralNewContext);

  if (!context) {
    throw Error(
      "useGeneralNewContext must be used inside an GeneralNewContextProvider"
    );
  }

  return context;
};
