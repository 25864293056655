import React, { useState, useEffect } from "react";
import "./ProfilePosts.css";
import { Pagination, Feed, Divider, Button, Icon } from "semantic-ui-react";
import Post from "../../Posts/Post/Post";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import { usePostsContext } from "../../../Hooks/usePostsContext";
import { useMetaData } from "../../../Context/MetaDataContext";
import axios from "axios";
const ProfilePosts = () => {
  const { posts, dispatch } = usePostsContext();
  const [error, setError] = useState(null);
  const { metaData } = useMetaData();
  const [totalPages, setTotalPages] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { user } = useAuthContext();
  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        try {
          const response = await fetch(
            `/api/posts/${user.user._id}/search?page=${currentPage}`
          );
          if (response.ok) {
            const json = await response.json();
            dispatch({ type: "FETCH_POST", payload: json.posts }); // Update with json.posts
            setTotalPages(json.totalPages); // Set totalPages from response
          } else {
            throw new Error("Failed to fetch alerts");
          }
        } catch (error) {
          setError(error.message);
        }
      }
    };
    fetchData();
  }, [dispatch, user, currentPage]);

  // LIKE HANDLER
  const handleLike = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/posts/" + eventId + "/like", {
      method: "PUT",
      body: JSON.stringify({ username: user.user.username }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "POST_LIKE",
        payload: { id: eventId, user: user.user.username },
      });
    } else {
      console.error("error");
    }
  };
  // DISLIKE HANDLER
  const handleDisLike = async (eventId) => {
    if (!user) {
      return;
    }
    const response = await fetch("/api/posts/" + eventId + "/disLike", {
      method: "PUT",
      body: JSON.stringify({ username: user.user.username }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });

    if (response.ok) {
      dispatch({
        type: "POST_DISLIKE",
        payload: { id: eventId, user: user.user.username },
      });
    } else {
      console.error("error");
    }
  };
  // handle Update
  const handleUpdate = async (eventId, post) => {
    if (!user) {
      return;
    }
    dispatch({
      type: "POST_UPDATE",
      payload: { id: eventId, data: post },
    });
  };
  // HANDLE DELETE
  const handleDelete = async (eventId) => {
    try {
      if (!user) {
        throw new Error("Please login");
      }
      const response = await fetch(`/api/posts/${eventId}`, {
        method: "DELETE",
        body: JSON.stringify({
          userId: user.user._id,
          userRole: user.user.role,
          isAdmin: user.user.isAdmin,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const json = await response.json();
      if (response.ok) {
        dispatch({ type: "DELETE_POST", payload: json });
      } else {
        throw new Error("Failed to delete post");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handlePageChange = (event, data) => {
    const { activePage } = data;
    setCurrentPage(Number(activePage)); // Ensure currentPage is a number
  };
  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1);
  };
  return (
    <>
      {user &&
        (user.user.role === 1 ||
          user.user.role === 2 ||
          user.user.role === 3 ||
          user.user.role === 4 ||
          user.user.role === 5 ||
          user.user.role === 6 ||
          user.user.role === 10) &&
        posts &&
        posts.length > 0 && (
          <div className="ProfilePosts">
            {error && <div>{error}</div>}
            <Divider horizontal>{metaData.CATEGORY.YOUR_HUB}</Divider>

            <Feed size="large">
              {posts &&
                posts.map((post) => (
                  <Post
                    post={post}
                    key={post._id}
                    onUpdate={handleUpdate}
                    onDelete={handleDelete}
                    onLike={handleLike}
                    onDisLike={handleDisLike}
                  />
                ))}
            </Feed>
            <div className="page">
              <Pagination
                className="page"
                secondary
                siblingRange={0}
                boundaryRange={1}
                defaultActivePage={1}
                firstItem={false}
                lastItem={false}
                totalPages={totalPages}
                activePage={currentPage}
                onPageChange={handlePageChange}
              />
              {/* {currentPage < totalPages && (
                <Button basic onClick={handleLoadMore}>
                  Load more...
                </Button>
              )} */}
            </div>
          </div>
        )}
    </>
  );
};

export default ProfilePosts;
