import React, { useEffect } from "react";

const CryptoTape = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
    script.async = true;
    script.innerHTML = `
      {
        "symbols": [
          {
            "proName": "BITSTAMP:BTCUSD",
            "title": "Bitcoin"
          },
          {
            "proName": "BITSTAMP:ETHUSD",
            "title": "Ethereum"
          },
          {
            "description": "Polygon",
            "proName": "BINANCE:MATICUSDT"
          },
          {
            "description": "Cardano",
            "proName": "BINANCE:ADAUSDT"
          },
          {
            "description": "Solana",
            "proName": "COINBASE:SOLUSD"
          }
        ],
        "showSymbolLogo": true,
        "colorTheme": "light",
        "isTransparent": false,
        "displayMode": "adaptive",
        "locale": "en"
      }
    `;

    const container = document.querySelector(".CryptoTape");
    if (container) {
      container.appendChild(script);
    }

    return () => {
      if (container) {
        container.innerHTML = "";
      }
    };
  }, []);

  return (
    <>
      <div className="CryptoTape"></div>
    </>
  );
};

export default CryptoTape;
