import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./UserProfile.css";
import noAvatar from "../../assets/img/noAvatar.png";
import ProfileBanner from "../../assets/img/ProfileBanner.png";
import {
  Card,
  Container,
  Button,
  Divider,
  Icon,
  Modal,
  Input,
} from "semantic-ui-react";
import { useAuthContext } from "../../Hooks/useAuthContext";
import useFetch from "../../Hooks/FetchData";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import moment from "moment";
import EditProfileModal from "../Modal/EditProfileModal";
import UpdateRole from "./UpdateRole";
moment().format();
const UserProfile = ({ postUserId }) => {
  const [error, setError] = useState(null);
  const { user } = useAuthContext();
  const [userProfile, setUserProfile] = useState("");
  const [open, setOpen] = React.useState(false);
  // Get the generalNews data from backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/user/${postUserId}`);
        if (response.ok) {
          const json = await response.json();
          setUserProfile(json);

          // dispatch({ type: "FETCH_SINGLE_GENERALNEW", payload: json });
        }
      } catch (error) {
        setError(error.message);
      }
    };
    fetchData();
  }, postUserId);

  return (
    <>
      <Helmet>
        <title>Profile</title>
      </Helmet>
      {user &&
      (user.user.role === 1 ||
        user.user.role === 2 ||
        user.user.role === 3 ||
        user.user.role === 4 ||
        user.user.role === 5 ||
        user.user.role === 6 ||
        user.user.role === 10) &&
      userProfile ? (
        <div className="UserProfile">
          {error && <div>{error}</div>}
          <Divider horizontal>
            <span className="breaking-news">
              {userProfile.username}'S PROFILE
            </span>
          </Divider>
          <div>
            <Container>
              <div className="ProfileCard">
                {userProfile && (
                  <div className="ProfileImages">
                    {userProfile.profileImage === "ProfileBanner.png" ? (
                      <img src={ProfileBanner} alt="" />
                    ) : (
                      <img src={userProfile.ProfileBanner} alt="" />
                    )}
                    {userProfile.avatarPicture === "noAvatar.png" ? (
                      <img src={noAvatar} alt="" />
                    ) : (
                      <img src={userProfile.avatarPicture} alt="" />
                    )}
                  </div>
                )}
                <div className="ProfileName">
                  <span>
                    {userProfile.role === 0 && "No Active Member"}
                    {userProfile.role === 7 && "BANNED"}
                    {userProfile.role === 1 && "Admin"}
                    {userProfile.role === 2 && "Moderator"}
                    {userProfile.role === 3 && "Alert"}
                    {userProfile.role === 4 && "Member"}
                    {userProfile.role === 5 && "Gold Member"}
                    {userProfile.role === 6 && "Premium Member"}
                    {userProfile.role === 10 && "Trail Member"}
                  </span>
                </div>
                <div className="ProfileMe">
                  <Card fluid>
                    <Card.Content>
                      <Card.Header>
                        {userProfile.username.toUpperCase()}
                      </Card.Header>
                      <Card.Meta>
                        <span className="date">
                          Joined since{" "}
                          {moment(userProfile.createdAt).format("MMMM, YYYY")}
                        </span>
                      </Card.Meta>
                      {userProfile.firstname && (
                        <Card.Description>
                          First Name: {userProfile.firstname}
                        </Card.Description>
                      )}
                      {userProfile.lastname && (
                        <Card.Description>
                          Last Name: {userProfile.lastname}
                        </Card.Description>
                      )}
                      {userProfile.livesin && (
                        <Card.Description>
                          From {userProfile.livesin}
                        </Card.Description>
                      )}
                      {userProfile.worksAt && (
                        <Card.Description>
                          Work at {userProfile.worksAt}
                        </Card.Description>
                      )}
                      {user &&
                        (user.user.role === 1 ||
                          user.user.role === 2 ||
                          user.user.role === 3 ||
                          user.user.role === 4 ||
                          user.user.role === 5 ||
                          user.user.role === 6) && (
                          <>
                            {" "}
                            {userProfile.phone && (
                              <Card.Description>
                                Zelle#: {userProfile.phone}
                              </Card.Description>
                            )}
                            {userProfile.email && (
                              <Card.Description>
                                Email: {userProfile.email}
                              </Card.Description>
                            )}
                          </>
                        )}

                      {userProfile.about && (
                        <Card.Description>
                          Bio: {userProfile.about}
                        </Card.Description>
                      )}
                    </Card.Content>
                    <Card.Content extra>
                      {userProfile.following &&
                        userProfile.following.length > 0 && (
                          <>
                            <Icon name="user" />
                            {userProfile.following.length} Following
                          </>
                        )}
                    </Card.Content>
                    {user && (user.user.role === 1 || user.user.role === 2) && (
                      <>
                        <Modal
                          onClose={() => setOpen(false)}
                          // centered={false}
                          onOpen={() => setOpen(true)}
                          open={open}
                          trigger={<Icon name="edit" />}
                        >
                          <UpdateRole userId={userProfile._id} />
                        </Modal>
                      </>
                    )}
                  </Card>
                </div>
              </div>
            </Container>
          </div>
        </div>
      ) : (
        <h1>SORRY THIS USER CAN'T VIEW</h1>
      )}
    </>
  );
};

export default UserProfile;
