import React from "react";
import { Modal, Icon } from "semantic-ui-react";
import WatchListShare from "../WatchListShare/WatchListShare";
const WatchListModal = () => {
  const [open, setOpen] = React.useState(false);
  const handleCloseModal = () => {
    setOpen(false);
  };
  return (
    <Modal
      onClose={() => setOpen(false)}
      // centered={false}
      closeIcon
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <div>
          <Icon
            name="list layout"
            size="big"
            style={{ color: "var(--watchlist)" }}
          />{" "}
          WL
        </div>
      }
    >
      <WatchListShare handleCloseModal={handleCloseModal} />
    </Modal>
  );
};

export default WatchListModal;
