import React, { useState, useContext, useEffect } from "react";
import Posts from "../Posts/Posts";
import PostShare from "../PostShare/PostShare";
import AlertClosed from "../Alerts/Alert/AlertClosed";
import "./PostContainer.css";
import { Divider, Card, List, Tab } from "semantic-ui-react";
import { useAlertsContext } from "../../Hooks/useAlertsContext";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useMetaData } from "../../Context/MetaDataContext";
import SearchResult from "../Search/SearchResult/SearchResult";
import { SearchTickerContext } from "../../Context/SearchTickerContext";
import Calendars from "../Calendars/Calendars";
import ImageUploader from "../Uploader/uploader";
import PostShareModal from "../Modal/PostShareModal";
import { isMobile } from "react-device-detect";
import BreakingNews from "../BreakingNews/BreakingNews";
import BreakingNewHome from "../BreakingNews/BreakingNewHome";
import GeneralNews from "../GeneralNews/GeneralNews";
import noAvatar from "../../assets/img/noAvatar.png";
import Insiders from "../Tickers/Insiders";
import TradingViewWidget from "../TradingView/TradingViewWidget";
import AlertsClosed from "../Alerts/AlertsClosed";
import TradingPlan from "../Posts/TradingPlan";
import GainLoss from "../Posts/GainLoss";
import Learning from "../Posts/Learning";
import Analysis from "../Posts/Analysis";
import CustomTabMenu from "./CustomTabMenu";
import Laugh from "../Posts/Laugh";
import AlertOptionsClosed from "../AlertOptions/AlertOptionsClosed";
import TradingJournal from "../Posts/TradingJournal";

const PostContainer = () => {
  const { user } = useAuthContext();
  const { metaData } = useMetaData();
  const { searchResults, setSearchResults } = useContext(SearchTickerContext);
  const handleClear = () => {
    setSearchResults([]);
  };
  const panes = [
    {
      menuItem: {
        key: "discuss",
        icon: "users",
        content: `${metaData.MENU.DISCUSS}`,
      },
      render: () => (
        <Tab.Pane attached={false}>
          <Posts />
        </Tab.Pane>
      ),
    },
    {
      // menuItem: "Trading Plan",
      menuItem: {
        key: "tradingplan",
        icon: "idea",
        content: `${metaData.MENU.TRADING_PLAN}`,
      },
      render: () => (
        <Tab.Pane attached={false}>
          <TradingPlan />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: "tradingjournal",
        icon: "hdd",
        content: `${metaData.MENU.TRADING_JOURNAL}`,
      },
      render: () => (
        <Tab.Pane attached={false}>
          <TradingJournal />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: "technical",
        icon: "chart line",
        content: `${metaData.MENU.TECHNICAL_LEARNING}`,
      },
      render: () => (
        <Tab.Pane attached={false}>
          <Learning />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: "analysis",
        icon: "newspaper outline",
        content: `${metaData.MENU.ANALYSIS}`,
      },
      render: () => (
        <Tab.Pane attached={false}>
          <Analysis />
        </Tab.Pane>
      ),
    },
    {
      // menuItem: "Gain & Loss",
      menuItem: {
        key: "gainloss",
        icon: "dollar",
        content: `${metaData.MENU.GAIN_LOSS}`,
      },
      render: () => (
        <Tab.Pane attached={false}>
          <GainLoss />
        </Tab.Pane>
      ),
    },
    {
      // menuItem: "Gain & Loss",
      menuItem: {
        key: "lol",
        icon: "smile",
        content: `${metaData.MENU.LAUGH}`,
      },
      render: () => (
        <Tab.Pane attached={false}>
          <Laugh />
        </Tab.Pane>
      ),
    },
  ];
  return (
    <div className="PostContainer">
      {!isMobile && (
        <>
          {searchResults.tickerData && searchResults.tickerData.length > 0 && (
            <div className="Alert">
              <Divider horizontal>
                SEARCH RESULTS {" have "}{" "}
                {searchResults.alertData &&
                searchResults.alertData.length < 1 ? (
                  " no"
                ) : (
                  <>
                    {searchResults.alertData.length +
                      searchResults.optionAlertData.length}
                  </>
                )}{" "}
                {searchResults.alertData.length > 1 ? " alerts" : " alert"}
              </Divider>

              <Card.Group centered>
                {searchResults.tickerData.map((tickerResult) => (
                  <SearchResult
                    tickerResult={tickerResult}
                    key={tickerResult._id}
                    handleClear={handleClear}
                  />
                ))}
              </Card.Group>
            </div>
          )}
        </>
      )}
      {/* <BreakingNews /> */}
      {/* <TradingViewWidget /> */}

      {user &&
        (user.user.isAdmin === true ||
          user.user.role === 1 ||
          user.user.role === 2 ||
          user.user.role === 3) && (
          <>{!isMobile ? <PostShare /> : <PostShareModal />}</>
        )}
      {/* <Tab
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        style={{ overflowX: "auto", maxwidth: "100%" }}
      /> */}
      <CustomTabMenu panes={panes} />
      {/* <Posts /> */}

      {!isMobile && <GeneralNews />}
      {/* <Insiders /> */}
      {!isMobile && <Calendars />}
      <AlertsClosed />
      <AlertOptionsClosed />
      {/* {!isMobile && <Calendars />} */}
    </div>
  );
};

export default PostContainer;
