import React, { useState } from "react";
import { useUpdate } from "../../../Hooks/useUpdate";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import { Icon, Message, Form, Button } from "semantic-ui-react";
import "./ProfileInfo.css";
function ProfileInfo() {
  const { user } = useAuthContext();
  // const [isRegister, setIsRegister] = useState(false);
  // const [confirmPass, setConfirmPass] = useState('');
  // const [username, setUserName] = useState('');
  // const [password, setPassword] = useState('');
  // const [email, setEmail] = useState('');
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [about, setAbout] = useState("");
  const [worksAt, setWorksAt] = useState("");
  const [livesin, setLivesin] = useState("");

  const { userUpdate, error, isLoading } = useUpdate();
  const handleSubmit = async (e) => {
    // setConfirmPass(false);
    e.preventDefault();
    await userUpdate(firstname, lastname, phone, about, worksAt, livesin);
  };
  const resetForm = () => {
    // setUserName(username);
    // setEmail(email);
    setPhone(phone);
    setAbout(about);
    setWorksAt(worksAt);
    setLivesin(livesin);
  };

  return (
    <div className="ProfileInfo">
      <Form>
        <h3>Update Your info</h3>
        <Form.Input
          fluid
          icon="user"
          iconPosition="left"
          placeholder="First Name"
          name="firstname"
          value={firstname}
          onChange={(e) => setFirstname(e.target.value)}
        />
        <Form.Input
          fluid
          icon="user"
          iconPosition="left"
          placeholder="Last Name"
          name="lastname"
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
        />
        <Form.Input
          fluid
          icon="user"
          iconPosition="left"
          placeholder="Lives in"
          name="livesin"
          value={livesin}
          onChange={(e) => setLivesin(e.target.value)}
        />
        <Form.Input
          fluid
          icon="user"
          iconPosition="left"
          placeholder="Work at"
          name="worksAt"
          value={worksAt}
          onChange={(e) => setWorksAt(e.target.value)}
        />
        <Form.Input
          fluid
          icon="phone"
          iconPosition="left"
          placeholder="Zelle Phone #"
          name="worksAt"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />

        <Form.TextArea
          placeholder="About me"
          name="about"
          value={about}
          onChange={(e) => setAbout(e.target.value)}
        />

        {/* <div>
					Profile Image
					<input type='file' name='profileImg' />
					Cover Image
					<input type='file' name='coverImg' />
				</div> */}

        <button className="button infoButton" onClick={handleSubmit}>
          Update
        </button>
      </Form>
    </div>
  );
}

export default ProfileInfo;
