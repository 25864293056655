import React, { useState } from "react";
import "./WatchListShare.css";
import noAvatar from "../../assets/img/noAvatar.png";
import {
  Button,
  Form,
  Label,
  Input,
  Message,
  TextArea,
  Checkbox,
} from "semantic-ui-react";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useWatchlistsContext } from "../../Hooks/useWatchlistContext";
const WatchListShare = (props) => {
  const { user } = useAuthContext();
  const { dispatch } = useWatchlistsContext();
  const [ticker, setTicker] = useState();
  const [support, setSupport] = useState([""]);
  const [resistance, setResistance] = useState([""]);
  const [analysts, setAnalysts] = useState();
  const [chart, setChart] = useState();
  const [call, setCall] = useState([""]);
  const [put, setPut] = useState([""]);
  const [error, setError] = useState(null);
  const [term, setTerm] = useState("DAILY");
  const [signal, setSignal] = useState("");
  const [weekMin, setWeekMin] = useState("");
  const [weekMax, setWeekMax] = useState("");
  const [monthMin, setMonthMin] = useState("");
  const [monthMax, setMonthMax] = useState("");
  const [isFocused, setIsFocused] = useState(null);
  const tradingviewLinkRegex =
    /^https:\/\/www\.tradingview\.com\/x\/[a-zA-Z0-9]+\/$/;

  const handleWatchlistSubmit = async (e) => {
    if (!user) {
      setError("You must be logged in");
      return;
    }
    e.preventDefault();
    if (chart && !tradingviewLinkRegex.test(chart)) {
      setError("Invalid TradingView URL link");
      return;
    }
    const formData = JSON.stringify({
      userId: user.user._id,
      username: user.user.username,
      ticker,
      support,
      resistance,
      analysts,
      call,
      chart,
      put,
      term,
      signal,
      weekMin,
      weekMax,
      monthMin,
      monthMax,
    });
    const response = await fetch("/api/wl", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: formData,
    });
    const json = await response.json();
    if (!response.ok) {
      setError(json.message);
    }
    if (response.ok) {
      setError(null);
      dispatch({ type: "CREATE_WATCHLIST", payload: json });
      props.handleCloseModal();
    } else {
      console.error("error");
    }
  };

  return (
    <>
      {user && (
        <div className="WatchListShare">
          {user.user.avatarPicture === "noAvatar.png" ? (
            <img src={noAvatar} alt="" />
          ) : (
            <img src={user.user.avatarPicture} alt="" />
          )}
          <div className="watchListPost">
            {error && (
              <Message size="mini" color="red">
                {error}
              </Message>
            )}
            <Form.Field>
              {isFocused === "ticker" && (
                <Label pointing="below">Ticker Required</Label>
              )}
              <Input
                type="text"
                placeholder="* Ticker"
                onChange={(e) => setTicker(e.target.value)}
                value={ticker}
                fluid
                onFocus={() => setIsFocused("ticker")}
                onBlur={() => setIsFocused(null)}
              />
            </Form.Field>
            <div className="checkbox-group">
              <Checkbox
                radio
                label="Bullish"
                value="Bullish"
                checked={signal === "Bullish"}
                onChange={(event, { value }) => setSignal(value)}
              />
              <Checkbox
                radio
                label="Bearish"
                value="Bearish"
                checked={signal === "Bearish"}
                onChange={(event, { value }) => setSignal(value)}
              />
              <Checkbox
                radio
                label="Sideway"
                value="Sideway"
                checked={signal === "Sideway"}
                onChange={(event, { value }) => setSignal(value)}
              />
            </div>
            <Form.Field>
              {isFocused === "analysts" && (
                <Label pointing="below">Analyst or Note for this Ticker</Label>
              )}
              <TextArea
                style={{ width: "100%", height: "75px" }}
                type="text"
                placeholder="Analyst or Note of this alert..."
                onChange={(e) => setAnalysts(e.target.value)}
                value={analysts}
                fluid
                onFocus={() => setIsFocused("analysts")}
                onBlur={() => setIsFocused(null)}
              />
            </Form.Field>
            <Form.Field>
              {isFocused === "chart" && (
                <Label pointing="below">Tradingview URL Chart Only</Label>
              )}
              <Input
                type="text"
                placeholder="Tradingview URL Chart Only"
                onChange={(e) => setChart(e.target.value)}
                value={chart}
                fluid
                onFocus={() => setIsFocused("chart")}
                onBlur={() => setIsFocused(null)}
              />
            </Form.Field>
            <Form.Field>
              {isFocused === "resistance" && (
                <Label pointing="below">
                  Resistance level press "Space" to add next level
                </Label>
              )}
              <Input
                type="text"
                placeholder="Resistance Level 1,2,3,4..."
                fluid
                onFocus={() => setIsFocused("resistance")}
                onBlur={() => setIsFocused(null)}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault(); // prevent the space from being typed
                    setResistance((prevResistance) => [...prevResistance, " "]); // add an empty
                  } else if (
                    e.key === "Backspace" &&
                    resistance[resistance.length - 1] === ""
                  ) {
                    e.preventDefault(); // prevent the backspace from navigating back
                    setResistance((prevResistance) =>
                      prevResistance.slice(0, -1)
                    ); // remove the last empty
                  }
                }}
                onChange={(e) => setResistance(e.target.value.split(","))}
                value={resistance}
                required
              />
            </Form.Field>
            <Form.Field>
              {isFocused === "support" && (
                <Label pointing="below">
                  Support level press "Space" to add next level
                </Label>
              )}
              <Input
                type="text"
                placeholder="Support Level 1,2,3,4..."
                fluid
                onFocus={() => setIsFocused("support")}
                onBlur={() => setIsFocused(null)}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault(); // prevent the space from being typed
                    setSupport((prevSupport) => [...prevSupport, " "]); // add an empty string
                  } else if (
                    e.key === "Backspace" &&
                    support.length > 0 &&
                    support[support.length - 1] === ""
                  ) {
                    e.preventDefault(); // prevent the backspace from navigating back
                    setSupport((prevSupport) => prevSupport.slice(0, -1)); // remove the last empty string
                  }
                }}
                onChange={(e) => setSupport(e.target.value.split(","))}
                value={support}
                required
              />
            </Form.Field>
            <Form.Field>
              {isFocused === "call" && (
                <Label pointing="below">
                  Call option strikes press "Space" to add another strike
                </Label>
              )}
              <Input
                type="text"
                placeholder="Call option strikes recomendation 1, 2, 3..."
                fluid
                onFocus={() => setIsFocused("call")}
                onBlur={() => setIsFocused(null)}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault(); // prevent the space from being typed
                    setCall((prevCall) => [...prevCall, " "]); // add an empty
                  } else if (
                    e.key === "Backspace" &&
                    call[call.length - 1] === ""
                  ) {
                    e.preventDefault(); // prevent the backspace from navigating back
                    setCall((prevCall) => prevCall.slice(0, -1)); // remove the last empty
                  }
                }}
                onChange={(e) => setCall(e.target.value.split(","))}
                value={call}
              />
            </Form.Field>
            <Form.Field>
              {isFocused === "put" && (
                <Label pointing="below">
                  Put option strikes press "Space" to add another strike
                </Label>
              )}
              <Input
                type="text"
                placeholder="Put option strikes recomendation 1, 2, 3..."
                fluid
                onFocus={() => setIsFocused("put")}
                onBlur={() => setIsFocused(null)}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault(); // prevent the space from being typed
                    setPut((prevPut) => [...prevPut, " "]); // add an empty
                  } else if (
                    e.key === "Backspace" &&
                    put[put.length - 1] === ""
                  ) {
                    e.preventDefault(); // prevent the backspace from navigating back
                    setPut((prevPut) => prevPut.slice(0, -1)); // remove the last empty
                  }
                }}
                onChange={(e) => setPut(e.target.value.split(","))}
                value={put}
              />
            </Form.Field>
            <div className="checkbox-group">
              <Checkbox
                radio
                label="DAILY"
                value="DAILY"
                checked={term === "DAILY"}
                onChange={(event, { value }) => setTerm(value)}
              />
              <Checkbox
                radio
                label="WEEKLY"
                value="WEEKLY"
                checked={term === "WEEKLY"}
                onChange={(event, { value }) => setTerm(value)}
              />
              <Checkbox
                radio
                label="MONTHLY"
                value="MONTHLY"
                checked={term === "MONTHLY"}
                onChange={(event, { value }) => setTerm(value)}
              />
            </div>
            {term === "WEEKLY" && (
              <>
                <Form.Field>
                  {isFocused === "weekMin" && (
                    <Label pointing="below">Week Min & Number Only</Label>
                  )}
                  <Input
                    type="text"
                    placeholder="Optional Weekly Minimum Price"
                    onChange={(e) => setWeekMin(e.target.value)}
                    value={weekMin}
                    fluid
                    onFocus={() => setIsFocused("weekMin")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
                <Form.Field>
                  {isFocused === "weekMax" && (
                    <Label pointing="below">Week Max & Number Only</Label>
                  )}
                  <Input
                    type="text"
                    placeholder="Optional Weekly Minimum Price"
                    onChange={(e) => setWeekMax(e.target.value)}
                    value={weekMax}
                    fluid
                    onFocus={() => setIsFocused("weekMax")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
              </>
            )}
            {term === "MONTHLY" && (
              <>
                <Form.Field>
                  {isFocused === "monthMin" && (
                    <Label pointing="below">Month Min & Number Only</Label>
                  )}
                  <Input
                    type="text"
                    placeholder="Optional Monthly Minimum Price"
                    onChange={(e) => setMonthMin(e.target.value)}
                    value={monthMin}
                    fluid
                    onFocus={() => setIsFocused("monthMin")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
                <Form.Field>
                  {isFocused === "monthMax" && (
                    <Label pointing="below">Month Max & Number Only</Label>
                  )}
                  <Input
                    type="text"
                    placeholder="Optional Monthly Maxium Price"
                    onChange={(e) => setMonthMax(e.target.value)}
                    value={monthMax}
                    fluid
                    onFocus={() => setIsFocused("monthMax")}
                    onBlur={() => setIsFocused(null)}
                  />
                </Form.Field>
              </>
            )}

            <div className="postOption">
              <Button
                className="share-button"
                style={{ color: "var(--blue)" }}
                onClick={handleWatchlistSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WatchListShare;
