import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "semantic-ui-css/semantic.min.css";
import "./index.css";
import { AuthContextProvider } from "./Context/AuthContext";
import { PostsContextProvider } from "./Context/PostContext";
import { AlertsContextProvider } from "./Context/AlertContext";
import { WatchlistsContextProvider } from "./Context/WatchListContext";
import { AlertOptionContextProvider } from "./Context/AlertOptionContext";
import { EarningContextProvider } from "./Context/EarningContext";
import { SearchTickerProvider } from "./Context/SearchTickerContext";
import { BreakingNewContextProvider } from "./Context/BreakingNewContext";
import { CalendarContextProvider } from "./Context/CalendarContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { TickerContextProvider } from "./Context/TickerContext";
import { GeneralNewContextProvider } from "./Context/GeneralNewContext";
import { JournalContextProvider } from "./Context/JournalContext";
import { MetaDataProvider } from "./Context/MetaDataContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //   <React.StrictMode>
  <GoogleOAuthProvider clientId="572147224333-vc1ibpihotigg2os1bg6o27eht9b0v31.apps.googleusercontent.com">
    <AuthContextProvider>
      <TickerContextProvider>
        <SearchTickerProvider>
          <PostsContextProvider>
            <EarningContextProvider>
              <WatchlistsContextProvider>
                <AlertOptionContextProvider>
                  <AlertsContextProvider>
                    <BreakingNewContextProvider>
                      <CalendarContextProvider>
                        <GeneralNewContextProvider>
                          <JournalContextProvider>
                            <MetaDataProvider>
                              <App />
                            </MetaDataProvider>
                          </JournalContextProvider>
                        </GeneralNewContextProvider>
                      </CalendarContextProvider>
                    </BreakingNewContextProvider>
                  </AlertsContextProvider>
                </AlertOptionContextProvider>
              </WatchlistsContextProvider>
            </EarningContextProvider>
          </PostsContextProvider>
        </SearchTickerProvider>
      </TickerContextProvider>
    </AuthContextProvider>
  </GoogleOAuthProvider>
  //   </React.StrictMode>
);
