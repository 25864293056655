import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { isMobile } from "react-device-detect";
import ChatChannel from "../Discords/ChatChannel/ChatChannel";
import Coins from "../Coins/Coins";
import Ipos from "../IPOs/Ipos";
import { Button, Icon } from "semantic-ui-react";
import CryptoTape from "../TradingView/CryptoTape";
const Footer = () => {
  const { user } = useAuthContext();

  return (
    <>
      <div className="Footer">
        {isMobile && <Coins />}
        {isMobile && <Ipos />}
        <CryptoTape />
        <ChatChannel />
        <span
          style={{
            display: "block",
            textAlign: "center",
            marginTop: "10px",
          }}
        >
          <Link to="https://www.facebook.com/smarttradinghub/" target="_blank">
            <Icon circular color="facebook" name="facebook" />
          </Link>
          <Link to="https://twitter.com/SmartTradingHub" target="_blank">
            <Icon circular color="twitter" name="twitter" />
          </Link>
          <Link to="/">
            <Icon circular color="blue" name="globe" />
          </Link>
          <br />
          Copyright &copy; 2023 By SmartTradingHub
        </span>
      </div>
    </>
  );
};

export default Footer;
