import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { Card, Image } from "semantic-ui-react";
import { SearchTickerContext } from "../../Context/SearchTickerContext";
import { Helmet } from "react-helmet";
const Future = () => {
  const [error, setError] = useState(null);
  const { handleTickerClick } = useContext(SearchTickerContext);
  const [topTickers, setTopTickers] = useState(null);
  // get polular ticker
  useEffect(() => {
    const fetchTickerData = async () => {
      try {
        const now = new Date();
        const currentHour = now.getUTCHours() - 4; // Adjust for Eastern Time (UTC-4)
        const isMarketOpen =
          currentHour >= 7 &&
          currentHour < 20 &&
          now.getUTCDay() >= 1 &&
          now.getUTCDay() <= 5;

        let tickerSymbols = [];
        if (isMarketOpen) {
          // tickerSymbols = ["SPY", "QQQ", "DIA", "IWM"];
          tickerSymbols = ["ES=F", "NQ=F", "YM=F", "RTY=F", "CL=F"];
        } else {
          tickerSymbols = [
            "ES=F",
            "NQ=F",
            "YM=F",
            "RTY=F",
            "NKD=F",
            "CL=F",
            "NG=F",
            "GC=F",
            "SI=F",
            "PL=F",
            "HG=F",
            "PA=F",
            "DX=F",
            "EURUSD=X",
            "CADUSD=X",
            "^VIX",
          ];
        }
        const requests = tickerSymbols.map((symbol) =>
          axios.get(`/api/ticker/search-by-name?name=${symbol}`)
        );

        const responses = await Promise.all(requests);
        const tickerData = responses.map((response) => response.data);
        setTopTickers(tickerData);
        // console.log(tickerData);
      } catch (error) {
        console.error(error);
      }
      //   finally {
      //     // Make the next request after a short delay
      //     setTimeout(fetchTickerData, 30000); // Request every 30 seconds (adjust the interval as needed)
      //   }
    };

    fetchTickerData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Future - Smart Trading Hub</title>
      </Helmet>
      <Card.Group centered>
        {topTickers &&
          topTickers.map((tickerArray) => {
            const ticker = tickerArray[0]; // Access the first element of each ticker array
            return (
              <Card
                key={ticker._id}
                color={ticker.regularMarketChange > 0 ? "green" : "red"}
              >
                <Card.Content onClick={() => handleTickerClick(ticker.ticker)}>
                  {ticker.logo ? (
                    <Image
                      avatar
                      floated="right"
                      size="large"
                      src={ticker.logo}
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        backgroundColor: "gray",
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "8px",
                        position: "absolute",
                        top: 15,
                        right: 10,
                        // left:0,
                      }}
                    >
                      {ticker.ticker}
                    </div>
                  )}
                  <Card.Header>
                    {ticker.ticker} {ticker.currentPrice}{" "}
                    <span
                      style={{
                        color:
                          ticker.regularMarketChange < 0
                            ? "red"
                            : ticker.regularMarketChange > 0
                            ? "green"
                            : "black",
                      }}
                    >
                      {ticker.regularMarketChange.toFixed(2)} (
                      {(ticker.regularMarketChangePercent * 100).toFixed(2)}
                      %)
                    </span>
                  </Card.Header>
                  <Card.Meta>{ticker.name}</Card.Meta>
                </Card.Content>
              </Card>
            );
          })}
      </Card.Group>
    </>
  );
};

export default Future;
