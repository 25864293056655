import React, { useState, useEffect, useRef } from "react";
import { useJournalContext } from "../../../Hooks/useJournalContext";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import ReactHtmlParser from "html-react-parser";
import noAvatar from "../../../assets/img/noAvatar.png";
import "./Journal.css";
import { Button, Card, Image, Modal, Icon } from "semantic-ui-react";
import moment from "moment";
import { isMobile } from "react-device-detect";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ModalImage from "react-modal-image";
import { Helmet } from "react-helmet";
moment().format();

const Journal = ({ journal, onDelete, onLike, onDisLike, onUpdate }) => {
  const { user } = useAuthContext();
  const [open, setOpen] = useState(false);
  const fetchUser = async (userId) => {
    try {
      const response = await fetch(`api/user/${userId}`);
      const journalUser = await response.json();
      return journalUser;
    } catch (error) {
      console.error(error);
    }
  };
  // Hook to fetch the user object associated with the post userId
  const [postUser, setPostUser] = useState(null);
  useEffect(() => {
    const getUser = async () => {
      const user = await fetchUser(journal.userId);
      setPostUser(user);
    };
    getUser();
  }, [journal.userId]);
  return (
    <>
      {journal && (
        <>
          <Helmet>
            <title>Trading Journal - Smart Trading Hub</title>
          </Helmet>
          <Card>
            <Card.Content>
              {postUser && (
                <>
                  {postUser.avatarPicture === "noAvatar.png" ? (
                    <Image
                      avatar
                      floated="right"
                      size="large"
                      src={noAvatar}
                      alt="Avatar"
                    />
                  ) : (
                    <Image
                      avatar
                      floated="right"
                      size="large"
                      src={postUser.avatarPicture}
                      alt="Avatar"
                    />
                  )}
                </>
              )}
              <Card.Header>{journal.username}</Card.Header>
              <Card.Meta>
                {moment(journal.createdAt).format("h:mm:ss A MMM, D YYYY")}
              </Card.Meta>
              {journal.body && (
                <Card.Description>{journal.body}</Card.Description>
              )}
              {journal.tradingviewImage && (
                <>
                  <Modal
                    closeIcon
                    dimmer="blurring"
                    size="fullscreen"
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    open={open}
                    trigger={
                      <Image
                        size="medium"
                        src={journal.tradingviewImage}
                        rounded
                      />
                    }
                  >
                    <TransformWrapper>
                      <TransformComponent>
                        <Modal.Header>{`Trading journal by "${
                          journal.username
                        }" ${moment(journal.createdAt)
                          .startOf("minutes")
                          .fromNow()}`}</Modal.Header>
                        <Image
                          src={journal.tradingviewImage}
                          alt={`Trading journal by ${journal.username} ${moment(
                            journal.createdAt
                          ).format("h:mm:ss A MMM, D YYYY")}`}
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </Modal>
                </>
              )}
              {journal.image && (
                <>
                  <Modal
                    closeIcon
                    dimmer="blurring"
                    size="fullscreen"
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    open={open}
                    trigger={
                      <Image size="medium" src={journal.image} rounded />
                    }
                  >
                    <TransformWrapper>
                      <TransformComponent>
                        <Modal.Header>{`Trading journal by "${
                          journal.username
                        }" ${moment(journal.createdAt)
                          .startOf("minutes")
                          .fromNow()}`}</Modal.Header>
                        <Image
                          src={journal.image}
                          alt={`Trading journal by ${journal.username} ${moment(
                            journal.createdAt
                          ).format("h:mm:ss A MMM, D YYYY")}`}
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </Modal>
                </>
              )}
            </Card.Content>
            <Card.Content extra>
              <div className="ui there buttons">
                <Button basic color="green" onClick={() => onLike(journal._id)}>
                  <Icon name="thumbs up" color="green" />
                  {journal.like.length}
                </Button>
                <Button
                  basic
                  color="red"
                  onClick={() => onDisLike(journal._id)}
                >
                  <Icon name="thumbs down" color="red" />
                  {journal.disLike.length}
                </Button>
                {user &&
                  (user.user.isAdmin === true ||
                    user.user.role === 1 ||
                    user.user.role === 2 ||
                    user.user._id === journal.userId) && (
                    <>
                      <Button
                        basic
                        color="green"
                        onClick={() => onDelete(journal._id)}
                      >
                        <Icon color="red" name="delete" />
                      </Button>
                    </>
                  )}
              </div>
            </Card.Content>
          </Card>
        </>
      )}
    </>
  );
};

export default Journal;
